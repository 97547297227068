import React from 'react'

import { useTranslation } from 'react-i18next'
import { Chip, Box, Grid, Typography } from '@mui/material'
import { PDFColoredIcon } from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import dayjs from 'dayjs'

import { CERTIFICATION_STATUS } from 'components/pages/AppPublish/Certification/types'
import STANDARDS from 'assets/standards/standards.json'
import { CONTROLLERS, OFFERTYPES } from 'features/Custom/serviceOnboard/types'
import { download } from 'utils/downloadUtils'
import { useFetchDocumentByTypeMutation } from 'features/Custom/appOnboard/apiSlice'
import { type CustomtableType } from '../type'
import RendertTable from '../RenderTable'

const fontStyles = {
  textTransform: 'uppercase',
  fontWeight: 500,
  letterSpacing: '.5px',
  borderRadius: '15px',
}

const Certification = (props: { appStatusData: any; offerTypeId: string }) => {
  const { t } = useTranslation()
  const [fetchDocumentByType] = useFetchDocumentByTypeMutation()

  const handleDownloadClick = async (
    documentId: string,
    documentName: string
  ) => {
    try {
      document.body.style.cursor = 'progress'
      const response = await fetchDocumentByType({
        id: props?.appStatusData?.id,
        documentId,
        controller:
          props?.offerTypeId === OFFERTYPES.APP
            ? CONTROLLERS.APP_ONBOARD
            : CONTROLLERS.SERVICE_ONBOARD,
        offerTypeId: props.offerTypeId,
      }).unwrap()
      const file = response.data
      download(file, '.pdf', documentName)
      return
    } catch (error) {
      alert(JSON.stringify(error))
    } finally {
      document.body.style.cursor = 'default'
    }
  }

  const handleCertificationStatus = (status: string | null) => {
    switch (status) {
      case CERTIFICATION_STATUS.CERTIFIED:
        return (
          <Chip
            label={t('content.appPublish.certification.certified')}
            variant="filled"
            size="small"
            sx={{
              ...fontStyles,
              color: '#486D28',
              backgroundColor: '#A6CE3966 !important',
            }}
          />
        )
      case CERTIFICATION_STATUS.CERTIFICATION_IN_PROGRESS:
        return (
          <Chip
            label={t('content.appPublish.certification.inprogress')}
            variant="outlined"
            size="small"
            sx={{
              ...fontStyles,
              color: '#4D4D4D',
              backgroundColor: '#fff !important',
              border: '1px solid #4D4D4D',
            }}
          />
        )
      case CERTIFICATION_STATUS.NO_CERTIFICATION:
        return (
          <Chip
            label={t('content.appPublish.certification.notCertified')}
            variant="filled"
            size="small"
            sx={{
              ...fontStyles,
              color: '#B62100',
              backgroundColor: '#B6210033 !important',
            }}
          />
        )
      // ! return no Certificate is status is null
      default:
        return null
    }
  }

  console.log(
    STANDARDS.filter((ele) =>
      props?.appStatusData?.certificate?.standards?.some(
        (id: { standardId: string }) => id.standardId === ele.id
      )
    )
  )

  const CertificationSubmissionDetails: CustomtableType = {
    head: [`${t('Certification status')}`, ''],
    body: [
      [
        <Box>
          {handleCertificationStatus(
            props.appStatusData?.certificate?.statusId
          )}
          {props.appStatusData?.certificate?.statusId ===
          CERTIFICATION_STATUS.CERTIFIED ? (
            <React.Fragment>
              <Box pt={1}>
                <PDFColoredIcon width={15} height={15} />
                <Typography
                  variant={'caption1' as any}
                  fontWeight={400}
                  ml={0.5}
                  sx={{
                    color: '#2484C6',
                    fontSize: '18px',
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() =>
                    handleDownloadClick(
                      props?.appStatusData?.documents?.find(
                        (ele: {
                          documentId: string
                          documentTypeId: string
                          documentName: string
                        }) => ele.documentTypeId === 'APP_CERTIFICATE'
                      )?.documentId,
                      props?.appStatusData?.documents?.find(
                        (ele: {
                          documentId: string
                          documentTypeId: string
                          documentName: string
                        }) => ele.documentTypeId === 'APP_CERTIFICATE'
                      )?.documentName
                    )
                  }
                >
                  {
                    props?.appStatusData?.documents?.find(
                      (ele: {
                        documentId: string
                        documentTypeId: string
                        documentName: string
                      }) => ele.documentTypeId === 'APP_CERTIFICATE'
                    )?.documentName
                  }
                </Typography>
                <Typography fontSize="18px" fontWeight={400} color="#000">
                  {t('content.submissionOverlay.details.expiryDate')}{' '}
                  <strong>
                    {dayjs(props.appStatusData?.certificate?.expiryDate).format(
                      'DD.MM.YYYY'
                    )}
                  </strong>
                </Typography>
              </Box>
              <Box pt={1}>
                <strong>
                  {t('content.submissionOverlay.details.selectedStandards')}
                </strong>
                <Grid container spacing={1} p={1}>
                  {STANDARDS.filter((ele) =>
                    props?.appStatusData?.certificate?.standards?.some(
                      (id: { standardId: string }) => id.standardId === ele.id
                    )
                  ).map((ele) => (
                    <Grid item xs={6}>
                      &#x2022; {ele.title}
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </React.Fragment>
          ) : null}
        </Box>,
      ],
    ],
  }

  return (
    <div className="appdetail-privacy">
      <div className="appdetail-privacy-table">
        <RendertTable data={CertificationSubmissionDetails || []} />
      </div>
    </div>
  )
}

export default Certification
