import { type CERTIFICATION_STATUS } from 'components/pages/AppPublish/Certification/types'

export const DEFAULT_VIEW = {
  id: 0,
  isFavorite: false,
  leadPictureId: '',
  imagePath: '',
  name: '',
  price: '',
  provider: '',
  shortDescription: '',
  useCases: [],
  preferenceText: 'Matching your personal preferences',
  defaultView: true,
  defaultViewLabel: 'Your favorite application will be displayed here',
  image: { id: '', src: '' },
}

export interface ApplicationListType {
  id: string
  leadPictureId?: string | undefined
  imagePath: string
  name: string | undefined
  model: PriceType
  amount: string
  price: string
  provider: string
  shortDescription: string
  useCases: string[]
  isFavorite?: boolean
  isSponsored?: boolean
  priceCategory?: PriceType
  type?: SponsoredType
  preferenceText?: string
  defaultView?: boolean
  isComingSoon?: boolean | false
  defaultViewLabel?: string
  currency?: string
  onSecondaryButtonClick?: any
  onButtonClick?: any
  addButtonClicked?: any
  image: {
    src: string
    id: string
  }
  certificateStatus?: CERTIFICATION_STATUS
}

export type PriceType =
  | 'FREE'
  | 'FIXED_PRICE'
  | 'PRICE_PER_USER'
  | 'PRICE_PER_TRANSACTION'
  | 'TIME_AND_MATERIAL'
  | 'OTHERS'

export type SponsoredType = 'RECOMMENDED' | 'NEW'

export const APPLICATION_LIST = [
  {
    id: 9,
    label: 'Cofinity-x',
    title: 'Digital Twin Aspect Debugger',
    subTitle:
      'We empower the automotive industry in its transformation to net zero and more agility within the value chains.',
    imagePath: '',
    buttonText: 'Details',
    isFavorite: false,
    preferenceText: 'Used by other users of your company',
    defaultView: false,
    defaultViewLabel: 'Your favorite application will be displayed here',
  },
  {
    id: 10,
    label: 'Cofinity-x',
    title: 'Digital Twin Aspect Debugger',
    subTitle:
      'We empower the automotive industry in its transformation to net zero and more agility within the value chains.',
    imagePath: '',
    buttonText: 'Details',
    isFavorite: false,
    preferenceText: 'Matching your personal preferences',
    defaultView: false,
    defaultViewLabel: 'Your favorite application will be displayed here',
  },
  {
    id: 11,
    label: 'Cofinity-x',
    title: 'Digital Twin Aspect Debugger',
    subTitle:
      'We empower the automotive industry in its transformation to net zero and more agility within the value chains.',
    imagePath: '',
    buttonText: 'Details',
    isFavorite: false,
    preferenceText: 'Used by other users of your company',
    defaultView: false,
    defaultViewLabel: 'Your favorite application will be displayed here',
  },
  {
    id: 12,
    label: 'Cofinity-x',
    title: 'Digital Twin Aspect Debugger',
    subTitle:
      'We empower the automotive industry in its transformation to net zero and more agility within the value chains.',
    imagePath: '',
    buttonText: 'Details',
    isFavorite: false,
    preferenceText: 'Matching your personal preferences',
    defaultView: false,
    defaultViewLabel: 'Your favorite application will be displayed here',
  },
  {
    id: 12,
    label: 'Cofinity-x',
    title: 'Digital Twin Aspect Debugger',
    subTitle:
      'We empower the automotive industry in its transformation to net zero and more agility within the value chains.',
    imagePath: '',
    buttonText: 'Details',
    isFavorite: false,
    preferenceText: 'Matching your personal preferences',
    defaultView: false,
    defaultViewLabel: 'Your favorite application will be displayed here',
  },
]
