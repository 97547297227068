import { styled } from '@mui/material/styles'

export const DataTableContainerStyled = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  gap: '20px',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
}))

export const DataTableSectionStyled = styled('div')<{ column: number }>(
  ({ theme, column }: any) => ({
    width: `calc(${100 / column}% - 40px)`,
  })
)

export const DataTableMainStyled = styled('div')(({ theme }: any) => ({
  minHeight: '222px',
  borderRadius: '10px',
  border: 'solid 1px #dedfe0',
  overflow: 'hidden',
  padding: '0 14px',
}))

export const DataTableTitleStyled = styled('h2')(({ theme }) => ({
  fontSize: '20px',
  fontWeight: 600,
  fontStyle: 'normal',
  lineHeight: 1.1,
  textAlign: 'left',
  color: '#fdb913',
}))

export const DataTableStyled = styled('table')(({ theme }) => ({
  backgroundColor: '#fff',
  width: '100%',
  borderSpacing: 0,
  overflow: 'hidden',
}))

export const DataTableRowStyled = styled('tr')(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: 1.38,
  textAlign: 'left',
  color: '#000',
}))

export const DataTableTdStyled = styled('td')(({ theme }) => ({
  padding: '16px 0',
  borderBottom: '1px solid #dedfe0',
  fontSize: '16px',
  fontWeight: 'normal',
  lineHeight: 1.38,
  textAlign: 'left',
  color: '#000',
  maxWidth: '30%',
}))

export const DataTableThStyled = styled('th')(({ theme }) => ({
  padding: '16px 16px',
  borderBottom: '1px solid #dedfe0',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: 1.38,
  textAlign: 'left',
  color: '#000',
}))
