import { type IntersectionOptions } from 'react-intersection-observer'

export const SERVICE_LABEL_IDS = {
  SERVICEDETAIL_DESCRIPTION: 'servicedetail_description',
  SERVICEDETAIL_FEATURES: 'servicedetail_features',
  SERVICEDETAIL_DOCUMENTS: 'servicedetail_document',
  SERVICEDETAIL_PRICING: 'servicedetail_pricing',
  SERVICEDETAIL_PROVIDER: 'servicedetail_provider',
}

export const NOTIFICATION_PAGE = {
  NOTIFICATION_ALL: 'notification_all',
  NOTIFICATION_GENERAL: 'notification_general',
  NOTIFICATION_APPS: 'notification_apps',
  NOTIFICATION_SERVICES: 'notification_services',
}

export const SERVICEDETAIL_TABS = [
  { label: 'Description', id: SERVICE_LABEL_IDS.SERVICEDETAIL_DESCRIPTION },
  { label: 'Benefits', id: SERVICE_LABEL_IDS.SERVICEDETAIL_FEATURES },
  { label: 'Pricing', id: SERVICE_LABEL_IDS.SERVICEDETAIL_PRICING },
  {
    label: 'Case studies',
    id: SERVICE_LABEL_IDS.SERVICEDETAIL_DOCUMENTS,
  },
  {
    label: 'Provider Information',
    id: SERVICE_LABEL_IDS.SERVICEDETAIL_PROVIDER,
  },
]

export const OBSERVER_OPTIONS: IntersectionOptions = {
  threshold: 1,
}
