import React, { useState, useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setSearchInput } from 'features/appManagement/actions'
import { Backdrop, CircularProgress } from '@mui/material'
import {
  PageLoadingTable,
  PageSnackbar,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { ShowEmptyMessage } from '../NotificationCenter/NotificationEmpty'
import {
  usePerformActionBasedOnAppStatusMutation,
  useFetchServiceQuery,
} from 'features/Custom/serviceMarketplaceAdmin/apiSlice'
import { ServiceMarketplaceDashboardColumns } from './ServiceMarketplaceDashboardColumn'
import { updateApplicationRequestSelector } from 'features/control/updates'
import ViewServiceInformationOverlay from './SubmissionOverlay'

interface ServiceProps {
  viewMarketService: boolean
  refresh: number
  searchExpr: string
  fetchHookArgs: {
    expr: string
    statusFilter: string
  }
  group: string
  filterStatus: string
  appCardNotification: boolean
  notificationType?: 'error' | 'success'
  loading: boolean
  notificationTitle: string
  notificationMessage: string
}

const initialValue = {
  viewMarketService: false,
  searchExpr: '',
  refresh: 0,
  fetchHookArgs: {
    expr: '',
    statusFilter: 'ALL',
  },
  group: 'ALL',
  filterStatus: 'ALL',
  appCardNotification: false,
  notificationType: undefined,
  loading: false,
  notificationTitle: '',
  notificationMessage: '',
}

export const ServiceMarketplaceDashboard = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [performActionBasedOnAppStatus] =
    usePerformActionBasedOnAppStatusMutation()
  const searchInputData = useSelector(updateApplicationRequestSelector)

  /**
   * @type {ServiceProps}
   * * Best Way to Manage State in React
   **/
  const [state, setState] = useReducer(
    (
      currentProperties: ServiceProps,
      updatedProperties: Partial<ServiceProps>
    ) => ({
      ...currentProperties,
      ...updatedProperties,
    }),
    initialValue
  )

  const filterView = [
    {
      buttonText: t('content.admin.registration-requests.filter.all'),
      buttonValue: 'All',
      onButtonClick: setView,
    },
    {
      buttonText: t('content.admin.registration-requests.filter.review'),
      buttonValue: 'InReview',
      onButtonClick: setView,
    },
    {
      buttonText: t('content.admin.registration-requests.filter.published'),
      buttonValue: 'Publish',
      onButtonClick: setView,
    },
  ]

  function setView(e: React.MouseEvent<HTMLInputElement>) {
    const viewValue = e.currentTarget.value
    setState({
      group: viewValue,
      refresh: Date.now(),
      filterStatus: viewValue,
    })
  }

  const handleDialogState = () => {
    setState({
      viewMarketService: !state.viewMarketService,
    })
  }

  const onValidate = (expr: string) => {
    const validateExpr = /^[ A-Za-z0-9]*$/.test(expr)
    if (validateExpr) dispatch(setSearchInput({ open: true, text: expr }))
    return validateExpr
  }

  const onActionClick = (
    serviceId: string,
    triggeredAction: string,
    hasPossibleAction: string[],
    serviceData: any
  ) => {
    switch (triggeredAction) {
      case 'PUBLISH':
      case 'REJECT':
        setState({
          loading: true,
        })
        performActionBasedOnAppStatus({ serviceId, triggeredAction })
          .unwrap()
          .then((res) => {
            setState({
              notificationType: 'success',
              notificationTitle: t(
                'content.serviceMarketplaceAdminDashboard.success.title'
              ),
              notificationMessage: t(
                'content.serviceMarketplaceAdminDashboard.success.message'
              ),
              appCardNotification: true,
              refresh: Date.now(),
            })
          })
          .catch((error) => {
            setState({
              notificationType: 'error',
              notificationTitle: t(
                'content.serviceMarketplaceAdminDashboard.error.title'
              ),
              notificationMessage: t(
                'content.serviceMarketplaceAdminDashboard.error.message'
              ),
              appCardNotification: true,
            })
          })
          .finally(() => {
            setState({
              loading: false,
            })
          })
        break
      case 'DISABLE':
      case 'DELETE':
        if (serviceData.subscriptionCount <= 0) {
          setState({
            loading: true,
          })
          performActionBasedOnAppStatus({ serviceId, triggeredAction })
            .unwrap()
            .then((res) => {
              setState({
                notificationType: 'success',
                notificationTitle: t(
                  'content.serviceMarketplaceAdminDashboard.success.title'
                ),
                notificationMessage: t(
                  'content.serviceMarketplaceAdminDashboard.success.message'
                ),
                appCardNotification: true,
                refresh: Date.now(),
              })
            })
            .catch((error) => {
              setState({
                notificationType: 'error',
                notificationTitle: t(
                  'content.serviceMarketplaceAdminDashboard.error.title'
                ),
                notificationMessage: t(
                  'content.serviceMarketplaceAdminDashboard.error.message'
                ),
                appCardNotification: true,
              })
            })
            .finally(() => {
              setState({
                loading: false,
              })
            })
          break
        } else {
          setState({
            notificationType: 'error',
            notificationTitle: 'Subscriptions:',
            notificationMessage: t(
              'content.serviceMarketplaceAdminDashboard.notificationMsg'
            ),
            appCardNotification: true,
          })
        }
    }
  }

  const columns: any = ServiceMarketplaceDashboardColumns(
    useTranslation,
    handleDialogState,
    onActionClick
  )

  useEffect(() => {
    if (onValidate(state.searchExpr)) {
      setState({
        fetchHookArgs: {
          statusFilter: state.filterStatus,
          expr: state.searchExpr,
        },
      })
    }
    // eslint-disable-next-line
  }, [state.filterStatus, state.searchExpr])

  return (
    <main className="connector-page-container">
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={state.loading}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <ViewServiceInformationOverlay
        openDialog={state.viewMarketService}
        handleOverlayClose={() => {
          setState({
            viewMarketService: !state.viewMarketService,
          })
        }}
      />
      <section>
        <div className="connector-table-container">
          <PageLoadingTable<any>
            searchExpr={state.searchExpr}
            searchInputData={searchInputData}
            toolbarVariant="searchAndFilter"
            disableRowSelectionOnClick={true}
            alignCell="start"
            rowHeight={140}
            title={t('content.edcconnector.tabletitle')}
            loadLabel={t('global.actions.loadmore')}
            fetchHook={useFetchServiceQuery}
            fetchHookArgs={state.fetchHookArgs}
            fetchHookRefresh={state.refresh}
            onSearch={(expr: string) => {
              if (!onValidate(expr)) return
              setState({
                refresh: Date.now(),
                searchExpr: expr,
              })
            }}
            searchDebounce={1000}
            getRowId={(row: { [key: string]: string }) => row.id}
            columns={columns}
            hasBorder={false}
            searchPlaceholder={t(
              'content.serviceMarketplaceAdminDashboard.searchfielddefaulttext'
            )}
            defaultFilter={state.group}
            filterViews={filterView}
            sx={{
              verticalAlign: 'middle',
              '.MuiDataGrid-cell': {
                outline: 'none !important',
                border: 'none !important',
                ':focus': {
                  outline: 'none !important',
                  border: 'none !important',
                },
              },
            }}
          />
        </div>
      </section>
      {state.appCardNotification && (
        <PageSnackbar
          open={state.appCardNotification}
          onCloseNotification={() => {
            setState({
              appCardNotification: false,
            })
          }}
          severity={state.notificationType}
          title={state.notificationTitle}
          description={state.notificationMessage}
          autoClose={true}
        />
      )}
    </main>
  )
}
