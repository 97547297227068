export type useCaseItems = {
  id: string
  label: string
}

export enum AppStatusType {
  IN_REVIEW = 'IN_REVIEW',
  PUBLISHED = 'PUBLISHED',
  REJECTED = 'REJECTED',
  DRAFT = 'DRAFT',
}

export enum TableFilters {
  ALL = 'ALL',
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export enum PossibleActionTypes {
  EDIT = 'EDIT',
  DISABLE = 'DISABLE',
  DELETE = 'DELETE',
}

export type appAdminResponseType = {
  id?: string
  title: string
  logo: string
  provider: string
  useCases: useCaseItems[] | []
  dateSubmitted: Date
  datePublished?: Date
  noOfSubscription: string | number
  status: AppStatusType
  possibleActionsForMarketAdmin: PossibleActionTypes[]
}

export declare type PaginFetchArgs = {
  page: number
  size: number
  sorting?: string
  offerStatusIdFilter?: string
}

export type PaginationData = {
  totalElements: number
  page: number
}

export declare type PaginMeta = {
  totalElements: number
  totalPages: number
  page: number
  contentSize: number
}

export declare type PaginResult<T> = {
  meta: PaginMeta
  content: T[]
}

export declare type AppAdminResponse = {
  meta: PaginationData
  content: Array<appAdminResponseType>
}

export type SearchParams = {
  readonly name?: string
  readonly page: number
  readonly size: number
}

export type AppAdminInitialState = {
  paginationData: PaginationData
  appList: Array<appAdminResponseType>
  loading: boolean
  error: string
}
