import {
  Box,
  Typography,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import cx_logo from '../../../../../assets/logo/cx-logo-short.svg'

const IsMember = ({
  isGrayScale,
  label,
}: {
  isGrayScale: boolean
  label: string
}) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" gap="10px">
      <Box
        component="img"
        src={cx_logo}
        alt="membershipFlag"
        sx={{
          width: 23,
          filter: `grayscale(${isGrayScale ? 1 : 0})`,
        }}
      />
      <Typography color="#4d4d4f" fontSize="1.125rem" fontWeight="500">
        {label}
      </Typography>
    </Box>
  )
}

export { IsMember }
