import React, { useEffect, useState, useRef } from 'react'

import {
  Paper,
  Box,
  Grid,
  FormControl,
  TextField,
  InputLabel,
  Autocomplete,
  Tooltip,
  IconButton,
  Backdrop,
  CircularProgress,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import { InfoOutlined } from '@mui/icons-material'
import {
  Button,
  Chip,
  Divider,
  PageNotifications,
  PageSnackbar,
  Typography,
  type UploadFileStatus,
  UploadStatus,
  theme,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { useDispatch, useSelector } from 'react-redux'
import { QueryStatus } from '@reduxjs/toolkit/dist/query'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

import { ConnectorFormInputField } from 'components/pages/AppPublish/OverView'
import { type LanguageObject, LANGUAGE_SUPPORTED } from 'utils/utilities'
import { Dropzone, type DropzoneFile } from 'components/shared/basic/Dropzone'
import Patterns from 'types/Patterns'
import { ENTITYSTATE } from 'features/Custom/appOnboard/types'
import {
  useFetchServiceStatusQuery,
  useUpdateDocumentUploadMutation,
  useDeleteDocumentMutation,
  useUpdateDescriptionBenefitsMutation,
} from 'features/Custom/serviceOnboard/apiSlice'
import {
  increment,
  serviceIdSelector,
  serviceStatusDataSelector,
  decrement,
  currentActiveStep,
} from 'features/Custom/serviceOnboard/slice'
import { setServiceStatus } from 'features/Custom/serviceOnboard/actions'
import { COFINITY_SERVICE_ONBOARDING_DOCUMENTATION_URL } from 'types/Constants'
import { ServiceTypeIdsEnum } from 'features/serviceManagement/apiSlice'
import { SIZE_LIMITS } from 'types/Config'

type ServiceFeatureType = {
  serviceFeaturesId?: string
  serviceKeyFeatureTitle: string
  serviceKeyFeatureDescription: LanguageObject[]
  entityState: string
}
interface DescriptionAndBenefitsProps {
  providerLogo: DropzoneFile | any
  descriptions: LanguageObject[]
  highlightImages: DropzoneFile[] | any
  images: DropzoneFile[] | any
  serviceFeaturesSummary: LanguageObject[]
  serviceFeatures: ServiceFeatureType[]
  videoLink: string
}

const DescriptionAndBenefits = ({
  skipTechnicalIntegrationStep,
}: {
  skipTechnicalIntegrationStep: (val: boolean) => void
}) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const serviceId = useSelector(serviceIdSelector)
  const activePage = useSelector(currentActiveStep)
  const [
    updateDescriptionBenefits,
    { status: descriptionBenefitsStatus, fulfilledTimeStamp, startedTimeStamp },
  ] = useUpdateDescriptionBenefitsMutation()
  const [deleteDocument, { status: documentDeleteStatus }] =
    useDeleteDocumentMutation()
  const [updateDocumentUpload, { status: documentUploadStatus }] =
    useUpdateDocumentUploadMutation()
  const serviceStatusData = useSelector(serviceStatusDataSelector)
  const [selectedLanguage, setSelectedLanguage] = useState<string[]>(['en'])
  const [appPageSnackbar, setAppPageSnackbar] = useState<boolean>(false)
  const [appPageNotification, setAppPageNotification] = useState(false)
  const [selectedFeaturesSummaryLanguage, setSelectedFeaturesSummaryLanguage] =
    useState<string[]>(['en'])
  const [
    selectedKeyFeatureDescriptionLanguage,
    setSelectedKeyFeatureDescriptionLanguage,
  ] = useState<string[]>(['en'])
  const [visibleDropdown, setVisibleDropdown] = useState<boolean>(false)
  const [visibleDropdownSummary, setVisibleDropdownSummary] =
    useState<boolean>(false)
  const [visibleDropdownKeyBenefits, setVisibleDropdownKeyBenefits] =
    useState<boolean>(false)
  const fixedLanguage = [LANGUAGE_SUPPORTED[0]]
  const { data: fetchServiceStatus, refetch } = useFetchServiceStatusQuery(
    serviceId ?? '',
    {
      refetchOnMountOrArgChange: true,
    }
  )

  const defaultValues = {
    providerLogo:
      serviceStatusData?.documents.filter(
        (eachDoc) => eachDoc.documentTypeId === 'APP_LOGO'
      ).length > 0
        ? {
            id: serviceStatusData?.documents.filter(
              (eachDoc) => eachDoc.documentTypeId === 'APP_LOGO'
            )[0]?.documentId,
            name: serviceStatusData?.documents.filter(
              (eachDoc) => eachDoc.documentTypeId === 'APP_LOGO'
            )[0]?.documentName,
          }
        : null,
    descriptions: LANGUAGE_SUPPORTED.map((language) => {
      return {
        [language]: {
          shortDescription: '',
          longDescription:
            serviceStatusData?.longDescription?.filter(
              (appStatus: any) => appStatus.languageCode === language
            )[0]?.longDescription ?? '',
        },
      }
    }),
    serviceFeaturesSummary: LANGUAGE_SUPPORTED.map((language) => {
      return {
        [language]: {
          shortDescription: '',
          longDescription:
            serviceStatusData?.feature[0]?.localizedSummary?.values?.filter(
              (appStatus: any) => appStatus.langCode === language
            )[0]?.value ?? '',
        },
      }
    }),
    serviceFeatures: serviceStatusData?.feature[0]?.keyFeatures?.map(
      (keyfeature: any) => {
        return {
          serviceFeaturesId: keyfeature?.id,
          serviceKeyFeatureTitle: keyfeature?.title ?? '',
          serviceKeyFeatureDescription: LANGUAGE_SUPPORTED.map((language) => {
            return {
              [language]: {
                shortDescription:
                  keyfeature?.localizedSummary?.values?.find(
                    (summery: any) => summery.langCode === language
                  )?.value ?? '',
                longDescription:
                  keyfeature?.localizedSummary?.values?.find(
                    (summery: any) => summery.langCode === language
                  )?.value ?? '',
              },
            }
          }),
          entityState: keyfeature?.entityState && ENTITYSTATE.UPDATED,
        }
      }
    ) ?? [
      {
        serviceFeaturesId: '00000000-0000-0000-0000-000000000000',
        serviceKeyFeatureTitle: '',
        serviceKeyFeatureDescription: LANGUAGE_SUPPORTED.map((language) => {
          return {
            [language]: {
              shortDescription: '',
              longDescription: '',
            },
          }
        }),
        entityState: ENTITYSTATE.ADDED,
      },
    ],
    highlightImages:
      serviceStatusData?.highlightedImages?.map(
        (eachValidDoc: any) => eachValidDoc
      ) || [],
    images:
      serviceStatusData?.images?.map((eachValidDoc: any) => eachValidDoc) || [],
    videoLink:
      serviceStatusData?.feature[0]?.videoLink &&
      serviceStatusData?.feature[0]?.videoLink?.split('https://')?.length > 1
        ? serviceStatusData?.feature[0]?.videoLink?.split('https://')[1]
        : serviceStatusData?.feature[0]?.videoLink ?? '',
  }

  const {
    handleSubmit,
    getValues,
    control,
    resetField,
    trigger,
    setValue,
    clearErrors,
    formState: { errors, isDirty, submitCount },
  } = useForm<DescriptionAndBenefitsProps>({
    mode: 'onChange',
    defaultValues,
  })

  const { fields, append, remove, update } = useFieldArray({
    name: 'serviceFeatures',
    rules: { maxLength: 3, minLength: 1 },
    control,
  })

  const defaultAppLogo = defaultValues?.providerLogo
  const defaultImages = defaultValues?.images
  const defaultHighlightImages = defaultValues?.highlightImages

  useEffect(() => {
    async function fetchReqData() {
      if (
        serviceStatusData?.longDescription?.filter(
          (appStatus: any) => appStatus.languageCode === 'de'
        )[0]?.longDescription.length >= 10
      ) {
        const newLanguages = [...fixedLanguage, 'de']
        setSelectedLanguage(newLanguages)
      }
      if (
        serviceStatusData?.feature[0]?.localizedSummary?.values?.filter(
          (appStatus: any) => appStatus.langCode === 'de'
        )[0]?.value.length >= 10
      ) {
        const newLanguages = [...fixedLanguage, 'de']
        setSelectedFeaturesSummaryLanguage(newLanguages)
      }
      if (
        serviceStatusData?.feature[0]?.keyFeatures[0]?.localizedSummary?.values?.filter(
          (appStatus: any) => appStatus.langCode === 'de'
        )[0]?.value.length >= 10
      ) {
        const newLanguages = [...fixedLanguage, 'de']
        setSelectedKeyFeatureDescriptionLanguage(newLanguages)
      }
      if (defaultAppLogo) {
        const documentId = defaultAppLogo?.id
        const setFileStatus = (status: UploadFileStatus) => {
          setValue('providerLogo', {
            id: documentId,
            name: defaultAppLogo.name,
            status,
          } as any)
        }
        setFileStatus(UploadStatus.UPLOAD_SUCCESS)
        clearErrors('providerLogo')
        await trigger('providerLogo')
      }
      if (defaultImages) {
        const images = defaultImages?.map((item: any) => {
          return {
            id: item,
            name: `APP_IMAGE_${item}`,
            status: UploadStatus.UPLOAD_SUCCESS,
          }
        })
        if (images.length > 0) {
          const setFileStatus = async (
            fileIndex: number,
            status: UploadFileStatus
          ) => {
            const nextFiles = images
            nextFiles[fileIndex] = {
              id: images[fileIndex].id,
              name: images[fileIndex].name,
              status,
            }
            setValue('images', nextFiles)
            clearErrors('images')
            await trigger('images')
          }

          for (let fileIndex = 0; fileIndex < images.length; fileIndex++) {
            setFileStatus(fileIndex, UploadStatus.UPLOAD_SUCCESS)
          }
        }
      }
      if (defaultHighlightImages) {
        const hightLightImage = defaultHighlightImages?.map((item: any) => {
          return {
            id: item,
            name: `APP_HIGHLIGHT_IMAGE_${item}`,
            status: UploadStatus.UPLOAD_SUCCESS,
          }
        })
        if (hightLightImage.length > 0) {
          const setFileStatus = async (
            fileIndex: number,
            status: UploadFileStatus
          ) => {
            const nextFiles = hightLightImage
            nextFiles[fileIndex] = {
              id: hightLightImage[fileIndex].id,
              name: hightLightImage[fileIndex].name,
              status,
            }
            setValue('highlightImages', nextFiles)
            clearErrors('highlightImages')
            await trigger('highlightImages')
          }

          for (
            let fileIndex = 0;
            fileIndex < hightLightImage.length;
            fileIndex++
          ) {
            setFileStatus(fileIndex, UploadStatus.UPLOAD_SUCCESS)
          }
        }
      }
    }
    Promise.all([fetchReqData()])
  }, [serviceStatusData])

  useEffect(() => {
    if (
      fulfilledTimeStamp !== undefined &&
      startedTimeStamp !== undefined &&
      fulfilledTimeStamp > startedTimeStamp
    ) {
      refetch()
    } else {
      dispatch(setServiceStatus(fetchServiceStatus))
    }
    skipTechnicalIntegrationStep(
      !fetchServiceStatus?.serviceTypeIds?.includes(
        ServiceTypeIdsEnum.DATASPACE_SERVICE
      )
    )
  }, [
    dispatch,
    fetchServiceStatus,
    descriptionBenefitsStatus,
    skipTechnicalIntegrationStep,
  ])

  const uploadDocumentApi = (
    serviceId: string,
    documentTypeId: string,
    file: any
  ) => {
    const data = {
      serviceId,
      documentTypeId,
      body: { file },
    }

    return updateDocumentUpload(data).unwrap()
  }

  const uploadAppLogo = async (files: any) => {
    const value = files
    console.log(files)
    if (value) {
      setValue('providerLogo', {
        name: value.name,
        size: value.size,
        status: UploadStatus.UPLOADING,
      } as DropzoneFile)

      await uploadDocumentApi(serviceId, 'APP_LOGO', value)
        .then((res) => {
          console.log('res', res)
          setValue('providerLogo', {
            name: value.name,
            size: value.size,
            status: UploadStatus.UPLOAD_SUCCESS,
          } as DropzoneFile)
        })
        .catch((error) => {
          console.log(error)
          setValue('providerLogo', {
            name: value.name,
            size: value.size,
            status: UploadStatus.UPLOAD_ERROR,
          } as DropzoneFile)
        })
    }
  }

  const uploadHighlightImages = async (files: any) => {
    const value = files
    if (value.length > 0) {
      const setFileStatus = (fileIndex: number, status: UploadFileStatus) => {
        const nextFiles = [...getValues().highlightImages] as any[]
        nextFiles[fileIndex] = {
          name: value[fileIndex].name,
          size: value[fileIndex].size,
          status,
        }
        setValue('highlightImages', nextFiles as any)
      }

      for (let fileIndex = 0; fileIndex < value.length; fileIndex++) {
        setFileStatus(fileIndex, UploadStatus.UPLOADING)
        await uploadDocumentApi(
          serviceId,
          'APP_HIGHLIGHTED_IMAGE',
          value[fileIndex]
        )
          .then(() => {
            setFileStatus(fileIndex, UploadStatus.UPLOAD_SUCCESS)
          })
          .catch(() => {
            setFileStatus(fileIndex, UploadStatus.UPLOAD_SUCCESS)
          })
      }
    }
  }

  const uploadImages = async (files: any) => {
    const value = files
    if (value.length > 0) {
      const setFileStatus = (fileIndex: number, status: UploadFileStatus) => {
        const nextFiles = [...getValues().images] as any[]
        nextFiles[fileIndex] = {
          name: value[fileIndex].name,
          size: value[fileIndex].size,
          status,
        }
        setValue('images', nextFiles as any)
      }

      for (let fileIndex = 0; fileIndex < value.length; fileIndex++) {
        setFileStatus(fileIndex, UploadStatus.UPLOADING)
        await uploadDocumentApi(serviceId, 'APP_IMAGE', value[fileIndex])
          .then(() => {
            setFileStatus(fileIndex, UploadStatus.UPLOAD_SUCCESS)
          })
          .catch(() => {
            setFileStatus(fileIndex, UploadStatus.UPLOAD_SUCCESS)
          })
      }
    }
  }

  const handleBenefitsSubmit = async (
    data: DescriptionAndBenefitsProps,
    buttonLabel: string
  ) => {
    const isFieldValidate = await trigger([
      'descriptions',
      'highlightImages',
      'images',
      'providerLogo',
      'serviceFeatures',
      'serviceFeaturesSummary',
      'videoLink',
    ])
    if (isFieldValidate) {
      onSubmit(data, buttonLabel)
    }
  }

  const onSubmit = async (
    data: DescriptionAndBenefitsProps,
    buttonLabel: string
  ) => {
    const saveData = {
      videoLink: data.videoLink,
      featureSummary: '',
      longDescriptions: LANGUAGE_SUPPORTED.map((language, index) => {
        return {
          languageCode: language,
          longDescription: data.descriptions[index][language].longDescription,
          shortDescription: serviceStatusData?.longDescription?.filter(
            (appStatus: any) => appStatus.languageCode === language
          )[0]?.shortDescription,
        }
      }),
      keyFeatures: data.serviceFeatures.map((eachFeature) => {
        const {
          entityState,
          serviceKeyFeatureDescription,
          serviceKeyFeatureTitle,
          serviceFeaturesId,
        } = eachFeature
        return {
          id: serviceFeaturesId,
          title: serviceKeyFeatureTitle,
          shortDescription: serviceKeyFeatureDescription[0].en.shortDescription,
          localizedSummary: {
            values: LANGUAGE_SUPPORTED.map((language, index) => {
              return {
                langCode: language,
                value:
                  serviceKeyFeatureDescription[index][language]
                    .shortDescription,
              }
            }),
          },
          entityState:
            entityState === ENTITYSTATE.DELETED
              ? entityState
              : buttonLabel === 'save' && submitCount > 0
              ? ENTITYSTATE.UPDATED
              : entityState,
        }
      }),
      localizedSummary: {
        values: LANGUAGE_SUPPORTED.map((language, index) => {
          return {
            langCode: language,
            value: data.serviceFeaturesSummary[index][language].longDescription,
          }
        }),
      },
    }
    if (isDirty) {
      try {
        await updateDescriptionBenefits({
          body: saveData,
          serviceId,
        })
          .unwrap()
          .then(() => {
            Promise.all([
              uploadAppLogo(getValues().providerLogo),
              uploadHighlightImages(getValues().highlightImages),
              uploadImages(getValues().images),
            ]).finally(() => {
              if (buttonLabel === 'save') {
                refetch()
                data.serviceFeatures.forEach((_eachFeature, index) => {
                  resetField(`serviceFeatures.${index}.entityState`, {
                    defaultValue: ENTITYSTATE.UPDATED,
                  })
                })
              }
            })
            buttonLabel === 'saveAndProceed' && dispatch(increment())
            buttonLabel === 'save' && setAppPageSnackbar(true)
          })
      } catch (error: any) {
        setAppPageNotification(true)
      }
    } else {
      buttonLabel === 'saveAndProceed' && dispatch(increment())
      buttonLabel === 'save' && setAppPageSnackbar(true)
    }
  }
  const onBackIconClick = () => {
    dispatch(setServiceStatus(fetchServiceStatus))
    dispatch(decrement())
  }

  return (
    <Paper
      elevation={5}
      style={{
        borderRadius: '30px',

        border: '1px solid  rgba(250, 250, 250, 1)',
      }}
    >
      <Box className="certification__item-box">
        <div className="app-market-card">
          <Box
            mt={4}
            sx={{
              backgroundColor: theme.palette.stepper.stepCurrent,
              borderRadius: '50%',
              width: '30px',
              height: '30px',
              color: '#fff',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Typography
              variant="body1"
              fontSize="14px"
              color="#fff"
              sx={{
                margin: 'auto',
                paddingTop: '4px',
                width: 'fit-content',
              }}
            >
              {activePage}
            </Typography>
          </Box>
          <Typography variant="h3" mt={5} mb={4} align="center">
            {t('content.servicePublish.descriptionAndBenefits.headerTitle')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={11} sx={{ mr: 'auto', ml: 'auto' }}>
              <Typography variant="body2" align="center">
                {t(
                  'content.servicePublish.descriptionAndBenefits.headerDescription'
                )}
              </Typography>
            </Grid>
          </Grid>
          <form>
            <div className="header-description">
              <div className="form-field">
                <InputLabel sx={{ mt: 3, display: 'flex' }}>
                  <strong
                    style={{
                      color: errors.providerLogo ? '#c02424' : 'inherit',
                    }}
                  >
                    {t(
                      'content.servicePublish.descriptionAndBenefits.serviceLogo'
                    ) + '*'}
                  </strong>
                  <Tooltip
                    title={`${t(
                      'content.servicePublish.descriptionAndBenefits.serviceLogoItxt'
                    )}`}
                    arrow
                  >
                    <IconButton
                      sx={{ color: '#939393', marginLeft: 'auto' }}
                      size="small"
                    >
                      <InfoOutlined />
                    </IconButton>
                  </Tooltip>
                </InputLabel>
                <ConnectorFormInputField
                  {...{
                    control,
                    trigger,
                    errors,
                    name: 'providerLogo',
                    type: 'dropzone',
                    acceptFormat: {
                      'image/png': [],
                      'image/jpeg': [],
                    },
                    maxFilesToUpload: 1,
                    maxFileSize: SIZE_LIMITS.FILESIZE_1MB,
                    rules: {
                      required: {
                        value: true,
                      },
                    },
                  }}
                  handleDelete={async (documentId: string) => {
                    /* Making this as default behaviour Until deleteDocument starts Working. */
                    setValue('providerLogo', null)
                    try {
                      await deleteDocument(documentId).unwrap()
                    } catch {
                      /* empty */
                    }
                  }}
                />
                {errors?.providerLogo?.type === 'required' && (
                  <Typography variant="body2" className="file-error-msg">
                    {t(
                      'content.servicePublish.descriptionAndBenefits.serviceLogoRequired'
                    )}
                  </Typography>
                )}
              </div>
              {LANGUAGE_SUPPORTED.filter((language) =>
                selectedLanguage.some((selected) => selected === language)
              ).map((language, index) => {
                return (
                  <div className="form-field" key={index}>
                    <InputLabel sx={{ mt: 3, display: 'flex' }}>
                      <strong
                        style={{
                          color: errors?.descriptions?.[index]?.[language]
                            ?.longDescription
                            ? '#c02424'
                            : 'inherit',
                        }}
                      >
                        {t(
                          `content.servicePublish.descriptionAndBenefits.longDescription${language}`
                        )}{' '}
                        {'*'}
                      </strong>
                    </InputLabel>
                    <ConnectorFormInputField
                      {...{
                        control,
                        trigger,
                        errors,
                        name: `descriptions.${index}.${language}.longDescription` as const,
                        type: 'input',
                        textarea: true,
                        placeholder: `${t(
                          'content.servicePublish.descriptionAndBenefits.longDescriptionPlaceholder'
                        )}`,
                        rules: {
                          required: {
                            value: language === 'en' ? true : false,
                            message: `${t(
                              `content.servicePublish.descriptionAndBenefits.longDescription${language}`
                            )} ${t(
                              'content.appPublish.appReleaseForm.isMandatory'
                            )}`,
                          },
                          minLength: {
                            value: 10,
                            message: `${t(
                              'content.appPublish.appReleaseForm.minimum'
                            )} 10 ${t(
                              'content.appPublish.appReleaseForm.charactersRequired'
                            )}`,
                          },
                          pattern: {
                            value:
                              language === 'en'
                                ? Patterns.appMarketCard.longDescriptionEN
                                : Patterns.appMarketCard.longDescriptionDE,
                            message: `${t(
                              'content.appPublish.appReleaseForm.validCharactersIncludes'
                            )} ${
                              language === 'en'
                                ? 'a-zA-Z0-9 !?@&#\'"()_-=/*.,;:'
                                : 'a-zA-ZÀ-ÿ0-9 !?@&#\'"()_-=/*.,;:'
                            }`,
                          },
                          maxLength: {
                            value: 450,
                            message: `${t(
                              'content.appPublish.appReleaseForm.maximum'
                            )} 450 ${t(
                              'content.appPublish.appReleaseForm.charactersAllowed'
                            )}`,
                          },
                        },
                      }}
                    />
                    {errors &&
                      errors?.descriptions?.[index]?.[language]?.longDescription
                        ?.type === 'required' && (
                        <Typography
                          variant="body2"
                          className="file-error-msg"
                          align="left"
                        >
                          {t(
                            `content.servicePublish.descriptionAndBenefits.longDescription${language}`
                          )}{' '}
                          {t('content.appPublish.appReleaseForm.isMandatory')}
                        </Typography>
                      )}
                    {errors &&
                      errors?.descriptions?.[index]?.[language]?.longDescription
                        ?.type === 'minLength' && (
                        <Typography
                          variant="body2"
                          className="file-error-msg"
                          align="left"
                        >
                          {t('content.appPublish.appReleaseForm.minimum')} 10
                          {t(
                            'content.appPublish.appReleaseForm.charactersRequired'
                          )}
                        </Typography>
                      )}
                    {errors &&
                      errors?.descriptions?.[index]?.[language]?.longDescription
                        ?.type === 'maxLength' && (
                        <Typography
                          variant="body2"
                          className="file-error-msg"
                          align="left"
                        >
                          {t('content.appPublish.appReleaseForm.maximum')} 450
                          {t(
                            'content.appPublish.appReleaseForm.charactersAllowed'
                          )}
                        </Typography>
                      )}
                    <Typography
                      variant="body2"
                      className="form-field"
                      align="right"
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      {getValues().descriptions[index]?.[language]
                        ?.longDescription?.length + '/450'}
                    </Typography>
                  </div>
                )
              })}
              <div className="form-field">
                <InputLabel
                  id="demo-multiple-name-label"
                  onClick={() => {
                    setVisibleDropdown(!visibleDropdown)
                  }}
                >
                  <strong
                    style={{
                      color: visibleDropdown ? '#E34C92' : '#4D4D4F',
                    }}
                  >
                    {t(
                      'content.servicePublish.descriptionAndBenefits.addlongdescription'
                    )}
                  </strong>
                </InputLabel>
                <FormControl sx={{ m: 1, width: '100%' }}>
                  {visibleDropdown && (
                    <Autocomplete
                      multiple
                      fullWidth
                      id="fixed-tags-demo"
                      value={selectedLanguage}
                      onChange={(event, newValue) => {
                        setSelectedLanguage([
                          ...fixedLanguage,
                          ...newValue.filter(
                            (eachLan) => fixedLanguage.indexOf(eachLan) === -1
                          ),
                        ])
                        LANGUAGE_SUPPORTED.filter((newLanguage) =>
                          newValue.every((lang) => lang !== newLanguage)
                        ).forEach((language) => {
                          const index = LANGUAGE_SUPPORTED.indexOf(language)
                          resetField(
                            `descriptions.${index}.${language}.longDescription`
                          )
                          setValue(
                            `descriptions.${index}.${language}.longDescription`,
                            '' as string as never
                          )
                        })
                      }}
                      options={LANGUAGE_SUPPORTED}
                      getOptionLabel={(option) => option}
                      renderTags={(tagValue, getTagProps) => {
                        return tagValue.map(
                          (option, index) => null
                          // eslint-disable-next-line react/jsx-key
                          // <Chip
                          //   label={option}
                          //   size="small"
                          //   color="primary"
                          //   {...getTagProps({ index })}
                          //   disabled={fixedLanguage.indexOf(option) !== -1}
                          //   deleteIcon={<CloseIcon />}
                          // />
                        )
                      }}
                      style={{ width: '100%' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t(
                            'content.servicePublish.avalibleLanguages'
                          )}
                          variant="standard"
                        />
                      )}
                    />
                  )}
                </FormControl>
              </div>
              <div className="form-field">
                <InputLabel sx={{ mt: 3, display: 'flex' }}>
                  <strong
                    style={{
                      color: errors?.highlightImages ? '#c02424' : 'inherit',
                    }}
                  >
                    {t(
                      'content.servicePublish.descriptionAndBenefits.highlightedImages'
                    ) + ' *'}
                  </strong>
                  <Tooltip
                    title={`${t(
                      'content.servicePublish.descriptionAndBenefits.addImagesItxt'
                    )}`}
                    arrow
                  >
                    <IconButton
                      sx={{ color: '#939393', marginLeft: 'auto' }}
                      size="small"
                    >
                      <InfoOutlined />
                    </IconButton>
                  </Tooltip>
                </InputLabel>
                <Controller
                  name="highlightImages"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: `${t(
                        'content.servicePublish.descriptionAndBenefits.filerequired'
                      )}`,
                    },
                  }}
                  render={({
                    field: { onChange: reactHookFormOnChange, value },
                  }) => {
                    return (
                      <Dropzone
                        files={value}
                        onChange={async (files, addedFiles, deletedFiles) => {
                          setValue('highlightImages', files)
                          if (files.length === 0) {
                            resetField('highlightImages')
                          }
                          if (deletedFiles?.length) {
                            await Promise.all(
                              deletedFiles?.map(async (eachDoc: any) => {
                                await deleteDocument(eachDoc.id).unwrap()
                              })
                            )
                          }
                          reactHookFormOnChange(files)
                          //Correct binded Data App Logo will be change
                          trigger('highlightImages')
                          // addedFiles && uploadHighlightImages(files)
                        }}
                        acceptFormat={{
                          'image/png': [],
                          'image/jpeg': [],
                        }}
                        maxFilesToUpload={3}
                        maxFileSize={819200}
                      />
                    )
                  }}
                />
                {errors?.highlightImages?.type === 'required' && (
                  <Typography variant="body2" className="file-error-msg">
                    {t(
                      'content.appPublish.appReleaseForm.fileUploadIsMandatory'
                    )}
                  </Typography>
                )}
                <Typography variant="body2" mt={3} sx={{ fontWeight: 'bold' }}>
                  {t('content.appPublish.appReleaseForm.note')}
                </Typography>
                <Typography variant="body2" mb={3}>
                  {t('content.appPublish.appReleaseForm.max3Images')}
                </Typography>
              </div>
              <Typography variant="h3" mt={5} mb={2}>
                {t(
                  'content.servicePublish.descriptionAndBenefits.serviceBenifit'
                )}
              </Typography>
              {LANGUAGE_SUPPORTED.filter((language) =>
                selectedFeaturesSummaryLanguage.some(
                  (selected) => selected === language
                )
              ).map((language, index) => {
                return (
                  <div className="form-field">
                    <InputLabel sx={{ mt: 3, display: 'flex' }}>
                      <strong
                        style={{
                          color: errors?.serviceFeaturesSummary?.[index]?.[
                            language
                          ]?.longDescription
                            ? '#c02424'
                            : 'inherit',
                        }}
                      >
                        {t(
                          'content.servicePublish.descriptionAndBenefits.benifitSummary'
                        ) + ' *'}
                      </strong>
                    </InputLabel>
                    <ConnectorFormInputField
                      {...{
                        control,
                        trigger,
                        errors,
                        name: `serviceFeaturesSummary.${index}.${language}.longDescription` as const,
                        type: 'input',
                        textarea: true,
                        placeholder: `${t(
                          'content.servicePublish.descriptionAndBenefits.benifitSummaryPlaceholder'
                        )}`,
                        rules: {
                          required: {
                            value: language === 'en' ? true : false,
                            message: `${t(
                              'content.servicePublish.descriptionAndBenefits.benifitSummary'
                            )} ${t(
                              'content.appPublish.appReleaseForm.isMandatory'
                            )}`,
                          },
                          minLength: {
                            value: 10,
                            message: `${t(
                              'content.appPublish.appReleaseForm.minimum'
                            )} 10 ${t(
                              'content.appPublish.appReleaseForm.charactersRequired'
                            )}`,
                          },
                          pattern: {
                            value:
                              language === 'en'
                                ? Patterns.appMarketCard.longDescriptionEN
                                : Patterns.appMarketCard.longDescriptionDE,
                            message: `${t(
                              'content.appPublish.appReleaseForm.validCharactersIncludes'
                            )} ${
                              language === 'en'
                                ? 'a-zA-Z0-9 !?@&#\'"()_-=/*.,;:'
                                : 'a-zA-ZÀ-ÿ0-9 !?@&#\'"()_-=/*.,;:'
                            }`,
                          },
                          maxLength: {
                            value: 450,
                            message: `${t(
                              'content.appPublish.appReleaseForm.maximum'
                            )} 450 ${t(
                              'content.appPublish.appReleaseForm.charactersAllowed'
                            )}`,
                          },
                        },
                      }}
                    />
                    {errors &&
                      errors?.serviceFeaturesSummary?.[index]?.[language]
                        ?.longDescription?.type === 'required' && (
                        <Typography
                          variant="body2"
                          className="file-error-msg"
                          align="left"
                        >
                          {t(
                            'content.servicePublish.descriptionAndBenefits.benifitSummary'
                          )}{' '}
                          {t('content.appPublish.appReleaseForm.isMandatory')}
                        </Typography>
                      )}
                    {errors &&
                      errors?.serviceFeaturesSummary?.[index]?.[language]
                        ?.longDescription?.type === 'minLength' && (
                        <Typography
                          variant="body2"
                          className="file-error-msg"
                          align="left"
                        >
                          {t('content.appPublish.appReleaseForm.minimum')} 10
                          {t(
                            'content.appPublish.appReleaseForm.charactersRequired'
                          )}
                        </Typography>
                      )}
                    {errors &&
                      errors?.serviceFeaturesSummary?.[index]?.[language]
                        ?.longDescription?.type === 'maxLength' && (
                        <Typography
                          variant="body2"
                          className="file-error-msg"
                          align="left"
                        >
                          {t('content.appPublish.appReleaseForm.maximum')} 450
                          {t(
                            'content.appPublish.appReleaseForm.charactersAllowed'
                          )}
                        </Typography>
                      )}
                    <Typography
                      variant="body2"
                      className="form-field"
                      align="right"
                      style={{
                        fontWeight: 600,
                      }}
                    >
                      {getValues().serviceFeaturesSummary[index]?.[language]
                        ?.longDescription?.length + '/450'}
                    </Typography>
                  </div>
                )
              })}
              <div className="form-field">
                <InputLabel
                  id="demo-multiple-name-label"
                  onClick={() => {
                    setVisibleDropdownSummary(!visibleDropdownSummary)
                  }}
                >
                  <strong
                    style={{
                      color: visibleDropdownSummary ? '#E34C92' : '#4D4D4F',
                    }}
                  >
                    {t(
                      'content.servicePublish.descriptionAndBenefits.addBenefitsSummaryOtherLang'
                    )}
                  </strong>
                </InputLabel>
                <FormControl sx={{ m: 1, width: '100%' }}>
                  {visibleDropdownSummary && (
                    <Autocomplete
                      multiple
                      fullWidth
                      id="fixed-tags-demo"
                      value={selectedFeaturesSummaryLanguage}
                      onChange={(event, newValue) => {
                        setSelectedFeaturesSummaryLanguage([
                          ...fixedLanguage,
                          ...newValue.filter(
                            (eachLan) => fixedLanguage.indexOf(eachLan) === -1
                          ),
                        ])
                        LANGUAGE_SUPPORTED.filter((newLanguage) =>
                          newValue.every((lang) => lang !== newLanguage)
                        ).forEach((language) => {
                          const index = LANGUAGE_SUPPORTED.indexOf(language)
                          resetField(
                            `serviceFeaturesSummary.${index}.${language}.longDescription`
                          )
                          setValue(
                            `serviceFeaturesSummary.${index}.${language}.longDescription`,
                            '' as string as never
                          )
                        })
                      }}
                      options={LANGUAGE_SUPPORTED}
                      getOptionLabel={(option) => option}
                      renderTags={(tagValue, getTagProps) => {
                        return tagValue.map(
                          (option, index) => null
                          // eslint-disable-next-line react/jsx-key

                          // <Chip
                          //   label={option}
                          //   size="small"
                          //   color="primary"
                          //   {...getTagProps({ index })}
                          //   disabled={fixedLanguage.indexOf(option) !== -1}
                          //   deleteIcon={<CloseIcon />}
                          // />
                        )
                      }}
                      style={{ width: '100%' }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder={t(
                            'content.servicePublish.avalibleLanguages'
                          )}
                          variant="standard"
                        />
                      )}
                    />
                  )}
                </FormControl>
              </div>
              {fields
                .filter((field) => field.entityState !== ENTITYSTATE.DELETED)
                .map((field, fieldIndex) => {
                  return (
                    <div className="form-field" key={field.id}>
                      {fieldIndex > 0 && (
                        <Chip
                          sx={{
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            float: 'right',
                          }}
                          label={t(
                            'content.servicePublish.descriptionAndBenefits.removekeyBenefits'
                          )}
                          icon={<DeleteIcon color="error" />}
                          onClick={() => {
                            if (field.entityState === ENTITYSTATE.ADDED) {
                              remove(fieldIndex)
                            } else {
                              update(fieldIndex, {
                                serviceFeaturesId: field.serviceFeaturesId,
                                serviceKeyFeatureTitle:
                                  field.serviceKeyFeatureTitle,
                                serviceKeyFeatureDescription:
                                  field.serviceKeyFeatureDescription,
                                entityState: ENTITYSTATE.DELETED,
                              })
                            }
                          }}
                        />
                      )}
                      <div className="form-field">
                        <ConnectorFormInputField
                          {...{
                            control,
                            trigger,
                            errors,
                            name: `serviceFeatures.${fieldIndex}.serviceKeyFeatureTitle` as const,
                            label: (
                              <strong
                                style={{
                                  color: errors?.serviceFeatures?.[fieldIndex]
                                    ?.serviceKeyFeatureTitle
                                    ? '#c02424'
                                    : 'inherit',
                                }}
                              >
                                {t(
                                  'content.servicePublish.descriptionAndBenefits.keyBenefitTitle'
                                ) + '*'}
                              </strong>
                            ),
                            type: 'input',
                            placeholder: `${t(
                              'content.servicePublish.descriptionAndBenefits.keyBenefitTitlePlaceholder'
                            )}`,
                            rules: {
                              required: {
                                value: true,
                                message: `${t(
                                  'content.servicePublish.descriptionAndBenefits.keyBenefitTitle'
                                )} ${t(
                                  'content.appPublish.appReleaseForm.isMandatory'
                                )}`,
                              },
                              pattern: {
                                value: Patterns.appMarketCard.appTitle,
                                message: `${t(
                                  'content.appPublish.appReleaseForm.validCharactersIncludes'
                                )} A-Za-z0-9.:_- @& and length should 4-110`,
                              },
                              minLength: {
                                value: 4,
                                message: `${t(
                                  'content.appPublish.appReleaseForm.minimum'
                                )} 4 ${t(
                                  'content.appPublish.appReleaseForm.charactersRequired'
                                )}`,
                              },
                              maxLength: {
                                value: 40,
                                message: `${t(
                                  'content.appPublish.appReleaseForm.maximum'
                                )} 40 ${t(
                                  'content.appPublish.appReleaseForm.charactersAllowed'
                                )}`,
                              },
                            },
                          }}
                        />
                        {errors.serviceFeatures &&
                          errors.serviceFeatures?.[fieldIndex]
                            ?.serviceKeyFeatureTitle?.type === 'required' && (
                            <Typography
                              variant="body2"
                              className="file-error-msg"
                            >
                              {
                                errors.serviceFeatures[fieldIndex]
                                  ?.serviceKeyFeatureTitle?.message
                              }
                            </Typography>
                          )}
                        {errors.serviceFeatures &&
                          errors.serviceFeatures?.[fieldIndex]
                            ?.serviceKeyFeatureTitle?.type === 'maxLength' && (
                            <Typography
                              variant="body2"
                              className="file-error-msg"
                            >
                              {
                                errors.serviceFeatures[fieldIndex]
                                  ?.serviceKeyFeatureTitle?.message
                              }
                            </Typography>
                          )}
                        {errors.serviceFeatures &&
                          errors.serviceFeatures?.[fieldIndex]
                            ?.serviceKeyFeatureTitle?.type === 'minLength' && (
                            <Typography
                              variant="body2"
                              className="file-error-msg"
                            >
                              {
                                errors.serviceFeatures[fieldIndex]
                                  ?.serviceKeyFeatureTitle?.message
                              }
                            </Typography>
                          )}
                      </div>
                      {LANGUAGE_SUPPORTED.filter((language) =>
                        selectedKeyFeatureDescriptionLanguage.some(
                          (selected) => selected === language
                        )
                      ).map((language, index) => {
                        return (
                          <div className="form-field">
                            <InputLabel sx={{ mt: 3, display: 'flex' }}>
                              <strong
                                style={{
                                  color: errors?.serviceFeatures?.[fieldIndex]
                                    ?.serviceKeyFeatureDescription?.[index]?.[
                                    language
                                  ]?.shortDescription
                                    ? '#c02424'
                                    : 'inherit',
                                }}
                              >
                                {t(
                                  `content.servicePublish.descriptionAndBenefits.keyBenefitDescription${language}`
                                ) + ' *'}
                              </strong>
                            </InputLabel>
                            <ConnectorFormInputField
                              {...{
                                control,
                                trigger,
                                errors,
                                name: `serviceFeatures.${fieldIndex}.serviceKeyFeatureDescription.${index}.${language}.shortDescription` as const,
                                type: 'input',
                                textarea: true,
                                placeholder: `${t(
                                  'content.servicePublish.descriptionAndBenefits.keyBenefitDescriptionPlaceholder'
                                )}`,
                                rules: {
                                  required: {
                                    value: language === 'en' ? true : false,
                                    message: `${t(
                                      `content.servicePublish.descriptionAndBenefits.keyBenefitDescription${language}`
                                    )} ${t(
                                      'content.appPublish.appReleaseForm.isMandatory'
                                    )}`,
                                  },
                                  minLength: {
                                    value: 10,
                                    message: `${t(
                                      'content.appPublish.appReleaseForm.minimum'
                                    )} 10 ${t(
                                      'content.appPublish.appReleaseForm.charactersRequired'
                                    )}`,
                                  },
                                  pattern: {
                                    value:
                                      language === 'en'
                                        ? Patterns.appMarketCard
                                            .commonDescriptionEN
                                        : Patterns.appMarketCard
                                            .commonDescriptionDE,
                                    message: `${t(
                                      'content.appPublish.appReleaseForm.validCharactersIncludes'
                                    )} ${
                                      language === 'en'
                                        ? 'a-zA-Z0-9 !?@&#\'"()_-=/*.,;:'
                                        : 'a-zA-ZÀ-ÿ0-9 !?@&#\'"()_-=/*.,;:'
                                    }`,
                                  },
                                  maxLength: {
                                    value: 110,
                                    message: `${t(
                                      'content.appPublish.appReleaseForm.maximum'
                                    )} 110 ${t(
                                      'content.appPublish.appReleaseForm.charactersAllowed'
                                    )}`,
                                  },
                                },
                              }}
                            />
                            {errors &&
                              errors.serviceFeatures?.[fieldIndex]
                                ?.serviceKeyFeatureDescription?.[index]?.[
                                language
                              ]?.shortDescription?.type === 'required' && (
                                <Typography
                                  variant="body2"
                                  className="file-error-msg"
                                  align="left"
                                >
                                  {t(
                                    `content.servicePublish.descriptionAndBenefits.keyBenefitDescription${language}`
                                  )}{' '}
                                  {t(
                                    'content.appPublish.appReleaseForm.isMandatory'
                                  )}
                                </Typography>
                              )}
                            {errors &&
                              errors.serviceFeatures?.[fieldIndex]
                                ?.serviceKeyFeatureDescription?.[index]?.[
                                language
                              ]?.shortDescription?.type === 'minLength' && (
                                <Typography
                                  variant="body2"
                                  className="file-error-msg"
                                  align="left"
                                >
                                  {t(
                                    'content.appPublish.appReleaseForm.minimum'
                                  )}{' '}
                                  10
                                  {t(
                                    'content.appPublish.appReleaseForm.charactersRequired'
                                  )}
                                </Typography>
                              )}
                            {errors &&
                              errors.serviceFeatures?.[fieldIndex]
                                ?.serviceKeyFeatureDescription?.[index]?.[
                                language
                              ]?.shortDescription?.type === 'maxLength' && (
                                <Typography
                                  variant="body2"
                                  className="file-error-msg"
                                  align="left"
                                >
                                  {t(
                                    'content.appPublish.appReleaseForm.maximum'
                                  )}{' '}
                                  110
                                  {t(
                                    'content.appPublish.appReleaseForm.charactersAllowed'
                                  )}
                                </Typography>
                              )}
                            <Typography
                              variant="body2"
                              className="form-field"
                              align="right"
                              style={{
                                fontWeight: 600,
                              }}
                            >
                              {getValues().serviceFeatures[fieldIndex]
                                ?.serviceKeyFeatureDescription?.[index]?.[
                                language
                              ]?.shortDescription?.length + '/110'}
                            </Typography>
                          </div>
                        )
                      })}
                      <div className="form-field">
                        <InputLabel
                          id="demo-multiple-name-label"
                          onClick={() => {
                            setVisibleDropdownKeyBenefits(
                              !visibleDropdownKeyBenefits
                            )
                          }}
                        >
                          <strong
                            style={{
                              color: visibleDropdownKeyBenefits
                                ? '#E34C92'
                                : '#4D4D4F',
                            }}
                          >
                            {t(
                              'content.servicePublish.descriptionAndBenefits.addKeyBenefitsSummaryOtherLang'
                            )}
                          </strong>
                        </InputLabel>
                        <FormControl sx={{ m: 1, width: '100%' }}>
                          {visibleDropdownKeyBenefits && (
                            <Autocomplete
                              multiple
                              fullWidth
                              id="fixed-tags-demo"
                              value={selectedKeyFeatureDescriptionLanguage}
                              onChange={(event, newValue) => {
                                setSelectedKeyFeatureDescriptionLanguage([
                                  ...fixedLanguage,
                                  ...newValue.filter(
                                    (eachLan) =>
                                      fixedLanguage.indexOf(eachLan) === -1
                                  ),
                                ])
                                LANGUAGE_SUPPORTED.filter((newLanguage) =>
                                  newValue.every((lang) => lang !== newLanguage)
                                ).forEach((language) => {
                                  const index =
                                    LANGUAGE_SUPPORTED.indexOf(language)
                                  resetField(
                                    `serviceFeatures.${fieldIndex}.serviceKeyFeatureDescription.${index}.${language}.shortDescription`
                                  )
                                  setValue(
                                    `serviceFeatures.${fieldIndex}.serviceKeyFeatureDescription.${index}.${language}.shortDescription`,
                                    '' as string as never
                                  )
                                })
                              }}
                              options={LANGUAGE_SUPPORTED}
                              getOptionLabel={(option) => option}
                              renderTags={(tagValue, getTagProps) => {
                                return tagValue.map(
                                  (option, index) => null
                                  // eslint-disable-next-line react/jsx-key
                                  // <Chip
                                  //   label={option}
                                  //   size="small"
                                  //   color="primary"
                                  //   {...getTagProps({ index })}
                                  //   disabled={
                                  //     fixedLanguage.indexOf(option) !== -1
                                  //   }
                                  //   deleteIcon={<CloseIcon />}
                                  // />
                                )
                              }}
                              style={{ width: '100%' }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  placeholder={t(
                                    'content.servicePublish.avalibleLanguages'
                                  )}
                                  variant="standard"
                                />
                              )}
                            />
                          )}
                        </FormControl>
                      </div>
                    </div>
                  )
                })}
              <Divider>
                {fields.length < 3 && (
                  <Chip
                    label={`+ Add a key benefit ${3 - fields.length} remaining`}
                    color="primary"
                    sx={{
                      borderRadius: 5,
                      fontWeight: 'bold',
                    }}
                    onClick={() => {
                      append({
                        serviceFeaturesId:
                          '00000000-0000-0000-0000-000000000000',
                        serviceKeyFeatureTitle: '',
                        serviceKeyFeatureDescription: LANGUAGE_SUPPORTED.map(
                          (language) => {
                            return {
                              [language]: {
                                shortDescription: '',
                                longDescription: '',
                              },
                            }
                          }
                        ),
                        entityState: ENTITYSTATE.ADDED,
                      })
                    }}
                  />
                )}
              </Divider>
              <div className="form-field">
                <InputLabel sx={{ mt: 3, display: 'flex' }}>
                  <strong
                    style={{
                      color: errors?.images ? '#c02424' : 'inherit',
                    }}
                  >
                    {t(
                      'content.servicePublish.descriptionAndBenefits.addImages'
                    ) + '*'}
                  </strong>
                  <Tooltip
                    title={`${t(
                      'content.servicePublish.descriptionAndBenefits.addImagesItxt'
                    )}`}
                    arrow
                  >
                    <IconButton
                      sx={{ color: '#939393', marginLeft: 'auto' }}
                      size="small"
                    >
                      <InfoOutlined />
                    </IconButton>
                  </Tooltip>
                </InputLabel>
                <Controller
                  name="images"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: `${t(
                        'content.servicePublish.descriptionAndBenefits.filerequired'
                      )}`,
                    },
                  }}
                  render={({
                    field: { onChange: reactHookFormOnChange, value },
                  }) => {
                    return (
                      <Dropzone
                        files={value}
                        onChange={async (files, addedFiles, deletedFiles) => {
                          setValue('images', files)
                          if (files.length === 0) {
                            resetField('images', {
                              defaultValue: null,
                            })
                          }
                          if (deletedFiles?.length) {
                            await Promise.all(
                              deletedFiles?.map(async (eachDoc: any) => {
                                await deleteDocument(eachDoc.id).unwrap()
                              })
                            )
                          }
                          reactHookFormOnChange(files)
                          trigger('images')
                        }}
                        acceptFormat={{
                          'image/png': [],
                          'image/jpeg': [],
                        }}
                        maxFilesToUpload={12}
                        maxFileSize={SIZE_LIMITS.FILESIZE_1MB}
                      />
                    )
                  }}
                />
                {errors?.images?.type === 'required' && (
                  <Typography variant="body2" className="file-error-msg">
                    {t(
                      'content.appPublish.appReleaseForm.fileUploadIsMandatory'
                    )}
                  </Typography>
                )}
                <Typography variant="body2" mt={3} sx={{ fontWeight: 'bold' }}>
                  {t('content.appPublish.appReleaseForm.note')}
                </Typography>
                <Typography variant="body2" mb={3}>
                  {t('content.appPublish.appPage.maxTwelveImages')}
                </Typography>
              </div>
              <div className="form-field">
                <InputLabel sx={{ mt: 3, display: 'flex' }}>
                  <strong
                    style={{
                      color: errors?.videoLink ? '#c02424' : 'inherit',
                    }}
                  >
                    {t(
                      'content.servicePublish.descriptionAndBenefits.addVideo'
                    )}
                  </strong>
                  <Tooltip
                    title={`${t(
                      'content.servicePublish.descriptionAndBenefits.addVideoTooltip'
                    )}`}
                    arrow
                  >
                    <IconButton
                      sx={{ color: '#939393', marginLeft: 'auto' }}
                      size="small"
                    >
                      <InfoOutlined />
                    </IconButton>
                  </Tooltip>
                </InputLabel>
                <ConnectorFormInputField
                  {...{
                    control,
                    trigger,
                    errors,
                    name: 'videoLink',
                    type: 'webAddress',
                    rules: {
                      pattern: {
                        value: Patterns.URLWithoutHttp,
                        message: t(
                          'content.servicePublish.descriptionAndBenefits.pleaseEnterVideoLinkURL'
                        ),
                      },
                    },
                  }}
                />
              </div>
            </div>
          </form>
        </div>
      </Box>
      <Box
        style={{
          padding: '5% 2% 5% 2%',
          borderBottomLeftRadius: '30px',
          borderBottomRightRadius: '30px',
          background: 'rgba(250, 250, 250, 1)',
        }}
      >
        {appPageNotification && (
          <Grid container xs={12} sx={{ mb: 2 }}>
            <Grid xs={6}></Grid>
            <Grid xs={6}>
              <PageNotifications
                title={t('content.appPublish.appReleaseForm.error.title')}
                description={t(
                  'content.appPublish.appReleaseForm.error.message'
                )}
                open
                severity="error"
              />
            </Grid>
          </Grid>
        )}
        <PageSnackbar
          open={appPageSnackbar}
          severity="success"
          description={t(
            'content.appPublish.appReleaseForm.dataSavedSuccessMessage'
          )}
          autoClose={true}
        />

        <Box textAlign={'center'}>
          <Typography variant="caption2">
            {t('content.appPublish.footerText')}{' '}
            <strong>
              <a
                href={COFINITY_SERVICE_ONBOARDING_DOCUMENTATION_URL}
                target="_blank"
                rel="noreferrer"
              >
                {t('content.appPublish.footerTextLink')}
              </a>
            </strong>
          </Typography>
        </Box>
        <Box textAlign="end" mt={2} pl={5} pr={5}>
          <Button
            startIcon={<KeyboardBackspaceIcon />}
            sx={{
              float: 'left',
            }}
            size="small"
            variant="outlined"
            name="back"
            onClick={() => {
              onBackIconClick()
            }}
          >
            Back
          </Button>
          <Button
            variant="outlined"
            disabled={
              descriptionBenefitsStatus === QueryStatus.pending ||
              documentDeleteStatus === QueryStatus.pending ||
              documentUploadStatus === QueryStatus.pending
            }
            sx={{ mr: 2 }}
            onClick={handleSubmit((data) => handleBenefitsSubmit(data, 'save'))}
          >
            {t('content.appPublish.footerButtons.save')}
          </Button>
          <Button
            variant="contained"
            name="send"
            disabled={
              descriptionBenefitsStatus === QueryStatus.pending ||
              documentDeleteStatus === QueryStatus.pending ||
              documentUploadStatus === QueryStatus.pending
            }
            onClick={handleSubmit((data) =>
              handleBenefitsSubmit(data, 'saveAndProceed')
            )}
          >
            {t('content.appPublish.footerButtons.saveAndProceed')}
          </Button>
        </Box>
      </Box>
      <Backdrop
        sx={{
          color: 'grey',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={
          descriptionBenefitsStatus === QueryStatus.pending ||
          documentDeleteStatus === QueryStatus.pending ||
          documentUploadStatus === QueryStatus.pending
        }
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </Paper>
  )
}
export default DescriptionAndBenefits
