import { type DropzoneFile } from 'components/shared/basic/Dropzone'

export interface CertificationType {
  document: DropzoneFile | string | null
  date: Date | null
  standards: string[]
}

export enum CERTIFICATION_STATUS {
  NO_CERTIFICATION = 'NO_CERTIFICATION',
  CERTIFICATION_IN_PROGRESS = 'IN_PROGRESS',
  CERTIFIED = 'CERTIFIED',
}
