import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import { Chip, TextField, Tooltip } from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import { Typography } from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { InfoOutlined } from '@mui/icons-material'
import CloseIcon from '@mui/icons-material/Close'
import { useTranslation } from 'react-i18next'
const KeyFeatureControlledTag = ({
  control,
  name,
  defaultValues,
  label,
  rules,
  placeholder,
}: any) => {
  const [inputValue, setInputValue] = useState<string>('')
  const { t } = useTranslation()
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValues}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {value.map((chip: string) => {
              const index = value.indexOf(chip)
              return (
                <Chip
                  // color="primary"
                  sx={{
                    color:'#D31184'
                  }}
                  size="small"
                  key={index}
                  label={chip}
                  style={{ margin: '5px' }}
                  onDelete={() => {
                    const newValues = value.filter(
                      (item: string) => item !== chip
                    )
                    onChange(newValues)
                  }}
                  deleteIcon={<CloseIcon />}
                />
              )
            })}
          </div>
          <div style={{ marginBottom: '10px' }}>
            <TextField
              fullWidth
              variant="standard"
              inputProps={{
                maxLength: 40,
              }}
              InputProps={{
                endAdornment:
                  name === 'tags' || name === 'keywords' ? (
                    <InputAdornment
                      position="end"
                      style={{
                        cursor: 'pointer',
                      }}
                    >
                      <Typography variant="helper">
                        <Tooltip
                          title={`${t(
                            'content.appPublish.appMarketCard.keywordTooltip'
                          )}`}
                          arrow
                        >
                          <InfoOutlined />
                        </Tooltip>
                      </Typography>
                    </InputAdornment>
                  ) : null,
              }}
              placeholder={placeholder ?? ''}
              value={inputValue}
              onChange={(e) => { setInputValue(e.target.value) }}
              disabled={value.length >= 10}
              onKeyPress={(e: any) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  const newValue = e.target.value.trim()
                  if (newValue) {
                    console.log(value)
                    console.log(newValue)

                    const isFound = value.some((element: any) => {
                      if (element === newValue) {
                        return true
                      }
                      return false
                    })
                    if (!isFound) {
                      onChange([...value, newValue])
                      setInputValue('')
                    }
                  }
                }
              }}
              error={!!error && error !== undefined}
              helperText={error?.message || ''}
            />
          </div>
        </>
      )}
    />
  )
}

export default KeyFeatureControlledTag
