import {
  Box,
  Typography,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'

export const SingleSectionTitle = ({ title }: { title: string }) => {
  return (
    <Typography
      marginBottom=".5rem"
      fontSize="20px"
      fontWeight={600}
      color="black"
    >
      {title}
    </Typography>
  )
}

export const SingleSectionDetail = ({
  title,
  value,
}: {
  title: string
  value: string
}) => {
  return (
    <Box display="flex" gap="10rem" marginTop=".5rem">
      <Box flexBasis="20%">
        <Typography fontSize="16px" fontWeight="400" color="#4D4D4F">
          {title}
        </Typography>
      </Box>
      <Box flexBasis="90%">
        <Typography fontSize="16px" color="#4D4D4F" fontWeight="500">
          {value}
        </Typography>
      </Box>
    </Box>
  )
}
