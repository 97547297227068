import React, { useEffect } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Chip from '@mui/material/Chip'
import { Controller } from 'react-hook-form'
function MultiSelectDropdown(props: any) {
  const [selectedOptions, setSelectedOptions] = React.useState<string[]>([])

  useEffect(() => {
    setSelectedOptions(props?.defaultValues)
  }, [])

  const handleSelectedOptionsChange = async (event: any, value: any) => {
    props.setValue(props.name, value)
    setSelectedOptions(value)
    await props.trigger(props.name)
  }

  const handleDeleteChip = async (optionToDelete: any) => {
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.filter((option) => option !== optionToDelete)
    )
    const updatedSelectedOptions = selectedOptions.filter(
      (option) => option !== optionToDelete
    )
    props.setValue(props.name, updatedSelectedOptions)
    await props.trigger(props.name)
  }

  useEffect(() => {
    props?.getMultiselectData(selectedOptions)
  }, [selectedOptions])

  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={props.rules}
      defaultValue={props.default}
      render={({ field, fieldState: { error, isDirty, isTouched } }) => {
        return (
          <Autocomplete
            {...field}
            multiple
            id="selectedOptions"
            options={props.options}
            value={selectedOptions ? selectedOptions : []}
            defaultValue={selectedOptions}
            onChange={(event, value) =>
              handleSelectedOptionsChange(event, value)
            }
            disabled={props?.disable ?? false}
            disableListWrap={!props.disable ?? false}
            disablePortal={!props.disable ?? false}
            getOptionDisabled={(options) => {
              if (props?.limitSelection !== undefined) {
                return selectedOptions.length >= props?.limitSelection
                  ? true
                  : false
              } else {
                return false
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder={props?.disable ? '' : props?.placeholder}
                error={!!error}
                helperText={error?.message || ''}
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  // color="primary"
                  sx={{ color: '#D31184' }}
                  size="small"
                  label={option}
                  onDelete={() => handleDeleteChip(option)}
                />
              ))
            }
          />
        )
      }}
    />
  )
}

export default MultiSelectDropdown
