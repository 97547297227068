import { type IntersectionOptions } from 'react-intersection-observer'

export const LABEL_IDS = {
  APPDETAIL_DESCRIPTION: 'appdetail_description',
  APPDETAIL_FEATURES: 'appdetail_features',
  APPDETAIL_PRICING: 'appdetail_pricing',
  APPDETAIL_PRIVACY: 'appdetail_privacy',
  APPDETAIL_DOCUMENTS: 'appdetail_document',
  APPDETAIL_PROVIDER: 'appdetail_provider',
}

export const NOTIFICATION_PAGE = {
  NOTIFICATION_ALL: 'notification_all',
  NOTIFICATION_GENERAL: 'notification_general',
  NOTIFICATION_APPS: 'notification_apps',
  NOTIFICATION_SERVICES: 'notification_services',
}

export const APPDETAIL_TABS = [
  { label: 'Description', id: LABEL_IDS.APPDETAIL_DESCRIPTION },
  { label: 'Features', id: LABEL_IDS.APPDETAIL_FEATURES },
  { label: 'Pricing', id: LABEL_IDS.APPDETAIL_PRICING },
  { label: 'Privacy Policy', id: LABEL_IDS.APPDETAIL_PRIVACY },
  { label: 'Relevant Documents', id: LABEL_IDS.APPDETAIL_DOCUMENTS },
  {
    label: 'Provider Information',
    id: LABEL_IDS.APPDETAIL_PROVIDER,
  },
]

export const OBSERVER_OPTIONS: IntersectionOptions = {
  threshold: 1,
}
