import React from 'react'
import {
  CrossIconGraphic,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Box,
  PDFIcon,
  DownloadIcon,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import {
  type DocumentMapper,
  useFetchNewDocumentByIdMutation,
} from 'features/admin/applicationRequestApiSlice'

import { download } from 'utils/downloadUtils'

interface DocumentDownloadOverlayProps {
  openDialog: boolean
  handleCloseOverlay: () => void
  docs: DocumentMapper[]
  companyName: string
}

function DocumentDownloadOverlay({
  openDialog,
  handleCloseOverlay,
  docs,
  companyName,
}: DocumentDownloadOverlayProps) {
  const [getDocumentById] = useFetchNewDocumentByIdMutation()

  const handleDownloadDocument = async (
    documentType: string,
    documentId: string
  ) => {
    try {
      const response = await getDocumentById(documentId).unwrap()

      const fileType = response.headers.get('content-type')
      const file = response.data

      download(file, fileType, documentType)
      return
    } catch (error) {
      console.error(error, 'ERROR WHILE FETCHING DOCUMENT')
    }
  }

  return (
    <div>
      <Dialog
        open={openDialog}
        sx={{
          '&.MuiDialog-paper': {
            maxWidth: '45%',
          },
        }}
      >
        <DialogContent sx={{ padding: '1rem 2rem', minHeight: '25rem' }}>
          <IconButton
            aria-label="close"
            onClick={handleCloseOverlay}
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
            }}
          >
            <CrossIconGraphic height={25} width={25} />
          </IconButton>
          <Box marginBottom="2rem">
            <Typography fontSize="18px" fontWeight="600" color="black">
              Documents for
            </Typography>
            <Typography color="#D31184" fontWeight="600" fontSize="24px">
              {companyName}
            </Typography>
          </Box>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
          >
            {docs.map((doc) => (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                sx={{ borderBottom: '1px solid #DEDFE0' }}
                padding=".2rem 0px"
                key={doc.documentId}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  gap=".5rem"
                >
                  <PDFIcon height={20} width={20} />
                  <Typography fontSize="18px" fontWeight="500">
                    {doc.documentType}
                  </Typography>
                </Box>
                <IconButton
                  onClick={() =>
                    handleDownloadDocument(doc.documentType, doc.documentId)
                  }
                >
                  <DownloadIcon />
                </IconButton>
              </Box>
            ))}
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default DocumentDownloadOverlay
