import React, { useState } from 'react'
import {
  Paper,
  Grid,
  Box,
  FormGroup,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  FormControl,
  Tooltip,
  Backdrop,
  CircularProgress,
  Chip,
} from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import InfoIcon from '@mui/icons-material/Info'
import { PricingRadio } from '../../AppPublish/Pricing/RadioGroup'
import AddIcon from '@mui/icons-material/Add'
import { Controller, Form, useForm } from 'react-hook-form'
import { ConnectorFormInputField } from '../../AppPublish/OverView/index'
import {
  Button,
  Datepicker,
  Typography,
  UploadStatus,
  theme,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import AddStandardOverlay from 'components/overlays/AddStandards'
import { COFINITY_APP_ONBOARDING_DOCUMENTATION_URL } from 'types/Constants'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import {
  useFetchServiceStatusQuery,
  useUpdateSubmitLogMutation,
  useUpdateDocumentUploadMutation,
  useDeleteDocumentMutation,
  usePostServiceCertificationUpdateMutation,
  useGetServiceCertificationUpdateQuery,
} from 'features/Custom/serviceOnboard/apiSlice'
import { setServiceStatus } from 'features/Custom/serviceOnboard/actions'
import { useDispatch, useSelector } from 'react-redux'
import { QueryStatus } from '@reduxjs/toolkit/dist/query'
import { OFFERTYPES } from 'features/Custom/serviceOnboard/types'
import { ReactComponent as CloseIcon } from 'assets/images/close-icon.svg'
import STANDARDS from 'assets/standards/standards.json'
import { type SERVICE_POST_CERTIFICATION } from 'features/Custom/appOnboard/types'
import dayjs from 'dayjs'
import { type DropzoneFile } from 'components/shared/basic/Dropzone'
import {
  CERTIFICATION_STATUS,
  type CertificationType,
} from 'components/pages/AppPublish/Certification/types'
import SuccessOverlay from 'components/pages/AppPublish/Certification/successOverlay'
import { N_ID } from 'components/pages/AppPublish/TechnicalIntegrationAndBetaTest/types'
import {
  serviceStatusDataSelector,
  decrement,
  serviceIdSelector,
  currentActiveStep,
} from 'features/Custom/serviceOnboard/slice'
import VerticalStepper from 'components/shared/basic/VerticalStepper'
import { SIZE_LIMITS } from 'types/Config'

export default function Certification() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const serviceId = useSelector(serviceIdSelector)
  const activePage = useSelector(currentActiveStep)
  const serviceStatusData = useSelector(serviceStatusDataSelector)
  const [UpdateSubmitLog, { status: updateSubmitAPIStatus }] =
    useUpdateSubmitLogMutation()
  const [updateDocumentUpload, { status: updateDocumentAPIStatus }] =
    useUpdateDocumentUploadMutation()
  const [postCertificationData, { status: serviceCertificationAPIStatus }] =
    usePostServiceCertificationUpdateMutation()
  const {
    data: serviceCertificationData,
    isFetching,
    isSuccess,
  } = useGetServiceCertificationUpdateQuery(serviceId, {
    refetchOnMountOrArgChange: true,
  })
  const [deleteDocument, { status: deleteDocumentAPIStatus }] =
    useDeleteDocumentMutation()
  const [selectedCertificate, setSelectedCertificate] = useState<
    CERTIFICATION_STATUS | string | null
  >(null)
  const [activeStep, setActiveStep] = React.useState<number>(0)
  const [showStandardOverlay, setShowStandardOverlay] =
    React.useState<boolean>(false)
  const [showSuccessPopup, setShowSuccessPopup] = React.useState<boolean>(false)
  const { data: fetchServiceStatus } = useFetchServiceStatusQuery(
    serviceId ?? '',
    {
      refetchOnMountOrArgChange: true,
    }
  )

  React.useEffect(() => {
    dispatch(setServiceStatus(fetchServiceStatus))
  }, [dispatch, fetchServiceStatus])

  React.useEffect(() => {
    if (isSuccess) {
      setSelectedCertificate(serviceCertificationData?.statusId ?? '')
    }
  }, [serviceCertificationData, isFetching])

  React.useEffect(() => {
    if (
      serviceStatusData?.documents.find(
        (eachDoc) => eachDoc.documentTypeId === 'APP_CERTIFICATE'
      )
    ) {
      setValue(
        'document',
        serviceStatusData?.documents.find(
          (eachDoc) => eachDoc.documentTypeId === 'APP_CERTIFICATE'
        )
          ? ({
              id: serviceStatusData?.documents.find(
                (eachDoc) => eachDoc.documentTypeId === 'APP_CERTIFICATE'
              )?.documentId,
              name: serviceStatusData?.documents.find(
                (eachDoc) => eachDoc.documentTypeId === 'APP_CERTIFICATE'
              )?.documentName,
              status: serviceStatusData?.documents.find(
                (eachDoc) => eachDoc.documentTypeId === 'APP_CERTIFICATE'
              )?.documentId
                ? UploadStatus.UPLOAD_SUCCESS
                : UploadStatus.NEW,
            } as DropzoneFile)
          : null
      )
      setActiveStep(0)
    }
    if (serviceStatusData?.certificate?.expiryDate) {
      setValue(
        'date',
        serviceStatusData?.certificate?.expiryDate
          ? new Date(serviceStatusData?.certificate.expiryDate)
          : null
      )
      setActiveStep(1)
    }
    if (serviceStatusData?.certificate?.standards.length) {
      setValue(
        'standards',
        serviceStatusData?.certificate?.standards.length
          ? STANDARDS.filter((standard) =>
              serviceStatusData?.certificate?.standards?.some(
                (selectedStandard) =>
                  selectedStandard.standardId === standard.id
              )
            ).map((filtredStandards) => filtredStandards.id)
          : []
      )
      setActiveStep(2)
    }
  }, [serviceStatusData, dispatch])

  const {
    trigger,
    control,
    getValues,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = useForm<CertificationType>({
    defaultValues: {
      document: null,
      date: serviceStatusData?.certificate?.expiryDate
        ? new Date(serviceStatusData?.certificate.expiryDate)
        : null,
      standards: serviceStatusData?.certificate?.standards.length
        ? STANDARDS.filter((standard) =>
            serviceStatusData?.certificate?.standards?.some(
              (selectedStandard) => selectedStandard.standardId === standard.id
            )
          ).map((filtredStandards) => filtredStandards.id)
        : [],
    },
    mode: 'onChange',
  })

  const steps: {
    label: string
    name: 'document' | 'date'
    children: JSX.Element
  }[] = [
    {
      label: t('content.appPublish.serviceCertification.overlays.step1'),
      name: 'document',
      children: (
        <Box
          sx={{
            width: '100%',
          }}
        >
          <ConnectorFormInputField
            {...{
              control,
              trigger,
              errors,
              rules: {
                required: {
                  value: true,
                  message: `Document ${t(
                    'content.appPublish.appReleaseForm.isMandatory'
                  )} `,
                },
              },
              name: 'document' as const,
              type: 'dropzone',
              shouldCrop: false,
              acceptFormat: {
                'application/pdf': ['.pdf'],
              },
              maxFilesToUpload: 1,
              maxFileSize: SIZE_LIMITS.FILESIZE_5MB,
            }}
            handleDelete={async (documentId: string) => {
              if (documentId !== N_ID.ID && documentId.length > 0) {
                await deleteDocument(documentId)
                  .unwrap()
                  .then(() => {
                    setValue('document', null)
                  })
                  .catch(() => {
                    console.log('Somthing went wrong')
                  })
              }
            }}
          />
          {errors?.document && errors?.document?.type === 'required' && (
            <Typography variant="body2" className="file-error-msg">
              {errors?.document?.message}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      label: t('content.appPublish.serviceCertification.overlays.step2'),
      name: 'date',
      children: (
        <Controller
          name="date"
          control={control}
          rules={{
            required: {
              value: true,
              message: t(
                'content.appPublish.serviceCertification.overlays.requiredMessage'
              ),
            },
          }}
          render={({ field }) => {
            return (
              <Datepicker
                error={!!errors.date}
                inputFormat="dd-MM-yyyy"
                defaultValue={getValues()?.date}
                daySelectedColor="#D31184"
                helperText={t(
                  'content.appPublish.serviceCertification.overlays.helperText'
                )}
                label=""
                locale="en"
                margin="dense"
                onChangeItem={(event) => {
                  field.onChange(event)
                  trigger('date')
                }}
                placeholder={t(
                  'content.appPublish.serviceCertification.overlays.placeholder'
                )}
                todayColor="#0F71CB"
                variant="standard"
                readOnly={false}
              />
            )
          }}
        />
      ),
    },
    {
      label: t('content.appPublish.serviceCertification.overlays.step3'),
      name: 'document',
      children: (
        <React.Fragment>
          {getValues().standards.length === 0 ? (
            <Typography
              variant="caption1"
              sx={{ fontWeight: '600', fontSize: '1rem' }}
            >
              Please select at least 1 standard
            </Typography>
          ) : null}
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Button
              startIcon={<AddIcon />}
              variant="contained"
              sx={{ mt: 2, mr: 1 }}
              onClick={() => {
                setShowStandardOverlay(true)
              }}
            >
              Add standards
            </Button>
            <Button
              variant="outlined"
              sx={{ mt: 2, mr: 1 }}
              onClick={handleBack}
            >
              Back
            </Button>
            <Box
              mt={1}
              mb={1}
              sx={{
                width: '300px',
                maxHeight: '200px',
              }}
            >
              {STANDARDS.filter((eachStandards) =>
                getValues().standards.some(
                  (id: string) => id === eachStandards.id
                )
              ).map((eachStandard) => (
                <Chip
                  label={eachStandard.title}
                  id={eachStandard.id}
                  key={eachStandard.id}
                  sx={{
                    borderRadius: '20px !important',
                    width: 'auto',
                    backgroundColor: '#4D4D4D',
                    color: '#fff',
                    margin: 1,
                    fontWeight: '600',
                    fontSize: '16px',
                  }}
                  deleteIcon={<CloseIcon />}
                  onDelete={async () => {
                    const newStandards = getValues().standards.filter(
                      (id) => id !== eachStandard.id
                    )
                    setValue('standards', newStandards)
                    await trigger('standards')
                  }}
                />
              ))}
            </Box>
          </Paper>
        </React.Fragment>
      ),
    },
  ]

  const handleSuccessOverlay = () => {
    setShowSuccessPopup(!showSuccessPopup)
  }
  async function handleNext() {
    const isValid = await trigger(['date', 'document', 'standards'], {
      shouldFocus: true,
    })

    if (isValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleOverlayState = () => {
    setShowStandardOverlay(false)
  }

  const uploadDocumentApi = async (
    serviceId: string,
    documentTypeId: string,
    file: any
  ) => {
    const data = {
      serviceId,
      documentTypeId,
      body: { file },
    }
    try {
      return await updateDocumentUpload(data).unwrap()
    } catch (error) {
      return error
    }
  }

  const handleCertificationSubmit = async () => {
    if (selectedCertificate === CERTIFICATION_STATUS.CERTIFIED) {
      const uploadImageValue = getValues().document as DropzoneFile
      const response = uploadDocumentApi(
        serviceId,
        'APP_CERTIFICATE',
        uploadImageValue
      )
      await response
        .then((res) => {
          setValue('document', {
            id: res,
            name: uploadImageValue.name,
            size: uploadImageValue.size,
            status: UploadStatus.UPLOAD_SUCCESS,
          } as DropzoneFile)
        })
        .catch(() => {
          setValue('document', {
            name: uploadImageValue.name,
            size: uploadImageValue.size,
            status: UploadStatus.UPLOAD_ERROR,
          } as DropzoneFile)
        })
      const data: SERVICE_POST_CERTIFICATION = {
        serviceId,
        body: {
          id: serviceStatusData?.certificate?.id ?? N_ID.ID,
          expiryDate: getValues().date
            ? dayjs(getValues().date).format('YYYY-MM-DDTHH:mm:ss[Z]')
            : null,
          standards: getValues().standards.length
            ? getValues().standards.map((id) => {
                return {
                  standardId: id,
                }
              })
            : [],
          statusId: selectedCertificate,
        },
      }
      await postCertificationData(data).then((res) => {
        console.log(res)
      })
    } else {
      const data: SERVICE_POST_CERTIFICATION = {
        serviceId,
        body: {
          id: serviceStatusData?.certificate?.id ?? N_ID.ID,
          expiryDate: null,
          standards: [],
          statusId: selectedCertificate,
        },
      }
      await postCertificationData(data).then((res) => {
        console.log(res)
      })
    }
  }

  const handleServiceSubmit = async (
    data: CertificationType,
    buttonLabel: 'save' | 'submit'
  ) => {
    await handleCertificationSubmit()
    const Savedata = {
      id:
        serviceStatusData?.appSubmitLog?.id ??
        '00000000-0000-0000-0000-000000000000',
      acceptedOverview: true,
      acceptedDocumentPrivacy: true,
      acceptedPricing: true,
      acceptedDescriptionFeature: true,
      submitted: buttonLabel === 'submit' ? true : false,
    }
    const formData: any = {
      serviceId,
      body: Savedata,
      offerTypeId: OFFERTYPES.SERVICE,
    }

    await UpdateSubmitLog(formData)
      .unwrap()
      .then(() => {
        if (buttonLabel === 'submit') {
          setShowSuccessPopup(true)
        }
      })
      .catch(() => {})
  }

  return (
    <Paper
      elevation={5}
      className="service-container service-detail__container service-detail__paper"
    >
      <Box className="certification__item-box">
        <Box
          mt={4}
          sx={{
            backgroundColor: theme.palette.stepper.stepCurrent,
            borderRadius: '50%',
            width: '30px',
            height: '30px',
            color: '#fff',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Typography
            variant="body1"
            fontSize="14px"
            color="#fff"
            sx={{
              margin: 'auto',
              paddingTop: '4px',
              width: 'fit-content',
            }}
          >
            {activePage}
          </Typography>
        </Box>
        <Typography variant="h3" mt={2} mb={2} align="center">
          {t('content.appPublish.serviceCertification.headerTitle')}
        </Typography>
        <Grid container spacing={2}>
          <Grid item md={11} sx={{ mr: 'auto', ml: 'auto' }}>
            <Typography variant="body2" align="center">
              {t('content.appPublish.serviceCertification.headerDescription')}
            </Typography>
          </Grid>
        </Grid>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: 'auto',
            paddingTop: 5,
          }}
        >
          <Form control={control}>
            <FormGroup
              style={{
                width: '100%',
              }}
            >
              <RadioGroup
                value={selectedCertificate}
                onChange={(event) => {
                  setSelectedCertificate(event.target.value)
                  setActiveStep(0)
                }}
                style={{
                  gap: '10px',
                  fontSize: '24px',
                }}
              >
                <FormControl>
                  <FormControlLabel
                    value={CERTIFICATION_STATUS.NO_CERTIFICATION}
                    label={
                      <Box
                        sx={{
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <span
                          style={{
                            color:
                              selectedCertificate ===
                              CERTIFICATION_STATUS.NO_CERTIFICATION
                                ? '#d31184'
                                : 'initial',
                          }}
                        >
                          {t(
                            'content.appPublish.serviceCertification.noCertification'
                          )}
                        </span>
                        <Tooltip
                          title={
                            <Trans
                              i18nKey={
                                'content.appPublish.serviceCertification.noCertificationTooltip'
                              }
                              components={{
                                italic: <i />,
                                bold: <strong />,
                              }}
                            />
                          }
                          placement="right"
                          arrow
                        >
                          <InfoIcon
                            style={{
                              marginLeft: 3,
                              fontSize: '14px',
                              color: 'grey',
                              verticalAlign: 'middle',
                            }}
                          />
                        </Tooltip>
                      </Box>
                    }
                    sx={{
                      '.MuiFormControlLabel-label': {
                        fontWeight: 600,
                        fontSize: '24px',
                        color: '#4D4D4D !important',
                      },
                    }}
                    control={<PricingRadio />}
                  />
                  <FormHelperText
                    sx={{
                      paddingLeft: 1.25,
                    }}
                  >
                    {t(
                      'content.appPublish.serviceCertification.noCertificationHelper'
                    )}
                  </FormHelperText>
                </FormControl>
                <FormControl>
                  <FormControlLabel
                    value={CERTIFICATION_STATUS.CERTIFICATION_IN_PROGRESS}
                    label={
                      <Box
                        sx={{
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <span
                          style={{
                            color:
                              selectedCertificate ===
                              CERTIFICATION_STATUS.CERTIFICATION_IN_PROGRESS
                                ? '#d31184'
                                : 'initial',
                          }}
                        >
                          {t(
                            'content.appPublish.serviceCertification.inprogress'
                          )}
                        </span>
                        <Tooltip
                          title={
                            <Trans
                              i18nKey={
                                'content.appPublish.serviceCertification.inprogressCertificationTooltip'
                              }
                              components={{
                                italic: <i />,
                                bold: <strong />,
                              }}
                            />
                          }
                          placement="right"
                          arrow
                        >
                          <InfoIcon
                            style={{
                              marginLeft: 3,
                              fontSize: '14px',
                              color: 'grey',
                              verticalAlign: 'middle',
                            }}
                          />
                        </Tooltip>
                      </Box>
                    }
                    sx={{
                      '.MuiFormControlLabel-label': {
                        fontWeight: 600,
                        fontSize: '24px',
                        color: '#4D4D4D !important',
                      },
                    }}
                    control={<PricingRadio />}
                  />
                  <FormHelperText
                    sx={{
                      paddingLeft: 1.25,
                    }}
                  >
                    {t(
                      'content.appPublish.serviceCertification.inprogressCertificationHelper'
                    )}
                  </FormHelperText>
                </FormControl>
                <FormControl>
                  <FormControlLabel
                    value={CERTIFICATION_STATUS.CERTIFIED}
                    label={
                      <Box
                        sx={{
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <span
                          style={{
                            color:
                              selectedCertificate ===
                              CERTIFICATION_STATUS.CERTIFIED
                                ? '#d31184'
                                : 'initial',
                          }}
                        >
                          {t(
                            'content.appPublish.serviceCertification.certified'
                          )}
                        </span>
                        <Tooltip
                          title={
                            <Trans
                              i18nKey={
                                'content.appPublish.serviceCertification.certifiedTooltip'
                              }
                              components={{
                                italic: <i />,
                                bold: <strong />,
                              }}
                            />
                          }
                          placement="right"
                          arrow
                        >
                          <InfoIcon
                            style={{
                              marginLeft: 3,
                              fontSize: '14px',
                              color: 'grey',
                              verticalAlign: 'middle',
                            }}
                          />
                        </Tooltip>
                      </Box>
                    }
                    sx={{
                      '.MuiFormControlLabel-label': {
                        fontWeight: 600,
                        fontSize: '24px',
                        color: '#4D4D4D !important',
                      },
                    }}
                    control={<PricingRadio />}
                  />
                  <FormHelperText
                    sx={{
                      paddingLeft: 1.25,
                    }}
                  >
                    {t(
                      'content.appPublish.serviceCertification.certifiedHelper'
                    )}
                  </FormHelperText>
                  {selectedCertificate === CERTIFICATION_STATUS.CERTIFIED ? (
                    <VerticalStepper
                      activeStep={activeStep}
                      handleBack={handleBack}
                      handleNext={handleNext}
                      steps={steps}
                      key={selectedCertificate}
                    />
                  ) : null}
                </FormControl>
              </RadioGroup>
            </FormGroup>
          </Form>
        </Box>
      </Box>
      <AddStandardOverlay
        selectedStandards={getValues().standards}
        setValue={setValue}
        trigger={trigger}
        errors={errors}
        openDialog={showStandardOverlay}
        handleOverlayClose={handleOverlayState}
        fieldName="standards"
      />
      <SuccessOverlay
        isVisible={showSuccessPopup}
        handleOverlayState={handleSuccessOverlay}
      />
      <Box
        style={{
          padding: '5% 2% 5% 2%',
          borderBottomLeftRadius: '30px',
          borderBottomRightRadius: '30px',
          background: 'rgba(250, 250, 250, 1)',
        }}
      >
        <Box textAlign={'center'}>
          <Typography variant="caption2" style={{ fontSize: '18px' }}>
            {t('content.servicePublish.footerText')}{' '}
            <strong>
              <a
                href={COFINITY_APP_ONBOARDING_DOCUMENTATION_URL}
                target="_blank"
                rel="noreferrer"
              >
                {t('content.appPublish.footerTextLink')}
              </a>
            </strong>
          </Typography>
        </Box>
        <Box textAlign="end">
          <Button
            startIcon={<KeyboardBackspaceIcon />}
            sx={{
              float: 'left',
            }}
            size="small"
            variant="outlined"
            name="back"
            onClick={() => dispatch(decrement())}
          >
            Back
          </Button>
          <Button
            variant="outlined"
            sx={{ mr: 2 }}
            disabled={
              selectedCertificate === null ||
              (selectedCertificate === CERTIFICATION_STATUS.CERTIFIED &&
                !isValid) ||
              updateSubmitAPIStatus === QueryStatus.pending
            }
            onClick={handleSubmit((data) => handleServiceSubmit(data, 'save'))}
          >
            {t('content.appPublish.footerButtons.save')}
          </Button>
          <Button
            variant="main"
            disabled={
              selectedCertificate === null ||
              (selectedCertificate === CERTIFICATION_STATUS.CERTIFIED &&
                getValues().standards.length <= 0) ||
              updateSubmitAPIStatus === QueryStatus.pending
            }
            onClick={handleSubmit((data) =>
              handleServiceSubmit(data, 'submit')
            )}
          >
            {t('content.servicePublish.footerButtons.submitService')}
          </Button>
        </Box>
      </Box>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={
          updateSubmitAPIStatus === QueryStatus.pending ||
          isFetching ||
          serviceCertificationAPIStatus === QueryStatus.pending ||
          updateDocumentAPIStatus === QueryStatus.pending ||
          deleteDocumentAPIStatus === QueryStatus.pending
        }
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </Paper>
  )
}
