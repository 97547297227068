import { createSlice } from '@reduxjs/toolkit'
import {
  type PaginationData,
  type ServiceMarketplaceInitialState,
  type ServiceMarketplaceAdminResponse,
} from './types'
import { type RootState } from 'features/store'
import { fetchApps } from './actions'
import uniq from 'lodash.uniq'

const initialState: ServiceMarketplaceInitialState = {
  paginationData: {} as PaginationData,
  serviceList: [],
  loading: true,
  error: '',
}

const serviceMarketplaceSliceDasboard = createSlice({
  name: 'serviceMarketplaceAdmin',
  initialState,
  reducers: {
    resetMarketplaceState: (state) => {
      state.serviceList = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchApps.pending, (state) => {
      state.paginationData = {} as PaginationData
      state.loading = true
    })
    builder.addCase(fetchApps.fulfilled, (state, { payload }) => {
      const responseList = payload as unknown as ServiceMarketplaceAdminResponse
      state.serviceList = uniq(state.serviceList.concat(responseList.content))
      state.paginationData = {
        totalElements: responseList.meta.totalElements,
        page: responseList.meta.page,
      } as PaginationData

      state.loading = false
    })
    builder.addCase(fetchApps.rejected, (state, action) => {
      state.paginationData = {} as PaginationData
      state.serviceList = []
      state.loading = false
      state.error = action.error.message as string
    })
  },
})

export const marketplaceSelector = (
  state: RootState
): ServiceMarketplaceInitialState => state.serviceMarketplaceAdmin

export default serviceMarketplaceSliceDasboard
