import * as React from 'react'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import InputLabel from '@mui/material/InputLabel'
import { useSelector } from 'react-redux'
import {
  TabPanel,
  a11yProps,
} from '../AppMarketAdminDashboard/AddConnectorOverlay/components/TabPanel'
import { serviceStatusDataSelector } from 'features/Custom/serviceOnboard/slice'
import { Details } from '../AppMarketAdminDashboard/AddConnectorOverlay/components/PanelComponents/Details'
import { DescriptionAndFeatures } from '../AppMarketAdminDashboard/AddConnectorOverlay/components/PanelComponents/DescriptionAndFeatures'
import { DocumentationAndPrivacy } from '../AppMarketAdminDashboard/AddConnectorOverlay/components/PanelComponents/DocumentationAndPrivacy'
import Pricing from '../AppMarketAdminDashboard/AddConnectorOverlay/components/PanelComponents/Pricing'
import Certificaton from '../AppMarketAdminDashboard/AddConnectorOverlay/components/PanelComponents/Certification'
import { OFFERTYPES } from 'features/Custom/serviceOnboard/types'
import { useTranslation } from 'react-i18next'

/**
 * This is Mainly Meant For Loding App data From Redux Store
 *    and display it for marketplace service Dashboard
 * @export
 * @return {*}
 */
const ViewServiceTabDetails = () => {
  const { t } = useTranslation()
  const [value, setValue] = React.useState(0)

  const appStatusData = useSelector(serviceStatusDataSelector)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          bgcolor: 'background.paper',
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label={
              <InputLabel style={styles.tabLabels}>
                {t(
                  'content.serviceMarketplaceAdminDashboard.overlaySteps.Overview'
                )}
              </InputLabel>
            }
            {...a11yProps(1)}
          />
          <Tab
            label={
              <InputLabel style={styles.tabLabels}>
                {t(
                  'content.serviceMarketplaceAdminDashboard.overlaySteps.DescriptionBenefits'
                )}
              </InputLabel>
            }
            wrapped
            {...a11yProps(2)}
          />
          <Tab
            label={
              <InputLabel style={styles.tabLabels}>
                {t(
                  'content.serviceMarketplaceAdminDashboard.overlaySteps.CaseStudies'
                )}
              </InputLabel>
            }
            wrapped
            {...a11yProps(3)}
          />
          <Tab
            label={
              <InputLabel style={styles.tabLabels}>
                {t(
                  'content.serviceMarketplaceAdminDashboard.overlaySteps.Pricing'
                )}
              </InputLabel>
            }
            wrapped
            {...a11yProps(4)}
          />
          <Tab
            label={
              <InputLabel style={styles.tabLabels}>
                {t('content.appPublish.stepper.certificate')}
              </InputLabel>
            }
            wrapped
            {...a11yProps(5)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Details
          appStatusData={appStatusData}
          offerTypeId={OFFERTYPES.SERVICE}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DescriptionAndFeatures
          appStatusData={appStatusData}
          offerTypeId={OFFERTYPES.SERVICE}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DocumentationAndPrivacy
          appStatusData={appStatusData}
          offerTypeId={OFFERTYPES.SERVICE}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Pricing
          appStatusData={appStatusData}
          offerTypeId={OFFERTYPES.SERVICE}
        />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Certificaton
          appStatusData={appStatusData}
          offerTypeId={OFFERTYPES.SERVICE}
        />
      </TabPanel>
    </Box>
  )
}

export default ViewServiceTabDetails

const styles = {
  tabLabels: {
    color: '#000000',
    fontSize: '20px',
    fontWeight: '500',
    padding: 2,
    cursor: 'pointer',
  },
}
