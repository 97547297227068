import React from 'react'

import Card from '@mui/material/Card'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import { Box, Chip } from '@mui/material'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import {
  ApplicationBannerIcon,
  Button,
  EuroIcon,
  Typography,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { t } from 'i18next'
import StarIcon from '@mui/icons-material/Star'

import { CERTIFICATION_STATUS } from 'components/pages/AppPublish/Certification/types'

import './styles.scss'

export interface ExtentedApplicationCardProps {
  label: string
  id: number | string
  title: string | undefined
  width?: string
  subTitle?: string
  subTitleMinHeight?: string
  imagePath: string
  buttonText?: string
  isFavorite: boolean
  showFavorite?: boolean
  preferenceText?: string
  defaultView: boolean
  defaultViewLabel?: string
  margin?: string
  onBtnClick: Function
  onFavoriteClick: Function
  sponsored?: { type: string; categories: string[] }
  price?: {
    type: 'Free' | 'Per month' | 'Fixed cost' | string
    amount: string | number
  }
  isPersist?: boolean
  isClickAllow?: boolean
  isComingSoon?: boolean
  comingSoonLabel?: string
  certificationStatus?: CERTIFICATION_STATUS
  isPreview?: boolean
}

const CustomApplicationCard = ({
  id,
  label,
  title = '',
  subTitle,
  imagePath,
  buttonText,
  price,
  onBtnClick,
  certificationStatus,
  isComingSoon,
  comingSoonLabel,
  showFavorite,
  isFavorite,
  onFavoriteClick,
  defaultView,
  defaultViewLabel,
  preferenceText,
  isPreview = false,
}: ExtentedApplicationCardProps) => {
  const onFavoriteHandler = (
    event: React.MouseEvent<HTMLElement>,
    id: number | string,
    isFavorite: boolean
  ) => {
    event.stopPropagation()
    onFavoriteClick(id, isFavorite)
  }

  const returnCertificationChip = (status: string | undefined) => {
    switch (status) {
      case CERTIFICATION_STATUS.CERTIFIED:
        return (
          <Box className="chip-wrapper-certified">
            <Chip
              label={t('content.appPublish.certification.certified')}
              variant="outlined"
              size="small"
            />
          </Box>
        )
      default:
        return null
    }
  }
  return (
    <Card
      sx={{
        maxWidth: 320,
        width: '320px',
        borderRadius: '20px',
        maxHeight: '30rem',
        height: '30rem',
        cursor: !isPreview ? 'pointer' : 'initial',
        transition: 'all 1s ease',
        '&:hover': {
          transform: !isPreview ? 'scale(1.05)' : 'initial',
          cursor: defaultView ? 'default' : 'pointer',
        },
      }}
      onClick={() => {
        if (!defaultView) {
          onBtnClick(id)
        }
      }}
    >
      <Box height={200} sx={{ position: 'relative' }}>
        {imagePath ? (
          <CardMedia
            component="img"
            width={'auto'}
            height={200}
            src={imagePath}
            alt={`card-img-of-${id}`}
            loading="lazy"
            sx={{
              aspectRatio: '16 / 10 !important',
              backgroundColor: !isPreview ? 'initial' : '#FAFAFA',
            }}
          />
        ) : (
          <Box
            component={'div'}
            sx={{
              backgroundColor: '#ededee',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}
          >
            <ApplicationBannerIcon />
          </Box>
        )}

        {certificationStatus
          ? returnCertificationChip(certificationStatus)
          : null}

        {showFavorite && (
          <Box
            component={'button'}
            sx={{
              backgroundColor: 'transparent',
              color: '#000',
              outline: '0',
              border: '0',
              borderRadius: '10px',
              cursor: 'pointer',
              position: 'absolute',
              right: '15px',
              top: '15px',
              padding: '0',
              zIndex: 9,
            }}
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              onFavoriteHandler(event, id, isFavorite)
            }}
          >
            {isFavorite ? (
              <StarIcon width={24} height={24} htmlColor="#FDB913" />
            ) : (
              <StarIcon width={24} height={24} htmlColor="#B2B2B2" />
            )}
          </Box>
        )}

        {isComingSoon ? (
          <Box
            component={'div'}
            sx={{
              backgroundColor: 'rgba(0, 0, 0, 0.6)',
              color: '#fff',
              position: 'absolute',
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // zIndex: 9,
              fontWeight: 600,
              fontSize: '24px',
            }}
          >
            {comingSoonLabel}
          </Box>
        ) : null}
      </Box>
      <CardContent sx={{ padding: '20px', height: '200px' }}>
        {defaultView ? (
          <Box
            component={'p'}
            sx={{
              padding: {
                sm: '69px 34px 109px',
                md: '69px 34px 109px',
                lg: '63px 23px 93px',
                xl: '53px 54px 88px',
              },
              textAlign: 'center',
              fontSize: { sm: '16px', md: '16px', lg: '16px', xl: '18px' },
              fontWeight: 600,
              color: '#000',
              lineHeight: 1.45,
            }}
          >
            {defaultViewLabel}
          </Box>
        ) : (
          <React.Fragment>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              <Typography
                variant="caption1"
                sx={{
                  fontWeight: 600,
                  color: '#A6A6A6',
                  fontSize: '16px',
                  wordBreak: 'break-word',
                  lineHeight: '1.2',
                }}
              >
                {label}
              </Typography>
            </Box>
            <Typography
              variant="h3"
              sx={{
                lineHeight: '26.4px',
                color: 'black',
              }}
            >
              {title}
            </Typography>
            <Typography className="description">{subTitle}</Typography>
          </React.Fragment>
        )}
      </CardContent>
      <CardActions
        sx={{
          height: 'auto',
          paddingLeft: '20px',
          paddingRight: '20px',
        }}
      >
        {!defaultView ? (
          <span
            style={{
              verticalAlign: 'bottom',
              // paddingTop: '24px',
            }}
          >
            {!isPreview ? (
              <Button
                variant="text"
                sx={{
                  color: '#4d4d4d',
                  marginTop: '25%',
                }}
                onClick={(e) => {
                  onBtnClick(id)
                  e.stopPropagation()
                  e.nativeEvent.stopImmediatePropagation()
                }}
                endIcon={
                  <ArrowForwardIosIcon
                    sx={{
                      verticalAlign: 'middle',
                    }}
                  />
                }
              >
                {buttonText}
              </Button>
            ) : null}
          </span>
        ) : null}

        <span
          style={{
            marginLeft: 'auto',
          }}
        >
          <span
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            {/* <Box className="chip-wrapper-subscribe">
              <Chip label="SUBSCRIBED" variant="outlined" size="small" />
            </Box> */}
            {preferenceText && (
              <Typography
                variant="h4"
                sx={{
                  textAlign: 'right',
                  margin: 0,
                  color: '#707070',
                  fontSize: '16px',
                  fontWeight: '600',
                  fontStyle: 'italic',
                  lineHeight: 1.64,
                }}
              >
                {preferenceText}
              </Typography>
            )}
            {price?.type ? (
              <React.Fragment>
                <Typography
                  variant="caption3"
                  textAlign={'right'}
                  sx={{
                    fontSize: '16px',
                    fontWeight: 600,
                    color: '#4D4D4D',
                  }}
                >
                  {price?.type}
                </Typography>
                <Typography
                  variant="caption3"
                  textAlign={'right'}
                  sx={{
                    fontSize: '24px',
                    fontWeight: 600,
                    color: '#D31184',
                  }}
                >
                  {price?.amount}{' '}
                  {price?.amount === 'Contact sales' ? null : (
                    <EuroIcon fill="#D31184" />
                  )}
                </Typography>
              </React.Fragment>
            ) : null}
          </span>
        </span>
      </CardActions>
    </Card>
  )
}

export default CustomApplicationCard
