import React from 'react'

const AppStatus = (props: {
  labelProps: {
    label: string
    labelColor: string
    backgroundColor: string
  }
  children?: React.ReactNode
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        backgroundColor: props.labelProps.backgroundColor,
        borderRadius: '5px',
        minWidth: 80,
        width: 'auto',
        padding: '5px 12px',
      }}
    >
      {props.children}
      <label style={{ color: props.labelProps.labelColor, marginLeft: '5px', fontWeight: '500' }}>
        {props.labelProps.label}
      </label>
    </div>
  )
}

export default React.memo(AppStatus)
