import { useLocation, useNavigate } from 'react-router-dom'
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  ListItemIcon,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { Tooltip } from '@mui/material'
import './NavigationDrawer.scss'

export default function SubMenuComp({
  item,
  open,
}: {
  item: any
  open: boolean
}) {
  const navigate = useNavigate()
  const location = useLocation()

  const handleRedirect = (path: string) => {
    navigate(path)
  }

  return (
    <List disablePadding sx={{ display: 'block', marginTop: '3px' }}>
      <ListItem disablePadding>
        <Tooltip title={item?.title} placement="right-end">
          <ListItemButton
            {...(!item?.children
              ? {
                  onClick: () => {
                    handleRedirect(item?.to)
                  },
                  selected: item.to === location.pathname,
                }
              : {})}
            disabled={item?.children ? true : false}
            data-testid={`menu_${item?.title.replace(/ /g, '_')}`}
            sx={{
              margin: '0px',
              padding: '8px',
              minHeight: '2.5rem',
              display: 'flex',
              gap: '.6rem',
              borderTopLeftRadius: 2,
              borderBottomLeftRadius: 2,
              ...(!item?.children
                ? { cursor: 'pointer' }
                : {
                    '&.Mui-disabled': { opacity: 1 },
                  }),
              '&.MuiListItemButton-root.Mui-selected svg path': {
                background:
                  'linear-gradient(90deg, rgb(253, 185, 19) 40%, rgb(211, 17, 132) 62%) !important',
              },
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 'auto',
              }}
            >
              {item?.icon}
            </ListItemIcon>
            {open && (
              <ListItemText
                sx={{
                  margin: 0,
                  fontSize: '1rem',
                  color: 'rgb(51, 51, 51)',
                  letterSpacing: '0.02em',
                  textTransform: 'uppercase',
                  fontWeight: '600',
                }}
                disableTypography
                primary={item?.title}
              />
            )}
          </ListItemButton>
        </Tooltip>
      </ListItem>

      {item?.children?.map((child: any) => (
        <ListItem disablePadding key={item?.children?.indexOf(child)}>
          <Tooltip title={child?.title} placement="right-end">
            <ListItemButton
              onClick={() => {
                handleRedirect(child.to)
              }}
              selected={child.to === location.pathname}
              sx={{
                margin: 0,
                padding: '8px',
                display: 'flex',
                minHeight: '2.5rem',
                gap: '1rem',
                borderTopLeftRadius: 2,
                borderBottomLeftRadius: 2,
                marginLeft: 4,
              }}
            >
              {open && (
                <ListItemText
                  data-testid={`submenu_${child?.title.replace(/ /g, '_')}`}
                  sx={{
                    display: 'inline-block',
                    margin: 0,
                    fontSize: '1.1rem',
                    color: 'rgb(51, 51, 51,0.95)',
                    textTransform: 'capitalize',
                    fontWeight: '500',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                  disableTypography
                  primary={child?.title}
                />
              )}
            </ListItemButton>
          </Tooltip>
        </ListItem>
      ))}
    </List>
  )
}
