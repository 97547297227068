import React, { useEffect } from 'react'
import {
  Box,
  Typography,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { useTranslation } from 'react-i18next'
import { AppDetails } from 'features/apps/details/types'
import { useInView } from 'react-intersection-observer'
import { SERVICE_LABEL_IDS, OBSERVER_OPTIONS } from '../../types'
import { type ServiceStatusDataState } from 'features/Custom/serviceOnboard/types'

function ServiceDescription({
  item,
  handleSetSelectedTab,
}: {
  item: ServiceStatusDataState
  handleSetSelectedTab: (tab: string) => void
}) {
  const { ref, inView } = useInView(OBSERVER_OPTIONS)
  const { t } = useTranslation()

  useEffect(() => {
    if (inView) {
      handleSetSelectedTab(SERVICE_LABEL_IDS.SERVICEDETAIL_DESCRIPTION)
    }
  }, [inView])

  return (
    <div ref={ref} id={SERVICE_LABEL_IDS.SERVICEDETAIL_DESCRIPTION}>
      <Box marginTop="2rem">
        <Typography
          fontSize="20px"
          fontWeight={600}
          color="#000"
          marginBottom="1rem"
        >
          {t('content.appdetail.description.title')}
        </Typography>
        <Typography
          fontSize="1rem"
          fontWeight={400}
          color="#000"
          marginBottom="2rem"
        >
          {/* Created For Dynamic Language shift for Localization. Can be used for future usecase 🏗️ */}
          {/* {
            item.longDescription.filter(
              (language) => language.languageCode === getLanguage()
            )[0].longDescription
          } */}
          {
            item.longDescription.filter(
              (language) => language.languageCode === 'en'
            )[0].longDescription
          }
        </Typography>
      </Box>
    </div>
  )
}

export default ServiceDescription
