import React, { useRef } from 'react'
import { Stack, Typography, Paper, Grid, styled } from '@mui/material'
import { useFetchDocumentByTypeMutation } from 'features/Custom/appOnboard/apiSlice'
import pageHeaderImg from '../../../assets/images/priceHeaderBg.svg'
import './Apptile'
import { CONTROLLERS, OFFERTYPES } from 'features/Custom/serviceOnboard/types'
import { useNavigate } from 'react-router-dom'
const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
})

const AppTile = (props: { row: any; type: string }) => {
  const [image, setImage] = React.useState<any>()
  const imgref = useRef<HTMLDivElement>(null)
  const [fetchDocumentByType] = useFetchDocumentByTypeMutation()
  const navigate = useNavigate()
  React.useEffect(() => {
    fetchImage()
  }, [])

  const fetchImage = async () => {
    const response = await fetchDocumentByType({
      id: props.row.id,
      documentId: props.row.logo,
      controller:
        props.type === OFFERTYPES.APP
          ? CONTROLLERS.APP_ONBOARD
          : CONTROLLERS.SERVICE_ONBOARD,
      offerTypeId: props.type,
    }).unwrap()
    const file = response.data
    setImage(URL.createObjectURL(file))
  }

  return (
    <Paper
      sx={{
        p: 2,
        minWidth: '100%',
        cursor: 'pointer',
        borderRadius: '18px',
        backgroundColor: 'transparent',
      }}
      onClick={() =>
        navigate(
          `/${props.type === OFFERTYPES.APP ? 'appdetail' : 'servicedetail'}/${
            props.row.id
          }`
        )
      }
    >
      <Grid container spacing={2}>
        <Grid item xs ref={imgref}>
          <Img
            id="appimg"
            style={{
              width: 85,
              height: 85,
              objectFit: 'contain',
              padding: 2,
            }}
            alt="app"
            src={image ?? pageHeaderImg}
          />
        </Grid>
        <Grid item zeroMinWidth xs={8} wrap="nowrap">
          <Stack>
            <Typography
              style={{
                fontSize: '12px',
                fontWeight: '900',
                color: '#FDB913',
                padding: 2,
              }}
              noWrap
            >
              {props.row.provider}
            </Typography>
            <Typography
              style={{
                fontSize: '16px',
                fontWeight: '900',
                color: '#000000',
                padding: 2,
              }}
              noWrap
            >
              {props.row.title}
            </Typography>
            <Typography
              style={{
                fontSize: '10px',
                color: '#E34C92',
                padding: 1,
                fontWeight: 'bolder',
                fontFamily: 'Karbon, sans-serif',
              }}
              noWrap
            >
              {props.row.useCases
                ?.map((eachUseCase: any) => `${eachUseCase?.label}`)
                .join(', ')}
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default AppTile
