import { styled, type Theme, type CSSObject } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
const drawerWidth = 340

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeInOut,
    duration: '500ms',
  }),
  overflowX: 'hidden',
  top: '57px',
  borderRadius: '0',
  height: 'calc(100vh - 57px)',
  borderTop: '1px solid #ededee',
  justifyContent: 'space-between',
  paddingLeft: '20px',
  paddingTop: '10px',
  paddingBottom: '40px',
  zIndex: '99',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.easeInOut,
    duration: '500ms',
  }),
  overflowX: 'hidden',
  width: '0px',
  [theme.breakpoints.up('sm')]: {
    width: '20px',
  },
  top: '57px',
  borderRadius: '0',
  height: 'calc(100vh - 57px)',
  borderTop: '1px solid #ededee',
  paddingLeft: '30px',
  paddingTop: '10px',
  paddingBottom: '40px',
  justifyContent: 'space-between',
  zIndex: '99',
})

export const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

export const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
  whiteSpace: 'nowrap',
}))

export const IconButtonStyled = styled(IconButton)<{ open: boolean }>(
  ({ theme, open }) => ({
    position: 'fixed',
    zIndex: 999,
    outline: 0,
    color: theme.palette.text.primary,
    left: open ? '328px' : '20px',
    border: '1px solid #ccc',
    background: '#fff',
    fill: open ? '#4D4D4D' : '#fff',
    width: '22px',
    height: '22px',
    top: '90px',
    boxShadow: '0px 0px 0px 0px transparent !important',
    ...(open && {
      ...openedMixinArrow(theme),
      '& .MuiDrawer-paper': openedMixinArrow(theme),
    }),
    ...(!open && {
      ...closedMixinArrow(theme),
      '& .MuiDrawer-paper': closedMixinArrow(theme),
    }),
  })
)

const openedMixinArrow = (theme: Theme): CSSObject => ({
  width: '22px',
  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.easeInOut,
    duration: '500ms',
  }),
})

const closedMixinArrow = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('all', {
    easing: theme.transitions.easing.easeInOut,
    duration: '300ms',
  }),
  width: '22px',
})
