import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppMarketplaceAdminAPI } from './api'
import { type SearchParams } from 'types/MainTypes'

const fetchApps = createAsyncThunk(
  'appMarketplaceAdmin/fetchApps',
  async ({ params }: { params: SearchParams; token: string }) => {
    try {
      // Call axios instance to get values
      return await AppMarketplaceAdminAPI.getInstance().getAllApps(params)
    } catch (error: unknown) {
      console.error('api call error:', error)
      throw Error('fetchConnectors api call error')
    }
  }
)

export { fetchApps }
