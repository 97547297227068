export enum USECASE_FRAMEWORK_CONDITION_TYPES {
  BEHAVIOR_TWIN_CREDENTIAL = 'BehaviorTwinCredential',
  PCF_CREDENTIAL = 'PcfCredential',
  SUSTAINABILITY_CREDENTIAL = 'SustainabilityCredential',
  QUALITY_CREDENTIAL = 'QualityCredential',
  TRACEABILITY_CREDENTIAL = 'TraceabilityCredential',
  RESILIENCY_CREDENTIAL = 'ResiliencyCredential',
}
export enum CREDENTIAL_TYPES {
  BPN_CREDENTAIL = 'BpnCredential',
  MEMBERSHIP_CREDENTIAL = 'MembershipCredential',
  DISMANTLER_CREDENTIAL = 'DismantlerCredential',
  BEHAVIOR_TWIN_CREDENTIAL = 'BehaviorTwinCredential',
  PCF_CREDENTIAL = 'PcfCredential',
  SUSTAINABILITY_CREDENTIAL = 'SustainabilityCredential',
  QUALITY_CREDENTIAL = 'QualityCredential',
  TRACEABILITY_CREDENTIAL = 'TraceabilityCredential',
  SUMMARY_CREDENTIAL = 'SummaryCredential',
  RESILIENCY_CREDENTIAL = 'ResiliencyCredential',
}
export const usecaseFrameworkConditionTypes: USECASE_FRAMEWORK_CONDITION_TYPES[] =
  [
    USECASE_FRAMEWORK_CONDITION_TYPES.BEHAVIOR_TWIN_CREDENTIAL,
    USECASE_FRAMEWORK_CONDITION_TYPES.PCF_CREDENTIAL,
    USECASE_FRAMEWORK_CONDITION_TYPES.SUSTAINABILITY_CREDENTIAL,
    USECASE_FRAMEWORK_CONDITION_TYPES.QUALITY_CREDENTIAL,
    USECASE_FRAMEWORK_CONDITION_TYPES.TRACEABILITY_CREDENTIAL,
    USECASE_FRAMEWORK_CONDITION_TYPES.RESILIENCY_CREDENTIAL,
  ]
export enum SEARCH_EXPR {
  EXPR = 'name',
}
export enum ACTIONS {
  REVOKE = 'REVOKE',
  DOWNLOAD = 'DOWNLOAD',
}
export enum STATUS {
  ACTIVE = 'Active',
  EXPIRED = 'Expired',
  PENDING = 'Pending',
  REVOKED = 'Revoked',
  SUSPENDED = 'Suspended',
}
