import React from 'react'
import { Box } from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { SingleSectionDetail } from '../common'

interface IdentityWalletProps {
  data: string
}

const getParsedData = (data: string) => {
  try {
    const temp = data.replace(/\n/g, '')
    const parsedJson = JSON.parse(temp)
    return parsedJson
  } catch (error) {
    return {}
  }
}

function IdentityWalletDetails({ data }: IdentityWalletProps) {
  const jsonData = getParsedData(data)

  return (
    <Box marginTop=".5rem" marginBottom="1rem">
      <SingleSectionDetail title="Name" value={jsonData?.name || '-'} />
      <SingleSectionDetail title="BPN" value={jsonData?.bpn || '-'} />
      <SingleSectionDetail title="DID" value={jsonData?.did || '-'} />
      <SingleSectionDetail title="VerKey" value={jsonData?.verKey || '-'} />
      <SingleSectionDetail
        title="Created at"
        value={jsonData?.createdAt || '-'}
      />
    </Box>
  )
}

export default IdentityWalletDetails
