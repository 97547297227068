import React from 'react'
import { Stepper } from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { type StepItem } from './ServiceOnboardReleaseProcess'

export const OnboardWrapper = ({
  activePage,
  stepList,
  numberOfSteps,
}: {
  activePage: number
  stepList: StepItem[]
  numberOfSteps: number
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Stepper
        list={stepList}
        activeStep={activePage}
        showSteps={numberOfSteps}
      />
    </div>
  )
}
