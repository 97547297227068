import React, { useEffect, useState } from 'react'
import { type StepItem } from './ServiceOnboardReleaseProcess'
import { OnboardWrapper } from './OnboardWrapper'
import ServiceOnboardSection from './StageSection'
import ServiceOverview from './ServiceOverview'
import { Box } from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import DescriptionAndBenefits from './DescriptionAndBenefits'
import CaseStudies from './CaseStudies'
import Pricing from './Pricing'
import Certification from './Certification'
import { useSelector } from 'react-redux'
import { serviceIdSelector } from 'features/Custom/serviceOnboard/slice'
import TechnicalIntegrationAndBetaTest from './TechnicalIntegrationAndBetaTest'

interface ServiceOnboardType {
  activePage: number
  stepsListWithTechnicalIntegration: StepItem[]
  stepsList: StepItem[]
  numberOfSteps: number
}

export default function ServiceOnboard({
  activePage,
  stepsList,
  stepsListWithTechnicalIntegration,
  numberOfSteps,
}: Readonly<ServiceOnboardType>) {
  const serviceId = useSelector(serviceIdSelector)
  useEffect(() => {
    activeStep()
    window.scrollTo(0, 0)
  }, [activePage])
  const [skipTechnicalIntegrationStep, setSkipTechnicalIntegrationStep] =
    useState(false)
  function activeStep(): JSX.Element | React.ReactNode {
    switch (activePage) {
      case 1:
        return <ServiceOverview />
      case 2:
        return (
          <DescriptionAndBenefits
            skipTechnicalIntegrationStep={setSkipTechnicalIntegrationStep}
          />
        )
      case 3:
        return <CaseStudies />
      case 4:
        return <Pricing />
      case 5:
        return skipTechnicalIntegrationStep ? (
          <Certification />
        ) : (
          <TechnicalIntegrationAndBetaTest />
        )
      case 6:
        return <Certification />
    }
  }
  return (
    <div className="service-release-process-form">
      <ServiceOnboardSection />
      <div className="create-app-section">
        <div className="service-container service-detail__container">
          <OnboardWrapper
            activePage={activePage}
            stepList={
              skipTechnicalIntegrationStep
                ? stepsList
                : stepsListWithTechnicalIntegration
            }
            numberOfSteps={
              skipTechnicalIntegrationStep
                ? stepsList.length
                : stepsListWithTechnicalIntegration.length
            }
          />
          <Box
            sx={{
              padding: 5,
            }}
          >
            {activeStep()}
          </Box>
        </div>
      </div>
    </div>
  )
}
