import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import {
  Box,
  EuroIcon,
  Grid,
  Typography,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { useTranslation } from 'react-i18next'
import CommonService from 'services/CommonService'
import { SERVICE_LABEL_IDS, OBSERVER_OPTIONS } from '../../types'
import { type ServiceStatusDataState } from 'features/Custom/serviceOnboard/types'
import ServicePricingDialog from '../ServicePricingDialog'
import { type PlansType } from 'features/apps/apiSlice'

function ServicePricing({
  item,
  handleSetSelectedTab,
}: {
  item: ServiceStatusDataState
  handleSetSelectedTab: (tab: string) => void
}) {
  const { inView, ref } = useInView(OBSERVER_OPTIONS)
  const [openPriceDialog, setOpenPriceDialog] = useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    if (inView) {
      handleSetSelectedTab(SERVICE_LABEL_IDS.SERVICEDETAIL_PRICING)
    }
  }, [inView])

  return (
    <>
      <ServicePricingDialog
        item={item}
        open={openPriceDialog}
        onClose={() => {
          setOpenPriceDialog(false)
        }}
      />
      <div
        ref={ref}
        className="appdetail-pricing"
        id={SERVICE_LABEL_IDS.SERVICEDETAIL_PRICING}
      >
        <Typography
          marginBottom="1rem"
          fontSize="1.25rem"
          fontWeight="600"
          color="#000"
        >
          {t('content.servicedetails.pricing.title')}
        </Typography>

        <Grid container spacing={2}>
          <Grid item sm={12} md={3}>
            <Grid container>
              <Grid item sm={12} md={5}>
                <Typography fontSize=".875rem" color="#4D4D4F" fontWeight="500">
                  {t('content.servicedetails.pricing.startingFrom')}:
                </Typography>
                <Typography
                  fontSize=".875rem"
                  color="#000"
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  fontWeight="600"
                  gap="5px"
                  flexDirection="row"
                >
                  {item.pricingDetail?.plans?.filter(
                    (plans: PlansType) => plans?.customPricingType
                  ).length <= 0 ? (
                    <EuroIcon fill="#4D4D4F" />
                  ) : null}
                  <span>
                    {item.pricingDetail?.plans?.filter(
                      (plans: PlansType) => plans?.customPricingType
                    ).length ? (
                      <strong>
                        {t('content.appPublish.pricing.customPricing')}
                      </strong>
                    ) : (
                      CommonService.convertPriceToEuroFormat(
                        item.pricingDetail.plans
                          .filter((plan: PlansType) => plan.amount !== '')
                          .sort(
                            (a: PlansType, b: PlansType) =>
                              parseInt(a?.amount) - parseInt(b?.amount)
                          )[0]?.amount
                      )
                    )}
                  </span>
                </Typography>
              </Grid>

              <Grid item sm={12} md={5}>
                <Typography fontSize=".875rem" color="#4D4D4F" fontWeight="500">
                  {t('content.servicedetails.pricing.pricingModel')}:
                </Typography>
                <Typography fontSize=".875rem" color="#000" fontWeight="500">
                  {item?.pricingDetail?.plans?.filter(
                    (plans: PlansType) => plans?.customPricingType
                  ).length > 0
                    ? t('content.partnernetwork.filters.others')
                    : item?.pricingDetail?.plans
                        .filter((plan: PlansType) => plan.amount !== '')
                        .sort(
                          (a: PlansType, b: PlansType) =>
                            parseInt(a?.amount) - parseInt(b?.amount)
                        )[0]?.model}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={9}>
            <Typography
              marginBottom=".5rem"
              fontSize=".875rem"
              fontWeight={500}
              color="#000"
            >
              {t('content.servicedetails.pricing.details')}
            </Typography>
            <Box
              marginBottom=".5rem"
              padding="1.5rem"
              sx={{ boxShadow: '0px 0px 8px #0000001A', borderRadius: '10px' }}
            >
              <Typography fontSize=".875rem" fontWeight={400} color="#000">
                {
                  item.pricingDetail?.localizedSummary.values?.filter(
                    (eachLang: { langCode: string; value: string }) =>
                      eachLang?.langCode === 'en'
                  )[0]?.value
                }
              </Typography>
            </Box>
            <Typography
              variant="button"
              onClick={() => {
                setOpenPriceDialog(true)
              }}
              color="#2484C6"
              fontSize="1rem"
              fontWeight="500"
              sx={{ cursor: 'pointer' }}
            >
              {t('content.servicedetails.pricing.seeAllPricing')}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default ServicePricing
