export type useCaseItems = {
  id: string
  label: string
}

export enum AppStatusType {
  IN_REVIEW = 'IN_REVIEW',
  PUBLISHED = 'PUBLISHED',
  REJECTED = 'REJECTED',
  DRAFT = 'DRAFT',
}

export enum TableFilters {
  ALL = 'ALL',
  IN_REVIEW = 'IN_REVIEW',
  PUBLISHED = 'PUBLISHED',
}

export enum PossibleActionTypes {
  PUBLISH = 'PUBLISH',
  REJECT = 'REJECT',
  DISABLE = 'DISABLE',
  DELETE = 'DELETE',
}

export type marketplaceAdminResponseType = {
  id?: string
  title: string
  logo: string
  provider: string
  useCases: useCaseItems[] | []
  dateSubmitted: Date
  status: AppStatusType
  possibleActionsForMarketAdmin: PossibleActionTypes[]
}

export declare type PaginFetchArgs = {
  page: number
  size: number
  sorting?: string
  offerStatusIdFilter?: string
}

export type PaginationData = {
  totalElements: number
  page: number
}

export declare type PaginMeta = {
  totalElements: number
  totalPages: number
  page: number
  contentSize: number
}

export declare type PaginResult<T> = {
  meta: PaginMeta
  content: T[]
}

export declare type AppMarketplaceAdminResponse = {
  meta: PaginationData
  content: Array<marketplaceAdminResponseType>
}

export type SearchParams = {
  readonly name?: string
  readonly page: number
  readonly size: number
}

export type MarketplaceInitialState = {
  paginationData: PaginationData
  appList: Array<marketplaceAdminResponseType>
  loading: boolean
  error: string
}
