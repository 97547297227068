import React, { useEffect, useState } from 'react'
import { PageHeader } from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { PageBreadcrumb } from 'components/shared/frame/PageBreadcrumb/PageBreadcrumb'
import { useTranslation } from 'react-i18next'
import pageHeaderImg from './../../../assets/logo/page-header-img.svg'
import { Box, Tabs, Tab } from '@mui/material'
import { PAGES, ROLES } from 'types/Constants'
import AppMarketAdminDashboard from '../AppMarketAdminDashboard'
import UserService from 'services/UserService'
import { OFFERTYPES } from 'features/Custom/serviceOnboard/types'
import { ServiceMarketplaceDashboard } from '../ServiceMarketplaceDashboard'

interface TabPanelProps {
  children?: React.ReactNode
  index: string
  value: string
}
/**
 * * Used to generate Tab Component
 * @param {TabPanelProps} props
 * @return {*}
 */
function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`marketplace-management-tabpanel-${index}`}
      aria-labelledby={`marketplace-management-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `marketplace-management-${index}`,
    'aria-controls': `marketplace-management-tabpanel-${index}`,
  }
}
/**
 * * this is Entry Point for marketplace management
 * @return {*}
 */
const MarketplaceManagement = () => {
  const { t } = useTranslation()
  const queryParams = new URLSearchParams(window.location.search)
  const offerTypeIdString = queryParams.get('offerTypeId')
  const offerTypeId =
    offerTypeIdString !== null ? offerTypeIdString : OFFERTYPES.APP
  const [tabIndex, setTabIndex] = useState<number>(
    offerTypeId === OFFERTYPES.APP ? 0 : 1
  )
  const [value, setValue] = useState<string>(offerTypeId)
  const [protectedTabs, setProtectedTabs] = useState<string[]>([])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue === 0) {
      setValue(OFFERTYPES.APP)
      setTabIndex(0)
    } else {
      setValue(OFFERTYPES.SERVICE)
      setTabIndex(1)
    }
  }

  // * Can be Useful for getting Routes if Business Needs changes for Role access
  function getProtectedTabs() {
    if (UserService.hasRole(ROLES.CX_ADMIN)) {
      setProtectedTabs(['Apps', 'Services'])
    } else {
      setProtectedTabs(['Services'])
    }
  }

  useEffect(() => {
    if (protectedTabs.length === 0) {
      getProtectedTabs()
    }
  }, [protectedTabs])

  return (
    <main className="connector-page-container">
      <PageHeader
        title={t('content.appMarketplaceAdminDashboard.headerTitleLabel')}
        subTitle={t('content.appMarketplaceAdminDashboard.subTitleLabel')}
        content={t('content.appMarketplaceAdminDashboard.content')}
        topPage={true}
        headerHeight={200}
        bgImage={pageHeaderImg}
      >
        <PageBreadcrumb
          breadcrumbs={[
            {
              label: `${t(
                'content.appMarketplaceAdminDashboard.MarketplaceBreadcum'
              )}`,
              link: '',
              type: 'text',
            },
          ]}
          backButtonVariant="text"
        />
      </PageHeader>
      <section>
        <Box
          sx={{ width: '100%' }}
          className={'marketplace-management_container'}
        >
          <Box className={'marketplace-management_tabs-box'}>
            <Tabs
              value={tabIndex}
              onChange={handleChange}
              aria-label="managementTabs"
              className={'marketplace-management_tabs'}
              sx={{
                '.Mui-selected': {
                  color: '#D31184 !important',
                  fontWeight: 600,
                },
              }}
            >
              {protectedTabs.map((tab, index) => {
                return (
                  <Tab
                    label={tab}
                    style={{
                      fontWeight: 600,
                    }}
                    className={'marketplace-management_tab'}
                    {...a11yProps(index)}
                  />
                )
              })}
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={OFFERTYPES.APP}>
            <AppMarketAdminDashboard />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={OFFERTYPES.SERVICE}>
            <ServiceMarketplaceDashboard />
          </CustomTabPanel>
        </Box>
      </section>
    </main>
  )
}

export default React.memo(MarketplaceManagement)
