import React, { useState, useEffect } from 'react'

import {
  Paper,
  Box,
  Grid,
  FormControl,
  Autocomplete,
  TextField,
  InputLabel,
  Divider,
  MenuItem,
  IconButton,
  Backdrop,
  CircularProgress,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'
import { useForm, useFieldArray, Controller } from 'react-hook-form'
import {
  Button,
  Chip,
  PageSnackbar,
  Typography,
  type UploadFileStatus,
  UploadStatus,
  theme,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { useDispatch, useSelector } from 'react-redux'
import DeleteIcon from '@mui/icons-material/Delete'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { QueryStatus } from '@reduxjs/toolkit/dist/query'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

import { type LanguageObject, LANGUAGE_SUPPORTED } from 'utils/utilities'
import Patterns from 'types/Patterns'
import { type DropzoneFile } from 'components/shared/basic/Dropzone'
import { ConnectorFormInputField } from 'components/pages/AppPublish/OverView'
import { PHONE_CODES } from 'types/CountryCode'
import { ENTITYSTATE } from 'features/Custom/appOnboard/types'
import {
  increment,
  serviceIdSelector,
  serviceStatusDataSelector,
  decrement,
  currentActiveStep,
} from 'features/Custom/serviceOnboard/slice'
import {
  useUpdateServiceProviderInfoMutation,
  useUpdateDocumentUploadMutation,
  useUpdateDocumentDetailsMutation,
  useDeleteDocumentMutation,
  useFetchServiceStatusQuery,
} from 'features/Custom/serviceOnboard/apiSlice'
import { setServiceStatus } from 'features/Custom/serviceOnboard/actions'
import { COFINITY_SERVICE_ONBOARDING_DOCUMENTATION_URL } from 'types/Constants'
import { SIZE_LIMITS } from 'types/Config'

type CaseStudyDocumentType = {
  docId?: string
  title: string
  caseStudiesDescription: LanguageObject[]
  caseStudyDocument:
    | DropzoneFile
    | {
        docId?: string
        name?: string
        status?: string
      }
    | null
  entityState: string
}

interface CaseStudiesType {
  relevantCaseStudies: CaseStudyDocumentType[]
  serviceProvider: string
  website: string
  email: string
  phone: string
  countryCode: string
}

const CaseStudies = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const serviceId = useSelector(serviceIdSelector)
  const activePage = useSelector(currentActiveStep)
  const [appPageNotification, setAppPageNotification] = useState(false)
  const [updateServiceProviderInfo, { status: updateServiceProviderStatus }] =
    useUpdateServiceProviderInfoMutation()
  const [UpdateDocumentDetails, { status: updateDocumentStatus }] =
    useUpdateDocumentDetailsMutation()
  const [deleteDocument, { status: updateDocumentDeleteStatus }] =
    useDeleteDocumentMutation()
  const serviceStatusData = useSelector(serviceStatusDataSelector)
  const [selectedLanguage, setSelectedLanguage] = useState<string[]>(['en'])
  const [visibleDropdown, setVisibleDropdown] = useState<boolean>(false)
  const fixedLanguage = [LANGUAGE_SUPPORTED[0]]
  const [updateDocumentUpload] = useUpdateDocumentUploadMutation()
  const [appPageSnackbar, setAppPageSnackbar] = useState<boolean>(false)
  const { data: fetchServiceStatus } = useFetchServiceStatusQuery(
    serviceId ?? '',
    {
      refetchOnMountOrArgChange: true,
    }
  )

  const defaultValues = {
    relevantCaseStudies: (serviceStatusData?.relatedDocuments?.length &&
      serviceStatusData?.relatedDocuments?.map((eachValidDoc) => {
        return {
          docId: eachValidDoc?.id,
          title: eachValidDoc?.title ?? '',
          caseStudiesDescription: LANGUAGE_SUPPORTED.map((language, index) => {
            return {
              [language]: {
                shortDescription:
                  eachValidDoc?.localizedSummary?.values?.filter(
                    (appStatus: any) => appStatus.langCode === language
                  )[0]?.value,
                longDescription: '',
              },
            }
          }),
          caseStudyDocument: {
            docId: eachValidDoc?.id,
            name: eachValidDoc?.documentName,
            status: UploadStatus.UPLOAD_SUCCESS,
          },
          entityState: eachValidDoc?.entityState && ENTITYSTATE.UPDATED,
        }
      })) || [
      {
        docId: '00000000-0000-0000-0000-000000000000',
        title: '',
        caseStudiesDescription: LANGUAGE_SUPPORTED.map((language, index) => {
          return {
            [language]: {
              shortDescription: '',
              longDescription: '',
            },
          }
        }),
        entityState: ENTITYSTATE.ADDED,
        caseStudyDocument: null,
      },
    ],
    countryCode:
      serviceStatusData?.contactNumber?.length > 1
        ? serviceStatusData?.contactNumber?.split(' ')[0]
        : '+49',
    email: serviceStatusData?.contactEmail ?? '',
    phone: serviceStatusData?.contactNumber?.split(' ')[1] ?? '',
    serviceProvider: serviceStatusData?.provider,
    website:
      serviceStatusData?.providerUri?.split('https://').length > 1
        ? serviceStatusData?.providerUri?.split('https://')[1]
        : serviceStatusData?.providerUri ?? '',
  }

  const {
    handleSubmit,
    getValues,
    control,
    trigger,
    resetField,
    clearErrors,
    formState: { errors, isDirty, submitCount },
    setValue,
  } = useForm<CaseStudiesType>({
    defaultValues,
    mode: 'onChange',
  })

  const { fields, append, remove, update } = useFieldArray({
    name: 'relevantCaseStudies',
    control,
  })

  const defaultDocuments = defaultValues.relevantCaseStudies
  useEffect(() => {
    async function getDocData() {
      if (
        serviceStatusData?.relatedDocuments[0]?.localizedSummary?.values?.filter(
          (appStatus: any) => appStatus.langCode === 'de'
        )[0]?.value.length >= 9
      ) {
        const newLanguages = [...fixedLanguage, 'de']
        setSelectedLanguage(newLanguages)
      }
      const images = defaultDocuments?.map((item: any) => {
        if (item.docId !== '00000000-0000-0000-0000-000000000000') {
          return {
            id: item?.caseStudyDocument?.docId ?? '',
            name: item?.caseStudyDocument?.name ?? '',
            status:
              item?.caseStudyDocument?.docId !==
              '00000000-0000-0000-0000-000000000000'
                ? UploadStatus.UPLOAD_SUCCESS
                : '',
          }
        } else {
          return null
        }
      })

      if (images?.length > 0) {
        const nextFiles = images
        const setFileStatus = (fileIndex: number, status: UploadFileStatus) => {
          nextFiles[fileIndex] = {
            id: images[fileIndex]?.id,
            name: images[fileIndex]?.name,
            status,
          }
        }

        for (let fileIndex = 0; fileIndex < images.length; fileIndex++) {
          if (nextFiles[fileIndex] !== null) {
            setFileStatus(fileIndex, UploadStatus.UPLOAD_SUCCESS)
            setValue(
              `relevantCaseStudies.${fileIndex}.caseStudyDocument`,
              nextFiles[fileIndex]
            )
            setValue(
              `relevantCaseStudies.${fileIndex}.title`,
              defaultDocuments[fileIndex]?.title ?? ''
            )
            LANGUAGE_SUPPORTED.forEach(function (language, index) {
              setValue(
                `relevantCaseStudies.${fileIndex}.caseStudiesDescription.${index}.${language}.shortDescription`,
                (defaultDocuments[fileIndex]?.caseStudiesDescription?.[index]?.[
                  language
                ]?.shortDescription as any as never) ?? ''
              )
              clearErrors(
                `relevantCaseStudies.${fileIndex}.caseStudiesDescription.${index}.${language}.shortDescription`
              )
            })
            clearErrors(`relevantCaseStudies.${fileIndex}.title`)
            clearErrors(`relevantCaseStudies.${fileIndex}.caseStudyDocument`)
            await trigger(`relevantCaseStudies.${fileIndex}.caseStudyDocument`)
          }
        }
      }
    }
    Promise.all([getDocData()])
  }, [serviceStatusData])

  useEffect(() => {
    dispatch(setServiceStatus(fetchServiceStatus))
  }, [dispatch, fetchServiceStatus])

  const uploadCaseStudyDocument = async (
    files: any,
    index: number,
    documentToBeUpdated: CaseStudyDocumentType,
    buttonLabel: string
  ) => {
    if (files?.status !== UploadStatus.UPLOAD_SUCCESS) {
      const value = files
      const responsId = null
      const setFileStatus = (status: UploadFileStatus) => {
        const nextFiles = {
          name: value.name,
          size: value.size,
          status,
        }
        setValue(
          `relevantCaseStudies.${index}.caseStudyDocument`,
          nextFiles as any
        )
      }
      setFileStatus(UploadStatus.UPLOADING)
      uploadDocumentApi(
        serviceId,
        'APP_TECHNICAL_INFORMATION',
        value,
        documentToBeUpdated
      )
        .then((res) => {
          setFileStatus(UploadStatus.UPLOAD_SUCCESS)
          return res
        })
        .catch((error) => {
          setFileStatus(UploadStatus.UPLOAD_ERROR)
        })
      return responsId
    } else {
      await UpdateDocumentDetails({
        serviceId,
        body: [
          {
            id: documentToBeUpdated?.docId,
            documentName: documentToBeUpdated?.caseStudyDocument?.name,
            title: documentToBeUpdated?.title,
            localizedSummary: {
              values: LANGUAGE_SUPPORTED.map((language, index) => {
                return {
                  langCode: language,
                  value:
                    documentToBeUpdated?.caseStudiesDescription[index][language]
                      .shortDescription,
                }
              }),
            },
            entityState:
              documentToBeUpdated.entityState === ENTITYSTATE.DELETED
                ? documentToBeUpdated.entityState
                : buttonLabel === 'save' && submitCount > 0
                ? ENTITYSTATE.UPDATED
                : documentToBeUpdated.entityState,
          },
        ],
      }).unwrap()
    }
  }

  const uploadDocumentApi = async (
    serviceId: string,
    documentTypeId: string,
    file: any,
    documentToBeUpdated: CaseStudyDocumentType
  ) => {
    const data = {
      serviceId,
      documentTypeId,
      body: { file },
    }
    await updateDocumentUpload(data)
      .unwrap()
      .then(async (result) => {
        await UpdateDocumentDetails({
          serviceId,
          body: [
            {
              id:
                documentToBeUpdated.entityState === ENTITYSTATE.ADDED &&
                (documentToBeUpdated.docId ===
                  '00000000-0000-0000-0000-000000000000' ||
                  documentToBeUpdated.docId === '')
                  ? result
                  : documentToBeUpdated.docId,
              documentName: documentToBeUpdated?.caseStudyDocument?.name,
              title: documentToBeUpdated?.title,
              localizedSummary: {
                values: LANGUAGE_SUPPORTED.map((language, index) => {
                  return {
                    langCode: language,
                    value:
                      documentToBeUpdated?.caseStudiesDescription[index]?.[
                        language
                      ]?.shortDescription,
                  }
                }),
              },
              entityState: documentToBeUpdated?.entityState,
            },
          ],
        }).unwrap()
      })
  }

  const handleValidationCheck = async (
    data: CaseStudiesType,
    buttonLabel: string
  ) => {
    const isValidData = await trigger([
      'countryCode',
      'email',
      'phone',
      'relevantCaseStudies',
      'serviceProvider',
      'website',
    ])
    if (isValidData) {
      onServiceCaseStudiesSubmit(data, buttonLabel)
    }
  }
  const onServiceCaseStudiesSubmit = async (
    data: CaseStudiesType,
    buttonLabel: string
  ) => {
    if (isDirty) {
      const promises = [
        updateServiceProviderInfo({
          body: {
            providerUri: data.website,
            contactEmail: data.email,
            contactNumber: `${data.countryCode} ${data.phone}`,
          },
          serviceId,
        }),
      ]
      try {
        await Promise.all(promises)
        await Promise.all(
          data.relevantCaseStudies
            .filter(({ caseStudyDocument }) => caseStudyDocument !== null)
            .map(async (doc, index) => {
              await uploadCaseStudyDocument(
                doc.caseStudyDocument,
                index,
                doc,
                buttonLabel
              )
            })
        )
        buttonLabel === 'saveAndProceed' && dispatch(increment())
        buttonLabel === 'save' && setAppPageSnackbar(true)
      } catch (error) {
        setAppPageNotification(true)
      }
    } else {
      buttonLabel === 'saveAndProceed' && dispatch(increment())
      buttonLabel === 'save' && setAppPageSnackbar(true)
    }
  }
  const onBackIconClick = () => {
    dispatch(setServiceStatus(fetchServiceStatus))
    dispatch(decrement())
  }
  return (
    <Paper
      elevation={5}
      style={{
        borderRadius: '30px',

        border: '1px solid  rgba(250, 250, 250, 1)',
      }}
    >
      <Box className="certification__item-box">
        <div className="app-market-card">
          <Box
            mt={4}
            sx={{
              backgroundColor: theme.palette.stepper.stepCurrent,
              borderRadius: '50%',
              width: '30px',
              height: '30px',
              color: '#fff',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Typography
              variant="body1"
              fontSize="14px"
              color="#fff"
              sx={{
                margin: 'auto',
                paddingTop: '4px',
                width: 'fit-content',
              }}
            >
              {activePage}
            </Typography>
          </Box>
          <Typography variant="h3" mt={5} mb={4} align="center">
            {t('content.servicePublish.caseStudies.headerTitle')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={11} sx={{ mr: 'auto', ml: 'auto' }}>
              <Typography variant="body2" align="center">
                {t('content.servicePublish.caseStudies.headerDescription')}
              </Typography>
            </Grid>
          </Grid>
          <form className="header-description">
            <Typography variant="h3" mt={5} mb={2}>
              {t('content.servicePublish.caseStudies.relevantDocsHeading')}
            </Typography>
            {fields
              .filter((field) => field.entityState !== ENTITYSTATE.DELETED)
              .map((field, fieldIndex) => {
                return (
                  <React.Fragment key={fieldIndex}>
                    <div className="form-field" key={field.id}>
                      {fieldIndex > 0 && (
                        <Chip
                          sx={{
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            float: 'right',
                          }}
                          label={t(
                            'content.appPublish.DocumentationAndPrivacy.removeDocuments'
                          )}
                          icon={<DeleteIcon color="error" />}
                          onClick={() => {
                            if (field.entityState === ENTITYSTATE.ADDED) {
                              remove(fieldIndex)
                            } else {
                              update(fieldIndex, {
                                docId: field.docId,
                                title: field.title,
                                caseStudiesDescription:
                                  field.caseStudiesDescription,
                                caseStudyDocument: field.caseStudyDocument,
                                entityState: ENTITYSTATE.DELETED,
                              })
                            }
                          }}
                        />
                      )}
                      <ConnectorFormInputField
                        {...{
                          control,
                          trigger,
                          errors,
                          name: `relevantCaseStudies.${fieldIndex}.title` as const,
                          label: t(
                            'content.servicePublish.caseStudies.releventDocsTitle'
                          ),
                          placeholder: `${t(
                            'content.servicePublish.caseStudies.releventDocsTitlePlaceHolder'
                          )}`,
                          type: 'input',
                          rules: {
                            required: {
                              value: false,
                              message: `${t(
                                'content.servicePublish.caseStudies.releventDocsTitle'
                              )} ${t(
                                'content.appPublish.appReleaseForm.isMandatory'
                              )} `,
                            },
                            minLength: {
                              value: 5,
                              message: `${t(
                                'content.appPublish.appReleaseForm.minimum'
                              )} 5 ${t(
                                'content.appPublish.appReleaseForm.charactersRequired'
                              )} `,
                            },
                            pattern: {
                              value: Patterns.appMarketCard.releventDocsTitle,
                              message: `${t(
                                'content.appPublish.appReleaseForm.validCharactersIncludes'
                              )} A - Za - z0 - 9.: _ - @& `,
                            },
                            maxLength: {
                              value: 40,
                              message: `${t(
                                'content.appPublish.appReleaseForm.maximum'
                              )} 40 ${t(
                                'content.appPublish.appReleaseForm.charactersAllowed'
                              )} `,
                            },
                          },
                        }}
                      />
                      {errors?.relevantCaseStudies &&
                        errors?.relevantCaseStudies[fieldIndex] &&
                        errors.relevantCaseStudies[fieldIndex]?.title?.type ===
                          'required' && (
                          <Typography
                            variant="body2"
                            className="file-error-msg"
                          >
                            {
                              errors.relevantCaseStudies[fieldIndex]?.title
                                ?.message
                            }
                          </Typography>
                        )}
                      {errors?.relevantCaseStudies &&
                        errors?.relevantCaseStudies[fieldIndex] &&
                        errors.relevantCaseStudies[fieldIndex]?.title?.type ===
                          'maxLength' && (
                          <Typography
                            variant="body2"
                            className="file-error-msg"
                          >
                            {
                              errors.relevantCaseStudies[fieldIndex]?.title
                                ?.message
                            }
                          </Typography>
                        )}
                      {errors?.relevantCaseStudies &&
                        errors?.relevantCaseStudies[fieldIndex] &&
                        errors.relevantCaseStudies[fieldIndex]?.title?.type ===
                          'minLength' && (
                          <Typography
                            variant="body2"
                            className="file-error-msg"
                          >
                            {
                              errors.relevantCaseStudies[fieldIndex]?.title
                                ?.message
                            }
                          </Typography>
                        )}
                    </div>
                    {LANGUAGE_SUPPORTED.filter((language) =>
                      selectedLanguage.some((selected) => selected === language)
                    ).map((language, index) => {
                      return (
                        <div className="form-field" key={index}>
                          <ConnectorFormInputField
                            {...{
                              control,
                              trigger,
                              errors,
                              name: `relevantCaseStudies.${fieldIndex}.caseStudiesDescription.${index}.${language}.shortDescription` as const,
                              type: 'input',
                              label: `${t(
                                'content.servicePublish.caseStudies.shortDescription'
                              )} ${`(${language})`}`,
                              textarea: true,
                              placeholder: `${t(
                                'content.servicePublish.caseStudies.shortDescriptionPlaceholder'
                              )}`,
                              rules: {
                                required: {
                                  value: false,
                                  message: `${t(
                                    'content.servicePublish.caseStudies.shortDescriptionPlaceholder'
                                  )} (${language}) ${t(
                                    'content.appPublish.appReleaseForm.isMandatory'
                                  )} `,
                                },
                                pattern: {
                                  value:
                                    language === 'en'
                                      ? Patterns.appMarketCard
                                          .releventDocumentDescriptionEN
                                      : Patterns.appMarketCard
                                          .releventDocumentDescriptionDE,
                                  message: `${t(
                                    'content.appPublish.appReleaseForm.validCharactersIncludes'
                                  )} ${
                                    language === 'en'
                                      ? 'a-zA-Z0-9 !?@&#\'"()_-=/*.,;:'
                                      : 'a-zA-ZÀ-ÿ0-9 !?@&#\'"()_-=/*.,;:'
                                  } `,
                                },
                                minLength: {
                                  value: 10,
                                  message: `${t(
                                    'content.appPublish.appReleaseForm.minimum'
                                  )} 10 ${t(
                                    'content.appPublish.appReleaseForm.charactersRequired'
                                  )}`,
                                },
                                maxLength: {
                                  value: 110,
                                  message: `${t(
                                    'content.appPublish.appReleaseForm.maximum'
                                  )} 110 ${t(
                                    'content.appPublish.appReleaseForm.charactersAllowed'
                                  )} `,
                                },
                              },
                            }}
                          />
                          {errors &&
                            errors.relevantCaseStudies &&
                            errors.relevantCaseStudies[fieldIndex]
                              ?.caseStudiesDescription?.[index]?.[language] &&
                            errors.relevantCaseStudies[fieldIndex]
                              ?.caseStudiesDescription?.[index]?.[language]
                              ?.shortDescription?.type === 'required' && (
                              <Typography
                                variant="body2"
                                className="file-error-msg"
                              >
                                {
                                  errors.relevantCaseStudies[fieldIndex]
                                    ?.caseStudiesDescription?.[index]?.[
                                    language
                                  ]?.shortDescription?.message
                                }
                              </Typography>
                            )}
                          {errors &&
                            errors.relevantCaseStudies &&
                            errors.relevantCaseStudies[fieldIndex]
                              ?.caseStudiesDescription?.[index]?.[language] &&
                            errors.relevantCaseStudies[fieldIndex]
                              ?.caseStudiesDescription?.[index]?.[language]
                              ?.shortDescription?.type === 'maxLength' && (
                              <Typography
                                variant="body2"
                                className="file-error-msg"
                              >
                                {
                                  errors.relevantCaseStudies[fieldIndex]
                                    ?.caseStudiesDescription?.[index]?.[
                                    language
                                  ]?.shortDescription?.message
                                }
                              </Typography>
                            )}
                          {errors &&
                            errors.relevantCaseStudies &&
                            errors.relevantCaseStudies[fieldIndex]
                              ?.caseStudiesDescription?.[index]?.[language] &&
                            errors.relevantCaseStudies[fieldIndex]
                              ?.caseStudiesDescription?.[index]?.[language]
                              ?.shortDescription?.type === 'minLength' && (
                              <Typography
                                variant="body2"
                                className="file-error-msg"
                              >
                                {
                                  errors.relevantCaseStudies[fieldIndex]
                                    ?.caseStudiesDescription?.[index]?.[
                                    language
                                  ]?.shortDescription?.message
                                }
                              </Typography>
                            )}
                          <Typography
                            variant="body2"
                            className="form-field"
                            align="right"
                            style={{
                              fontWeight: 600,
                            }}
                          >
                            {getValues()?.relevantCaseStudies[fieldIndex]
                              ?.caseStudiesDescription?.[index]?.[language]
                              ?.shortDescription?.length + '/110'}
                          </Typography>
                        </div>
                      )
                    })}
                    <div className="form-field">
                      <InputLabel
                        id="demo-multiple-name-label"
                        onClick={() => {
                          setVisibleDropdown(!visibleDropdown)
                        }}
                      >
                        <strong
                          style={{
                            color: visibleDropdown ? '#E34C92' : '#4D4D4F',
                          }}
                        >
                          {t(
                            'content.servicePublish.caseStudies.addShortDescOtherLang'
                          )}
                        </strong>
                      </InputLabel>
                    </div>
                    <FormControl sx={{ m: 1, width: '100%' }}>
                      {visibleDropdown && (
                        <Autocomplete
                          multiple
                          fullWidth
                          id="fixed-tags-demo"
                          value={selectedLanguage}
                          onChange={(event, newValue) => {
                            setSelectedLanguage([
                              ...fixedLanguage,
                              ...newValue.filter(
                                (eachLan) =>
                                  fixedLanguage.indexOf(eachLan) === -1
                              ),
                            ])
                            LANGUAGE_SUPPORTED.filter((newLanguage) =>
                              newValue.every((lang) => lang !== newLanguage)
                            ).forEach((language) => {
                              const index = LANGUAGE_SUPPORTED.indexOf(language)
                              resetField(
                                `relevantCaseStudies.${fieldIndex}.caseStudiesDescription.${index}.${language}.shortDescription`
                              )
                              setValue(
                                `relevantCaseStudies.${fieldIndex}.caseStudiesDescription.${index}.${language}.shortDescription`,
                                '' as string as never
                              )
                            })
                          }}
                          options={LANGUAGE_SUPPORTED}
                          getOptionLabel={(option) => option}
                          renderTags={(tagValue, getTagProps) => {
                            return tagValue.map(
                              (option, index) => null
                              // eslint-disable-next-line react/jsx-key
                              // <Chip
                              //   label={option}
                              //   size="small"
                              //   color="primary"
                              //   {...getTagProps({ index })}
                              //   disabled={fixedLanguage.indexOf(option) !== -1}
                              //   deleteIcon={<CloseIcon />}
                              // />
                            )
                          }}
                          style={{ width: '100%' }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder={t(
                                'content.servicePublish.avalibleLanguages'
                              )}
                              variant="standard"
                            />
                          )}
                        />
                      )}
                    </FormControl>
                    <div className="form-field">
                      <InputLabel
                        id="demo-multiple-name-label"
                        sx={{
                          marginBottom: 2.5,
                        }}
                      >
                        <strong>
                          {t('content.servicePublish.caseStudies.addDocuments')}
                        </strong>
                      </InputLabel>
                      <ConnectorFormInputField
                        {...{
                          control,
                          trigger,
                          errors,
                          name: `relevantCaseStudies.${fieldIndex}.caseStudyDocument` as const,
                          type: 'dropzone',
                          acceptFormat: {
                            'application/pdf': ['.pdf'],
                          },
                          maxFilesToUpload: 1,
                          maxFileSize: SIZE_LIMITS.FILESIZE_5MB,
                          rules: {
                            required: {
                              value: false,
                              message: `${t(
                                'content.servicePublish.caseStudies.document'
                              )} ${t(
                                'content.appPublish.appReleaseForm.isMandatory'
                              )}`,
                            },
                          },
                        }}
                        handleDelete={async (documentId: string) => {
                          console.log(documentId)
                          setValue(
                            `relevantCaseStudies.${fieldIndex}.caseStudyDocument`,
                            null
                          )
                          setValue(
                            `relevantCaseStudies.${fieldIndex}.docId`,
                            ''
                          )
                          setValue(
                            `relevantCaseStudies.${fieldIndex}.entityState`,
                            ENTITYSTATE.ADDED
                          )
                          try {
                            await deleteDocument(documentId).unwrap()
                          } catch {
                            /* empty */
                          }
                        }}
                      />
                      {errors?.relevantCaseStudies &&
                        errors?.relevantCaseStudies[fieldIndex] &&
                        errors?.relevantCaseStudies[fieldIndex]
                          ?.caseStudyDocument?.type === 'required' && (
                          <Typography
                            variant="body2"
                            className="file-error-msg"
                          >
                            {
                              errors?.relevantCaseStudies[fieldIndex]
                                ?.caseStudyDocument?.message
                            }
                          </Typography>
                        )}
                    </div>
                  </React.Fragment>
                )
              })}
            <Divider>
              {fields.length < 11 && (
                <Chip
                  color="primary"
                  sx={{
                    borderRadius: 5,
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                  label={`${t(
                    'content.servicePublish.caseStudies.addMoreDocs'
                  )} ${11 - fields.length} ${t(
                    'content.servicePublish.caseStudies.addMoreDocsRemaining'
                  )}`}
                  onClick={() => {
                    append({
                      docId: '00000000-0000-0000-0000-000000000000',
                      title: '',
                      caseStudiesDescription: LANGUAGE_SUPPORTED.map(
                        (language) => {
                          return {
                            [language]: {
                              shortDescription: '',
                              longDescription: '',
                            },
                          }
                        }
                      ),
                      caseStudyDocument: null,
                      entityState: ENTITYSTATE.ADDED,
                    })
                  }}
                />
              )}
            </Divider>
            <Typography variant="h3" mt={5} mb={2}>
              {t('content.servicePublish.caseStudies.providerInformation')}
            </Typography>
            <div className="form-field">
              <ConnectorFormInputField
                {...{
                  control,
                  trigger,
                  errors,
                  name: 'serviceProvider',
                  label: (
                    <strong>
                      {t('content.servicePublish.caseStudies.servicePrivider')}{' '}
                      *
                    </strong>
                  ),
                  type: 'input',
                  required: {
                    value: true,
                    message: `${t(
                      'content.servicePublish.caseStudies.servicePrivider'
                    )} ${t('content.appPublish.appReleaseForm.isMandatory')}`,
                  },
                  disabled: true,
                }}
              />
            </div>
            <div className="form-field">
              <ConnectorFormInputField
                {...{
                  control,
                  trigger,
                  errors,
                  name: 'website',
                  type: 'webAddress',
                  label: (
                    <strong
                      style={{
                        color: errors?.website ? '#c02424' : 'inherit',
                      }}
                    >
                      {t('content.servicePublish.caseStudies.website')} *
                    </strong>
                  ),
                  placeholder: `${t(
                    'content.servicePublish.caseStudies.websitePlaceHolder'
                  )}`,
                  rules: {
                    required: {
                      value: true,
                      message: `${t(
                        'content.servicePublish.caseStudies.website'
                      )} ${t(
                        'content.appPublish.appReleaseForm.isMandatory'
                      )} `,
                    },
                    pattern: {
                      value: Patterns.URLWithoutHttp,
                      message: t(
                        'content.appPublish.appPage.pleaseEnterWebsiteURL'
                      ),
                    },
                  },
                }}
              />
              {errors?.website && errors?.website?.type === 'required' && (
                <Typography variant="body2" className="file-error-msg">
                  {errors?.website?.message}
                </Typography>
              )}
            </div>
            <div className="form-field">
              <ConnectorFormInputField
                {...{
                  control,
                  trigger,
                  errors,
                  name: 'email',
                  type: 'input',
                  label: `${t('content.servicePublish.caseStudies.email')} *`,
                  placeholder: `${t(
                    'content.servicePublish.caseStudies.emailPlaceHolder'
                  )}`,
                  rules: {
                    required: {
                      value: true,
                      message: `${t(
                        'content.servicePublish.caseStudies.email'
                      )} ${t(
                        'content.appPublish.appReleaseForm.isMandatory'
                      )} `,
                    },
                    pattern: {
                      value: Patterns.MAIL,
                      message: t(
                        'content.appPublish.DocumentationAndPrivacy.providerEmailvalidation'
                      ),
                    },
                  },
                }}
              />
              {errors?.email && errors?.email?.type === 'required' && (
                <Typography variant="body2" className="file-error-msg">
                  {errors?.email?.message}
                </Typography>
              )}
            </div>
            <InputLabel sx={{ display: 'flex' }}>
              <strong>
                {t('content.servicePublish.caseStudies.phoneNumber') + ' *'}
              </strong>
            </InputLabel>
            <div
              style={{
                display: 'flex',
                marginTop: '-40px',
              }}
            >
              <Controller
                name="countryCode"
                control={control}
                render={({ field }) => {
                  return (
                    <TextField
                      id="standard-select-phone"
                      select
                      defaultValue={defaultValues?.countryCode}
                      variant="standard"
                      onChange={async (event) => {
                        field.onChange(event.target.value)
                        await trigger('countryCode')
                      }}
                      sx={{
                        marginTop: '40px',
                        width: '18%',
                      }}
                    >
                      {PHONE_CODES.map((option) => (
                        <MenuItem key={option.code} value={option.code}>
                          {option.code}
                          <strong style={{ color: '#FDB913' }}>
                            &nbsp;&nbsp;{option.name}
                          </strong>
                        </MenuItem>
                      ))}
                    </TextField>
                  )
                }}
              />
              <div
                className="form-field"
                style={{
                  flex: 1,
                  marginTop: '40px',
                }}
              >
                <ConnectorFormInputField
                  {...{
                    control,
                    trigger,
                    errors,
                    name: 'phone',
                    placeholder: `${t(
                      'content.servicePublish.caseStudies.phoneNumberPlaceHolder'
                    )}`,
                    type: 'input',
                    rules: {
                      required: {
                        value: true,
                        message: `${t(
                          'content.servicePublish.caseStudies.phoneNumber'
                        )} ${t(
                          'content.appPublish.appReleaseForm.isMandatory'
                        )} `,
                      },
                      pattern: {
                        value: Patterns.appPage.newPhone,
                        message: t(
                          'content.apprelease.appPage.pleaseEnterValidContact'
                        ),
                      },
                      maxLength: {
                        value: 15,
                        message: `${t(
                          'content.appPublish.appReleaseForm.maximum'
                        )} 15 ${t(
                          'content.appPublish.appReleaseForm.charactersAllowed'
                        )}`,
                      },
                    },
                  }}
                />
                {errors?.phone && errors?.phone?.type === 'required' && (
                  <Typography variant="body2" className="file-error-msg">
                    {errors?.phone?.message}
                  </Typography>
                )}
              </div>
            </div>
          </form>
        </div>
      </Box>
      <Box
        style={{
          padding: '5% 2% 5% 2%',
          borderBottomLeftRadius: '30px',
          borderBottomRightRadius: '30px',
          background: 'rgba(250, 250, 250, 1)',
        }}
      >
        <PageSnackbar
          open={appPageSnackbar}
          onCloseNotification={() => {
            setAppPageSnackbar(false)
          }}
          severity="success"
          description={t(
            'content.appPublish.appReleaseForm.dataSavedSuccessMessage'
          )}
          autoClose={true}
        />

        <Box textAlign={'center'}>
          <Typography variant="caption2">
            {t('content.appPublish.footerText')}{' '}
            <strong>
              <a
                href={COFINITY_SERVICE_ONBOARDING_DOCUMENTATION_URL}
                target="_blank"
                rel="noreferrer"
              >
                {t('content.appPublish.footerTextLink')}
              </a>
            </strong>
          </Typography>
        </Box>
        <Box textAlign="end" mt={2} pl={5} pr={5}>
          <Button
            startIcon={<KeyboardBackspaceIcon />}
            sx={{
              float: 'left',
            }}
            size="small"
            variant="outlined"
            name="back"
            onClick={() => {
              onBackIconClick()
            }}
          >
            Back
          </Button>
          <Button
            variant="outlined"
            disabled={
              updateServiceProviderStatus === QueryStatus.pending ||
              updateDocumentStatus === QueryStatus.pending ||
              updateDocumentDeleteStatus === QueryStatus.pending
            }
            sx={{ mr: 2 }}
            onClick={handleSubmit((data: CaseStudiesType) =>
              handleValidationCheck(data, 'save')
            )}
          >
            {t('content.appPublish.footerButtons.save')}
          </Button>
          <Button
            variant="contained"
            name="send"
            disabled={
              updateServiceProviderStatus === QueryStatus.pending ||
              updateDocumentStatus === QueryStatus.pending ||
              updateDocumentDeleteStatus === QueryStatus.pending
            }
            onClick={handleSubmit((data: CaseStudiesType) =>
              handleValidationCheck(data, 'saveAndProceed')
            )}
          >
            {t('content.appPublish.footerButtons.saveAndProceed')}
          </Button>
        </Box>
      </Box>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={
          updateServiceProviderStatus === QueryStatus.pending ||
          updateDocumentStatus === QueryStatus.pending ||
          updateDocumentDeleteStatus === QueryStatus.pending
        }
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </Paper>
  )
}
export default CaseStudies
