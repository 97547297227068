import * as React from 'react'
import { useEffect } from 'react'

import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Paper,
  tableCellClasses,
  TableHead,
  styled,
  TableCell,
  Skeleton,
} from '@mui/material'
import TableSortLabel from '@mui/material/TableSortLabel'
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import TablePagination from '@mui/material/TablePagination'
import { ACTIONS } from 'components/pages/AppPublish/TechnicalIntegrationAndBetaTest/types'
import { Typography } from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

import './style.scss'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    padding: '10px 8px',
    borderTop: '1px solid #EDEDED',
    borderBottom: 'none',
    cursor: 'pointer',
    color: '#000000',
    fontFamily: 'Karbon',
    fontWeight: '400',
    fontSize: '18px',
    lineHeight: '25.2px',
    letterSpacing: '0px',
    fontStyle: 'normal',
  },

  [`&.${tableCellClasses.head}`]: {
    color: '#4D4D4D',
    padding: '10px 8px',
    textTransform: 'uppercase',
    borderBottom: '3px solid #EDEDED !important',
    fontFamily: 'Karbon',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '1',
    letterSpacing: '0.5px',
    fontStyle: 'normal',
  },

  [`&.${tableCellClasses.footer}`]: {
    borderBottom: '1px solid #EDEDED',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export enum ALIGNMENTS {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  JUSTIFY = 'justify',
}

export interface ColumnProps<T> {
  field: keyof T
  label: string
  fieldType: string
  cellAlignment: ALIGNMENTS
}
// Generic Row interface
export interface HeaderProps<T> {
  id: string
  data: T
}
export interface CredentialCheckType {
  type: string
}
interface RowProps<T extends CredentialCheckType> {
  row: HeaderProps<T>
  columns: ColumnProps<T>[]
}

interface CollapsibleTableProps<T> {
  data: HeaderProps<T>[]
  columns: ColumnProps<T>[]
}
/**
 * to Check for Actions provided or not to render Action icons
 *
 * @param {*} value
 * @return {*}  {value is string[]}
 */
const isStringArray = (value: any): value is string[] => {
  return Array.isArray(value) && value.every((item) => typeof item === 'string')
}

const Row = <T extends CredentialCheckType>({
  row,
  columns,
  handleToggleOverlay,
}: RowProps<T> & {
  handleToggleOverlay: (type: string, value: boolean, rowData: any) => void
}) => {
  const [open, setOpen] = React.useState(false)
  const [activeRow, setActiveRow] = React.useState('')
  const [highlightRow, setHighlightRow] = React.useState('')
  const highlightCurrentCredential = () => {
    const queryString = window.location.href
    const urlParams = new URL(queryString)
    const credentialName = urlParams.searchParams.get('selectedCredential')

    if (credentialName && credentialName === row.data.type) {
      setHighlightRow('active-row-item')
      setActiveRow((prevActiveRow) =>
        prevActiveRow === 'active-row' ? '' : 'active-row'
      )
      setOpen(!open)
      setTimeout(() => {
        setHighlightRow('')
      }, 2000)
    }

    return credentialName
  }

  useEffect(() => {
    highlightCurrentCredential()
  }, [])

  return (
    <React.Fragment>
      <StyledTableRow
        className={`${activeRow}  ${highlightRow}`}
        onClick={() => {
          setOpen(!open)
        }}
      >
        {columns
          .filter((column) => column.fieldType !== 'expanded')
          .map((column, index) => (
            <StyledTableCell key={index} align={column.cellAlignment}>
              {isStringArray(row.data[column.field]) &&
              column.fieldType === 'actions'
                ? (row.data[column.field] as unknown as string[]).map(
                    //to map actions if string[] suppplied
                    (action, actionIndex) => (
                      <React.Fragment key={actionIndex}>
                        {action === 'EDIT' ? (
                          <IconButton
                            aria-label="edit"
                            disabled={action !== ACTIONS.EDIT}
                            onClick={() => {
                              handleToggleOverlay(ACTIONS.EDIT, true, row)
                            }}
                          >
                            <CreateOutlinedIcon
                              htmlColor={
                                action !== ACTIONS.EDIT ? '#DFDFDF' : 'black'
                              }
                            />
                          </IconButton>
                        ) : (
                          // Render other icons for different actions here
                          <IconButton
                            aria-label="edit"
                            disabled={action !== ACTIONS.DELETE}
                            onClick={() => {
                              handleToggleOverlay(ACTIONS.DELETE, true, row)
                            }}
                          >
                            <DeleteIcon
                              htmlColor={
                                action !== ACTIONS.DELETE ? '#DFDFDF' : 'black'
                              }
                            />
                          </IconButton>
                        )}
                      </React.Fragment>
                    )
                  )
                : column.fieldType === 'status'
                ? row.data[column.field] !== null
                  ? (row.data[column.field] as unknown as string)
                  : '' ?? ''
                : (row.data[column.field] as React.ReactNode)}
            </StyledTableCell>
          ))}
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }}
          colSpan={columns.length}
          className={`${activeRow} ${highlightRow}`}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1, position: 'relative' }}>
              {columns
                .filter((column) => column.fieldType === 'expanded')
                .map((column, index) => (
                  <div key={index}>
                    {row.data[column.field] as unknown as JSX.Element}
                  </div>
                ))}
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => {
                  setOpen(!open)
                }}
                className="table-clean__arrow-up"
              >
                {open ? <KeyboardArrowUpIcon /> : ''}
              </IconButton>
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  )
}

const CleanCollapsibleTable = <T extends CredentialCheckType>({
  data,
  columns,
  searchQuery,
  handleToggleOverlay,
  isLoading,
  searchColumn,
  className,
  defaultSortKey,
}: CollapsibleTableProps<T> & {
  searchQuery: string
  handleToggleOverlay: (type: string, value: boolean, rowData: any) => void
  isLoading: boolean
  searchColumn: (keyof T)[]
  className?: string
  defaultSortKey: keyof T
}) => {
  const [currentPage, setCurrentPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [sorting, setSorting] = React.useState<{
    column: keyof T | '' // Use keyof T to ensure column is a valid key of type T
    order: 'asc' | 'desc'
  }>({
    column: '', // Initial column value
    order: 'asc',
  })
  useEffect(() => {
    handleSortClick(defaultSortKey)
  }, [])

  const handleSortClick = (column: keyof T) => {
    if (column !== 'actions') {
      setSorting((prevSorting) => ({
        column,
        order:
          prevSorting.column === column && prevSorting.order === 'asc'
            ? 'desc'
            : 'asc',
      }))
    }
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value))
    setCurrentPage(0)
  }

  const filteredData = data.filter((item) => {
    return searchColumn.some((column) => {
      const searchStr = String(item.data[column]) // Convert to string
      return searchStr.toLowerCase().includes(searchQuery.toLowerCase())
    })
  })

  const startIndex = currentPage * rowsPerPage
  const endIndex = startIndex + rowsPerPage
  const paginatedData = filteredData.slice(startIndex, endIndex)
  const emptyRows =
    currentPage > 0
      ? Math.max(0, (1 + currentPage) * rowsPerPage - filteredData.length)
      : 0

  const sortedData = [...paginatedData].sort((a, b) => {
    const aValue = a.data[sorting.column as keyof T]
    const bValue = b.data[sorting.column as keyof T]
    if (aValue < bValue) return sorting.order === 'asc' ? -1 : 1
    if (aValue > bValue) return sorting.order === 'asc' ? 1 : -1
    return 0
  })

  return (
    <React.Fragment>
      <TableContainer component={Paper} className={className}>
        <Table>
          <TableHead>
            <StyledTableRow>
              {columns
                .filter((col) => col.fieldType !== 'expanded')
                .slice(1) // Remove the first item from the array
                .map((column, index) => (
                  <StyledTableCell
                    key={index}
                    onClick={() => {
                      handleSortClick(column.field)
                    }}
                    align={column.cellAlignment}
                    colSpan={index === 0 ? 2 : 1}
                  >
                    <TableSortLabel
                      active={sorting.column === column.field}
                      direction={
                        sorting.order === 'desc' ? sorting.order : 'asc'
                      }
                    >
                      {column.label}
                    </TableSortLabel>
                  </StyledTableCell>
                ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row, index) => (
              <Row
                key={index}
                row={row}
                columns={columns}
                handleToggleOverlay={handleToggleOverlay}
              />
            ))}
            {isLoading ? (
              <TableRow
                style={{
                  height: 60,
                }}
              >
                <TableCell colSpan={12}>
                  <Skeleton variant="rectangular" width={'100%'} height={60} />
                </TableCell>
              </TableRow>
            ) : null}
            {filteredData.length <= 0 ? (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={12} align="center">
                  <Typography variant="body3" fontWeight={600}>
                    No fields found
                  </Typography>
                </TableCell>
              </TableRow>
            ) : null}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 60 * emptyRows,
                }}
              >
                <TableCell colSpan={12} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        page={currentPage}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        className="table-clean__pagination"
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </React.Fragment>
  )
}

export default CleanCollapsibleTable
