import * as React from 'react'

import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  Paper,
  tableCellClasses,
  TableHead,
  styled,
  TableCell,
  Skeleton,
} from '@mui/material'
import TableSortLabel from '@mui/material/TableSortLabel'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined'
import DeleteIcon from '@mui/icons-material/Delete'
import TablePagination from '@mui/material/TablePagination'
import dayjs from 'dayjs'
import updateLocale from 'dayjs/plugin/updateLocale'
import { ACTIONS } from 'components/pages/AppPublish/TechnicalIntegrationAndBetaTest/types'
import { Typography } from '@cofinity-x/cofinity-x-portal-shared-components-merged'

dayjs.extend(updateLocale)
dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a few sec',
    m: 'a min',
    mm: '%d min',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years',
  },
})

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#EDEDED',
    color: theme.palette.common.black,
    fontFamily: 'Karbon',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    fontFamily: 'Karbon',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: 'normal',
  },
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}))

export enum ALIGNMENTS {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  JUSTIFY = 'justify',
}

export interface ColumnProps<T> {
  field: keyof T
  label: string
  fieldType: string
  cellAlignment: ALIGNMENTS
}
// Generic Row interface
export interface HeaderProps<T> {
  id: string
  data: T
}

interface RowProps<T> {
  row: HeaderProps<T>
  columns: ColumnProps<T>[]
}

interface CollapsibleTableProps<T> {
  data: HeaderProps<T>[]
  columns: ColumnProps<T>[]
}
/**
 * to Check for Actions provided or not to render Action icons
 *
 * @param {*} value
 * @return {*}  {value is string[]}
 */
const isStringArray = (value: any): value is string[] => {
  return Array.isArray(value) && value.every((item) => typeof item === 'string')
}

const Row = <T,>({
  row,
  columns,
  handleToggleOverlay,
}: RowProps<T> & {
  handleToggleOverlay: (type: string, value: boolean, rowData: any) => void
}) => {
  const [open, setOpen] = React.useState(false)

  return (
    <React.Fragment>
      <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <StyledTableCell align="left">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen(!open)
            }}
          >
            {open ? <KeyboardArrowDownIcon /> : <ChevronRightIcon />}
          </IconButton>
        </StyledTableCell>
        {columns
          .filter((column) => column.fieldType !== 'expanded')
          .map((column, index) => (
            <StyledTableCell key={index} align={column.cellAlignment}>
              {isStringArray(row.data[column.field]) &&
              column.fieldType === 'actions'
                ? (row.data[column.field] as unknown as string[]).map(
                    //to map actions if string[] suppplied
                    (action, actionIndex) => (
                      <React.Fragment key={actionIndex}>
                        {action === 'EDIT' ? (
                          <IconButton
                            aria-label="edit"
                            disabled={action !== ACTIONS.EDIT}
                            onClick={() => {
                              handleToggleOverlay(ACTIONS.EDIT, true, row)
                            }}
                          >
                            <CreateOutlinedIcon
                              htmlColor={
                                action !== ACTIONS.EDIT ? '#DFDFDF' : 'black'
                              }
                            />
                          </IconButton>
                        ) : (
                          // Render other icons for different actions here
                          <IconButton
                            aria-label="edit"
                            disabled={action !== ACTIONS.DELETE}
                            onClick={() => {
                              handleToggleOverlay(ACTIONS.DELETE, true, row)
                            }}
                          >
                            <DeleteIcon
                              htmlColor={
                                action !== ACTIONS.DELETE ? '#DFDFDF' : 'black'
                              }
                            />
                          </IconButton>
                        )}
                      </React.Fragment>
                    )
                  )
                : column.fieldType === 'datetime'
                ? row.data[column.field] !== null
                  ? dayjs(row.data[column.field] as unknown as string).fromNow()
                  : '-' ?? '-'
                : (row.data[column.field] as React.ReactNode)}
            </StyledTableCell>
          ))}
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell
          style={{ paddingBottom: 0, paddingTop: 0 }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              {columns
                .filter((column) => column.fieldType === 'expanded')
                .map((column, index) => (
                  <div key={index}>
                    {row.data[column.field] as unknown as JSX.Element}
                  </div>
                ))}
            </Box>
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </React.Fragment>
  )
}

const CollapsibleTable = <T,>({
  data,
  columns,
  searchQuery,
  handleToggleOverlay,
  isLoading,
  searchColumn,
}: CollapsibleTableProps<T> & {
  searchQuery: string
  handleToggleOverlay: (type: string, value: boolean, rowData: any) => void
  isLoading: boolean
  searchColumn: keyof T
}) => {
  const [currentPage, setCurrentPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(10)
  const [sorting, setSorting] = React.useState<{
    column: keyof T | '' // Use keyof T to ensure column is a valid key of type T
    order: 'asc' | 'desc'
  }>({
    column: '', // Initial column value
    order: 'asc',
  })

  const handleSortClick = (column: keyof T) => {
    console.log(column)

    if (column !== 'actions') {
      setSorting((prevSorting) => ({
        column,
        order:
          prevSorting.column === column && prevSorting.order === 'asc'
            ? 'desc'
            : 'asc',
      }))
    }
  }

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setCurrentPage(newPage)
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value))
    setCurrentPage(0)
  }

  const filteredData = data.filter((item) => {
    const searchStr = item.data[searchColumn] as unknown as string
    return searchStr.toLowerCase().includes(searchQuery.toLowerCase())
  })

  const startIndex = currentPage * rowsPerPage
  const endIndex = startIndex + rowsPerPage
  const paginatedData = filteredData.slice(startIndex, endIndex)
  const emptyRows =
    currentPage > 0
      ? Math.max(0, (1 + currentPage) * rowsPerPage - filteredData.length)
      : 0

  const sortedData = [...paginatedData].sort((a, b) => {
    const aValue = a.data[sorting.column as keyof T]
    const bValue = b.data[sorting.column as keyof T]
    if (aValue < bValue) return sorting.order === 'asc' ? -1 : 1
    if (aValue > bValue) return sorting.order === 'asc' ? 1 : -1
    return 0
  })

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <StyledTableRow>
              <StyledTableCell align="left" />
              {columns
                .filter((col) => col.fieldType !== 'expanded')
                .map((column, index) => (
                  <StyledTableCell
                    onClick={() => {
                      handleSortClick(column.field)
                    }}
                    align={column.cellAlignment}
                    key={index}
                    sx={{
                      cursor: 'pointer',
                    }}
                  >
                    <TableSortLabel
                      active={sorting.column === column.field}
                      direction={
                        sorting.order === 'desc' ? sorting.order : 'asc'
                      }
                    >
                      {column.label}
                    </TableSortLabel>
                  </StyledTableCell>
                ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {sortedData.map((row, index) => (
              <Row
                key={index}
                row={row}
                columns={columns}
                handleToggleOverlay={handleToggleOverlay}
              />
            ))}
            {isLoading ? (
              <TableRow
                style={{
                  height: 60,
                }}
              >
                <TableCell colSpan={12}>
                  <Skeleton variant="rectangular" width={'100%'} height={60} />
                </TableCell>
              </TableRow>
            ) : null}
            {filteredData.length <= 0 ? (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={12} align="center">
                  <Typography variant="body3" fontWeight={600}>
                    No roles found
                  </Typography>
                </TableCell>
              </TableRow>
            ) : null}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 60 * emptyRows,
                }}
              >
                <TableCell colSpan={12} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredData.length}
        page={currentPage}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </React.Fragment>
  )
}

export default CollapsibleTable
