import { useState } from 'react'
import {
  CarouselBox,
  CrossIconGraphic,
  Grid,
  type ImageType,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { Dialog, DialogContent, IconButton } from '@mui/material'

function ImageGallery({
  images,
}: Readonly<{
  images: { url: string; text?: string }[]
}>) {
  const [selectedImage, setSelectedImage] = useState<ImageType>()
  return (
    <Grid container marginY="3rem">
      <Grid item xs={12} width={'400px'}>
        <CarouselBox
          title="images"
          variableWidth
          slidesToShow={1}
          slidesToScroll={1}
          infinite
        >
          {images?.map((img: any) => {
            return (
              <img
                key={images.indexOf(img)}
                style={{
                  width: '100%',
                  height: '320px',
                  objectFit: 'cover',
                  borderRadius: '10px',
                }}
                src={img.url}
                alt={img.text ?? images.indexOf(img)}
                onClick={() => { setSelectedImage(img) }}
              />
            )
          })}
        </CarouselBox>
      </Grid>
      {selectedImage && (
        <Dialog
          sx={{
            '.MuiPaper-root': {
              minWidth: '400px',
              borderRadius: '4px',
            },
          }}
          open={true}
          onClose={(event, reason) => {
            if (reason === 'backdropClick') {
              setSelectedImage(undefined) 
            }
          }}
        >
          <DialogContent
            sx={{
              padding: 0,
              backgroundColor: 'transparent',
            }}
          >
            <IconButton
              aria-label="close"
              sx={{
                position: 'absolute',
                right: 16,
                top: 16,
                backgroundColor: '#fff',
              }}
              onClick={() => { setSelectedImage(undefined) }}
            >
              <CrossIconGraphic />
            </IconButton>
            <img
              src={selectedImage?.url}
              alt={selectedImage?.text}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
            />
          </DialogContent>
        </Dialog>
      )}
    </Grid>
  )
}

export default ImageGallery
