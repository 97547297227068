import {
  Box,
  Typography,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { SingleSectionDetail, SingleSectionTitle } from '../common'

import LocalOfferIcon from '@mui/icons-material/LocalOffer'
import { adminRegistrationSelector } from 'features/admin/registration/slice'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

function DetailsSection() {
  const { t } = useTranslation()
  const { detailLoading, companyDetail: selectedCompany } = useSelector(
    adminRegistrationSelector
  )

  const vatvalue: any = selectedCompany?.uniqueIds?.map((item: any) => {
    return item.value
  }) ?? [''] // Added For unhandled Expection from backend (if backend gives 502 or 503)
  const vattype: any = selectedCompany?.uniqueIds?.map((item: any) => {
    return item.type
  }) ?? [''] // Added For unhandled Expection from backend (if backend gives 502 or 503)
  const getLocaleStr = (str: string) => {
    if (str === 'ACTIVE_PARTICIPANT') {
      return t(
        'content.admin.registration-requests.overlay.activeParticipation'
      )
    } else if (str === 'APP_PROVIDER') {
      return t('content.admin.registration-requests.overlay.appProvider')
    } else {
      return t('content.admin.registration-requests.overlay.serviceProvider')
    }
  }
  const getCompanyID = (companyId: any) => {
    switch (companyId) {
      case 'COMMERCIAL_REG_NUMBER':
        return 'EORI'
      case 'VAT_ID':
        return 'VAT ID'
      case 'LEI_CODE':
        return 'LEI CODE'
      case 'VIES':
        return 'VIES'
      case 'EORI':
        return 'EORI'
      default:
        return 'Company Identifier'
    }
  }

  return !detailLoading && selectedCompany !== undefined ? (
    <Box>
      <Box marginTop="1rem" marginBottom="1rem">
        <SingleSectionTitle
          title={t(
            'content.admin.registration-requests.overlay.companydatatitle'
          )}
        />
        <SingleSectionDetail
          title={t('content.admin.registration-requests.overlay.companyName')}
          value={selectedCompany?.name}
        />
        <SingleSectionDetail title="BPN" value={selectedCompany?.bpn ?? '-'} />
        <SingleSectionDetail
          title={getCompanyID(vattype[0])}
          value={vatvalue}
        />
      </Box>
      <div
        style={{
          borderBottomWidth: '1px',
          borderColor: '#e3e3e3',
          borderBottomStyle: 'solid',
        }}
      />
      <Box marginTop="1rem" marginBottom="1rem">
        <SingleSectionTitle
          title={t('content.admin.registration-requests.overlay.address')}
        />
        <SingleSectionDetail
          title="Street"
          value={selectedCompany?.streetName ?? '-'}
        />
        <SingleSectionDetail
          title="PLZ/City"
          value={`${selectedCompany?.zipCode}/${selectedCompany?.city}`}
        />
        <SingleSectionDetail
          title="Country"
          value={selectedCompany?.countryDe ?? '-'}
        />
      </Box>
      <div
        style={{
          borderBottomWidth: '1px',
          borderColor: '#e3e3e3',
          borderBottomStyle: 'solid',
        }}
      />
      <Box marginTop="1rem" marginBottom="1rem">
        <SingleSectionTitle
          title={t('content.admin.registration-requests.overlay.roles')}
        />

        <Box alignItems="center">
          {selectedCompany?.companyRoles.map(
            (role: { agreements: any; companyRole: string }) => (
              <Box
                key={role.companyRole}
                display="flex"
                alignItems="center"
                gap=".2rem"
                padding={1}
              >
                <LocalOfferIcon
                  sx={{
                    height: '15px',
                    width: '15px',
                    color: '#2484C6',
                  }}
                />
                <Typography fontSize={'16px'} fontWeight="400" color="#2484C6">
                  {getLocaleStr(role.companyRole)}
                </Typography>
                <Typography fontSize={'16px'} fontWeight="400">
                  {role.companyRole === 'ACTIVE_PARTICIPANT'
                    ? t(
                        'content.admin.registration-requests.overlay.agreementSign'
                      )
                    : t(
                        'content.admin.registration-requests.overlay.agreementSignThirdParty'
                      )}
                  <span style={{ fontWeight: 600 }}>
                    {role.agreements[0].consentDate === null
                      ? '-'
                      : dayjs(role.agreements[0].consentDate).format(
                          'DD.MM.YYYY'
                        )}
                  </span>
                </Typography>
              </Box>
            )
          )}
        </Box>
      </Box>
      <div
        style={{
          borderBottomWidth: '1px',
          borderColor: '#e3e3e3',
          borderBottomStyle: 'solid',
        }}
      />
      <div
        style={{
          borderBottomWidth: '1px',
          borderColor: '#e3e3e3',
          borderBottomStyle: 'solid',
        }}
      />
    </Box>
  ) : null
}

export default DetailsSection
