import {
  Box,
  Typography,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { type AppDetails } from 'features/apps/details/types'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import './styles.scss'
import STANDARDS from 'assets/standards/standards.json'

import { Grid } from '@mui/material'
import {
  CONTROLLERS,
  OFFERTYPES,
  type ServiceStatusDataState,
} from 'features/Custom/serviceOnboard/types'
import { useFetchDocumentByTypeMutation } from 'features/Custom/appOnboard/apiSlice'
import { download } from 'utils/downloadUtils'
import React from 'react'

const CertificationStandards = ({
  item,
  heading,
  subHeading,
  offerType,
  documentId,
  documentName,
}: {
  item: AppDetails | ServiceStatusDataState
  heading: string
  subHeading: string
  offerType: OFFERTYPES
  documentName: string
  documentId: string
}) => {
  const [fetchDocumentByType] = useFetchDocumentByTypeMutation()
  const [filterLength, setFilterLength] = React.useState<number>(10)
  const actualLength = STANDARDS.filter((standard) =>
    item.certificate.standards.some((id) => id.standardId === standard.id)
  ).length

  const handleDownloadClick = async () => {
    try {
      document.body.style.cursor = 'progress'
      const response = await fetchDocumentByType({
        id: item.id,
        documentId,
        controller:
          offerType === OFFERTYPES.APP
            ? CONTROLLERS.APP_ONBOARD
            : CONTROLLERS.SERVICE_ONBOARD,
        offerTypeId: offerType,
      }).unwrap()
      const file = response.data
      download(file, '.pdf', documentName)
      return
    } catch (error) {
      console.error(error, 'ERROR WHILE FETCHING DOCUMENT')
    } finally {
      document.body.style.cursor = 'default'
    }
  }

  return (
    <div className="standards-wrapper">
      <div className="typography-wrapper">
        <Typography
          fontSize="1.25rem"
          fontWeight="600"
          color="#000"
          marginBottom="1rem"
        >
          {heading}
        </Typography>
        <Typography fontSize="1rem" fontWeight={600} color="#000">
          {subHeading}
        </Typography>
      </div>
      <Box className="main-container">
        <Box className="document-wrapper">
          <DescriptionOutlinedIcon
            htmlColor="#2484C6"
            sx={{
              fontSize: '16px',
            }}
          />
          <Typography
            variant="caption1"
            fontWeight={400}
            sx={{
              color: '#2484C6',
              fontSize: '16px',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            onClick={handleDownloadClick}
          >
            {documentName}
          </Typography>
        </Box>
        <Box className="standards-list">
          <Grid
            container
            rowSpacing={1}
            mt={1}
            mb={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {STANDARDS.filter((standard) =>
              item.certificate.standards.some(
                (id) => id.standardId === standard.id
              )
            )
              .slice(0, filterLength)
              .map((eachStandard, key) => (
                <Grid item xs={6} key={key}>
                  <Typography variant="subtitle1" color="#000">
                    &#x2022; {eachStandard.title}
                  </Typography>
                </Grid>
              ))}
          </Grid>
          {actualLength >= filterLength && actualLength !== filterLength ? (
            <Typography
              variant="caption1"
              sx={{
                fontSize: '1rem',
                fontWeight: 600,
                color: '#2484C6',
                transition: '0.2ms ease',
                cursor: 'pointer',
              }}
              onClick={() => {
                setFilterLength(actualLength)
              }}
            >
              See all standards + {actualLength - filterLength}...
            </Typography>
          ) : actualLength === filterLength ? (
            <Typography
              variant="caption1"
              sx={{
                fontSize: '1rem',
                fontWeight: 600,
                color: '#2484C6',
                transition: '0.2ms ease',
                cursor: 'pointer',
              }}
              onClick={() => {
                setFilterLength(10)
              }}
            >
              Show less
            </Typography>
          ) : null}
        </Box>
      </Box>
    </div>
  )
}

export default CertificationStandards
