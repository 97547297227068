import { useEffect } from 'react'
import {
  Box,
  Grid,
  Typography,
  type ImageType,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { useInView } from 'react-intersection-observer'
import { useTranslation } from 'react-i18next'
import { LABEL_IDS, OBSERVER_OPTIONS } from '../types'
import ImageGallery from '../ImageGallery'
import { getEmbedUrl } from 'utils/utilities'
import i18next from 'i18next'

const FeatureText = ({
  title,
  description,
}: {
  title: string
  description: string
}) => {
  return (
    <Box>
      <Typography color="#2484C6" fontWeight={500} fontSize="1.125rem">
        {title}
      </Typography>
      <Typography
        marginBottom="1.5rem"
        fontSize="1rem"
        color="#000"
        fontWeight={400}
      >
        {description}
      </Typography>
    </Box>
  )
}

function AppDetailFeature({
  data,
  images,
  handleSetSelectedTab,
  appId,
}: {
  data: any
  images: ImageType[]
  handleSetSelectedTab: (tab: string) => void
  appId?: string
}) {
  const { inView, ref } = useInView(OBSERVER_OPTIONS)
  const { t } = useTranslation()

  useEffect(() => {
    if (inView) {
      handleSetSelectedTab(LABEL_IDS.APPDETAIL_FEATURES)
    }
  }, [inView])

  return (
    <>
      {data && (
        <div ref={ref} id={LABEL_IDS.APPDETAIL_FEATURES}>
          <Grid
            container
            marginBottom="3rem"
            columnSpacing={{ lg: 17, md: 13 }}
          >
            <Grid item xs={12} md={12}>
              <Typography
                marginBottom="1rem"
                fontSize="1.25rem"
                fontWeight={600}
                color="#000"
              >
                {t('content.appdetail.features.title')}
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography
                marginBottom=".5rem"
                fontSize="1.125rem"
                fontWeight={500}
                color="#000"
              >
                {t('content.appdetail.features.summary')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                marginBottom="2.5rem"
                fontSize="1rem"
                fontWeight={400}
                color="#000"
              >
                {data?.summary.length <= 0
                  ? data.localizedSummary.values.filter(
                      (each: { langCode: string }) =>
                        each.langCode === i18next.language
                    )?.[0]?.value ??
                    data.localizedSummary.values.filter(
                      (each: { langCode: string }) => each.langCode === 'en'
                    )?.[0]?.value
                  : data?.summary}
              </Typography>
            </Grid>
            <Grid item sm={12} md={6}>
              <Typography
                marginBottom="1rem"
                fontSize="1.125rem"
                color="#4D4D4F"
                fontWeight={500}
              >
                {t('content.appdetail.features.keyFeature')}
              </Typography>

              {data?.keyFeatures.map(
                (
                  d: {
                    title: string
                    shortDescription: string
                    localizedSummary: {
                      values: { langCode: string; value: string }[]
                    }
                  },
                  key: string
                ) => (
                  <FeatureText
                    key={key}
                    title={d?.title}
                    description={
                      d.localizedSummary.values.filter(
                        (each: { langCode: string }) =>
                          each.langCode === i18next.language
                      )?.[0]?.value ??
                      d.localizedSummary.values.filter(
                        (each: { langCode: string }) => each.langCode === 'en'
                      )?.[0]?.value
                    }
                  />
                )
              )}
            </Grid>
            <Grid item sm={12} md={6}>
              {data?.videoLink && (
                <Box
                  height="30vh"
                  width="100%"
                  borderRadius="10px"
                  sx={{
                    backgroundColor: 'transparant',
                  }}
                >
                  <Box
                    height="100%"
                    width="100%"
                    borderRadius="10px"
                    component="iframe"
                    sx={{
                      objectFit: 'cover',
                    }}
                    title="Title"
                    src={getEmbedUrl(
                      `https://${
                        data?.videoLink &&
                        data?.videoLink?.split('https://')?.length > 1
                          ? data?.videoLink?.split('https://')[1]
                          : data?.videoLink ?? '#'
                      }`
                    )}
                    allowFullScreen
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  />
                </Box>
              )}
            </Grid>
          </Grid>
          {images && <ImageGallery images={images} />}
        </div>
      )}
    </>
  )
}

export default AppDetailFeature
