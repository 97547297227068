import {
  Box,
  Dialog,
  DialogContent,
  DialogHeader,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import OverlayContent from './OverlayContent'
import { type UseFormTrigger, type FieldErrors, type UseFormSetValue } from 'react-hook-form'
import { type CertificationType } from 'components/pages/AppPublish/Certification/types'

interface AddStandardOverlayProps {
  selectedStandards: string[]
  openDialog: boolean
  handleOverlayClose: () => void
  loading?: boolean
  trigger: UseFormTrigger<CertificationType>
  errors: FieldErrors<CertificationType>
  setValue: UseFormSetValue<CertificationType>
  fieldName: 'date' | 'document' | 'standards'
}

const AddStandardOverlay = ({
  selectedStandards,
  openDialog,
  handleOverlayClose,
  loading = false,
  trigger,
  errors,
  setValue,
  fieldName,
}: AddStandardOverlayProps) => {
  return (
    <Dialog
      open={openDialog}
      additionalModalRootStyles={{
        maxWidth: '100vw',
        width: '880px',
      }}
      scroll="paper"
      onBackdropClick={handleOverlayClose}
    >
      <DialogHeader
        wrapperStyle={{
          paddingTop: 5,
        }}
        closeWithIcon={true}
        onCloseWithIcon={() => {
          handleOverlayClose()
        }}
      />
      <DialogContent
        sx={{
          padding: '0px 63px 40px 63px',
          maxHeight: '672px',
        }}
      >
        {loading ? (
          <Box
            sx={{
              width: '100%',
              height: 200,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span
              style={{
                padding: 10,
              }}
            >
              <span className="invite-loader" />
            </span>
          </Box>
        ) : (
          <OverlayContent
            selectedStandards={selectedStandards}
            handleOverlayClose={handleOverlayClose}
            trigger={trigger}
            errors={errors}
            setValue={setValue}
            name={fieldName}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

export default AddStandardOverlay
