/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from 'react'

import {
  Divider,
  Box,
  InputLabel,
  Grid,
  Paper,
  FormControl,
  Autocomplete,
  TextField,
  Select,
  MenuItem,
  Backdrop,
  IconButton,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
} from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import CloseIcon from '@mui/icons-material/Close'
import DeleteIcon from '@mui/icons-material/Delete'
import { useDispatch, useSelector } from 'react-redux'
import {
  Button,
  Chip,
  PageNotifications,
  PageSnackbar,
  Typography,
  theme,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { useTranslation } from 'react-i18next'
import { QueryStatus } from '@reduxjs/toolkit/dist/query'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'

import { type LanguageObject, LANGUAGE_SUPPORTED } from 'utils/utilities'
import { ConnectorFormInputField } from 'components/pages/AppPublish/OverView'
import KeyFeatureControlledTag from 'components/pages/AppPublish/ControlledTags'
import { setServiceStatus } from 'features/Custom/serviceOnboard/actions'
import {
  serviceIdSelector,
  serviceStatusDataSelector,
  decrement,
  increment,
  currentActiveStep,
} from 'features/Custom/serviceOnboard/slice'
import {
  useFetchServiceStatusQuery,
  useUpdatePricingDetailsMutation,
} from 'features/Custom/serviceOnboard/apiSlice'
import { ENTITYSTATE } from 'features/Custom/serviceOnboard/types'
import Patterns from 'types/Patterns'
import { COFINITY_SERVICE_ONBOARDING_DOCUMENTATION_URL } from 'types/Constants'
import { PHONE_CODES } from 'types/CountryCode'
import { PricingRadio } from 'components/pages/AppPublish/Pricing/RadioGroup'

type PricingPlanType = {
  planId?: string
  planTitle: string
  amount: string
  pricingModel: string
  CustomPricingType: 'fixed' | 'custom' | string
  SalesContactName: string
  SalesContactEmail: string
  SalesContactPhone?: string
  countryCode?: string
  briefDescription: LanguageObject[]
  keyFeaturesPricingPlan: {
    keyFeatureId: string
    en: string[]
    de: string[]
    entityState: string
  }
  entityState: string
}

interface PricingPlanModelType {
  pricingId: string
  pricingDescription: LanguageObject[]
  pricingPlans: PricingPlanType[]
  entityState: string
}

const PRICING_MODELS = ['Time & material', 'Fixed price']

const Pricing = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const serviceStatusData = useSelector(serviceStatusDataSelector)
  const activePage = useSelector(currentActiveStep)
  const [appPageNotification, setAppPageNotification] = useState(false)
  const [appPageSnackbar, setAppPageSnackbar] = useState<boolean>(false)
  const [visibleDropdown, setVisibleDropdown] = useState<boolean>(false)
  const [visibleDropdownPricingplan, setVisibleDropdownPricingplan] =
    useState<boolean>(false)
  const [visibleDropdownKeyFeatrue, setVisibleDropdownKeyFeatrue] =
    useState<boolean>(false)
  const [selectedLanguage, setSelectedLanguage] = useState<string[]>(['en'])
  const [selectedBriefLanguage, setSelectedBriefLanguage] = useState<string[]>([
    'en',
  ])
  const [selectedKeyFeatureLanguage, setSelectedKeyFeatureLanguage] = useState<
    string[]
  >(['en'])
  const fixedLanguage = [LANGUAGE_SUPPORTED[0]]
  const serviceId = useSelector(serviceIdSelector)
  const { data: fetchServiceStatus, refetch } = useFetchServiceStatusQuery(
    serviceId ?? '',
    {
      refetchOnMountOrArgChange: true,
    }
  )
  const [updatePricingDetails, { status: pricingApiStatus }] =
    useUpdatePricingDetailsMutation()

  const defaultValues = {
    pricingId:
      serviceStatusData?.pricingDetail?.id ??
      '00000000-0000-0000-0000-000000000000',
    pricingDescription: LANGUAGE_SUPPORTED.map((language) => {
      return {
        [language]: {
          shortDescription: '',
          longDescription:
            serviceStatusData?.pricingDetail?.localizedSummary?.values?.filter(
              (eachSummary: any) => eachSummary.langCode === language
            )[0]?.value,
        },
      }
    }),
    pricingPlans: (serviceStatusData?.pricingDetail?.plans?.length &&
      serviceStatusData?.pricingDetail?.plans?.map((eachPlan: any) => {
        return {
          planId: eachPlan?.id,
          planTitle: eachPlan?.title,
          amount:
            eachPlan?.amount === 'Contact sales'
              ? 'Contact sales'
              : new Intl.NumberFormat('de-DE').format(
                  parseFloat(eachPlan?.amount.replaceAll(',', ''))
                ),
          CustomPricingType:
            eachPlan?.customPricingType === true
              ? 'custom'
              : 'fixed' ?? 'fixed',
          SalesContactName: eachPlan?.salesContactName,
          SalesContactEmail: eachPlan?.salesContactEmail,
          SalesContactPhone: eachPlan?.salesContactPhone?.split(' ')[1],
          countryCode:
            (eachPlan?.salesContactPhone?.split(' ')[0].length &&
              eachPlan?.salesContactPhone?.split(' ')[0]) ??
            '+49',
          briefDescription: LANGUAGE_SUPPORTED.map((language) => {
            return {
              [language]: {
                shortDescription: eachPlan?.localizedSummary?.values?.filter(
                  (eachLang: any) => eachLang?.langCode === language
                )[0]?.value,
                longDescription: '',
              },
            }
          }),
          keyFeaturesPricingPlan: {
            keyFeatureId: eachPlan?.planFeatures[0]?.id,
            en: eachPlan?.planFeatures[0]?.name?.split(',') ?? [],
            de: eachPlan?.planFeatures[0]?.localizedSummary?.values
              ?.filter(
                (eachLang: any) =>
                  eachLang?.langCode === 'de' && eachLang.value.length > 1
              )[0]
              ?.value?.split(','),
            entityState:
              eachPlan?.planFeatures[0]?.enitityState && ENTITYSTATE.UPDATED,
          },
          pricingModel: eachPlan?.model,
          entityState: eachPlan?.entityState && ENTITYSTATE.UPDATED,
        }
      })) ?? [
      {
        planId: '00000000-0000-0000-0000-000000000000',
        planTitle: '',
        amount: '',
        pricingModel: '',
        CustomPricingType: 'fixed',
        SalesContactName: '',
        SalesContactEmail: '',
        SalesContactPhone: '',
        countryCode: '+49',
        briefDescription: LANGUAGE_SUPPORTED.map((language) => {
          return {
            [language]: {
              shortDescription: '',
              longDescription: '',
            },
          }
        }),
        keyFeaturesPricingPlan: {
          keyFeatureId: '00000000-0000-0000-0000-000000000000',
          en: [],
          de: [],
          entityState: ENTITYSTATE.ADDED,
        },
        entityState: ENTITYSTATE.ADDED,
      },
    ],
    entityState:
      (serviceStatusData?.pricingDetail?.entityState && ENTITYSTATE.UPDATED) ??
      ENTITYSTATE.ADDED,
  }

  const {
    handleSubmit,
    getValues,
    control,
    trigger,
    setValue,
    resetField,
    clearErrors,
    formState: { errors, isDirty, isSubmitted, submitCount },
  } = useForm<PricingPlanModelType>({
    defaultValues,
    mode: 'onChange',
  })

  const { fields, append, remove, update } = useFieldArray({
    name: 'pricingPlans',
    control,
  })

  const onBackIconClick = () => {
    dispatch(setServiceStatus(fetchServiceStatus))
    dispatch(decrement())
  }

  useEffect(() => {
    dispatch(setServiceStatus(fetchServiceStatus))
    if (
      serviceStatusData?.pricingDetail?.localizedSummary?.values?.filter(
        (eachSummary: any) => eachSummary.langCode === 'de'
      )[0]?.value.length >= 9
    ) {
      const newLangs = [...fixedLanguage, 'de']
      setSelectedLanguage(newLangs)
    }
    if (
      serviceStatusData?.pricingDetail?.plans?.[0]?.localizedSummary?.values?.filter(
        (eachSummary: any) => eachSummary.langCode === 'de'
      )[0]?.value.length >= 9
    ) {
      const newLangs = [...fixedLanguage, 'de']
      setSelectedBriefLanguage(newLangs)
    }
    if (
      serviceStatusData?.pricingDetail?.plans?.[0]?.planFeatures[0]?.localizedSummary?.values?.filter(
        (eachLang: any) => eachLang?.langCode === 'de'
      )[0].value.length >= 1
    ) {
      const newLangs = [...fixedLanguage, 'de']
      setSelectedKeyFeatureLanguage(newLangs)
    }
  }, [dispatch, serviceStatusData, isSubmitted])

  const validateFields = async (
    data: PricingPlanModelType,
    buttonLabel: string
  ) => {
    const isValid = await trigger(['pricingDescription', 'pricingPlans'])

    if (isValid) {
      onPricingSubmission(data, buttonLabel)
    }
  }
  const onPricingSubmission = async (
    data: PricingPlanModelType,
    buttonLabel: string
  ) => {
    const saveData = {
      id: data.pricingId,
      description: '',
      amount: '',
      model: '',
      currency: 'euro',
      freeVersion: false,
      freeTrial: false,
      url: '',
      localizedSummary: {
        values: LANGUAGE_SUPPORTED.map((language, index) => {
          return {
            langCode: language,
            value:
              data.pricingDescription[index][language].longDescription ?? '',
          }
        }),
      },
      entityState: data.entityState,
      plans: data.pricingPlans.map((plans) => {
        return {
          title: plans.planTitle,
          id: plans.planId,
          model: plans.pricingModel,
          currency: 'euro',
          amount: plans.amount ?? '0',
          CustomPricingType: plans.CustomPricingType === 'fixed' ? false : true,
          SalesContactName: plans.SalesContactName ?? '',
          SalesContactEmail: plans.SalesContactEmail ?? '',
          SalesContactPhone: `${plans.countryCode} ${
            plans.SalesContactPhone ?? ''
          }`,
          countryCode: plans.countryCode ?? '+49',
          frequency: '',
          description: '',
          localizedSummary: {
            values: LANGUAGE_SUPPORTED.map((language, index) => {
              return {
                langCode: language,
                value:
                  plans.briefDescription[index][language].shortDescription ??
                  '',
              }
            }),
          },
          planFeatures: [
            {
              id: plans.keyFeaturesPricingPlan.keyFeatureId,
              name: plans.keyFeaturesPricingPlan.en.toString(),
              localizedSummary: {
                values: LANGUAGE_SUPPORTED.map((language, index) => {
                  return {
                    langCode: language,
                    value:
                      language === 'en'
                        ? plans.keyFeaturesPricingPlan.en?.toString() ?? ''
                        : plans.keyFeaturesPricingPlan.de?.toString() ?? '',
                  }
                }),
              },
              entityState:
                plans.keyFeaturesPricingPlan.entityState === ENTITYSTATE.DELETED
                  ? plans.keyFeaturesPricingPlan.entityState
                  : buttonLabel === 'save' && submitCount > 0
                  ? ENTITYSTATE.UPDATED
                  : plans.keyFeaturesPricingPlan.entityState,
            },
          ],
          entityState:
            plans.entityState === ENTITYSTATE.DELETED
              ? plans.entityState
              : buttonLabel === 'save' && submitCount > 0
              ? ENTITYSTATE.UPDATED
              : plans.entityState,
        }
      }),
    }
    const formData = {
      serviceId,
      body: saveData,
    }
    if (isDirty) {
      await updatePricingDetails(formData)
        .unwrap()
        .then(() => {
          buttonLabel === 'saveAndProceed' && dispatch(increment())
          if (buttonLabel === 'save') {
            refetch()
            setAppPageSnackbar(true)
          }
        })
        .catch(() => {
          setAppPageNotification(true)
        })
    } else {
      buttonLabel === 'saveAndProceed' && dispatch(increment())
      if (buttonLabel === 'save') {
        // fetchServiceStatus = useFetchServiceStatusQuery(serviceId ?? '', {
        //   refetchOnMountOrArgChange: true,
        // }).data
        refetch()
        setAppPageSnackbar(true)
      }
    }
  }

  return (
    <Paper
      elevation={5}
      className="service-container service-detail__container service-detail__paper"
    >
      <Box className="certification__item-box">
        <div className="app-market-card">
          <Box
            mt={4}
            sx={{
              backgroundColor: theme.palette.stepper.stepCurrent,
              borderRadius: '50%',
              width: '30px',
              height: '30px',
              color: '#fff',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Typography
              variant="body1"
              fontSize="14px"
              color="#fff"
              sx={{
                margin: 'auto',
                paddingTop: '4px',
                width: 'fit-content',
              }}
            >
              {activePage}
            </Typography>
          </Box>
          <Typography variant="h3" mt={5} mb={4} align="center">
            {t('content.servicePublish.pricing.headerTitle')}
          </Typography>
          <Grid container spacing={2}>
            <Grid item md={11} sx={{ mr: 'auto', ml: 'auto' }}>
              <Typography variant="body2" align="center">
                {t('content.servicePublish.pricing.headerDescription')}
              </Typography>
            </Grid>
          </Grid>
          <form className="header-description">
            <Typography variant="h3" mt={5} mb={-3}>
              {t('content.servicePublish.pricing.pricingOverview')}
            </Typography>
            {LANGUAGE_SUPPORTED.filter((language) =>
              selectedLanguage.some((selected) => selected === language)
            ).map((language, index) => {
              return (
                <div className="form-field">
                  <ConnectorFormInputField
                    {...{
                      control,
                      trigger,
                      errors,
                      name: `pricingDescription.${index}.${language}.longDescription` as const,
                      type: 'input',
                      textarea: true,
                      placeholder: `${t(
                        'content.servicePublish.pricing.pricingdetailsPlaceholder'
                      )}`,
                      label: (
                        <strong
                          style={{
                            color: errors?.pricingDescription?.[index]?.[
                              language
                            ]?.longDescription
                              ? '#c02424'
                              : 'inherit',
                          }}
                        >
                          {t('content.servicePublish.pricing.pricingDetails')}{' '}
                          {`(${language}) *`}
                        </strong>
                      ),
                      rules: {
                        required: {
                          value: language === 'en' ? true : false,
                          message: `${t(
                            'content.appPublish.pricing.pricingDetails'
                          )} ${`(${language})`} ${t(
                            'content.appPublish.appReleaseForm.isMandatory'
                          )}`,
                        },
                        minLength: {
                          value: 10,
                          message: `${t(
                            'content.appPublish.appReleaseForm.minimum'
                          )} 10 ${t(
                            'content.appPublish.appReleaseForm.charactersRequired'
                          )}`,
                        },
                        pattern: {
                          value:
                            language === 'en'
                              ? Patterns.appMarketCard.pricingDetailsEN
                              : Patterns.appMarketCard.pricingDetailsDE,
                          message: `${t(
                            'content.appPublish.appReleaseForm.validCharactersIncludes'
                          )} ${
                            language === 'en'
                              ? 'a-zA-Z0-9 !?@&#\'"()_-=/*.,;:'
                              : 'a-zA-ZÀ-ÿ0-9 !?@&#\'"()_-=/*.,;:'
                          }`,
                        },
                        maxLength: {
                          value: 450,
                          message: `${t(
                            'content.appPublish.appReleaseForm.maximum'
                          )} 450 ${t(
                            'content.appPublish.appReleaseForm.charactersAllowed'
                          )}`,
                        },
                      },
                    }}
                  />
                  {errors &&
                    errors?.pricingDescription?.[index]?.[language]
                      ?.longDescription?.type === 'required' && (
                      <Typography
                        variant="body2"
                        className="file-error-msg"
                        align="left"
                      >
                        {t('content.appPublish.pricing.pricingDetails')}{' '}
                        {`(${language})`}{' '}
                        {t('content.appPublish.appReleaseForm.isMandatory')}
                      </Typography>
                    )}
                  {errors &&
                    errors?.pricingDescription?.[index]?.[language]
                      ?.longDescription?.type === 'minLength' && (
                      <Typography
                        variant="body2"
                        className="file-error-msg"
                        align="left"
                      >
                        {t('content.appPublish.appReleaseForm.minimum')} 10
                        {t(
                          'content.appPublish.appReleaseForm.charactersRequired'
                        )}
                      </Typography>
                    )}
                  {errors &&
                    errors?.pricingDescription?.[index]?.[language]
                      ?.longDescription?.type === 'maxLength' && (
                      <Typography
                        variant="body2"
                        className="file-error-msg"
                        align="left"
                      >
                        {t('content.appPublish.appReleaseForm.maximum')} 450
                        {t(
                          'content.appPublish.appReleaseForm.charactersAllowed'
                        )}
                      </Typography>
                    )}
                  <Typography
                    variant="body2"
                    className="form-field"
                    align="right"
                    style={{
                      fontWeight: 600,
                    }}
                  >
                    {(getValues().pricingDescription[index][language]
                      .longDescription?.length ?? 0) + '/450'}
                  </Typography>
                </div>
              )
            })}
            <div className="form-field">
              <InputLabel
                id="demo-multiple-name-label"
                onClick={() => {
                  setVisibleDropdown(!visibleDropdown)
                }}
              >
                <strong
                  style={{
                    color: visibleDropdown ? '#E34C92' : '#4D4D4F',
                    cursor: 'pointer',
                  }}
                >
                  {t('content.servicePublish.pricing.addPricingOtherLang')}
                </strong>
              </InputLabel>
            </div>
            <FormControl sx={{ m: 1, width: '100%' }}>
              {visibleDropdown && (
                <Autocomplete
                  multiple
                  fullWidth
                  id="fixed-tags-demo"
                  value={selectedLanguage}
                  onChange={(event, newValue) => {
                    setSelectedLanguage([
                      ...fixedLanguage,
                      ...newValue.filter(
                        (eachLan) => fixedLanguage.indexOf(eachLan) === -1
                      ),
                    ])
                    LANGUAGE_SUPPORTED.filter((newLanguage) =>
                      newValue.every((lang) => lang !== newLanguage)
                    ).forEach((language) => {
                      const index = LANGUAGE_SUPPORTED.indexOf(language)
                      resetField(
                        `pricingDescription.${index}.${language}.longDescription`
                      )
                      setValue(
                        `pricingDescription.${index}.${language}.longDescription`,
                        '' as string as never
                      )
                    })
                  }}
                  options={LANGUAGE_SUPPORTED}
                  getOptionLabel={(option) => option}
                  renderTags={(tagValue, getTagProps) => {
                    return tagValue.map(
                      (option, index) => null
                      // <Chip
                      //   label={option}
                      //   size="small"
                      //   color="primary"
                      //   {...getTagProps({ index })}
                      //   disabled={fixedLanguage.indexOf(option) !== -1}
                      //   deleteIcon={<CloseIcon />}
                      // />
                    )
                  }}
                  style={{ width: '100%' }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={t('content.appPublish.avalibleLanguages')}
                      variant="standard"
                    />
                  )}
                />
              )}
            </FormControl>
            <Typography variant="h3" mt={3} style={{ display: 'flex' }}>
              {' '}
              {t('content.servicePublish.pricing.pricingPlan')}
            </Typography>
            {fields
              .filter((field) => field.entityState !== ENTITYSTATE.DELETED)
              .map((field, fieldIndex) => {
                return (
                  <React.Fragment>
                    <div className="form-field" key={field.id}>
                      {fieldIndex > 0 && (
                        <Chip
                          sx={{
                            fontWeight: 'bold',
                            cursor: 'pointer',
                            float: 'right',
                          }}
                          label={t(
                            'content.servicePublish.pricing.removePricingPlan'
                          )}
                          icon={<DeleteIcon color="error" />}
                          onClick={() => {
                            if (field.entityState === ENTITYSTATE.ADDED) {
                              remove(fieldIndex)
                            } else {
                              update(fieldIndex, {
                                planId: field.planId,
                                planTitle: field.planTitle,
                                amount: field.amount,
                                pricingModel: field.pricingModel,
                                CustomPricingType: field.CustomPricingType,
                                SalesContactName: field.SalesContactName,
                                SalesContactEmail: field.SalesContactEmail,
                                SalesContactPhone: field.SalesContactPhone,
                                entityState: ENTITYSTATE.DELETED,
                                keyFeaturesPricingPlan: {
                                  keyFeatureId:
                                    field.keyFeaturesPricingPlan.keyFeatureId,
                                  de: field.keyFeaturesPricingPlan.de,
                                  en: field.keyFeaturesPricingPlan.en,
                                  entityState: ENTITYSTATE.DELETED,
                                },
                                briefDescription: field.briefDescription,
                              })
                            }
                          }}
                        />
                      )}
                    </div>
                    <div className="form-field">
                      <ConnectorFormInputField
                        {...{
                          control,
                          trigger,
                          errors,
                          name: `pricingPlans.${fieldIndex}.planTitle`,
                          label: (
                            <strong
                              style={{
                                color: errors?.pricingPlans?.[fieldIndex]
                                  ?.planTitle
                                  ? '#c02424'
                                  : 'inherit',
                              }}
                            >
                              {t(
                                'content.servicePublish.pricing.pricingPlanTitle'
                              ) + ' *'}
                            </strong>
                          ),
                          placeholder: t(
                            'content.servicePublish.pricing.pricingPlanTitlePlaceholder'
                          ),
                          type: 'input',
                          rules: {
                            required: {
                              value: true,
                              message: `${t(
                                'content.servicePublish.pricing.pricingPlanTitle'
                              )} ${t(
                                'content.appPublish.appReleaseForm.isMandatory'
                              )}`,
                            },
                            minLength: {
                              value: 4,
                              message: `${t(
                                'content.appPublish.appReleaseForm.minimum'
                              )} 4 ${t(
                                'content.appPublish.appReleaseForm.charactersRequired'
                              )}`,
                            },
                            pattern: {
                              value: Patterns.appMarketCard.pricingPlanTitle,
                              message: `${t(
                                'content.appPublish.appReleaseForm.validCharactersIncludes'
                              )} A-Za-z0-9.:_- @&€`,
                            },
                            maxLength: {
                              value: 40,
                              message: `${t(
                                'content.appPublish.appReleaseForm.maximum'
                              )} 40 ${t(
                                'content.appPublish.appReleaseForm.charactersAllowed'
                              )}`,
                            },
                          },
                        }}
                      />
                      {errors?.pricingPlans &&
                        errors.pricingPlans[fieldIndex]?.planTitle?.type ===
                          'required' && (
                          <Typography
                            variant="body2"
                            className="file-error-msg"
                          >
                            {
                              errors?.pricingPlans[fieldIndex]?.planTitle
                                ?.message
                            }
                          </Typography>
                        )}
                      {errors?.pricingPlans &&
                        errors.pricingPlans[fieldIndex]?.planTitle?.type ===
                          'minLength' && (
                          <Typography
                            variant="body2"
                            className="file-error-msg"
                          >
                            {
                              errors?.pricingPlans[fieldIndex]?.planTitle
                                ?.message
                            }
                          </Typography>
                        )}
                      {errors?.pricingPlans &&
                        errors.pricingPlans[fieldIndex]?.planTitle?.type ===
                          'maxLength' && (
                          <Typography
                            variant="body2"
                            className="file-error-msg"
                          >
                            {
                              errors?.pricingPlans[fieldIndex]?.planTitle
                                ?.message
                            }
                          </Typography>
                        )}
                    </div>
                    <Typography variant="body2" color="text.secondary">
                      <h4>{t('content.appPublish.pricing.pricing')}</h4>
                    </Typography>

                    <Controller
                      control={control}
                      name={
                        `pricingPlans.${fieldIndex}.CustomPricingType` as const
                      }
                      render={({ field: { onChange, value } }) => {
                        return (
                          <FormControl fullWidth={true}>
                            <RadioGroup
                              value={value}
                              onChange={onChange}
                              row
                              style={{
                                gap: '10px',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  border:
                                    value === 'fixed'
                                      ? '2px solid #FDB913'
                                      : '2px solid #d4d4d4',
                                  borderRadius: '10px',
                                  padding: '10px',
                                  textAlign: 'center',
                                }}
                              >
                                <FormControlLabel
                                  label={
                                    <strong>
                                      {t(
                                        'content.appPublish.pricing.fixedPricing'
                                      )}
                                    </strong>
                                  }
                                  sx={{
                                    '.MuiFormControlLabel-label': {
                                      fontWeight: 600,
                                    },
                                  }}
                                  value={'fixed'}
                                  control={<PricingRadio />}
                                  onChange={() => {
                                    setValue(
                                      `pricingPlans.${fieldIndex}.SalesContactName`,
                                      '' as string as never
                                    )
                                    setValue(
                                      `pricingPlans.${fieldIndex}.SalesContactEmail`,
                                      '' as string as never
                                    )
                                    setValue(
                                      `pricingPlans.${fieldIndex}.SalesContactPhone`,
                                      '' as string as never
                                    )
                                    setValue(
                                      `pricingPlans.${fieldIndex}.amount`,
                                      '' as string as never
                                    )
                                  }}
                                />
                              </div>
                              <div
                                style={{
                                  display: 'flex',
                                  border:
                                    value === 'custom'
                                      ? '2px solid #FDB913'
                                      : '2px solid #d4d4d4',
                                  borderRadius: '10px',
                                  padding: '10px',
                                  textAlign: 'center',
                                }}
                              >
                                <FormControlLabel
                                  label={
                                    <strong>
                                      {t(
                                        'content.appPublish.pricing.customPricing'
                                      )}
                                    </strong>
                                  }
                                  sx={{
                                    '.MuiFormControlLabel-label': {
                                      fontWeight: 600,
                                    },
                                  }}
                                  value={'custom'}
                                  control={<PricingRadio />}
                                  onChange={() => {
                                    setValue(
                                      `pricingPlans.${fieldIndex}.amount`,
                                      '' as string as never
                                    )
                                    setValue(
                                      `pricingPlans.${fieldIndex}.pricingModel`,
                                      '' as string as never
                                    )
                                  }}
                                />
                              </div>
                            </RadioGroup>
                            <div className="price-type__box">
                              {value === 'fixed' ? (
                                <div className="price-type__fixed">
                                  <React.Fragment key={fieldIndex}>
                                    <div className="form-field">
                                      <ConnectorFormInputField
                                        {...{
                                          control,
                                          trigger,
                                          errors,
                                          name: `pricingPlans.${fieldIndex}.amount` as const,
                                          label: t(
                                            'content.servicePublish.pricing.amount'
                                          ),
                                          placeholder: t(
                                            'content.servicePublish.pricing.amountTooltip'
                                          ),
                                          type: 'input',
                                          rules: {
                                            required: {
                                              value: false,
                                              message: `${t(
                                                'content.servicePublish.pricing.amountmandatory'
                                              )} ${t(
                                                'content.appPublish.appReleaseForm.isMandatory'
                                              )}`,
                                            },
                                            pattern: {
                                              value: Patterns.CURRENCY,
                                              message: `${t(
                                                'content.appPublish.appReleaseForm.validCharactersIncludes'
                                              )} ${', .'} Ex. 10.00, 0.10, 10.10`,
                                            },
                                          },
                                        }}
                                      />
                                      {errors?.pricingPlans &&
                                        errors.pricingPlans[fieldIndex]?.amount
                                          ?.type === 'pattern' && (
                                          <Typography
                                            variant="body2"
                                            className="file-error-msg"
                                          >
                                            {
                                              errors?.pricingPlans[fieldIndex]
                                                ?.amount?.message
                                            }
                                          </Typography>
                                        )}
                                    </div>
                                    <div className="form-field form-field-relative">
                                      <InputLabel>
                                        <strong
                                          style={{
                                            color: errors?.pricingPlans?.[
                                              fieldIndex
                                            ]?.pricingModel
                                              ? '#c02424'
                                              : 'inherit',
                                          }}
                                        >
                                          {' '}
                                          {t(
                                            'content.servicePublish.pricing.pricingModel'
                                          ) + ' *'}
                                        </strong>
                                      </InputLabel>
                                      <Controller
                                        name={`pricingPlans.${fieldIndex}.pricingModel`}
                                        control={control}
                                        rules={{
                                          required: {
                                            value: true,
                                            message: `${t(
                                              'content.servicePublish.pricing.pricingModelMandatory'
                                            )} ${t(
                                              'content.appPublish.appReleaseForm.isMandatory'
                                            )}`,
                                          },
                                        }}
                                        render={({
                                          field: { onChange, value },
                                          fieldState: { error },
                                        }) => {
                                          return (
                                            <Select
                                              fullWidth
                                              value={value}
                                              onChange={onChange}
                                              variant="standard"
                                              error={!!error}
                                              renderValue={(value) =>
                                                value?.length ? (
                                                  Array.isArray(value) ? (
                                                    value.join(', ')
                                                  ) : (
                                                    value
                                                  )
                                                ) : (
                                                  <em>
                                                    {' '}
                                                    {t(
                                                      'content.servicePublish.pricing.pricingModel'
                                                    )}
                                                  </em>
                                                )
                                              }
                                              sx={{
                                                '& .MuiSelect-select .notranslate::after':
                                                  /* eslint-disable no-constant-condition */
                                                  'Select pricing model'
                                                    ? {
                                                        content: `"${t(
                                                          'content.servicePublish.pricing.pricingModel'
                                                        )}"`,
                                                        opacity: 0.22,
                                                        fontSize: '16px',
                                                        fontWeight: 600,
                                                      }
                                                    : {},
                                              }}
                                            >
                                              {PRICING_MODELS.map(
                                                (eachitem) => (
                                                  <MenuItem value={eachitem}>
                                                    {eachitem}
                                                  </MenuItem>
                                                )
                                              )}
                                            </Select>
                                          )
                                        }}
                                      />
                                      {errors?.pricingPlans &&
                                        errors.pricingPlans[fieldIndex]
                                          ?.pricingModel?.type ===
                                          'required' && (
                                          <Typography
                                            variant="body2"
                                            className="file-error-msg"
                                          >
                                            {
                                              errors?.pricingPlans[fieldIndex]
                                                ?.pricingModel?.message
                                            }
                                          </Typography>
                                        )}
                                    </div>
                                    {/* BriefDescription */}
                                    {LANGUAGE_SUPPORTED.filter((language) =>
                                      selectedBriefLanguage.some(
                                        (selected) => selected === language
                                      )
                                    ).map((language, index) => {
                                      return (
                                        <div className="form-field">
                                          <ConnectorFormInputField
                                            {...{
                                              control,
                                              trigger,
                                              errors,
                                              name: `pricingPlans.${fieldIndex}.briefDescription.${index}.${language}.shortDescription` as const,
                                              type: 'input',
                                              label: (
                                                <strong
                                                  style={{
                                                    color: errors
                                                      ?.pricingPlans?.[
                                                      fieldIndex
                                                    ]?.briefDescription?.[
                                                      index
                                                    ]?.[language]
                                                      ?.shortDescription
                                                      ? '#c02424'
                                                      : 'inherit',
                                                  }}
                                                >
                                                  {t(
                                                    'content.servicePublish.pricing.briefDescription'
                                                  )}{' '}
                                                  {`(${language}) *`}
                                                </strong>
                                              ),
                                              textarea: true,
                                              placeholder: `${t(
                                                'content.servicePublish.pricing.briefDescriptionPlcaholder'
                                              )}`,
                                              rules: {
                                                required: {
                                                  value:
                                                    language === 'en'
                                                      ? true
                                                      : false,
                                                  message: `${t(
                                                    `${t(
                                                      'content.servicePublish.pricing.briefDescription'
                                                    )} ${`(${language})`}`
                                                  )} ${t(
                                                    'content.appPublish.appReleaseForm.isMandatory'
                                                  )}`,
                                                },
                                                minLength: {
                                                  value: 10,
                                                  message: `${t(
                                                    'content.appPublish.appReleaseForm.minimum'
                                                  )} 10 ${t(
                                                    'content.appPublish.appReleaseForm.charactersRequired'
                                                  )}`,
                                                },
                                                pattern: {
                                                  value:
                                                    language === 'en'
                                                      ? Patterns.appMarketCard
                                                          .pricingBriefDescriptionEN
                                                      : Patterns.appMarketCard
                                                          .pricingBriefDescriptionDE,
                                                  message: `${t(
                                                    'content.appPublish.appReleaseForm.validCharactersIncludes'
                                                  )} ${
                                                    language === 'en'
                                                      ? 'a-zA-Z0-9 !?@&#\'"()_-=/*.,;:€'
                                                      : 'a-zA-ZÀ-ÿ0-9 !?@&#\'"()_-=/*.,;:€'
                                                  }`,
                                                },
                                                maxLength: {
                                                  value: 110,
                                                  message: `${t(
                                                    'content.appPublish.appReleaseForm.maximum'
                                                  )} 110 ${t(
                                                    'content.appPublish.appReleaseForm.charactersAllowed'
                                                  )}`,
                                                },
                                              },
                                            }}
                                          />
                                          {errors &&
                                            errors.pricingPlans &&
                                            errors.pricingPlans[fieldIndex]
                                              ?.briefDescription?.[index]?.[
                                              language
                                            ] &&
                                            errors.pricingPlans[fieldIndex]
                                              ?.briefDescription?.[index]?.[
                                              language
                                            ]?.shortDescription?.type ===
                                              'required' && (
                                              <Typography
                                                variant="body2"
                                                className="file-error-msg"
                                              >
                                                {
                                                  errors.pricingPlans[
                                                    fieldIndex
                                                  ]?.briefDescription?.[
                                                    index
                                                  ]?.[language]
                                                    ?.shortDescription?.message
                                                }
                                              </Typography>
                                            )}
                                          {errors &&
                                            errors.pricingPlans &&
                                            errors.pricingPlans[fieldIndex]
                                              ?.briefDescription?.[index]?.[
                                              language
                                            ] &&
                                            errors.pricingPlans[fieldIndex]
                                              ?.briefDescription?.[index]?.[
                                              language
                                            ]?.shortDescription?.type ===
                                              'maxLength' && (
                                              <Typography
                                                variant="body2"
                                                className="file-error-msg"
                                              >
                                                {
                                                  errors.pricingPlans[
                                                    fieldIndex
                                                  ]?.briefDescription?.[
                                                    index
                                                  ]?.[language]
                                                    ?.shortDescription?.message
                                                }
                                              </Typography>
                                            )}
                                          {errors &&
                                            errors.pricingPlans &&
                                            errors.pricingPlans[fieldIndex]
                                              ?.briefDescription?.[index]?.[
                                              language
                                            ] &&
                                            errors.pricingPlans[fieldIndex]
                                              ?.briefDescription?.[index]?.[
                                              language
                                            ]?.shortDescription?.type ===
                                              'minLength' && (
                                              <Typography
                                                variant="body2"
                                                className="file-error-msg"
                                              >
                                                {
                                                  errors.pricingPlans[
                                                    fieldIndex
                                                  ]?.briefDescription?.[
                                                    index
                                                  ]?.[language]
                                                    ?.shortDescription?.message
                                                }
                                              </Typography>
                                            )}
                                          <Typography
                                            variant="body2"
                                            className="form-field"
                                            align="right"
                                            style={{
                                              fontWeight: 600,
                                            }}
                                          >
                                            {getValues()?.pricingPlans[
                                              fieldIndex
                                            ]?.briefDescription?.[index]?.[
                                              language
                                            ]?.shortDescription?.length +
                                              '/110'}
                                          </Typography>
                                        </div>
                                      )
                                    })}
                                    <div className="form-field">
                                      <InputLabel
                                        id="demo-multiple-name-label"
                                        onClick={() => {
                                          setVisibleDropdownPricingplan(
                                            !visibleDropdownPricingplan
                                          )
                                        }}
                                      >
                                        <strong
                                          style={{
                                            color: visibleDropdownPricingplan
                                              ? '#E34C92'
                                              : '#4D4D4F',
                                            marginBottom: -5,
                                            cursor: 'pointer',
                                          }}
                                        >
                                          {t(
                                            'content.servicePublish.pricing.addBriefDescriptionOtherLang'
                                          )}
                                        </strong>
                                      </InputLabel>
                                    </div>
                                    <div className="form-field">
                                      <FormControl sx={{ m: 1, width: '100%' }}>
                                        {visibleDropdownPricingplan && (
                                          <Autocomplete
                                            multiple
                                            fullWidth
                                            id="fixed-tags-demo"
                                            value={selectedBriefLanguage}
                                            onChange={(event, newValue) => {
                                              setSelectedBriefLanguage([
                                                ...fixedLanguage,
                                                ...newValue.filter(
                                                  (eachLan) =>
                                                    fixedLanguage.indexOf(
                                                      eachLan
                                                    ) === -1
                                                ),
                                              ])
                                              LANGUAGE_SUPPORTED.filter(
                                                (newLanguage) =>
                                                  newValue.every(
                                                    (lang) =>
                                                      lang !== newLanguage
                                                  )
                                              ).forEach((language) => {
                                                const index =
                                                  LANGUAGE_SUPPORTED.indexOf(
                                                    language
                                                  )
                                                resetField(
                                                  `pricingPlans.${fieldIndex}.briefDescription.${index}.${language}.shortDescription`
                                                )
                                                setValue(
                                                  `pricingPlans.${fieldIndex}.briefDescription.${index}.${language}.shortDescription`,
                                                  '' as string as never
                                                )
                                              })
                                            }}
                                            options={LANGUAGE_SUPPORTED}
                                            getOptionLabel={(option) => option}
                                            renderTags={(
                                              tagValue,
                                              getTagProps
                                            ) => {
                                              return tagValue.map(
                                                (option, index) => null
                                                // <Chip
                                                //   label={option}
                                                //   size="small"
                                                //   color="primary"
                                                //   {...getTagProps({ index })}
                                                //   disabled={
                                                //     fixedLanguage.indexOf(
                                                //       option
                                                //     ) !== -1
                                                //   }
                                                //   deleteIcon={<CloseIcon />}
                                                // />
                                              )
                                            }}
                                            style={{ width: '100%' }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder={t(
                                                  'content.appPublish.avalibleLanguages'
                                                )}
                                                variant="standard"
                                              />
                                            )}
                                          />
                                        )}
                                      </FormControl>
                                    </div>
                                    {/* KeyFeatrues */}
                                    <div className="form-field">
                                      {selectedKeyFeatureLanguage.map(
                                        (name: string, i) => (
                                          <div key={name}>
                                            <Typography
                                              variant="body2"
                                              color="text.secondary"
                                            >
                                              {name === 'en' ? (
                                                <strong
                                                  style={{
                                                    color: errors
                                                      ?.pricingPlans?.[
                                                      fieldIndex
                                                    ]?.keyFeaturesPricingPlan?.[
                                                      name
                                                    ]
                                                      ? '#c02424'
                                                      : 'inherit',
                                                  }}
                                                >
                                                  {t(
                                                    'content.servicePublish.pricing.keyFeatures'
                                                  )}{' '}
                                                  {`(${name}) *`}
                                                </strong>
                                              ) : (
                                                <strong>
                                                  {t(
                                                    'content.servicePublish.pricing.keyFeatures'
                                                  )}{' '}
                                                  {`(${name})`}
                                                </strong>
                                              )}
                                            </Typography>
                                            <KeyFeatureControlledTag
                                              name={`pricingPlans.${fieldIndex}.keyFeaturesPricingPlan.${
                                                name === 'en' ? 'en' : 'de'
                                              }`}
                                              control={control}
                                              label={`Key features of plan (${name})`}
                                              placeholder={t(
                                                'content.servicePublish.pricing.keyFeaturesplaceholder'
                                              )}
                                              defaultValues={
                                                name === 'en'
                                                  ? defaultValues &&
                                                    defaultValues?.pricingPlans &&
                                                    defaultValues?.pricingPlans[
                                                      fieldIndex
                                                    ]?.keyFeaturesPricingPlan
                                                      ?.en
                                                  : defaultValues &&
                                                    defaultValues?.pricingPlans &&
                                                    defaultValues?.pricingPlans[
                                                      fieldIndex
                                                    ]?.keyFeaturesPricingPlan
                                                      ?.de
                                              }
                                              rules={{
                                                required: {
                                                  value:
                                                    name === 'en'
                                                      ? true
                                                      : false,
                                                  message: `${t(
                                                    'content.appPublish.pricing.keyFeatureMandatory'
                                                  )}`,
                                                },
                                              }}
                                            />
                                            {errors?.pricingPlans &&
                                            errors?.pricingPlans[fieldIndex]
                                              ?.keyFeaturesPricingPlan?.en ? (
                                              ''
                                            ) : (
                                              <Typography
                                                sx={{ fontSize: '12px' }}
                                              >
                                                {t(
                                                  'content.appPublish.pricing.keyFeatrureLabel'
                                                )}
                                              </Typography>
                                            )}
                                          </div>
                                        )
                                      )}
                                      <div className="form-field">
                                        <InputLabel
                                          sx={{
                                            mb: 3,
                                            mt: 3,
                                            color: visibleDropdownKeyFeatrue
                                              ? '#E34C92'
                                              : '#4D4D4F',
                                            cursor: 'pointer',
                                          }}
                                          id="demo-multiple-name-label"
                                          onClick={() => {
                                            setVisibleDropdownKeyFeatrue(
                                              !visibleDropdownKeyFeatrue
                                            )
                                          }}
                                        >
                                          <strong>
                                            {t(
                                              'content.servicePublish.pricing.addKeyFeaturesOtherLang'
                                            )}
                                          </strong>
                                        </InputLabel>
                                        <FormControl
                                          sx={{ m: 1, width: '100%' }}
                                        >
                                          {visibleDropdownKeyFeatrue && (
                                            <Autocomplete
                                              multiple
                                              fullWidth
                                              value={selectedKeyFeatureLanguage}
                                              // placeholder={t(
                                              //   "content.servicePublish.pricing.keyFeaturesMax"
                                              // )}
                                              onChange={(event, newValue) => {
                                                setSelectedKeyFeatureLanguage([
                                                  ...fixedLanguage,
                                                  ...newValue.filter(
                                                    (eachLan) =>
                                                      fixedLanguage.indexOf(
                                                        eachLan
                                                      ) === -1
                                                  ),
                                                ])
                                                setValue(
                                                  `pricingPlans.${fieldIndex}.keyFeaturesPricingPlan.de`,
                                                  []
                                                )
                                                clearErrors(
                                                  `pricingPlans.${fieldIndex}.keyFeaturesPricingPlan.de`
                                                )
                                              }}
                                              options={LANGUAGE_SUPPORTED}
                                              getOptionLabel={(option) =>
                                                option
                                              }
                                              renderTags={(
                                                tagValue,
                                                getTagProps
                                              ) => {
                                                return tagValue.map(
                                                  (option, index) => null
                                                  // <Chip
                                                  //   {...getTagProps({ index })}
                                                  //   label={option}
                                                  //   size="small"
                                                  //   color="primary"
                                                  //   disabled={
                                                  //     fixedLanguage.indexOf(
                                                  //       option
                                                  //     ) !== -1
                                                  //   }
                                                  //   deleteIcon={<CloseIcon />}
                                                  // />
                                                )
                                              }}
                                              renderInput={(params) => (
                                                <TextField
                                                  {...params}
                                                  placeholder={t(
                                                    'content.servicePublish.pricing.keyFeaturesplaceholder'
                                                  )}
                                                  variant="standard"
                                                />
                                              )}
                                            />
                                          )}
                                        </FormControl>
                                      </div>
                                    </div>
                                  </React.Fragment>
                                </div>
                              ) : (
                                <div className="price-type__custom">
                                  <React.Fragment>
                                    <div
                                      className="form-field"
                                      style={{
                                        marginTop: 3,
                                      }}
                                    >
                                      <InputLabel
                                        sx={{ mt: 3, display: 'flex' }}
                                      >
                                        <strong
                                          style={{
                                            color: errors?.pricingPlans?.[
                                              fieldIndex
                                            ]?.SalesContactName
                                              ? '#c02424'
                                              : 'inherit',
                                          }}
                                        >
                                          {t(
                                            'content.appPublish.pricing.salesContactName'
                                          ) + ' *'}
                                        </strong>
                                      </InputLabel>
                                      <br />
                                      <ConnectorFormInputField
                                        {...{
                                          control,
                                          trigger,
                                          errors,
                                          name: `pricingPlans.${fieldIndex}.SalesContactName` as const,
                                          placeholder:
                                            'Enter name of contact person',
                                          type: 'input',
                                          rules: {
                                            required: {
                                              value: true,
                                              message: `${t(
                                                'Sales Contact Name'
                                              )} ${t(
                                                'content.appPublish.appReleaseForm.isMandatory'
                                              )}`,
                                            },
                                            minLength: {
                                              value: 2,
                                              message: `${t(
                                                'content.appPublish.appReleaseForm.minimum'
                                              )} 2 ${t(
                                                'content.appPublish.appReleaseForm.charactersRequired'
                                              )}`,
                                            },
                                            pattern: {
                                              value:
                                                Patterns.appMarketCard
                                                  .pricingPlanTitle,
                                              message: `${t(
                                                'content.appPublish.appReleaseForm.validCharactersIncludes'
                                              )} A-Za-z0-9.:_- @&€`,
                                            },
                                            maxLength: {
                                              value: 40,
                                              message: `${t(
                                                'content.appPublish.appReleaseForm.maximum'
                                              )} 40 ${t(
                                                'content.appPublish.appReleaseForm.charactersAllowed'
                                              )}`,
                                            },
                                          },
                                        }}
                                      />
                                      {errors?.pricingPlans &&
                                        errors.pricingPlans?.[fieldIndex]
                                          ?.SalesContactName?.type ===
                                          'required' && (
                                          <Typography
                                            variant="body2"
                                            className="file-error-msg"
                                          >
                                            {
                                              errors?.pricingPlans[fieldIndex]
                                                ?.SalesContactName?.message
                                            }
                                          </Typography>
                                        )}
                                    </div>
                                    <div className="form-field">
                                      <InputLabel sx={{ display: 'flex' }}>
                                        <strong
                                          style={{
                                            color: errors?.pricingPlans?.[
                                              fieldIndex
                                            ]?.SalesContactEmail
                                              ? '#c02424'
                                              : 'inherit',
                                          }}
                                        >
                                          {t(
                                            'content.appPublish.pricing.contactEmail'
                                          ) + ' *'}
                                        </strong>
                                      </InputLabel>
                                      <ConnectorFormInputField
                                        {...{
                                          control,
                                          trigger,
                                          errors,
                                          name: `pricingPlans.${fieldIndex}.SalesContactEmail` as const,
                                          // label: `${t(`content.appPublish.DocumentationAndPrivacy.email` )} *`,
                                          placeholder: `${t(
                                            'content.appPublish.DocumentationAndPrivacy.emailPlaceHolder'
                                          )}`,
                                          type: 'input',
                                          rules: {
                                            required: {
                                              value: true,
                                              message: `${t(
                                                'content.appPublish.DocumentationAndPrivacy.email'
                                              )} ${t(
                                                'content.appPublish.appReleaseForm.isMandatory'
                                              )} `,
                                            },
                                            pattern: {
                                              value: Patterns.MAIL,
                                              message: t(
                                                'content.appPublish.DocumentationAndPrivacy.providerEmailvalidation'
                                              ),
                                            },
                                          },
                                        }}
                                      />
                                      {errors?.pricingPlans &&
                                        errors?.pricingPlans?.[fieldIndex]
                                          ?.SalesContactEmail?.type ===
                                          'required' && (
                                          <Typography
                                            variant="body2"
                                            className="file-error-msg"
                                          >
                                            {
                                              errors?.pricingPlans[fieldIndex]
                                                ?.SalesContactEmail?.message
                                            }
                                          </Typography>
                                        )}
                                    </div>
                                    <Typography
                                      variant="body2"
                                      color="text.secondary"
                                    >
                                      <strong
                                        style={{
                                          color:
                                            errors &&
                                            errors?.pricingPlans?.[fieldIndex]
                                              ?.SalesContactPhone
                                              ? '#c02424'
                                              : 'inherit',
                                        }}
                                      >
                                        {t(
                                          'content.appPublish.DocumentationAndPrivacy.phoneNumber'
                                        )}{' '}
                                        (
                                        {t(
                                          'content.appPublish.pricing.optional'
                                        )}
                                        )
                                      </strong>
                                    </Typography>
                                    <div
                                      style={{
                                        display: 'flex',
                                      }}
                                    >
                                      <Controller
                                        name={
                                          `pricingPlans.${fieldIndex}.countryCode` as const
                                        }
                                        control={control}
                                        render={({ field }) => {
                                          return (
                                            <Select
                                              id="standard-select-phone"
                                              defaultValue="+49"
                                              value={field.value ?? '+49'}
                                              variant="standard"
                                              onChange={field.onChange}
                                              sx={{
                                                width: '20%',
                                              }}
                                            >
                                              {PHONE_CODES.map((option) => (
                                                <MenuItem
                                                  key={option.code}
                                                  value={option.code}
                                                >
                                                  {option.code}
                                                  <strong
                                                    style={{ color: '#FDB913' }}
                                                  >
                                                    &nbsp;&nbsp;{option.name}
                                                  </strong>
                                                </MenuItem>
                                              ))}
                                            </Select>
                                          )
                                        }}
                                      />
                                      <div
                                        style={{
                                          width: '100%',
                                        }}
                                      >
                                        <ConnectorFormInputField
                                          {...{
                                            control,
                                            trigger,
                                            errors,
                                            name: `pricingPlans.${fieldIndex}.SalesContactPhone` as const,
                                            placeholder: `${t(
                                              'content.appPublish.DocumentationAndPrivacy.phoneNumberPlaceHolder'
                                            )}`,
                                            type: 'input',
                                            rules: {
                                              pattern: {
                                                value:
                                                  Patterns.appPage.newPhone,
                                                message: t(
                                                  'content.apprelease.appPage.pleaseEnterValidContact'
                                                ),
                                              },
                                              maxLength: {
                                                value: 15,
                                                message: `${t(
                                                  'content.appPublish.appReleaseForm.maximum'
                                                )} 15 ${t(
                                                  'content.appPublish.appReleaseForm.charactersAllowed'
                                                )}`,
                                              },
                                            },
                                          }}
                                        />
                                      </div>
                                    </div>
                                    {errors &&
                                      errors?.pricingPlans?.[fieldIndex]
                                        ?.SalesContactPhone && (
                                        <Typography
                                          variant="body2"
                                          className="file-error-msg"
                                        >
                                          {
                                            errors?.pricingPlans?.[fieldIndex]
                                              ?.SalesContactPhone?.message
                                          }
                                        </Typography>
                                      )}
                                    {/* BriefDescription */}
                                    {LANGUAGE_SUPPORTED.filter((language) =>
                                      selectedBriefLanguage.some(
                                        (selected) => selected === language
                                      )
                                    ).map((language, index) => {
                                      return (
                                        <div className="form-field">
                                          <ConnectorFormInputField
                                            {...{
                                              control,
                                              trigger,
                                              errors,
                                              name: `pricingPlans.${fieldIndex}.briefDescription.${index}.${language}.shortDescription` as const,
                                              type: 'input',
                                              label: (
                                                <strong
                                                  style={{
                                                    color: errors
                                                      ?.pricingPlans?.[
                                                      fieldIndex
                                                    ]?.briefDescription?.[
                                                      index
                                                    ]?.[language]
                                                      ?.shortDescription
                                                      ? '#c02424'
                                                      : 'inherit',
                                                  }}
                                                >
                                                  {t(
                                                    'content.servicePublish.pricing.briefDescription'
                                                  )}{' '}
                                                  {`(${language}) *`}
                                                </strong>
                                              ),
                                              textarea: true,
                                              placeholder: `${t(
                                                'content.servicePublish.pricing.briefDescriptionPlcaholder'
                                              )}`,
                                              rules: {
                                                required: {
                                                  value:
                                                    language === 'en'
                                                      ? true
                                                      : false,
                                                  message: `${t(
                                                    `${t(
                                                      'content.servicePublish.pricing.briefDescription'
                                                    )} ${`(${language})`}`
                                                  )} ${t(
                                                    'content.appPublish.appReleaseForm.isMandatory'
                                                  )}`,
                                                },
                                                minLength: {
                                                  value: 10,
                                                  message: `${t(
                                                    'content.appPublish.appReleaseForm.minimum'
                                                  )} 10 ${t(
                                                    'content.appPublish.appReleaseForm.charactersRequired'
                                                  )}`,
                                                },
                                                pattern: {
                                                  value:
                                                    language === 'en'
                                                      ? Patterns.appMarketCard
                                                          .pricingBriefDescriptionEN
                                                      : Patterns.appMarketCard
                                                          .pricingBriefDescriptionDE,
                                                  message: `${t(
                                                    'content.appPublish.appReleaseForm.validCharactersIncludes'
                                                  )} ${
                                                    language === 'en'
                                                      ? 'a-zA-Z0-9 !?@&#\'"()_-=/*.,;:€'
                                                      : 'a-zA-ZÀ-ÿ0-9 !?@&#\'"()_-=/*.,;:€'
                                                  }`,
                                                },
                                                maxLength: {
                                                  value: 110,
                                                  message: `${t(
                                                    'content.appPublish.appReleaseForm.maximum'
                                                  )} 110 ${t(
                                                    'content.appPublish.appReleaseForm.charactersAllowed'
                                                  )}`,
                                                },
                                              },
                                            }}
                                          />
                                          {errors &&
                                            errors.pricingPlans &&
                                            errors.pricingPlans[fieldIndex]
                                              ?.briefDescription?.[index]?.[
                                              language
                                            ] &&
                                            errors.pricingPlans[fieldIndex]
                                              ?.briefDescription?.[index]?.[
                                              language
                                            ]?.shortDescription?.type ===
                                              'required' && (
                                              <Typography
                                                variant="body2"
                                                className="file-error-msg"
                                              >
                                                {
                                                  errors.pricingPlans[
                                                    fieldIndex
                                                  ]?.briefDescription?.[
                                                    index
                                                  ]?.[language]
                                                    ?.shortDescription?.message
                                                }
                                              </Typography>
                                            )}
                                          {errors &&
                                            errors.pricingPlans &&
                                            errors.pricingPlans[fieldIndex]
                                              ?.briefDescription?.[index]?.[
                                              language
                                            ] &&
                                            errors.pricingPlans[fieldIndex]
                                              ?.briefDescription?.[index]?.[
                                              language
                                            ]?.shortDescription?.type ===
                                              'maxLength' && (
                                              <Typography
                                                variant="body2"
                                                className="file-error-msg"
                                              >
                                                {
                                                  errors.pricingPlans[
                                                    fieldIndex
                                                  ]?.briefDescription?.[
                                                    index
                                                  ]?.[language]
                                                    ?.shortDescription?.message
                                                }
                                              </Typography>
                                            )}
                                          {errors &&
                                            errors.pricingPlans &&
                                            errors.pricingPlans[fieldIndex]
                                              ?.briefDescription?.[index]?.[
                                              language
                                            ] &&
                                            errors.pricingPlans[fieldIndex]
                                              ?.briefDescription?.[index]?.[
                                              language
                                            ]?.shortDescription?.type ===
                                              'minLength' && (
                                              <Typography
                                                variant="body2"
                                                className="file-error-msg"
                                              >
                                                {
                                                  errors.pricingPlans[
                                                    fieldIndex
                                                  ]?.briefDescription?.[
                                                    index
                                                  ]?.[language]
                                                    ?.shortDescription?.message
                                                }
                                              </Typography>
                                            )}
                                          <Typography
                                            variant="body2"
                                            className="form-field"
                                            align="right"
                                            style={{
                                              fontWeight: 600,
                                            }}
                                          >
                                            {getValues()?.pricingPlans[
                                              fieldIndex
                                            ]?.briefDescription?.[index]?.[
                                              language
                                            ]?.shortDescription?.length +
                                              '/110'}
                                          </Typography>
                                        </div>
                                      )
                                    })}
                                    <div className="form-field">
                                      <InputLabel
                                        id="demo-multiple-name-label"
                                        onClick={() => {
                                          setVisibleDropdownPricingplan(
                                            !visibleDropdownPricingplan
                                          )
                                        }}
                                      >
                                        <strong
                                          style={{
                                            color: visibleDropdownPricingplan
                                              ? '#E34C92'
                                              : '#4D4D4F',
                                            marginBottom: -5,
                                            cursor: 'pointer',
                                          }}
                                        >
                                          {t(
                                            'content.servicePublish.pricing.addBriefDescriptionOtherLang'
                                          )}
                                        </strong>
                                      </InputLabel>
                                    </div>
                                    <div className="form-field">
                                      <FormControl sx={{ m: 1, width: '100%' }}>
                                        {visibleDropdownPricingplan && (
                                          <Autocomplete
                                            multiple
                                            fullWidth
                                            id="fixed-tags-demo"
                                            value={selectedBriefLanguage}
                                            onChange={(event, newValue) => {
                                              setSelectedBriefLanguage([
                                                ...fixedLanguage,
                                                ...newValue.filter(
                                                  (eachLan) =>
                                                    fixedLanguage.indexOf(
                                                      eachLan
                                                    ) === -1
                                                ),
                                              ])
                                              LANGUAGE_SUPPORTED.filter(
                                                (newLanguage) =>
                                                  newValue.every(
                                                    (lang) =>
                                                      lang !== newLanguage
                                                  )
                                              ).forEach((language) => {
                                                const index =
                                                  LANGUAGE_SUPPORTED.indexOf(
                                                    language
                                                  )
                                                resetField(
                                                  `pricingPlans.${fieldIndex}.briefDescription.${index}.${language}.shortDescription`
                                                )
                                                setValue(
                                                  `pricingPlans.${fieldIndex}.briefDescription.${index}.${language}.shortDescription`,
                                                  '' as string as never
                                                )
                                              })
                                            }}
                                            options={LANGUAGE_SUPPORTED}
                                            getOptionLabel={(option) => option}
                                            renderTags={(
                                              tagValue,
                                              getTagProps
                                            ) => {
                                              return tagValue.map(
                                                (option, index) => null
                                                // <Chip
                                                //   label={option}
                                                //   size="small"
                                                //   color="primary"
                                                //   {...getTagProps({ index })}
                                                //   disabled={
                                                //     fixedLanguage.indexOf(
                                                //       option
                                                //     ) !== -1
                                                //   }
                                                //   deleteIcon={<CloseIcon />}
                                                // />
                                              )
                                            }}
                                            style={{ width: '100%' }}
                                            renderInput={(params) => (
                                              <TextField
                                                {...params}
                                                placeholder={t(
                                                  'content.appPublish.avalibleLanguages'
                                                )}
                                                variant="standard"
                                              />
                                            )}
                                          />
                                        )}
                                      </FormControl>
                                    </div>
                                  </React.Fragment>
                                </div>
                              )}
                            </div>
                          </FormControl>
                        )
                      }}
                    />
                  </React.Fragment>
                )
              })}
            <Divider>
              {fields.length < 5 && (
                <Chip
                  label={`${t(
                    'content.servicePublish.pricing.addPricingPlan'
                  )} ${5 - fields.length} ${t(
                    'content.servicePublish.pricing.remainingPlans'
                  )}`}
                  color="confirmed"
                  sx={{
                    fontWeight: 'bold',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    append({
                      planId: '00000000-0000-0000-0000-000000000000',
                      planTitle: '',
                      amount: '',
                      CustomPricingType: 'fixed',
                      SalesContactName: '',
                      SalesContactEmail: '',
                      SalesContactPhone: '',
                      briefDescription: LANGUAGE_SUPPORTED.map((language) => {
                        return {
                          [language]: {
                            shortDescription: '',
                            longDescription: '',
                          },
                        }
                      }),
                      keyFeaturesPricingPlan: {
                        keyFeatureId: '00000000-0000-0000-0000-000000000000',
                        en: [],
                        de: [],
                        entityState: ENTITYSTATE.ADDED,
                      },
                      pricingModel: '',
                      entityState: ENTITYSTATE.ADDED,
                    })
                  }}
                />
              )}
            </Divider>
          </form>
        </div>
      </Box>
      <Box
        style={{
          padding: '5% 2% 5% 2%',
          borderBottomLeftRadius: '30px',
          borderBottomRightRadius: '30px',
          background: 'rgba(250, 250, 250, 1)',
        }}
      >
        {appPageNotification && (
          <Grid container xs={12} sx={{ mb: 2 }}>
            <Grid xs={6}></Grid>
            <Grid xs={6}>
              <PageNotifications
                title={t('content.appPublish.appReleaseForm.error.title')}
                description={t(
                  'content.appPublish.appReleaseForm.error.message'
                )}
                open
                severity="error"
                onCloseNotification={() => {
                  setAppPageNotification(false)
                }}
              />
            </Grid>
          </Grid>
        )}
        <PageSnackbar
          open={appPageSnackbar}
          onCloseNotification={() => {
            setAppPageSnackbar(false)
          }}
          severity="success"
          description={t(
            'content.appPublish.appReleaseForm.dataSavedSuccessMessage'
          )}
          autoClose={true}
        />

        <Box textAlign={'center'}>
          <Typography variant="caption2">
            {t('content.servicePublish.footerText')}{' '}
            <strong>
              <a
                href={COFINITY_SERVICE_ONBOARDING_DOCUMENTATION_URL}
                target="_blank"
                rel="noreferrer"
              >
                <a
                  href={COFINITY_SERVICE_ONBOARDING_DOCUMENTATION_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('content.servicePublish.footerTextLink')}
                </a>
              </a>
            </strong>
          </Typography>
        </Box>
        <Box textAlign="end" mt={2} pl={5} pr={5}>
          <Button
            startIcon={<KeyboardBackspaceIcon />}
            sx={{
              float: 'left',
            }}
            size="small"
            variant="outlined"
            name="back"
            onClick={() => {
              onBackIconClick()
            }}
          >
            Back
          </Button>
          <Button
            variant="outlined"
            disabled={pricingApiStatus === QueryStatus.pending}
            sx={{ mr: 2 }}
            onClick={handleSubmit((data) => validateFields(data, 'save'))}
          >
            {t('content.servicePublish.footerButtons.save')}
          </Button>
          <Button
            variant="contained"
            name="send"
            disabled={pricingApiStatus === QueryStatus.pending}
            onClick={handleSubmit((data) =>
              validateFields(data, 'saveAndProceed')
            )}
          >
            {t('content.servicePublish.footerButtons.saveAndProceed')}
          </Button>
        </Box>
      </Box>
      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={pricingApiStatus === QueryStatus.pending}
      >
        <CircularProgress color="primary" />
      </Backdrop>
    </Paper>
  )
}
export default Pricing
