import React from 'react'
import ServiceOnboard from './ServiceOnboard'
import { currentActiveStep } from 'features/Custom/serviceOnboard/slice'
import { useSelector } from 'react-redux'

export type StepItem = {
  headline: string
  step: number
}

const STEP_LIST: StepItem[] = [
  {
    headline: 'Overview',
    step: 1,
  },
  {
    headline: 'Description & benefits',
    step: 2,
  },
  {
    headline: 'Case studies',
    step: 3,
  },
  {
    headline: 'Pricing',
    step: 4,
  },
  {
    headline: 'Certification',
    step: 5,
  },
]

const STEP_LIST_WITH_TECHNICAL_INTEGRATION: StepItem[] = [
  {
    headline: 'Overview',
    step: 1,
  },
  {
    headline: 'Description & benefits',
    step: 2,
  },
  {
    headline: 'Case studies',
    step: 3,
  },
  {
    headline: 'Pricing',
    step: 4,
  },
  {
    headline: 'Service Roles',
    step: 5,
  },
  {
    headline: 'Certification',
    step: 6,
  },
]

export const ServiceOnboardReleaseProcess = () => {
  const activePage = useSelector(currentActiveStep)
  return (
    <ServiceOnboard
      activePage={activePage}
      stepsList={STEP_LIST}
      numberOfSteps={STEP_LIST.length}
      stepsListWithTechnicalIntegration={STEP_LIST_WITH_TECHNICAL_INTEGRATION}
    />
  )
}
