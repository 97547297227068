import React, { useState } from 'react'
import RenderTable from '../RenderTable'
import { type CustomtableType } from '../type'
import { InputLabel } from '@mui/material'
import { AttachFile } from '@mui/icons-material'
import { download } from 'utils/downloadUtils'
import { useFetchDocumentByTypeMutation } from 'features/Custom/appOnboard/apiSlice'
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import ImageViewer from './ImageViewer'
import { type AppStatusDataState } from 'features/Custom/appOnboard/types'
import {
  CONTROLLERS,
  OFFERTYPES,
  type ServiceStatusDataState,
} from 'features/Custom/serviceOnboard/types'
import { useTranslation } from 'react-i18next'

export const Details = (props: {
  appStatusData: AppStatusDataState | ServiceStatusDataState
  offerTypeId: string
}) => {
  const { t } = useTranslation()
  const [imagePreview, setImagePreview] = useState<string>('')
  const [loaded, setLoaded] = useState<boolean>(false)
  const [fetchDocumentByType] = useFetchDocumentByTypeMutation()
  const handleImageDownload = async (
    id: string,
    documentName: string,
    documentId: string
  ) => {
    try {
      const response = await fetchDocumentByType({
        id,
        documentId,
        controller:
          props.offerTypeId === OFFERTYPES.APP
            ? CONTROLLERS.APP_ONBOARD
            : CONTROLLERS.SERVICE_ONBOARD,
        offerTypeId: props.offerTypeId,
      }).unwrap()
      const file = response.data
      download(file, '.pdf', documentName)
    } catch (error) {
      console.error(error, 'ERROR WHILE FETCHING DOCUMENT')
    }
  }
  const handleView = async (
    id: string,
    documentName: string,
    documentId: string
  ) => {
    try {
      const response = await fetchDocumentByType({
        id,
        documentId,
        controller:
          props.offerTypeId === OFFERTYPES.APP
            ? CONTROLLERS.APP_ONBOARD
            : CONTROLLERS.SERVICE_ONBOARD,
        offerTypeId: props.offerTypeId,
      }).unwrap()
      const file = response.data
      const blob = new Blob([file], { type: 'image/*' })
      const blobURL = URL.createObjectURL(blob)
      setImagePreview(blobURL)
    } catch (error) {
      console.error(error, 'ERROR WHILE FETCHING DOCUMENT')
    }
  }

  const detailsData: CustomtableType = {
    head: [`${t('content.submissionOverlay.details.detailsLabel')}`, ''],
    body: [
      [
        props.offerTypeId === OFFERTYPES.APP
          ? `${t('content.submissionOverlay.details.app.appTitle')}`
          : `${t('content.submissionOverlay.details.service.serviceTitle')}`,
        <React.Fragment>
          <strong>{props.appStatusData.title}</strong>
        </React.Fragment>,
      ],
      [
        props.offerTypeId === OFFERTYPES.APP
          ? `${t('content.submissionOverlay.details.app.appProvider')}`
          : `${t('content.submissionOverlay.details.service.serviceProvider')}`,
        <React.Fragment>
          <strong>{props.appStatusData?.provider}</strong>
        </React.Fragment>,
      ],
      [
        `${t('content.submissionOverlay.details.keywords')}`,
        <React.Fragment>
          <strong>
            {props.appStatusData?.tags?.toString() ??
              `${t('content.submissionOverlay.details.noKeywords')}`}
          </strong>
        </React.Fragment>,
      ],
      [
        `${t('content.submissionOverlay.details.useCases')}`,
        <React.Fragment>
          <InputLabel sx={{ fontWeight: 'bold' }}>
            {props.appStatusData?.useCases
              ?.map((eachUseCase: any) => `${eachUseCase?.label}`)
              .join(', ')}
          </InputLabel>
        </React.Fragment>,
      ],
      props.offerTypeId === OFFERTYPES.APP
        ? [
            `${t(
              'content.submissionOverlay.details.app.applicationLanguages'
            )}`,
            <React.Fragment>
              <strong>
                {props.appStatusData?.languages?.toString()?.toUpperCase()}
              </strong>
            </React.Fragment>,
          ]
        : [],
    ],
  }
  const shortDescriptionData: CustomtableType = {
    head: [`${t('content.submissionOverlay.details.shortDescription')}`, ''],
    body: [
      [
        `${
          props.appStatusData?.longDescription?.filter(
            (lan: any) => lan?.languageCode === 'en'
          )[0]?.shortDescription
        }`,
      ],
    ],
  }
  const coverImageData: CustomtableType = {
    head: [`${t('content.submissionOverlay.details.coverImage')}`, ''],
    body: props.appStatusData?.documents
      ?.filter((eachDoc: any) => eachDoc.documentTypeId === 'APP_LEADIMAGE')
      ?.map((eachDoc: any) => [
        [
          <React.Fragment>
            <AttachFile htmlColor="#2484C6" />
            <strong
              style={{
                textDecoration: 'none',
                color: '#2484C6',
                cursor: 'default',
              }}
            >
              {eachDoc?.documentName ??
                `${t('content.submissionOverlay.details.noDocumentFound')}`}
            </strong>
          </React.Fragment>,
        ],
        [
          <React.Fragment>
            <Button
              sx={{ padding: '.2rem 1rem' }}
              variant="main"
              size="small"
              onClick={() => {
                handleImageDownload(
                  props.appStatusData.id,
                  eachDoc.documentName,
                  eachDoc.documentId
                )
              }}
            >
              {t('content.submissionOverlay.downloadBtn')}
            </Button>
            <Button
              sx={{ padding: '.2rem 1rem', marginLeft: 2 }}
              variant="main"
              size="small"
              onClick={() => {
                handleView(
                  props.appStatusData.id,
                  eachDoc.documentName,
                  eachDoc.documentId
                )
                setLoaded(true)
              }}
            >
              {t('content.submissionOverlay.viewBtn')}
            </Button>
          </React.Fragment>,
        ],
      ]),
  }
  return (
    <div className="appdetail-privacy">
      <div className="appdetail-privacy-table">
        <Dialog
          open={loaded}
          additionalModalRootStyles={{
            maxWidth: '100vw',
            width: '1004px',
          }}
          scroll="paper"
        >
          <DialogHeader
            wrapperStyle={{
              paddingTop: 5,
            }}
            closeWithIcon={true}
            onCloseWithIcon={() => {
              setLoaded(false)
            }}
          />
          <DialogContent
            sx={{
              padding: '0px 63px 40px 63px',
              maxHeight: '672px',
            }}
          >
            <ImageViewer file={imagePreview} />
          </DialogContent>
        </Dialog>

        <RenderTable key={'details'} data={detailsData} />
        <RenderTable key={'shortDescriptionData'} data={shortDescriptionData} />
        <RenderTable key={'coverImageData'} data={coverImageData} />
      </div>
    </div>
  )
}
