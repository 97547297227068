import * as React from 'react'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import InputLabel from '@mui/material/InputLabel'
import { useSelector } from 'react-redux'
import {
  TabPanel,
  a11yProps,
} from 'components/pages/AppMarketAdminDashboard/AddConnectorOverlay/components/TabPanel'
import { Apps } from './PanelComponents/Apps'
import { AllNotification } from './PanelComponents/AllNotification'
import { Services } from './PanelComponents/Services'
import { General } from './PanelComponents/General'
import { useTranslation } from 'react-i18next'

export default function NotificationTabs() {
  const { t } = useTranslation('notification')
  const [value, setValue] = React.useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%', padding: '20px 0 0' }}>
      <Box
        sx={{
          bgcolor: 'background.paper',
          borderBottom: 1,
          borderColor: 'divider',
          width: 450,
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label={
              <InputLabel style={styles.tabLabels}>
                {t('item.sortOptions.all')}
              </InputLabel>
            }
          />
          <Tab
            label={
              <InputLabel style={styles.tabLabels}>
                {t('item.sortOptions.apps')}
              </InputLabel>
            }
            wrapped
            {...a11yProps(0)}
          />
          <Tab
            label={
              <InputLabel style={styles.tabLabels}>
                {t('item.sortOptions.services')}
              </InputLabel>
            }
            wrapped
            {...a11yProps(1)}
          />
          <Tab
            label={
              <InputLabel style={styles.tabLabels}>
                {t('item.sortOptions.general')}
              </InputLabel>
            }
            wrapped
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <AllNotification />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Apps />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Services />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <General />
      </TabPanel>
    </Box>
  )
}

const styles = {
  tabLabels: {
    color: '#000000',
    fontSize: '20px',
    fontWeight: '500',
    padding: 2,
    cursor: 'pointer',
  },
}
