import {
  Button,
  DialogActions,
  DialogContent,
  DialogHeader,
  Typography,
  Dialog,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { useTranslation } from 'react-i18next'
import { InputLabel } from '@mui/material'
import { Dropzone, type DropzoneFile } from 'components/shared/basic/Dropzone'
import { Controller, useForm } from 'react-hook-form'
import Patterns from 'types/Patterns'
import { SuccessDialog } from './successDialog'
import React, { useState } from 'react'
import { ErrorDialog } from './errorDialog'
import { ConnectorFormInputField } from 'components/pages/AppPublish/OverView'
import { useCreateSupportTicketMutation } from 'features/Custom/customerSupport/apiSlice'
import { SIZE_LIMITS } from 'types/Config'
import { FocusTrap } from '@mui/base/FocusTrap'

export interface SupportDialogProps {
  open: boolean
  onClose: (value: boolean) => void
}

interface FormDataType {
  title: string
  shortDescription: string
  uploadImage: DropzoneFile[] | any | null
}

export function SupportDialog(props: SupportDialogProps) {
  const { onClose, open } = props
  const { t } = useTranslation()
  const {
    control,
    trigger,
    setValue,
    resetField,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormDataType>({
    mode: 'onChange',
  })

  const [createSupportTicket, { isLoading }] = useCreateSupportTicketMutation()
  const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false)
  const [isSuccessDialogOpen, setIsSuccessDialogOpen] = useState(false)

  const handleTicketSubmit = async (data: FormDataType) => {
    const body = new FormData()
    body.append('Summary', data.title)
    body.append('Description', data.shortDescription)
    data.uploadImage?.forEach((file: File, index: number) => {
      body.append(`Attachments[${index}]`, file)
    })

    try {
      await createSupportTicket(body).unwrap()
      setIsSuccessDialogOpen(true)
      reset()
    } catch (error) {
      setIsErrorDialogOpen(true)
    }
  }

  const handleDialogClose = (e: React.KeyboardEvent<HTMLFormElement>) => {
    // Prevent dialog from closing when tabbing through the form
    if (e.key === 'Tab') {
      e.stopPropagation()
    }
  }

  const handleClose = () => {
    if (!isLoading) {
      onClose(true)
      reset()
    }
  }
  return (
    <>
      <Dialog
        open={open}
        additionalModalRootStyles={{
          width: '600px',
          textAlign: 'center',
        }}
      >
        <DialogHeader
          {...{
            title: 'Customer Support',
            closeWithIcon: true,
          }}
          onCloseWithIcon={handleClose}
          wrapperStyle={{ padding: '50px 50px 35px' }}
        />
        <FocusTrap open={true}>
          <form
            onSubmit={handleSubmit(handleTicketSubmit)}
            onKeyDown={handleDialogClose}
          >
            <DialogContent>
              <div className="form-field">
                <ConnectorFormInputField
                  {...{
                    control,
                    trigger,
                    autoFocus: true,
                    errors,
                    name: 'title',
                    label: 'Title',
                    type: 'input',
                    placeholder: `${t('content.helpdesk.title.placeholder')}`,
                    rules: {
                      required: {
                        value: true,
                        message: `${t('content.helpdesk.title.mandatory')}`,
                      },
                      minLength: {
                        value: 5,
                        message: `${t(
                          'content.appPublish.appReleaseForm.minimum'
                        )} 5 ${t(
                          'content.appPublish.appReleaseForm.charactersRequired'
                        )}`,
                      },
                      pattern: {
                        value: Patterns.support.title,
                        message: `${t(
                          'content.appPublish.appReleaseForm.validCharactersIncludes'
                        )} A-Za-z0-9.:_- @&`,
                      },
                      maxLength: {
                        value: 40,
                        message: `${t(
                          'content.appPublish.appReleaseForm.maximum'
                        )} 40 ${t(
                          'content.appPublish.appReleaseForm.charactersAllowed'
                        )}`,
                      },
                    },
                  }}
                />
              </div>
              <div className="form-field">
                <ConnectorFormInputField
                  {...{
                    control,
                    trigger,
                    errors,
                    name: 'shortDescription',
                    label: 'Description',
                    type: 'input',
                    placeholder: `${t(
                      'content.helpdesk.description.placeholder'
                    )}`,
                    textarea: true,
                    rules: {
                      required: {
                        value: true,
                        message: `${t(
                          'content.helpdesk.description.mandatory'
                        )}`,
                      },
                      minLength: {
                        value: 5,
                        message: `${t(
                          'content.appPublish.appReleaseForm.minimum'
                        )} 5 ${t(
                          'content.appPublish.appReleaseForm.charactersRequired'
                        )}`,
                      },
                      pattern: {
                        value: Patterns.support.description,
                        message: `${t(
                          'content.appPublish.appReleaseForm.validCharactersIncludes'
                        )} A-Za-z0-9.:_- @&`,
                      },
                      maxLength: {
                        value: 250,
                        message: `${t(
                          'content.appPublish.appReleaseForm.maximum'
                        )} 250 ${t(
                          'content.appPublish.appReleaseForm.charactersAllowed'
                        )}`,
                      },
                    },
                  }}
                />
              </div>
              <div className="form-field">
                <InputLabel sx={{ mb: 3, mt: 3, display: 'flex' }}>
                  <strong>{'Document / Pictures'}</strong>
                </InputLabel>
                <Controller
                  name="uploadImage"
                  control={control}
                  rules={{
                    required: {
                      value: false,
                      message: `${t(
                        'content.appPublish.appPage.filerequired'
                      )}`,
                    },
                  }}
                  render={({
                    field: { onChange: reactHookFormOnChange, value },
                  }) => {
                    return (
                      <Dropzone
                        files={value}
                        onChange={(files, addedFiles, deletedFiles) => {
                          setValue('uploadImage', files)
                          if (files.length === 0) {
                            resetField('uploadImage')
                          }
                          reactHookFormOnChange(files)
                          trigger('uploadImage')
                        }}
                        acceptFormat={{
                          'image/png': [],
                          'application/pdf': [],
                        }}
                        maxFilesToUpload={5}
                        maxFileSize={SIZE_LIMITS.FILESIZE_1MB}
                      />
                    )
                  }}
                />
                {errors?.uploadImage?.type === 'required' && (
                  <Typography variant="body2" className="file-error-msg">
                    {t(
                      'content.appPublish.appReleaseForm.fileUploadIsMandatory'
                    )}
                  </Typography>
                )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={handleClose}>
                {t('content.helpdesk.buttons.cancel')}
              </Button>
              <Button type="submit" variant="contained" disabled={isLoading}>
                {t('content.helpdesk.buttons.send')}
              </Button>
            </DialogActions>
          </form>
        </FocusTrap>
      </Dialog>
      <SuccessDialog
        open={isSuccessDialogOpen}
        onClose={() => {
          setIsSuccessDialogOpen(false)
        }}
      />
      <ErrorDialog
        open={isErrorDialogOpen}
        onClose={() => {
          setIsErrorDialogOpen(false)
        }}
      />
    </>
  )
}
