import React from 'react'
import { type AppMarketplaceApp } from 'features/apps/apiSlice'
import './Filters.scss'
import { useTranslation } from 'react-i18next'

type FilterProps = {
  items?: AppMarketplaceApp[]
  onClick: (tag: string) => void
  selectedTab: string[]
}

function Filters({ items, onClick, selectedTab }: FilterProps) {
  const { t } = useTranslation()

  const filters = [
    ...new Set(
      items?.reduce(
        (prev: any, nxt: any) => {
          /* eslint-disable no-unsafe-optional-chaining */
          return [...prev, ...nxt?.useCases]
        },
        ['All'] as string[]
      )
    ),
  ]

  return (
    <div className="overview-section--filters">
      <h2>{t('global.objects.filters')}</h2>
      <div className="tags">
        {filters?.map((filter: any) => (
          <span
            key={filter}
            className={selectedTab.includes(filter) ? 'active' : ''}
            onClick={() => {
              onClick(filter)
            }}
          >
            {filter}
          </span>
        ))}
      </div>
    </div>
  )
}

export default Filters
