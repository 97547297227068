import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  type PaginFetchArgs,
  type PaginResult,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { apiBaseQuery } from 'utils/rtkUtil'
import { TableFilters, type appAdminResponseType } from './types'
import { OFFERTYPES } from 'features/Custom/serviceOnboard/types'

export const apiSlice = createApi({
  reducerPath: 'rtk/apps/serviceAdmin',
  baseQuery: fetchBaseQuery(apiBaseQuery()),
  endpoints: (builder) => ({
    fetchApps: builder.query<PaginResult<appAdminResponseType>, PaginFetchArgs>(
      {
        query: (fetchArgs) => {
          const isFetchedArgs = fetchArgs.args && fetchArgs.args.expr
          if (
            isFetchedArgs &&
            fetchArgs.args.statusFilter &&
            fetchArgs.args.statusFilter !== TableFilters.ALL
          ) {
            return `/api/Apps/ServiceOnboard/AllApps?page=${
              fetchArgs.page
            }&size=10&offerTitleFilter=${
              fetchArgs.args!.expr
            }&offerStatusIdFilter=${fetchArgs.args!.statusFilter}&offerTypeId=${
              OFFERTYPES.SERVICE
            }`
          } else if (
            !isFetchedArgs &&
            fetchArgs.args.statusFilter &&
            fetchArgs.args.statusFilter !== TableFilters.ALL
          ) {
            return `/api/Apps/ServiceOnboard/AllApps?page=${
              fetchArgs.page
            }&size=10&offerStatusIdFilter=${
              fetchArgs.args!.statusFilter
            }&offerTypeId=${OFFERTYPES.SERVICE}`
          } else if (
            isFetchedArgs ||
            (fetchArgs.args.statusFilter &&
              fetchArgs.args.statusFilter === TableFilters.ALL)
          ) {
            return `/api/Apps/ServiceOnboard/AllApps?size=10&page=${
              fetchArgs.page
            }&offerTitleFilter=${fetchArgs.args!.expr}&offerTypeId=${
              OFFERTYPES.SERVICE
            }`
          } else {
            return `/api/Apps/ServiceOnboard/AllApps?page=${fetchArgs.page}&size=10&offerTypeId=${OFFERTYPES.SERVICE}`
          }
        },
      }
    ),
    performActionBasedOnAppStatus: builder.mutation<void, any>({
      query: (data: { serviceId: string; triggeredAction: string }) => {
        const { serviceId, triggeredAction } = data
        return {
          // url: `/api/Apps/ServiceOnboard/PerformAction/${serviceId}/Action/${triggeredAction}`,
          url: `/api/Apps/ServiceOnboard/PerformAction/${serviceId}/Action/${triggeredAction}?offerTypeId=${OFFERTYPES.SERVICE}`,
          method: 'PUT',
        }
      },
    }),
  }),
})

export const { useFetchAppsQuery, usePerformActionBasedOnAppStatusMutation } =
  apiSlice
