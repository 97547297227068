import { useTranslation } from 'react-i18next'
import {
  Box,
  StaticTable,
  type TableType,
  Typography,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { useInView } from 'react-intersection-observer'
import { useEffect } from 'react'
import 'components/styles/document.scss'
import { SERVICE_LABEL_IDS, OBSERVER_OPTIONS } from '../../types'
import {
  OFFERTYPES,
  type ServiceStatusDataState,
} from 'features/Custom/serviceOnboard/types'
import { InputLabel } from '@mui/material'
import { DocumentActions } from 'components/pages/AppDetail/components/AppDetailDocuments'

export default function ServiceCaseStudies({
  item,
  handleSetSelectedTab,
}: {
  item: ServiceStatusDataState
  handleSetSelectedTab: (tab: string) => void
}) {
  const { t } = useTranslation()
  const { inView, ref } = useInView(OBSERVER_OPTIONS)

  const tableData: TableType = {
    head: [
      `${t('content.apprelease.appMarketCard.title')}`,
      `${t('content.apprelease.appMarketCard.shortDescription')}`,
      `${t('content.apprelease.appMarketCard.file')}`,
    ],
    body: item.documents
      .filter(
        (document) => document.documentTypeId === 'APP_TECHNICAL_INFORMATION'
      )
      ?.map((doc) => [
        doc.documentName,
        `${
          item?.relatedDocuments
            ?.filter((eachDoc: any) => eachDoc.id === doc.documentId)[0]
            ?.localizedSummary?.values?.filter(
              (eachLan: any) => eachLan?.langCode === 'en'
            )[0]?.value ?? 'No description found'
        }`,
        () => (
          <DocumentActions
            id={item.id}
            hideIcon={false}
            documentId={doc.documentId}
            documentName={doc.documentName}
            offerTypeId={OFFERTYPES.SERVICE}
          />
        ),
      ]),
  }

  useEffect(() => {
    if (inView) {
      handleSetSelectedTab(SERVICE_LABEL_IDS.SERVICEDETAIL_DOCUMENTS)
    }
  }, [inView])

  return item.documents.filter(
    (document) => document.documentTypeId === 'APP_TECHNICAL_INFORMATION'
  )?.length > 0 ? (
    <div
      ref={ref}
      className="appdetail-howtouse"
      id={SERVICE_LABEL_IDS.SERVICEDETAIL_DOCUMENTS}
    >
      <div className="howtouse-content">
        <Typography
          fontWeight={600}
          fontSize="1.25rem"
          color="#000"
          marginBottom="1rem"
        >
          {t('content.servicedetails.howtouse.heading')}
        </Typography>
        <Typography
          marginBottom="1.5rem"
          fontSize="1rem"
          fontWeight={400}
          color="#000"
        >
          {t('content.servicedetails.howtouse.message')}
        </Typography>
      </div>
      <StaticTable
        // notFoundMessage={t('global.objects.noDocumentUploaded')}
        horizontal={false}
        data={tableData || []}
      />
    </div>
  ) : (
    <div
      ref={ref}
      className="appdetail-howtouse"
      id={SERVICE_LABEL_IDS.SERVICEDETAIL_DOCUMENTS}
    >
      <div className="howtouse-content">
        <Typography
          fontWeight={600}
          fontSize="1.25rem"
          color="#000"
          marginBottom="1rem"
        >
          {t('content.servicedetails.howtouse.heading')}
        </Typography>
      </div>
      <Box textAlign="left">
        <InputLabel>
          <strong>Sorry, no case studies found</strong>
        </InputLabel>
      </Box>
    </div>
  )
}
