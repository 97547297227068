import React from 'react'
import {
  Box,
  Button,
  Dialog,
  Grid,
  Typography,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'

import bg_image from '../../../../../assets/images/coming_soon_bg.svg'

type ComingSoonProps = {
  openModal: boolean
  handleClose: () => void
}

function ComingSoon({ openModal, handleClose }: ComingSoonProps) {
  return (
    <Dialog open={openModal} additionalModalRootStyles={{ width: '50rem' }}>
      <Grid container>
        <Grid item xs={12}>
          <Box
            sx={{
              padding: '4rem 3rem',
              position: 'relative',
              textAlign: 'center',
              backgroundImage: 'linear-gradient(to right, #FDB913, #E34C92)',
              '::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundSize: '100% auto',
                backgroundPosition: 'center',
                backgroundImage: `url(${bg_image})`,
                backgroundRepeat: 'no-repeat',
              },
            }}
          >
            <Typography
              fontSize="28px"
              color="#fff"
              fontWeight="700"
              marginBottom=".5rem"
            >
              Coming Soon
            </Typography>
            <Typography fontSize="18px" color="#fff" fontWeight="600">
              The Service Marketplace will be launched end of Q3/2023
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          padding="3rem 0rem"
        >
          <Button onClick={handleClose} sx={{ padding: '.5rem 2rem' }}>
            Close
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default ComingSoon
