import * as React from 'react'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import { useDispatch } from 'react-redux'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const dispatch = useDispatch()
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

export default function ImageDialog(props: any) {
  const dispatch = useDispatch()
  return (
    <BootstrapDialog
      onClose={props.handleOpenDialog}
      aria-labelledby="customized-dialog-title"
      open={props.open}
    >
      <BootstrapDialogTitle
        id="customized-dialog-title"
        onClose={props.handleOpenDialog}
      >
        App Cover Image
      </BootstrapDialogTitle>
      <DialogContent dividers>{props.children}</DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={(event) => {
            props.handleOpenDialog()
          }}
        >
          Cancel
        </Button>
        <Button
          autoFocus
          variant="contained"
          onClick={(event) => {
            props.saveChanges(event)
            props.handleOpenDialog()
          }}
        >
          Save
        </Button>
      </DialogActions>
    </BootstrapDialog>
  )
}
