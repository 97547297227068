import CustomApplicationCard from 'components/shared/basic/ApplicationCard'

interface AppPreviewProps {
  image?: {
    image: any
    alt: string | undefined
  }
  title?: string
  subtitle?: string
  description?: string
  useCases?: string[]
}

const AppReviewCard = ({
  image,
  description,
  subtitle,
  title,
}: AppPreviewProps) => {
  return (
    <div style={{ position: 'sticky', top: '100px' }}>
      <CustomApplicationCard
        id={''}
        label={subtitle ?? ''}
        title={title ?? ''}
        subTitle={description}
        buttonText={''}
        imagePath={image?.image ?? '././Frame.svg'}
        isFavorite={false}
        defaultView={false}
        isPreview={true}
        onBtnClick={() => {}}
        onFavoriteClick={() => {}}
      />
    </div>
  )
}

export default AppReviewCard
