import { type LanguageObject } from 'utils/utilities'

export interface Tab {
  id: string
  title: string
  contentPlaceholder: string
  isValid?: boolean
}

export interface TechnicalIntegrationFormProps {
  roleId: string
  role: string
  roleDescription: LanguageObject[]
  languages?: string[]
}

export interface UserData {
  rolename: string
  description: JSX.Element
  timestamp: Date | number | string
  actions: string[]
  extendedRowContent: React.ReactNode | JSX.Element
  userRoleDescription: {
    languageShortName: string
    description: string
  }[]
}
export enum SEARCH_EXPR {
  EXPR = 'rolename',
}

export enum ACTIONS {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export enum N_ID {
  ID = '00000000-0000-0000-0000-000000000000',
}
