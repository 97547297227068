import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  type PaginFetchArgs,
  type PaginResult,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { apiBaseQuery } from 'utils/rtkUtil'
import { TableFilters, type ServiceMarketplaceResponseType } from './types'
import { OFFERTYPES } from 'features/Custom/serviceOnboard/types'

export const apiSlice = createApi({
  reducerPath: 'rtk/apps/serviceMarketplaceAdmin',
  baseQuery: fetchBaseQuery(apiBaseQuery()),
  endpoints: (builder) => ({
    fetchService: builder.query<
      PaginResult<ServiceMarketplaceResponseType>,
      PaginFetchArgs
    >({
      query: (fetchArgs) => {
        const isFetchedArgs = fetchArgs.args && fetchArgs.args.expr
        if (
          isFetchedArgs &&
          fetchArgs.args.statusFilter &&
          fetchArgs.args.statusFilter !== TableFilters.ALL
        ) {
          return `/api/Apps/MarketAdmin/AllApps?page=${
            fetchArgs.page
          }&size=10&offerTitleFilter=${
            fetchArgs.args!.expr
          }&offerStatusIdFilter=${fetchArgs.args!.statusFilter}&offerTypeId=${
            OFFERTYPES.SERVICE
          }`
        } else if (
          !isFetchedArgs &&
          fetchArgs.args.statusFilter &&
          fetchArgs.args.statusFilter !== TableFilters.ALL
        ) {
          return `/api/Apps/MarketAdmin/AllApps?page=${
            fetchArgs.page
          }&size=10&offerStatusIdFilter=${
            fetchArgs.args!.statusFilter
          }&offerTypeId=${OFFERTYPES.SERVICE}`
        } else if (
          isFetchedArgs ||
          (fetchArgs.args.statusFilter &&
            fetchArgs.args.statusFilter === TableFilters.ALL)
        ) {
          return `/api/Apps/MarketAdmin/AllApps?size=10&page=${
            fetchArgs.page
          }&offerTitleFilter=${fetchArgs.args!.expr}&offerTypeId=${
            OFFERTYPES.SERVICE
          }`
        } else {
          return `/api/Apps/MarketAdmin/AllApps?page=${fetchArgs.page}&size=10&offerTypeId=${OFFERTYPES.SERVICE}`
        }
      },
    }),
    performActionBasedOnAppStatus: builder.mutation<void, any>({
      query: (data: { serviceId: string; triggeredAction: string }) => {
        const { serviceId, triggeredAction } = data
        return {
          url: `/api/Apps/MarketAdmin/PerformAction/${serviceId}/Action/${triggeredAction}?offerTypeId=${OFFERTYPES.SERVICE}`,
          method: 'PUT',
        }
      },
    }),
  }),
})

export const {
  useFetchServiceQuery,
  usePerformActionBasedOnAppStatusMutation,
} = apiSlice
