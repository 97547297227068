//import StageSection from '../UserManagement/StageSection'
import AppReleaseProcessForm from '../AppPublish/AppPublishComponent/components'
import { useDispatch } from 'react-redux'
import { setAppId } from 'features/Custom/appOnboard/actions'
import { modifyCurrentStep } from 'features/Custom/appOnboard/slice'
function AppOnboarding() {
  const queryParams = new URLSearchParams(window.location.search)
  const appId = queryParams.get('appId')
  const step = queryParams.get('step')
  const dispatch = useDispatch()
  if (appId?.length) {
    dispatch(setAppId(appId))
  }
  // if (step !== null) {
  //   dispatch(modifyCurrentStep(parseInt(step)))
  // }
  return (
    <div>
      <main className="app-store">
        <AppReleaseProcessForm></AppReleaseProcessForm>
      </main>
    </div>
  )
}

export default AppOnboarding
