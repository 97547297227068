import { useTranslation } from 'react-i18next'

const WelcomeBox = () => {
  const { t } = useTranslation()

  return (
    <div className="swiper-section">
      <div className="swiper-content">
        <h5>
          {t('content.appstore.appOverviewSection.automativeDigitalSolutions')}
        </h5>
        <h2
          dangerouslySetInnerHTML={{
            __html: t(
              'content.appstore.appOverviewSection.automativeDigitalGateway'
            ),
          }}
        ></h2>
      </div>

      <div className="swiper-media">
        <iframe
          width="400"
          height="230"
          src="https://www.youtube.com/embed/XQIV5iE0mNc?si=0KlqA32gebKC9J3z"
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
          data-type="text/html"
          style={{
            borderRadius: '15px',
            border: '0px',
          }}
        ></iframe>
      </div>
    </div>
  )
}

export { WelcomeBox }
