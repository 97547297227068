import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  Carousel,
  Grid,
  Typography,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import category_bg from '../../../../../assets/images/category_bg.svg'
import {
  APP_CATEGORY_COLORS,
  PRICES_ENUM,
  UPDATED_PRICING_MODEL,
} from 'types/Constants'
import { DrawerStatusContext } from 'context/DrawerMenuStatus'
import { type ApplicationListType } from 'components/pages/Home/components/MyApplications/MyApplication.types'
import CommonService from 'services/CommonService'
import CustomApplicationCard from 'components/shared/basic/ApplicationCard'

interface CategoryViewProps {
  category: string
  items: any[]
  ind: number
}

interface DotStyleType {
  left?: string
}

export default function CategoryView({
  category,
  items,
  ind,
}: CategoryViewProps) {
  const { t } = useTranslation()
  const { drawerStatus } = useContext(DrawerStatusContext)
  const [drawerOpen, setDrawerOpen] = useState(+drawerStatus || 0)
  const [dotStyle, setDotStyle] = useState<DotStyleType>()
  const [matches, setMatches] = useState({
    macbook: window.matchMedia('(min-width: 1366px) and (max-width: 1600px)')
      .matches,
    desktop: window.matchMedia('(min-width: 1600px and (max-width: 1910px))')
      .matches,
  })

  useEffect(() => {
    window.addEventListener('resize', resizeEvent)
  }, [])

  useEffect(() => {
    setDrawerOpen(+drawerStatus)
  }, [drawerStatus])

  const resizeEvent = (event: any) => {
    setMatches({
      macbook: window.matchMedia('(min-width: 1366px) and (max-width: 1600px)')
        .matches,
      desktop: window.matchMedia('(min-width: 1600px and (max-width: 1910px))')
        .matches,
    })
  }

  useEffect(() => {
    if (drawerOpen && matches.macbook) {
      setDotStyle({ left: '-150px' })
    } else if (matches.macbook) {
      setDotStyle({ left: '-180px' })
    } else if (drawerOpen && !matches.macbook) {
      setDotStyle({ left: '-200px' })
    } else {
      setDotStyle({ left: '-150px' })
    }
  }, [drawerOpen, matches])

  return items.some((i) => i.isSponsored === false) ? (
    <Grid container marginY="5rem" alignItems="center" gap="1rem">
      <Grid item xs={2} md={2}>
        <Box
          className="card card__corloful"
          height="30rem"
          width="100%"
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
          alignItems="center"
          borderRadius="15px"
          position="relative"
          sx={{
            padding: {
              sm: '1rem',
              md: '1rem',
              lg: '2rem 5px',
              xl: '2rem',
            },
            backgroundColor: APP_CATEGORY_COLORS[ind]
              ? APP_CATEGORY_COLORS[ind]
              : '#A6CE39',

            '::before': {
              content: '""',
              backgroundImage: `url(${category_bg})`,
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              position: 'absolute',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            },
          }}
        >
          <Typography
            fontWeight="600"
            color="#fff"
            px={2}
            lineHeight="1"
            textAlign="center"
            sx={{
              fontSize: {
                sm: '16px',
                md: '16px',
                lg: '24px',
                xl: '30px',
              },
            }}
          >
            {category || 'Please provide a category'}
          </Typography>
          <Button
            variant="text"
            sx={{
              padding: '0px',
              fontWeight: '600',
              color: '#fff',

              ':hover': {
                color: '#fff',
              },
              fontSize: {
                sm: '16px',
                md: '16px',
                lg: '18px',
                xl: '20px',
              },
            }}
          >
            {t('content.appstore.appOverviewSection.exploreMore')}
          </Button>
        </Box>
      </Grid>
      <Grid
        item
        xs={9.3}
        md={9.3}
        width="500px"
        className="application-card-container"
      >
        <Carousel
          infinite={false}
          variableWidth={true}
          slidesToScroll={1}
          slidesToShow={2}
          slideDotStyed={dotStyle}
          responsive={[
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 1480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 1920,
              settings: {
                slidesToShow: drawerOpen ? 1 : 2,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          {items?.map((item: ApplicationListType, index: number) => (
            <Box paddingY={'20px'} key={item.id}>
              <CustomApplicationCard
                width={'320px'}
                key={item.id}
                id={item?.id}
                label={item?.provider}
                title={item?.name}
                subTitle={item?.shortDescription}
                buttonText={`${t('global.field.details')}`}
                imagePath={item?.image?.src}
                isFavorite={item?.addButtonClicked}
                preferenceText={item?.preferenceText}
                defaultView={false}
                defaultViewLabel={''}
                isComingSoon={item?.isComingSoon}
                comingSoonLabel={t('hints.coming_soon')}
                onFavoriteClick={item?.onSecondaryButtonClick}
                onBtnClick={item?.onButtonClick}
                price={{
                  type: item?.model
                    ? item?.model
                    : UPDATED_PRICING_MODEL.OTHERS,
                  amount:
                    item.amount.length && item.amount !== 'NaN'
                      ? CommonService.convertPriceToEuroFormat(
                          item.amount ?? item.price
                        )
                      : 'Contact sales',
                }}
                certificationStatus={item.certificateStatus}
              />
            </Box>
          ))}
        </Carousel>
      </Grid>
    </Grid>
  ) : null
}
