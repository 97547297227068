import { LoadMoreButton } from 'components/shared/basic/LoadMoreButton'
import {
  useGetNotificationMetaQuery,
  useGetNotificationsQuery,
} from 'features/notification/apiSlice'
import { initialNotificationState } from 'features/notification/slice'
import {
  NOTIFICATION_TOPIC,
  PAGE,
  SORT_OPTION,
  PAGE_SIZE,
  type CXNotificationPagination,
  type CXNotificationContent,
} from 'features/notification/types'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { groupBy } from 'lodash'
import dayjs from 'dayjs'
import { NotificationGroup } from '../..'
import { Box } from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { ShowEmptyMessage } from '../../NotificationEmpty'

export const General = () => {
  const { t } = useTranslation('notification')
  const sectionElement: any = useRef()
  const { data: pages } = useGetNotificationMetaQuery(null)
  const [sortOption, setSortOption] = useState<string>(SORT_OPTION)
  const [searchExpr, setSearchExpr] = useState<string>('')
  const [showModal, setShowModal] = useState<boolean>(false)
  const [filterOption, setFilterOption] = useState<string>(
    NOTIFICATION_TOPIC.INFO
  )
  const [loaded, setLoaded] = useState<boolean>(false)
  const [page, setPage] = useState<number>(PAGE)
  const initialNotification = useSelector(initialNotificationState)
  const [notificationState, setNotificationState] =
    useState(initialNotification)
  const { data, isFetching, refetch } =
    useGetNotificationsQuery(notificationState)
  const [notificationItems, setNotificationItems] = useState<
    CXNotificationContent[]
  >([])
  const [paginationData, setPaginationData] =
    useState<CXNotificationPagination>()
  const nextPage = () => { setPage(page + 1) }

  useEffect(() => {
    setNotificationItems([])
    if (page === 0) {
      refetch()
    } else {
      setPage(initialNotification.page)
    }
    // eslint-disable-next-line
  }, [initialNotification, refetch])

  useEffect(() => {
    if (data) {
      setPaginationData(data?.meta)
      setNotificationItems(
        data?.meta.page === 0 ? data.content : (i) => i.concat(data?.content)
      )
    }
  }, [data])

  useEffect(() => {
    if (loaded) {
      setNotificationItems([])
      setLoaded(false)
    }
    setNotificationState({
      page,
      size: PAGE_SIZE,
      args: {
        notificationTopic: filterOption,
        sorting: sortOption,
      },
    })
  }, [filterOption, sortOption, page, loaded])

  const groups = groupBy(
    notificationItems.map((item) => ({
      ...item,
      contentParsed: item.content && JSON.parse(item.content),
    })),
    (item: CXNotificationContent) => dayjs(item.created).format('YYYY-MM-DD')
  )
  return (
    <div>
      {Object.entries(groups).length === 0 ? (
        <ShowEmptyMessage />
      ) : (
        Object.entries(groups).map((entry: any) => (
          <div key={entry[0]}>
            <NotificationGroup
              label={entry[0]}
              items={entry[1]}
              notificationTopic={NOTIFICATION_TOPIC.INFO}
            />
          </div>
        ))
      )}
      {isFetching && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          <span
            style={{
              padding: 10,
            }}
          >
            <span className="invite-loader" />
          </span>
        </Box>
      )}

      {!isFetching &&
        paginationData &&
        paginationData.contentSize >= PAGE_SIZE && (
          <LoadMoreButton onClick={nextPage} />
        )}
    </div>
  )
}
