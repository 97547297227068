import { useTranslation } from 'react-i18next'
import {
  Button,
  Typography,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { COFINITY_USECASE_TRACEABILITY_URL } from 'types/Constants'
import './UsecaseParticipation.scss'
import { SubscriptionStatus } from 'features/apps/apiSlice'
import { Link } from 'react-router-dom'
import { CardContent, Checkbox, styled } from '@mui/material'
import Collapse from '@mui/material/Collapse'
import React, { useState } from 'react'
import { error, success } from 'services/NotifyService'
import {
  type UsecaseResponse,
  type VerifiedCredentialsData,
  useAddUsecaseMutation,
} from 'features/usecase/usecaseApiSlice'
import { getAssetBase } from 'services/EnvironmentService'
import { isBefore } from 'date-fns'

interface RenderStatusProps {
  item: UsecaseResponse
  credential: VerifiedCredentialsData
  isExpanded: boolean
}

const RenderStatus = ({ item, credential, isExpanded }: RenderStatusProps) => {
  const { t } = useTranslation()
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
  const status = credential.ssiDetailData?.participationStatus
  const [addUsecase] = useAddUsecaseMutation()

  const handleDocumentSwitch = () => {
    let pdfUrl
    switch (item.credentialType) {
      case 'TRACEABILITY_FRAMEWORK':
        pdfUrl = `${getAssetBase()}/docs/231016_Catena-X_Use_Case_Framework_Traceability.pdf`
        break
      case 'PCF_FRAMEWORK':
        pdfUrl = `${getAssetBase()}/docs/231016_Catena-X_Use_Case_Framework_PCF.pdf`
        break
      case 'BEHAVIOR_TWIN_FRAMEWORK':
        pdfUrl = `${getAssetBase()}/docs/231016_Catena-X_Use_Case_Framework_Quality.pdf`
        break
        case 'FRAMEWORK_AGREEMENT_QUALITY':
        pdfUrl = `${getAssetBase()}/docs/231016_Catena-X_Use_Case_Framework_Quality.pdf`
        break
      default:
        pdfUrl = ''
    }
    if (pdfUrl.length) {
      handleUpload(
        credential.externalDetailData.id,
        item.credentialType,
        pdfUrl
      )
    }
  }

  async function getFileFromUrl(
    url: string,
    name: string,
    defaultType = 'application/pdf'
  ) {
    const response = await fetch(url)
    if (!response.ok)
      throw new Error(
        `Failed to fetch file from ${url}: ${response.statusText}`
      )

    const data = await response.blob()
    console.log(data)
    return new File([data], name, { type: defaultType })
  }

  const handleUpload = async (
    verifiedCredentialTypeId: string,
    credentialType: string,
    templateUrl: string
  ) => {
    try {
      // Fetch the file from the URL
      const file = await getFileFromUrl(templateUrl, 'document')

      const data = {
        verifiedCredentialTypeId,
        credentialType,
        document: file,
      }
      await addUsecase(data as any).unwrap()
      success(t('content.usecaseParticipation.editUsecase.success'))
    } catch (err) {
      console.error(err)
      error(t('content.usecaseParticipation.editUsecase.error'), '', '')
    }
  }
  // Calculate the current date
  const currentDate = new Date()

  // Check if the expiry date is before the current date
  const isExpired = isBefore(
    new Date(credential.externalDetailData.expiry),
    currentDate
  )

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckboxChecked(event.target.checked)
  }

  if (isExpired) {
    return (
      <>
        <div className="status-label-container">
          <Typography variant="caption3" className="status-label red">
            {t('content.usecaseParticipation.editUsecase.expired')}
          </Typography>
        </div>
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <CardContent>
            <CardContent>
              <div className="card-footer">
                <Checkbox disabled />
                <span className="framework-section">
                  <span className="acknowledgement">
                    {[
                      t(
                        'content.usecaseParticipation.editUsecase.acknowlegment.part1'
                      ),
                    ]}{' '}
                    &nbsp;
                  </span>
                  <Link
                    className="framework-link"
                    to={credential.externalDetailData.template}
                    target="_blank"
                  >
                    {[
                      t(
                        'content.usecaseParticipation.editUsecase.acknowlegment.part2'
                      ),
                    ]}{' '}
                    {item.useCase}.
                  </Link>
                </span>
              </div>
            </CardContent>
          </CardContent>
        </Collapse>
      </>
    )
  } else if (
    status === SubscriptionStatus.PENDING ||
    status === SubscriptionStatus.ACTIVE
  ) {
    const statusClass = status === SubscriptionStatus.PENDING ? 'grey' : 'green'
    return (
      <>
        <div className="status-label-container">
          <Typography
            variant="caption3"
            className={`status-label ${statusClass}`}
          >
            {status}
          </Typography>
        </div>
        <Collapse in={isExpanded} timeout="auto" unmountOnExit>
          <CardContent>
            <div className="card-footer">
              <Checkbox disabled />
              <span className="framework-section">
                <span className="acknowledgement">
                  {[
                    t(
                      'content.usecaseParticipation.editUsecase.acknowlegment.part1'
                    ),
                  ]}{' '}
                  &nbsp;
                </span>
                <Link
                  className="framework-link"
                  to={credential.externalDetailData.template}
                  target="_blank"
                >
                  {[
                    t(
                      'content.usecaseParticipation.editUsecase.acknowlegment.part2'
                    ),
                  ]}{' '}
                  {item.useCase}.
                </Link>
              </span>
            </div>
          </CardContent>
        </Collapse>
      </>
    )
  } else {
    return (
      <Collapse
        in={isExpanded}
        timeout="auto"
        unmountOnExit
        className="submit-section"
      >
        <CardContent>
          <div className="card-footer">
            <Checkbox
              className="checkbox-card"
              checked={isCheckboxChecked}
              onChange={handleCheckboxChange}
            />
            <span className="framework-section">
              <span className="acknowledgement">
                {[
                  t(
                    'content.usecaseParticipation.editUsecase.acknowlegment.part1'
                  ),
                ]}{' '}
                &nbsp;
              </span>
              <Link
                className="framework-link"
                to={credential.externalDetailData.template}
                target="_blank"
              >
                {[
                  t(
                    'content.usecaseParticipation.editUsecase.acknowlegment.part2'
                  ),
                ]}{' '}
                {item.useCase}.
              </Link>
            </span>

            <Button
              className="submit-button"
              variant="contained"
              disabled={!isCheckboxChecked}
              onClick={handleDocumentSwitch}
            >
              Submit
            </Button>
          </div>
        </CardContent>
      </Collapse>
    )
  }
}

export default RenderStatus
