import { Fade, Step, StepLabel, StepContent, Stepper } from '@mui/material'
import {
  Box,
  Button,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import React from 'react'

type Step = {
  label: string
  name: string
  children: React.ReactNode
}

interface VerticalStepperProps {
  activeStep: number
  steps: Step[]
  handleNext: () => void
  handleBack: () => void
}

const stepperStyle = {
  '.Mui-active': {
    color: '#d31184 !important',
  },
  '.Mui-completed': {
    color: 'green !important',
  },
  '.MuiStepIcon-root .Mui-completed': {
    color: 'green !important',
  },
  '.MuiStepIcon-text': {
    fontWeight: '600',
  },
}

const VerticalStepper = (props: VerticalStepperProps) => {
  return (
    <Fade in timeout={500}>
      <Box sx={{ width: 'auto' }}>
        <Stepper
          activeStep={props.activeStep}
          orientation="vertical"
          sx={stepperStyle}
        >
          {props.steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                sx={{
                  '.MuiStepLabel-label': {
                    fontWeight: '500',
                    fontSize: '1.1rem',
                  },
                }}
              >
                {step.label}
              </StepLabel>
              <StepContent>
                <Box sx={{ mb: 2 }}>{step.children}</Box>
                <Box sx={{ mb: 2 }}>
                  <div>
                    {index !== props.steps.length - 1 ? (
                      <Button
                        variant="contained"
                        onClick={props.handleNext}
                        sx={{ mt: 2, mr: 1 }}
                      >
                        {index === props.steps.length - 1
                          ? 'Finish'
                          : 'Continue'}
                      </Button>
                    ) : null}
                    {index < props.steps.length - 1 ? (
                      <Button
                        variant="outlined"
                        sx={{ mt: 2, mr: 1 }}
                        disabled={index === 0}
                        onClick={props.handleBack}
                      >
                        Back
                      </Button>
                    ) : null}
                  </div>
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Fade>
  )
}

export default VerticalStepper
