import React from 'react'
import {
  Tab,
  Tabs,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { useTranslation } from 'react-i18next'
import { SERVICEDETAIL_TABS } from '../types'

function ServiceDetailsTab({
  callback,
  selectedTab,
}: {
  callback: Function
  selectedTab: string
}) {
  const { t } = useTranslation()

  return (
    <div className="appdetail-tabs-container">
      <Tabs
        value={selectedTab}
        onChange={(e, newValue) => {
          callback(newValue)
        }}
        sx={{
          position: 'relative',
          ':after': {
            position: 'absolute',
            content: '\'\'',
            top: 0,
            bottom: '1px',
            left: 0,
            right: 0,
            borderBottom: '1px solid #EDEDEE',
            zIndex: '-1',
          },

          '& .MuiTabs-flexContainer': {
            justifyContent: 'flex-start',
            gap: '2rem',
          },
        }}
      >
        {SERVICEDETAIL_TABS.map((tab) => (
          <Tab
            sx={{ color: '#4D4D4F', '&.Mui-selected': { color: '#D31184' } }}
            label={t(`content.servicedetails.tabs.${tab.id}`)}
            value={tab.id}
            key={tab.id}
          />
        ))}
      </Tabs>
    </div>
  )
}

export default ServiceDetailsTab
