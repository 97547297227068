import React from 'react'
import { CrossIconGraphic } from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { Badge } from '@mui/material'

type MessageType = {
  ideal?: boolean
}

/**
 * * Use this coponnent to show when notifications are empty / Component is in progress
 * @return {JSX.Element}
 */

export const ShowEmptyMessage = ({
  ideal = false,
}: MessageType): JSX.Element => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
      }}
    >
      <Badge
        variant="dot"
        sx={{
          '.MuiBadge-dot': {
            background: '#F58220',
            right: '50%',
            top: -11.5,
            width: 21,
            height: 21,
            borderRadius: 21,
          },
        }}
      >
        <CrossIconGraphic height={100} width={100} />
      </Badge>
      <h2
        style={{
          userSelect: 'none',
          marginBottom: 0,
          color: '#000000',
        }}
      >
        It’s quiet here.
      </h2>
      {!ideal && (
        <label
          style={{
            width: 355,
            textAlign: 'center',
            padding: 5,
            lineHeight: 1.15,
            fontWeight: 500,
            userSelect: 'none',
          }}
        >
          You don’t have any new notifications. but once you do they will be
          here.
        </label>
      )}
    </div>
  )
}
