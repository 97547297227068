import { useEffect } from 'react'
import {
  Box,
  Grid,
  Typography,
  type ImageType,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { useInView } from 'react-intersection-observer'
import { useTranslation } from 'react-i18next'
import { SERVICE_LABEL_IDS, OBSERVER_OPTIONS } from '../../types'
import { type ServiceStatusDataState } from 'features/Custom/serviceOnboard/types'
import ImageGallery from 'components/pages/AppDetail/components/ImageGallery'
import { getEmbedUrl } from 'utils/utilities'
const BenefitText = ({
  title,
  description,
}: {
  title: string
  description: string
}) => {
  return (
    <Box>
      <Typography color="#2484C6" fontWeight={500} fontSize="1.125rem">
        {title}
      </Typography>
      <Typography
        marginBottom="1.5rem"
        fontSize="1rem"
        color="#000"
        fontWeight={400}
      >
        {description}
      </Typography>
    </Box>
  )
}

function ServiceDetailsBenefits({
  images,
  handleSetSelectedTab,
  serviceId,
  item,
}: {
  images: ImageType[]
  handleSetSelectedTab: (tab: string) => void
  serviceId?: string
  item: ServiceStatusDataState
}) {
  const { inView, ref } = useInView(OBSERVER_OPTIONS)
  const { t } = useTranslation()

  useEffect(() => {
    if (inView) {
      handleSetSelectedTab(SERVICE_LABEL_IDS.SERVICEDETAIL_FEATURES)
    }
  }, [inView])

  return (
    <>
      {item && (
        <div ref={ref} id={SERVICE_LABEL_IDS.SERVICEDETAIL_FEATURES}>
          <Grid
            container
            marginBottom="3rem"
            columnSpacing={{ lg: 17, md: 13 }}
          >
            <Grid item xs={12} md={12}>
              <Typography
                marginBottom="1rem"
                fontSize="1.25rem"
                fontWeight={600}
                color="#000"
              >
                {t('content.servicedetails.features.title')}
              </Typography>
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography
                marginBottom=".5rem"
                fontSize="1.125rem"
                fontWeight={500}
                color="#000"
              >
                {t('content.servicedetails.features.summary')}
              </Typography>
            </Grid>
            <Grid item xs={12} md={12}>
              <Typography
                marginBottom="2.5rem"
                fontSize="1rem"
                fontWeight={400}
                color="#000"
              >
                {item?.feature[0]?.summary.length <= 0
                  ? item?.feature[0]?.localizedSummary.values.filter(
                      (each: { langCode: string; value: string }) =>
                        each.langCode === 'en'
                    )[0].value
                  : item?.feature[0]?.summary}
              </Typography>
            </Grid>
            <Grid item sm={12} md={6}>
              <Typography
                marginBottom="1rem"
                fontSize="1.125rem"
                color="#4D4D4F"
                fontWeight={500}
              >
                {t('content.servicedetails.features.keyFeature')}
              </Typography>

              {item.feature[0]?.keyFeatures.map((d: any) => (
                <BenefitText
                  key={d}
                  title={d?.title}
                  description={d.shortDescription}
                />
              ))}
            </Grid>
            <Grid item sm={12} md={6}>
              {item.feature[0]?.videoLink && (
                <Box
                  height="30vh"
                  width="100%"
                  borderRadius="10px"
                  sx={{ backgroundColor: '#4D4D4D' }}
                >
                  <Box
                    height="100%"
                    width="100%"
                    borderRadius="10px"
                    component="iframe"
                    title="Title"
                    src={getEmbedUrl(
                      `https://${
                        item.feature[0]?.videoLink &&
                        item.feature[0]?.videoLink?.split('https://')?.length >
                          1
                          ? item.feature[0]?.videoLink?.split('https://')[1]
                          : item.feature[0]?.videoLink ?? '#'
                      }`
                    )}
                    allowFullScreen
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  />
                </Box>
              )}
            </Grid>
          </Grid>
          {images && <ImageGallery images={images} />}
        </div>
      )}
    </>
  )
}

export default ServiceDetailsBenefits
