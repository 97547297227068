import { Button } from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

function InviteBusiness() {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className="align-center swiper-section">
      <div className="swiper-content">
        <h5>
          {t('content.appstore.appOverviewSection.automativeDigitalSolutions')}
        </h5>
        <h2
          dangerouslySetInnerHTML={{
            __html: t(
              'content.appstore.appOverviewSection.busineespartnerbutton'
            ),
          }}
        ></h2>
      </div>

      {/* <div className="swiper-media marginRight">
        <Button onClick={() => navigate('/invite')}>
          {t('content.appstore.appOverviewSection.label')}
        </Button>
      </div> */}
    </div>
  )
}

export default InviteBusiness
