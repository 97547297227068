import * as React from 'react'
import Box from '@mui/material/Box'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import InputLabel from '@mui/material/InputLabel'
import { useSelector } from 'react-redux'
import { TabPanel, a11yProps } from './TabPanel'
import { appStatusDataSelector } from 'features/Custom/appOnboard/slice'
import { Details } from './PanelComponents/Details'
import { DescriptionAndFeatures } from './PanelComponents/DescriptionAndFeatures'
import { DocumentationAndPrivacy } from './PanelComponents/DocumentationAndPrivacy'
import Pricing from './PanelComponents/Pricing'
import Concent from './PanelComponents/Certification'
import { OFFERTYPES } from 'features/Custom/serviceOnboard/types'
import { useTranslation } from 'react-i18next'

/**
 * This is Mainly Meant For Loding App data From Redux Store
 *    and display it for marketplace Admin Dashboard
 * @export
 * @return {*}
 */
export default function MarketplaceAppTabs() {
  const { t } = useTranslation()
  const [value, setValue] = React.useState(0)

  const appStatusData = useSelector(appStatusDataSelector)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  // TODO: Map AppStatusData into These 4 Tabs
  return (
    <Box sx={{ width: '100%' }}>
      <Box
        sx={{
          bgcolor: 'background.paper',
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab
            label={
              <InputLabel style={styles.tabLabels}>
                {t('content.appPublish.stepper.overviewCard')}
              </InputLabel>
            }
          />
          <Tab
            label={
              <InputLabel style={styles.tabLabels}>
                {t('content.appPublish.stepper.descriptionAndFeatures')}
              </InputLabel>
            }
            wrapped
            {...a11yProps(0)}
          />
          <Tab
            label={
              <InputLabel style={styles.tabLabels}>
                {t('content.appPublish.stepper.documentationAndPrivacy')}
              </InputLabel>
            }
            wrapped
            {...a11yProps(1)}
          />
          <Tab
            label={
              <InputLabel style={styles.tabLabels}>
                {t('content.appPublish.stepper.pricing')}
              </InputLabel>
            }
            wrapped
            {...a11yProps(2)}
          />
          <Tab
            label={
              <InputLabel style={styles.tabLabels}>
                {t('content.appPublish.stepper.certificate')}
              </InputLabel>
            }
            wrapped
            {...a11yProps(3)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Details appStatusData={appStatusData} offerTypeId={OFFERTYPES.APP} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DescriptionAndFeatures
          appStatusData={appStatusData}
          offerTypeId={OFFERTYPES.APP}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <DocumentationAndPrivacy
          appStatusData={appStatusData}
          offerTypeId={OFFERTYPES.APP}
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <Pricing appStatusData={appStatusData} offerTypeId={OFFERTYPES.APP} />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <Concent appStatusData={appStatusData} offerTypeId={OFFERTYPES.APP} />
      </TabPanel>
    </Box>
  )
}

const styles = {
  tabLabels: {
    color: '#000000',
    fontSize: '20px',
    fontWeight: '500',
    padding: 2,
    cursor: 'pointer',
  },
}
