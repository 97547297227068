import { useEffect, type DependencyList } from 'react'

export function useDebounceEffect(
  fn: () => void,
  waitTime: number,
  deps?: DependencyList
) {
  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined

    const cleanup = () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }

    if (deps) {
      timeout = setTimeout(() => {
        fn()
      }, waitTime)
    }

    return cleanup
  }, deps)
}
