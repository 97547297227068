import React, { useRef } from 'react'
import InviteBusiness from 'components/pages/AppMarketplace/components/StageSection/BusinessPartner'
import { WelcomeBox } from 'components/pages/AppMarketplace/components/StageSection/Welcome'
import { PageBreadcrumb } from 'components/shared/frame/PageBreadcrumb/PageBreadcrumb'
import {
  Grid,
  HeroCarousel,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import HMI from 'components/pages/AppMarketplace/components/StageSection/HMI'
import { useTranslation } from 'react-i18next'

export const StageSection = () => {
  const { t } = useTranslation()
  const modalElement: any = useRef()
  return (
    <div className="stage-app-store">
      <div className="stage-app-store__header">
        <Grid container justifyContent="center">
          <Grid item xs={11} md={11} lg={10} xl={9}>
            <PageBreadcrumb
              breadcrumbs={[
                {
                  label: `${t('pages.servicemarketplace')}`,
                  link: '',
                  type: 'text',
                },
              ]}
            />
          </Grid>
        </Grid>
      </div>
      <div className="stage-app-store__body">
        <Grid container justifyContent="center">
          <Grid item xs={11} md={11} lg={10} xl={9} width={'400px'}>
            <HeroCarousel>
              <div ref={modalElement}>
                <HMI />
              </div>
              <div ref={modalElement}>
                <WelcomeBox />
              </div>
              <div ref={modalElement}>
                <InviteBusiness />
              </div>
            </HeroCarousel>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}
