import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  PageHeader,
  Typography,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { PageBreadcrumb } from 'components/shared/frame/PageBreadcrumb/PageBreadcrumb'
import { useFetchUsecaseQuery } from 'features/usecase/usecaseApiSlice'
import './UsecaseParticipation.scss'
import { Card, CardContent, styled } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import IconButton, { type IconButtonProps } from '@mui/material/IconButton'
import RenderStatus from './RenderStatus'
import lightbulb from '../../../assets/images/lightbulb.png'
import credentialIcon from '../../../assets/images/credential_icon.svg'
import expiryIcon from '../../../assets/images/expiry_icon.svg'
import useCaseIcon1 from '../../../assets/images/usecase-icon1.svg'
import useCaseIcon2 from '../../../assets/images/usecase-icon2.svg'
import useCaseIcon3 from '../../../assets/images/usecase-icon3.svg'
import useCaseIcon4 from '../../../assets/images/usecase-icon4.svg'
import useCaseIcon5 from '../../../assets/images/usecase-icon5.svg'

import pageHeaderImg from './../../../assets/logo/page-header-img.svg'

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props
  return <IconButton {...other} />
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

export default function UsecaseParticipation() {
  const { t } = useTranslation()
  const [expandedStates, setExpandedStates] = useState(
    new Map<number, boolean>()
  )
  const { data, refetch } = useFetchUsecaseQuery()

  const handleExpandClick = (index: number) => {
    setExpandedStates((prevStates) =>
      new Map(prevStates).set(index, !prevStates.get(index))
    )
  }

  useEffect(() => {
    refetch()
  }, [refetch])

  const useCaseIcons: { [key: string]: string } = {
    Traceability: useCaseIcon1,
    'Sustainability & CO2-Footprint': useCaseIcon2,
    'Quality Management': useCaseIcon3,
    'Circular Economy': useCaseIcon4,
    'Behavior Twin': useCaseIcon5,
  }

  const compareVersions = (v1: string, v2: string) => {
    const parts1 = v1.split('.').map(Number)
    const parts2 = v2.split('.').map(Number)

    for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
      const num1 = parts1[i] || 0
      const num2 = parts2[i] || 0

      if (num1 > num2) return 1
      if (num2 > num1) return -1
    }
    return 0
  }

  const isExpired = (expiryDate: any) => {
    const currentDate = new Date()
    return new Date(expiryDate) < currentDate
  }

  return (
    <main className="usecase-participation">
      <PageHeader
        title={t('content.usecaseParticipation.titleLabel')}
        subTitle={t('content.usecaseParticipation.subtitleLabel')}
        content={t('content.usecaseParticipation.description')}
        topPage={true}
        headerHeight={200}
        bgImage={pageHeaderImg}
      >
        <PageBreadcrumb
          backButtonVariant="text"
          breadcrumbs={[t('content.usecaseParticipation.description')]}
        />
      </PageHeader>
      <div className="usecase-main">
        <div className="container">
          <div className="instructions-container">
            <img
              src={lightbulb}
              alt="Lightbulb Icon"
              className="instructions-icon"
            />
            <div className="hint-text">
              {t('content.usecaseParticipation.heading')}
              <span className="hint-highlight">
                {t('content.usecaseParticipation.headingHighlight')}
              </span>
              {t('content.usecaseParticipation.heading2')}
            </div>
          </div>

          {data?.map((item, index) => {
            const hasVerifiedCredentials =
              item.verifiedCredentials && item.verifiedCredentials.length > 0
            const useCaseIcon =
              useCaseIcons[item.useCase as keyof typeof useCaseIcons] || ''
            const isExpanded = expandedStates.get(index) || false

            let latestCredential = null
            if (hasVerifiedCredentials) {
              const sortedCredentials = [...item.verifiedCredentials].sort(
                (a, b) =>
                  compareVersions(
                    a.externalDetailData.version,
                    b.externalDetailData.version
                  )
              )
              latestCredential = sortedCredentials[sortedCredentials.length - 1]
            }

            if (hasVerifiedCredentials && latestCredential) {
              return (
                <Card
                  className={`usecase-card ${
                    !hasVerifiedCredentials ? 'disabled' : ''
                  }`}
                  key={index}
                >
                  <CardContent className="card-content">
                    <ExpandMore
                      expand={isExpanded}
                      onClick={() => {
                        handleExpandClick(index)
                      }}
                      aria-expanded={isExpanded}
                      aria-label="show more"
                      className="float-right"
                    >
                      <ExpandMoreIcon />
                    </ExpandMore>
                    <div className="card-header">
                      <div
                        className="usecase-logo"
                        style={{ backgroundImage: `url(${useCaseIcon})` }}
                      ></div>
                      <p className="card-title">{item.useCase}</p>
                      {hasVerifiedCredentials && latestCredential ? (
                        <div>
                          <div className="credential-info">
                            <img src={credentialIcon} alt="Credential Icon" />
                            <span className="credential">
                              {
                                latestCredential.externalDetailData
                                  .verifiedCredentialExternalTypeId
                              }
                              &nbsp;
                              {'[' + t('content.usecaseParticipation.version')}
                              {latestCredential.externalDetailData.version +
                                ']'}
                            </span>
                          </div>
                          <div
                            className={`expiry-info ${
                              isExpired(
                                latestCredential?.externalDetailData.expiry
                              )
                                ? 'expired'
                                : ''
                            }`}
                          >
                            <img
                              src={expiryIcon}
                              alt="Expiry Date Icon"
                              className={`expiry-icon ${
                                isExpired(
                                  latestCredential?.externalDetailData.expiry
                                )
                                  ? 'red-icon'
                                  : ''
                              }`}
                            />
                            <span className="expiry-date">
                              {t('content.usecaseParticipation.expiry')}
                              {latestCredential.externalDetailData.expiry
                                ? latestCredential.externalDetailData.expiry.split(
                                    'T'
                                  )[0]
                                : ''}
                            </span>
                          </div>
                          {isExpanded && (
                            <RenderStatus
                              item={item}
                              credential={latestCredential}
                              isExpanded={isExpanded}
                            />
                          )}
                        </div>
                      ) : (
                        <div className="credential"></div>
                      )}
                    </div>
                  </CardContent>
                </Card>
              )
            } else {
              return null // Don't render the card if there are no verified credentials
            }
          })}
          <div className="notes">
            <Typography variant="label2">
              {t('content.usecaseParticipation.note')}
            </Typography>
            <Typography variant="caption2" className="note-detail">
              {t('content.usecaseParticipation.noteDetail')}
            </Typography>
          </div>
        </div>
      </div>
    </main>
  )
}
