import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CarouselBox,
  CrossIconGraphic,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { PricingCard } from 'components/pages/AppDetail/components/AppDetailPricing/PricingCard'
import HeaderBg from './../../../../../assets/images/priceHeaderBg.svg'
import FooterBg from './../../../../../assets/images/priceFooterBg.svg'
import { PRICES_ENUM } from 'types/Constants'
import { type ServiceStatusDataState } from 'features/Custom/serviceOnboard/types'

interface PlansInterface {
  type?: 'FREE' | 'PER_MONTH' | 'FIXED'
  amount: string
  currency: string
  model: string
  frequency: string
  customPricingType: string
  salesContactName: string
  salesContactEmail: string
  salesContactPhone?: string
  description: string
  features: string[]
  title?: string
  localizedSummary?: { values: { langCode: string; value: string }[] }
}

function ServicePricingDialog({
  item,
  open,
  onClose,
}: {
  item: ServiceStatusDataState
  open: boolean
  onClose: () => void
}) {
  const { t } = useTranslation()
  const [matches, setMatches] = useState({
    ipad: window.matchMedia('(min-width: 600px) and (max-width: 1250px)')
      .matches,
  })

  const labels = {
    pricingModel: `${t('content.servicedetails.pricing.pricingModel')}`,
    paymentFrequency: `${t('content.servicedetails.pricing.paymentFrequency')}`,
    planIncludes: `${t('content.servicedetails.pricing.planIncludes')}`,
    showMore: `${t('content.servicedetails.pricing.showMore')}`,
    showLess: `${t('content.servicedetails.pricing.showLess')}`,
    showAll: `${t('content.servicedetails.pricing.showAll')}`,
  }
  return (
    <Dialog
      open={open}
      additionalModalRootStyles={{
        boxShadow: '0 0 0 0 transparent',
        width: 'auto',
        padding: '0 20px',
      }}
      onBackdropClick={onClose}
    >
      <DialogContent sx={{ padding: '33px 8px !important' }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 10,
            top: 10,
            zIndex: 99,
          }}
        >
          <CrossIconGraphic height={20} width={20} />
        </IconButton>
        <Typography
          marginBottom="1rem"
          fontSize="1.50rem"
          fontWeight="600"
          textAlign="center"
        >
          {t('content.appdetail.pricing.yourPricingOptions')}
        </Typography>
        {item.pricingDetail && item.pricingDetail?.plans ? (
          <Grid container justifyContent={'center'} style={{ marginTop: 0 }}>
            <Grid
              xs={12}
              item
              width={
                item.pricingDetail?.plans.length <= 3
                  ? item.pricingDetail?.plans.length * 380 + 'px'
                  : '1140px'
              }
              style={{ display: 'flex' }}
            >
              {item.pricingDetail.plans.map((eachPlan: any, index: number) => (
                <PricingCard
                  id={''}
                  key={index}
                  title={eachPlan.title}
                  amount={eachPlan.amount}
                  currency={eachPlan.currency}
                  customPricingType={eachPlan.customPricingType}
                  salesContactName={eachPlan.salesContactName}
                  salesContactEmail={eachPlan.salesContactEmail}
                  salesContactPhone={eachPlan.salesContactPhone}
                  model={eachPlan.model}
                  description={
                    (eachPlan?.localizedSummary &&
                      eachPlan?.localizedSummary?.values?.filter(
                        (eachDescription: {
                          langCode: string
                          value: string
                        }) => eachDescription.langCode === 'en'
                      )[0]?.value) ??
                    'No Description Provided'
                  }
                  features={eachPlan.planFeatures[0].name
                    .toString()
                    .trim()
                    .split(',')}
                  labels={labels}
                  images={{ header: HeaderBg, footer: FooterBg }}
                  onBtnClick={() => {
                    console.log()
                  }}
                />
              ))}
            </Grid>
          </Grid>
        ) : null}
      </DialogContent>
    </Dialog>
  )
}

export default ServicePricingDialog
