import Dialog from '@mui/material/Dialog'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogHeader,
  Typography,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { useTranslation } from 'react-i18next'

export interface ErrorDialog {
  open: boolean
  onClose: (value: string) => void
}

export function ErrorDialog(props: ErrorDialog) {
  const { onClose, open } = props
  const { t } = useTranslation()
  const handleClose = () => {
    onClose('true')
  }
  return (
    <Dialog fullWidth={true} maxWidth="md" onClose={handleClose} open={open}>
      <DialogHeader
        {...{
          title: 'Support ticket could not be sent!',
          closeWithIcon: true,
          onCloseWithIcon: () => {
            handleClose()
          },
        }}
        wrapperStyle={{ padding: '50px 50px 35px' }}
      />
      <DialogContent>
        <Typography variant="body2" className="file-error-msg">
          An error has occured!
        </Typography>
        <Typography variant="body2" className="file-error-msg">
          Please try sending your request again.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}
