import { PageHeader } from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { useTranslation } from 'react-i18next'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import PageHeaderImg from '../../../../assets/logo/page-header-img.svg'

export default function ServiceOnboardSection() {
  return (
    <div className="stage-app-store-overview">
      <div className="breadNav">
        <div className="innerCrumb">
          <Breadcrumbs
            aria-label="breadcrumb"
            style={{
              fontSize: '16px',
              fontWeight: 500,
            }}
          >
            <Link underline="hover" color="inherit" href="/home">
              Home
            </Link>
            <Link color="inherit">Service Onboarding</Link>
          </Breadcrumbs>
        </div>
        <PageHeader
          title={'Service'}
          subTitle={'onboarding'}
          content={'Add a new Service to Cofinity-X'}
          topPage={true}
          headerHeight={200}
          bgImage={PageHeaderImg}
        />
      </div>
    </div>
  )
}
