import { Typography } from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { type CustomtableType } from './type'

const RendertTable = (props: { data: CustomtableType }) => {
  return (
    <table className="vertical-table">
      <thead>
        <tr>
          {props.data?.head?.map((col, c) => (
            <th key={c}>{col}</th>
          ))}
        </tr>
      </thead>
      <tbody
        style={{
          overflow: 'auto',
        }}
      >
        {props.data.body?.length > 0 ? (
          props.data?.body?.map((row, r) => (
            <tr key={r}>
              {row.map((CustomComp: any, c) => {
                return <td key={c}>{CustomComp}</td>
              })}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={props.data?.head?.length}>
              <Typography
                textAlign="center"
                color="red"
                fontSize="1rem"
                fontWeight={600}
              >
                {'not found'}
              </Typography>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  )
}

export default RendertTable
