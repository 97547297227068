import * as React from 'react'
import {
  DataTableContainerStyled,
  DataTableRowStyled,
  DataTableSectionStyled,
  DataTableStyled,
  DataTableTdStyled,
  DataTableThStyled,
  DataTableTitleStyled,
  DataTableMainStyled,
} from './UserDataTable.styled'
import EditIcon from '@mui/icons-material/ModeEditOutlineOutlined'
import Input from '@mui/material/Input'
import {
  Controller,
  type Control,
  type UseFormHandleSubmit,
  type UseFormSetValue,
  type UseFormSetError,
  type FormState,
  type UseFormTrigger,
} from 'react-hook-form'
import { useFetchOwnUserDetailsQuery } from 'features/admin/userApiSlice'
import FormHelperText from '@mui/material/FormHelperText'
import { t } from 'i18next'
import MultiSelectDropdown from '../AppPublish/MultiselectDropdown'
import { type useCasesItem } from 'features/Custom/appOnboard/types'

type DataTableType = {
  data: DataType[]
  item: DataType
  columns?: number
  userId?: any
  openEditOverlay: Function
  contentEditable?: boolean | undefined
  userEditableFields?: string[] | undefined
  selectedUseCases?: string[] | undefined
  initialUseCaseList?: useCasesItem[] | undefined
  options: string[] | undefined
  control?: Control<any, any> | undefined
  handleSubmit?: UseFormHandleSubmit<any> | undefined
  setValue?: UseFormSetValue<any> | undefined
  setError?: UseFormSetError<any> | undefined
  formState?: FormState<any> | undefined
  handleUserDetailsSubmit?: (data: {
    firstName: string
    lastName: string
    preferedUseCases: string[]
  }) => Promise<void>
  trigger?: UseFormTrigger<any>
}

type DataType = {
  cardCategory?: string
  cardContentItems: {
    [key: string]: {
      label: string
      value: string | string[]
    }
  }
}

export const UserDataTable = ({
  data = [],
  columns = 3,
  item,
  userId = '',
  openEditOverlay,
  contentEditable,
  userEditableFields,
  selectedUseCases,
  initialUseCaseList,
  options,
  control,
  handleSubmit,
  setValue,
  setError,
  formState,
  handleUserDetailsSubmit,
  trigger,
}: DataTableType) => {
  const { data: userData } = useFetchOwnUserDetailsQuery('', {
    refetchOnMountOrArgChange: true,
  })

  const getFieldName = (name: string): string => {
    switch (name) {
      case t('content.account.name'):
        return 'firstName'
      case t('content.account.lastName'):
        return 'lastName'
      case t('content.account.preferedUseCases'):
        return 'preferedUseCases'
      default:
        return ''
    }
  }

  return (
    <>
      <DataTableSectionStyled column={columns}>
        <DataTableTitleStyled>{item?.cardCategory}</DataTableTitleStyled>
        <DataTableMainStyled>
          <DataTableStyled>
            <tbody>
              {Object.entries(item?.cardContentItems).map(
                ([key, value], index) => (
                  <DataTableRowStyled key={index}>
                    <DataTableTdStyled>
                      {item?.cardContentItems[key].label}
                    </DataTableTdStyled>
                    {contentEditable ? (
                      <DataTableTdStyled>
                        <>
                          {[
                            t('content.account.name'),
                            t('content.account.lastName'),
                          ]?.includes(item?.cardContentItems[key].label) ? (
                            <Controller
                              name={getFieldName(
                                item?.cardContentItems[key].label
                              )}
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: `${item?.cardContentItems[key].label} is mandatory`,
                                },
                                maxLength: {
                                  value: 30,
                                  message: 'Maximum 30 characters are allowed',
                                },
                                minLength: {
                                  value: 4,
                                  message: 'Minimum 4 characters are required',
                                },
                              }}
                              render={({ field: { onChange, value } }) => {
                                return (
                                  <>
                                    <Input
                                      fullWidth
                                      required={contentEditable}
                                      disabled={!contentEditable}
                                      disableUnderline={!contentEditable}
                                      error={
                                        !!formState?.errors[
                                          getFieldName(
                                            item?.cardContentItems[key].label
                                          )
                                        ]
                                      }
                                      value={value}
                                      onChange={(event) => {
                                        trigger &&
                                          trigger(
                                            getFieldName(
                                              item?.cardContentItems[key].label
                                            )
                                          )
                                        onChange(event)
                                      }}
                                    />
                                    <FormHelperText
                                      style={{
                                        color: 'red',
                                      }}
                                    >
                                      {formState?.errors &&
                                        formState?.errors[
                                          getFieldName(
                                            item?.cardContentItems[key].label
                                          )
                                        ] &&
                                        (formState?.errors[
                                          getFieldName(
                                            item?.cardContentItems[key].label
                                          )
                                        ]?.message as React.ReactNode)}
                                    </FormHelperText>
                                  </>
                                )
                              }}
                            />
                          ) : [t('content.account.preferedUseCases')]?.includes(
                              item?.cardContentItems[key].label
                            ) ? (
                            <>
                              <MultiSelectDropdown
                                control={control}
                                trigger={trigger}
                                disable={!contentEditable}
                                rules={{
                                  required: {
                                    value: true,
                                    message: 'Please select upto 3 use cases',
                                  },
                                }}
                                name={getFieldName(
                                  item?.cardContentItems[key].label
                                )}
                                setValue={setValue}
                                setError={setError}
                                helperTextMessage={t(
                                  'content.appPublish.appMarketCard.useCasesMandatoryLimit'
                                )}
                                isRequired={true}
                                limitSelection={3}
                                sendUseCasesToAppcard={(data: any) => {
                                  if (data?.length > 0 && data !== undefined) {
                                    const filteredUseCaseIds =
                                      initialUseCaseList
                                        ?.filter((everyUseCase: any) =>
                                          data?.some(
                                            (everyTag: any) =>
                                              everyUseCase?.name === everyTag
                                          )
                                        )
                                        .map(
                                          (eachUseCase: any) =>
                                            eachUseCase.useCaseId
                                        )
                                    // setUseCaseIds(filteredUseCaseIds)
                                    console.log(filteredUseCaseIds)
                                  }
                                }}
                                options={options}
                                defaultValues={selectedUseCases}
                              />
                            </>
                          ) : Array.isArray(value?.value) ? (
                            value?.value.join(', ')
                          ) : (
                            value?.value
                          )}
                          {userId && value?.label === 'BPN' && (
                            <EditIcon
                              style={{ cursor: 'pointer' }}
                              onClick={() => openEditOverlay()}
                            />
                          )}
                        </>
                      </DataTableTdStyled>
                    ) : (
                      <DataTableThStyled>
                        {[t('content.account.preferedUseCases')]?.includes(
                          item?.cardContentItems[key].label
                        )
                          ? initialUseCaseList
                              ?.filter((item) =>
                                userData?.useCases?.some(
                                  (x) => x === item.useCaseId
                                )
                              )
                              .map((each) => each.name)
                              .join(', ')
                          : Array.isArray(value?.value)
                          ? value?.value.join(', ')
                          : value?.value}
                      </DataTableThStyled>
                    )}
                  </DataTableRowStyled>
                )
              )}
            </tbody>
          </DataTableStyled>
        </DataTableMainStyled>
      </DataTableSectionStyled>
    </>
  )
}
