import React, { useEffect } from 'react'
import {
  Box,
  Typography,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { useTranslation } from 'react-i18next'
import { type AppDetails } from 'features/apps/details/types'
import { useInView } from 'react-intersection-observer'
import { LABEL_IDS, OBSERVER_OPTIONS } from '../types'

function AppDetailDescription({
  item,
  handleSetSelectedTab,
}: {
  item: AppDetails
  handleSetSelectedTab: (tab: string) => void
}) {
  const { ref, inView } = useInView(OBSERVER_OPTIONS)
  const { t } = useTranslation()

  useEffect(() => {
    if (inView) {
      handleSetSelectedTab(LABEL_IDS.APPDETAIL_DESCRIPTION)
    }
  }, [inView])

  return (
    <div ref={ref} id={LABEL_IDS.APPDETAIL_DESCRIPTION}>
      <Box marginTop="2rem">
        <Typography
          fontSize="20px"
          fontWeight={600}
          color="#000"
          marginBottom="1rem"
        >
          {t('content.appdetail.description.title')}
        </Typography>
        <Typography
          fontSize="1rem"
          fontWeight={400}
          color="#000"
          marginBottom="2rem"
        >
          {item?.longDescription}
        </Typography>
      </Box>
    </div>
  )
}

export default AppDetailDescription
