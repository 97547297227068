import i18n from 'i18next'
import { styled, lighten, darken } from '@mui/system'
import Patterns from 'types/Patterns'

export const LANGUAGE_SUPPORTED: string[] = ['en', 'de']

export type LanguageObject = {
  [key: string]: {
    shortDescription: string
    longDescription: string
  }
}

export const getLanguage = (): string => {
  return (
    i18n.language ||
    (typeof window !== 'undefined' && window.localStorage.i18nextLng) ||
    'en'
  )
}

export const GroupHeader = styled('div')(({ theme }) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}))

export const GroupItems = styled('ul')({
  padding: 0,
})

export function getEmbedUrl(watchUrl: string): string {
  if (!watchUrl) {
    return '#'
  }
  if (watchUrl.startsWith('https://vimeo.com/')) {
    const id = watchUrl.replace('https://vimeo.com/', '')
    if (id.startsWith('album')) {
      //Playlist
      const vimeoPlaylistId = id.replace('album/', '')
      const embedLink = `https://player.vimeo.com/playlist/${vimeoPlaylistId}`
      return embedLink
    } else {
      //normal video
      const embedLink = `https://player.vimeo.com/video/${id}`
      return embedLink
    }
  } else {
    const videoMatch = watchUrl.match(Patterns.VIDEOREGEX)
    const playlistMatch = watchUrl.match(Patterns.PLAYLISTREGEX)

    if (videoMatch && videoMatch[2].length === 11) {
      return `https://www.youtube.com/embed/${videoMatch[2]}?autoplay=0`
    } else if (playlistMatch && playlistMatch[1]) {
      return `https://www.youtube.com/embed/videoseries?list=${playlistMatch[1]}`
    } else {
      return '#'
    }
  }
}
