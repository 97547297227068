import { createSlice } from '@reduxjs/toolkit'
import {
  type PaginationData,
  type MarketplaceInitialState,
  type AppMarketplaceAdminResponse,
} from './types'
import { type RootState } from 'features/store'
import { fetchApps } from './actions'
import uniq from 'lodash.uniq'

const initialState: MarketplaceInitialState = {
  paginationData: {} as PaginationData,
  appList: [],
  loading: true,
  error: '',
}

const marketplaceSlice = createSlice({
  name: 'appMarketplaceAdmin',
  initialState,
  reducers: {
    resetMarketplaceState: (state) => {
      state.appList = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchApps.pending, (state) => {
      state.paginationData = {} as PaginationData
      state.loading = true
    })
    builder.addCase(fetchApps.fulfilled, (state, { payload }) => {
      const responseList = payload as unknown as AppMarketplaceAdminResponse
      state.appList = uniq(state.appList.concat(responseList.content))
      state.paginationData = {
        totalElements: responseList.meta.totalElements,
        page: responseList.meta.page,
      } as PaginationData

      state.loading = false
    })
    builder.addCase(fetchApps.rejected, (state, action) => {
      state.paginationData = {} as PaginationData
      state.appList = []
      state.loading = false
      state.error = action.error.message as string
    })
  },
})

export const marketplaceSelector = (
  state: RootState
): MarketplaceInitialState => state.appMarketplaceAdmin

export default marketplaceSlice
