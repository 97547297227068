import Chip from '@mui/material/Chip'
import React from 'react'
import ROLES from '../../../assets/standards/roles.json'
import STANDARDS from '../../../assets/standards/standards.json'
import USECASES from '../../../assets/standards/usecases.json'
import { ReactComponent as CloseIcon } from '../../../assets/images/close-icon.svg'
import { t } from 'i18next'
import { DataGrid, type GridColDef, type GridSelectionModel } from '@mui/x-data-grid'
import {
  Button,
  Box,
  SearchIcon,
  Typography,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import {
  Grid,
  InputAdornment,
  TextField,
} from '@mui/material'

import { type CertificationType } from 'components/pages/AppPublish/Certification/types'
import { type UseFormTrigger, type FieldErrors, type UseFormSetValue } from 'react-hook-form'

// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
// const checkedIcon = <CheckBoxIcon fontSize="small" />

const columns: GridColDef[] = [
  {
    field: 'capabilities',
    headerName: 'Capabilities',
    flex: 1,
    type: 'string',
    disableColumnMenu: true,
    sortable: true,
  },
  {
    field: 'version',
    headerName: 'Version',
    type: 'number',
    flex: 1,
    sortable: true,
    disableColumnMenu: true,
  },
  {
    field: 'title',
    headerName: 'Title',
    type: 'string',
    flex: 2,
    disableColumnMenu: true,
    sortable: true,
  },
]

const dataGridStyle = {
  border: 'none',
  '.MuiDataGrid-columnHeaderTitle': {
    textTransform: 'uppercase',
    fontWeight: 600,
    fontSize: '1rem',
  },
  '.MuiDataGrid-columnHeaderCheckbox': {
    height: '0px !important',
  },
  '.MuiDataGrid-selectedRowCount': {
    fontWeight: 600,
  },
  '.MuiTablePagination-root .MuiTablePagination-selectLabel': {
    fontWeight: 600,
  },
  '.MuiTablePagination-displayedRows': {
    fontWeight: 600,
  },
}

const chipStyle = {
  borderRadius: '20px !important',
  width: 'auto',
  backgroundColor: '#4D4D4D !important',
  color: '#fff !important',
  margin: '6px !important',
  fontWeight: '600 !important',
  fontSize: '16px !important',
}

const searchTextfield = {
  '& label.Mui-focused': {
    color: '#A0AAB4',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2',
  },
  '.MuiOutlinedInput-input': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  '& .MuiOutlinedInput-root': {
    height: 40,
    fontFamily: 'Karbon',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: 'normal',
    '& fieldset': {
      borderColor: '#E0E3E7',
      borderRadius: '30px',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#D31184',
    },
  },
}

interface OverlayProps {
  trigger: UseFormTrigger<CertificationType>
  errors: FieldErrors<CertificationType>
  setValue: UseFormSetValue<CertificationType>
  handleOverlayClose: () => void
  selectedStandards: string[]
  name: 'date' | 'document' | 'standards'
}

const OverlayContent = ({
  selectedStandards,
  errors,
  trigger,
  setValue,
  handleOverlayClose,
  name,
}: OverlayProps) => {
  const [rows, setRows] = React.useState<
    { id: string; capabilities: string; title: string; version: string }[]
  >([])
  const [roles, setRoles] = React.useState<{ title: string; name: string }[]>()
  const [usecases, setUsecases] = React.useState<
    { title: string; name: string }[]
  >([])
  const [searchExpr, setSearchExpr] = React.useState<string>('')
  const [selectionModel, setSelectionModel] =
    React.useState<GridSelectionModel>(selectedStandards)

  React.useEffect(() => {
    setRows(
      STANDARDS.filter(
        (eachStandards) =>
          eachStandards.capabilities
            .toLocaleLowerCase()
            .includes(searchExpr.toLocaleLowerCase()) ||
          eachStandards.title
            .toLocaleLowerCase()
            .includes(searchExpr.toLocaleLowerCase())
      )
    )
  }, [searchExpr])

  React.useEffect(() => {
    setRows(
      STANDARDS.map((eachStandard) => {
        return {
          id: eachStandard.id,
          capabilities: eachStandard.capabilities,
          title: eachStandard.title,
          version: eachStandard.version,
        }
      })
    )
    setRoles(
      ROLES.map((eachRole) => {
        return {
          title: eachRole.title,
          name: eachRole.name,
        }
      })
    )
    setUsecases(
      USECASES.map((eachRole) => {
        return {
          title: eachRole.title,
          name: eachRole.name,
        }
      })
    )
  }, [])

  const handleDeteleStandards = (event: any, id: string) => {
    const newStandards = selectionModel.filter((model) => model !== id)
    setSelectionModel(newStandards)
  }

  const handleAddClick = async () => {
    setValue(name, selectionModel as unknown as string[])
    await trigger(name).then(() => { handleOverlayClose() })
  }

  return (
    <Box sx={{ mt: 1, mb: 1 }}>
      <Typography
        variant="h2"
        sx={{
          fontWeight: '600',
          color: '#d31184',
        }}
      >
        Add Standards
      </Typography>
      <Typography
        variant="body3"
        sx={{
          fontWeight: '600',
        }}
      >
        {t('content.appPublish.certification.overlays.description')}
      </Typography>
      <Box mt={2} mb={2} sx={{ width: 'auto' }}>
        <Grid container gap={2}>
          <Grid item xs={4}>
            <TextField
              onChange={(event) => { setSearchExpr(event.target.value) }}
              placeholder={'Search for keywords'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={searchTextfield}
            />
          </Grid>
          {/* need to enable after relation is Complete for Standards */}
          {/* <Grid item xs={4}>
            <Autocomplete
              multiple
              disableCloseOnSelect
              sx={{
                width: '100%',
              }}
              options={roles}
              id="tags-standard"
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <span {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  <Typography
                    variant="caption1"
                    sx={{
                      fontWeight: '500',
                    }}
                  >
                    {option.name}
                  </Typography>
                </span>
              )}
              limitTags={1}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <div
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '8rem',
                    }}
                    key={index}
                  >
                    <Typography
                      {...getTagProps({ index })}
                      noWrap
                      variant="caption1"
                    >
                      {option.name}
                    </Typography>
                  </div>
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={'Search for keywords'}
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item xs={4}>
            <Autocomplete
              multiple
              disableCloseOnSelect
              sx={{
                width: '100%',
              }}
              options={usecases}
              id="tags-standard"
              getOptionLabel={(option) => option.name}
              renderOption={(props, option, { selected }) => (
                <span {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  <Typography
                    variant="caption1"
                    sx={{
                      fontWeight: '500',
                    }}
                  >
                    {option.name}
                  </Typography>
                </span>
              )}
              limitTags={1}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <div
                    style={{
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      width: '8rem',
                    }}
                  >
                    <Typography
                      {...getTagProps({ index })}
                      noWrap
                      variant="caption1"
                    >
                      {option.name}
                    </Typography>
                  </div>
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder={'Select use cases'}
                  variant="standard"
                />
              )}
            />
          </Grid>
          <Grid item>
            <span className="certificationStepper">
              <Button variant="contained">Reset</Button>
            </span>
          </Grid> */}
        </Grid>
      </Box>
      <Box
        mt={2}
        mb={2}
        style={{
          height: 400,
          width: 'auto',
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          checkboxSelection
          filterMode="client"
          keepNonExistentRowsSelected
          selectionModel={selectionModel}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel)
          }}
          sx={dataGridStyle}
        />
      </Box>
      <Box mb={1} maxHeight={100} overflow={'scroll'}>
        {STANDARDS.filter((eachStandards) =>
          selectionModel.some((ids) => ids === eachStandards.id)
        ).map((eachStandard) => (
          <Chip
            sx={chipStyle}
            label={eachStandard.title}
            id={eachStandard.id}
            key={eachStandard.id}
            deleteIcon={<CloseIcon />}
            onDelete={(event) => { handleDeteleStandards(event, eachStandard.id) }}
          />
        ))}
      </Box>
      <Box mt={1} mb={1} display={'flex'} justifyContent={'space-between'}>
        <Button variant="outlined" onClick={handleOverlayClose}>
          {t('global.actions.back')}
        </Button>
        <Button variant="contained" onClick={handleAddClick}>
          {t('global.actions.add')}
        </Button>
      </Box>
    </Box>
  )
}

export default OverlayContent
