import {
  Box,
  CrossIconGraphic,
  Dialog,
  DialogContent,
  IconButton,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'

function PdfPreview({
  file,
  open,
  onClose,
}: {
  file: string | Uint8Array
  open: boolean
  onClose: () => void
}) {
  return (
    <Dialog
      open={open}
      additionalModalRootStyles={{
        maxWidth: '100vw',
        width: '1004px',
      }}
      scroll="paper"
      onBackdropClick={onClose}
    >
      {file.length <= 0 ? (
        <Box textAlign="center" height={200} width={200}>
          <span
            style={{
              padding: 10,
            }}
          >
            <span className="invite-loader" />
          </span>
        </Box>
      ) : (
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
            }}
          >
            <CrossIconGraphic height={20} width={20} />
          </IconButton>
          <embed
            type="application/pdf"
            src={`${file as any}#zoom=85&scrollbar=0&toolbar=0&navpanes=0`}
            width={800}
            height={500}
          />
        </DialogContent>
      )}
    </Dialog>
  )
}

export default PdfPreview
