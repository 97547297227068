import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  CarouselBox,
  CrossIconGraphic,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Typography,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { PricingCard } from './PricingCard'
import { useAppPlansQuery } from 'features/apps/apiSlice'
import HeaderBg from './../../../../../assets/images/priceHeaderBg.svg'
import FooterBg from './../../../../../assets/images/priceFooterBg.svg'
import { PRICES_ENUM } from 'types/Constants'
import i18next from 'i18next'

interface PlansInterface {
  type?: 'FREE' | 'PER_MONTH' | 'FIXED'
  amount: string
  currency: string
  model: string
  frequency: string
  customPricingType: string
  salesContactName: string
  salesContactEmail: string
  salesContactPhone?: string
  description: string
  features: string[]
  title?: string
  localizedSummary?: { values: { langCode: string; value: string }[] }
}

function PricingDialog({
  id,
  open,
  onClose,
}: {
  id: string
  open: boolean
  onClose: () => void
}) {
  const { t } = useTranslation()
  const { data, isLoading, error } = useAppPlansQuery(id ?? '')
  const [matches, setMatches] = useState({
    ipad: window.matchMedia('(min-width: 600px) and (max-width: 1250px)')
      .matches,
  })

  const labels = {
    pricingModel: `${t('content.appdetail.pricing.pricingModel')}`,
    paymentFrequency: `${t('content.appdetail.pricing.paymentFrequency')}`,
    planIncludes: `${t('content.appdetail.pricing.planIncludes')}`,
    showMore: `${t('content.appdetail.pricing.showMore')}`,
    showLess: `${t('content.appdetail.pricing.showLess')}`,
    showAll: `${t('content.appdetail.pricing.showAll')}`,
  }
  return (
    <Dialog
      open={open}
      additionalModalRootStyles={{
        boxShadow: '0 0 0 0 transparent',
        width: 'auto',
        padding: '0 20px',
      }}
      onBackdropClick={onClose}
    >
      <DialogContent sx={{ padding: '33px 8px !important' }}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 10,
            top: 10,
            zIndex: 99,
          }}
        >
          <CrossIconGraphic height={20} width={20} />
        </IconButton>
        <Typography
          marginBottom="1rem"
          fontSize="1.50rem"
          fontWeight="600"
          textAlign="center"
        >
          {t('content.appdetail.pricing.yourPricingOptions')}
        </Typography>

        {data && data?.plans ? (
          <Grid container justifyContent={'center'} style={{ marginTop: 0 }}>
            <Grid
              xs={12}
              item
              width={
                data?.plans.length <= 3
                  ? data?.plans.length * 380 + 'px'
                  : '1140px'
              }
              style={{ display: 'flex' }}
            >
              {data.plans.map((item: PlansInterface, index: number) => (
                <PricingCard
                  id={''}
                  key={index}
                  title={item.title}
                  type={
                    item.type ? PRICES_ENUM[item.type] : PRICES_ENUM.FREE
                  }
                  amount={item.amount}
                  currency={item.currency}
                  model={item.model}
                  frequency={item.frequency}
                  customPricingType={item.customPricingType}
                  salesContactName={item.salesContactName}
                  salesContactEmail={item.salesContactEmail}
                  salesContactPhone={item.salesContactPhone}
                  description={
                    item?.localizedSummary?.values?.length
                      ? item?.localizedSummary?.values?.filter(
                          (eachDescription) =>
                            eachDescription.langCode === i18next.language
                        )?.[0]?.value ??
                        item?.localizedSummary?.values?.filter(
                          (eachDescription) => eachDescription.langCode === 'en'
                        )?.[0]?.value
                      : 'No description provided'
                  }
                  features={item.features.toString().trim().split(',')}
                  labels={labels}
                  images={{ header: HeaderBg, footer: FooterBg }}
                  onBtnClick={() => { console.log() }}
                />
              ))}
            </Grid>
          </Grid>
        ) : null}
      </DialogContent>
    </Dialog>
  )
}

export default PricingDialog
