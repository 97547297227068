import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { ServiceOnboardReleaseProcess } from './ServiceOnboardReleaseProcess'
import {
  setServiceId,
  setServiceStatus,
} from 'features/Custom/serviceOnboard/actions'
import { useFetchServiceStatusQuery } from 'features/Custom/serviceOnboard/apiSlice'

const ServicePublish = () => {
  const queryParams = new URLSearchParams(window.location.search)
  const serviceId = queryParams.get('serviceId')
  const dispatch = useDispatch()
  const { data: fetchServiceStatus } = useFetchServiceStatusQuery(
    serviceId ?? '',
    {
      refetchOnMountOrArgChange: true,
    }
  )
  useEffect(() => {
    if (serviceId?.length) {
      dispatch(setServiceId(serviceId))
      dispatch(setServiceStatus(fetchServiceStatus))
    }
  }, [serviceId, fetchServiceStatus, dispatch])

  return (
    <div>
      <main className="app-store">
        <ServiceOnboardReleaseProcess></ServiceOnboardReleaseProcess>
      </main>
    </div>
  )
}

export default ServicePublish
