import { InputLabel } from '@mui/material'
import {
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Box,
  Button,
  Grid,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { t } from 'i18next'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import quickhelp from '../../../../assets/images/quickhelp.png'
import successbg from '../../../../assets/images/successBackground.png'

interface SuccessProps {
  isVisible: boolean
  handleOverlayState: () => void
}

export default function SuccessOverlay(props: SuccessProps) {
  const navigation = useNavigate()
  return (
    <Dialog
      open={props.isVisible}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '400px',
          },
        },
      }}
    >
      <DialogContent>
        <div>
          <img
            src={successbg}
            style={{
              position: 'absolute',
              right: '0px',
              top: '70px',
            }}
          />
        </div>
        <IconButton
          aria-label="close"
          onClick={props.handleOverlayState}
          sx={{
            position: 'absolute',
            right: 16,
            top: 16,
          }}
        >
          {/* <CrossIconGraphic height={20} width={20} /> */}
        </IconButton>
        <Typography variant="h3" mt={2}>
          {t('content.appPublish.submit.headerTitle')}
        </Typography>
        <Typography variant="h6" mt={2}>
          {t('content.appPublish.submit.headerDescriptionComplete')}
        </Typography>
        <Typography variant="h4" mt={2}>
          {t('content.appPublish.submit.headerDescription')}
        </Typography>
        <Box textAlign="left" mt={3}>
          <Button
            variant="contained"
            name="send"
            onClick={() => { navigation('/') }}
          >
            {t('content.appPublish.submit.proceedBtn')}
          </Button>
        </Box>
        <Typography variant="h3" mt={4}>
          {t('content.appPublish.submit.quickHelp')}
        </Typography>

        <Grid container xs={12}>
          <Grid xs={1}>
            <div className="">
              <img
                src={quickhelp}
                style={{
                  position: 'absolute',
                  bottom: '39px',
                  left: '80px',
                }}
              />
            </div>
          </Grid>
          <Grid xs={11}>
            <Typography variant="h4" mt={2}>
              <strong> {t('content.appPublish.submit.moreInfo')}</strong>
              <InputLabel> {t('content.appPublish.submit.help')} </InputLabel>
            </Typography>
          </Grid>
        </Grid>
        <div></div>
      </DialogContent>
    </Dialog>
  )
}
