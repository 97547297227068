import React, { createContext, useState, FC, useEffect } from 'react'

export type DrawerContextState = {
  drawerStatus: string
  changeStatus: (name: string) => void
}

const contextDefaultValues: DrawerContextState = {
  drawerStatus: '0',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  changeStatus: () => {},
}

export const DrawerStatusContext =
  createContext<DrawerContextState>(contextDefaultValues)

type DrawerProps = {
  children: React.ReactNode
}

const DrawerStatusProvider: React.FC<DrawerProps> = (props: DrawerProps) => {
  const [drawerStatus, setDrawerStatus] = useState<string>(
    contextDefaultValues.drawerStatus
  )

  const changeStatus = (newStatus: string) => {
    setDrawerStatus(newStatus)
  }

  return (
    <DrawerStatusContext.Provider
      value={{
        drawerStatus,
        changeStatus,
      }}
    >
      {props.children}
    </DrawerStatusContext.Provider>
  )
}

export default DrawerStatusProvider
