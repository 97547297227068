import React, { useState, useEffect, useRef, useMemo } from 'react'
import {
  Paper,
  Box,
  Grid,
  FormControl,
  TextField,
  Chip,
  InputLabel,
  Autocomplete,
  Tooltip,
  IconButton,
  Backdrop,
  CircularProgress,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { Controller, useForm } from 'react-hook-form'
import { type DropzoneFile } from 'components/shared/basic/Dropzone'
import AppReviewCard from 'components/pages/AppPublish/OverView/AppPreviewCard'
import PageHeaderImg from '../../../../assets/logo/page-header-img.svg'
import {
  ConnectorFormInputField,
  centerAspectCrop,
} from 'components/pages/AppPublish/OverView'
import { LANGUAGE_SUPPORTED, type LanguageObject } from 'utils/utilities'
import { isString } from 'lodash'
import MultiSelectDropdown from 'components/shared/basic/MultiselectDropdown'
import KeyFeatureControlledTag from 'components/pages/AppPublish/ControlledTags'
import { InfoOutlined } from '@mui/icons-material'
import {
  Button,
  PageNotifications,
  PageSnackbar,
  Typography,
  type UploadFileStatus,
  UploadStatus,
  theme,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { useTranslation } from 'react-i18next'
import Patterns from 'types/Patterns'
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  type PixelCrop,
} from 'react-image-crop'
import 'react-image-crop/src/ReactCrop.scss'
import ImageDialog from 'components/pages/AppPublish/OverView/CropDialog'
import { useDebounceEffect } from 'components/pages/AppPublish/OverView/useDebounceEffect'
import { canvasPreview } from 'components/pages/AppPublish/OverView/canvasPreview'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  currentActiveStep,
  increment,
  serviceIdSelector,
  serviceStatusDataSelector,
} from 'features/Custom/serviceOnboard/slice'
import UserService from 'services/UserService'
import {
  useFetchServiceStatusQuery,
  useFetchDocumentByIdMutation,
  useFetchUseCasesByServiceQuery,
  useSaveServiceMutation,
  useUpdateDocumentUploadMutation,
  useAddCreateServiceMutation,
  useDeleteDocumentMutation,
} from 'features/Custom/serviceOnboard/apiSlice'
import {
  setServiceId,
  setServiceStatus,
} from 'features/Custom/serviceOnboard/actions'
import { useFetchOwnCompanyDetailsQuery } from 'features/admin/userApiSlice'
import { useFetchProvidersInformationQuery } from 'features/Custom/appOnboard/apiSlice'
import {
  ORGANIZATION_NAME,
  ROLES,
  COFINITY_SERVICE_ONBOARDING_DOCUMENTATION_URL,
} from 'types/Constants'
import { initialState } from 'features/Custom/serviceOnboard/types'
import { N_ID } from 'components/pages/AppPublish/TechnicalIntegrationAndBetaTest/types'
import { QueryStatus } from '@reduxjs/toolkit/dist/query'
import {
  type ServiceTypeIdsType,
  useFetchServiceTypeIdsQuery,
} from 'features/serviceManagement/apiSlice'
import { SIZE_LIMITS } from 'types/Config'

interface ServiceOverviewType {
  title: string
  provider: string
  descriptions: LanguageObject[]
  useCases: string[]
  keywords: string[]
  coverImage: DropzoneFile | string | null
  serviceTypeIds: string[]
}
const ServiceOverview = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const serviceId = useSelector(serviceIdSelector)
  const activeStep = useSelector(currentActiveStep)
  const { data: companyDetails } = useFetchOwnCompanyDetailsQuery()
  const { data: providersData } = useFetchProvidersInformationQuery()
  const serviceStatusData = useSelector(serviceStatusDataSelector)
  const [deleteDocument] = useDeleteDocumentMutation()
  const [appCardSnackbar, setAppCardSnackbar] = useState<boolean>(false)
  const [appCardNotification, setAppCardNotification] = useState(false)
  const useCasesListData = useFetchUseCasesByServiceQuery().data
  const [updateDocumentUpload, { status: uploadDocumentStatus }] =
    useUpdateDocumentUploadMutation()
  const useCasesList = useMemo(() => useCasesListData || [], [useCasesListData])
  const [fetchDocumentById] = useFetchDocumentByIdMutation()
  const [addCreateService, { status: createStatus }] =
    useAddCreateServiceMutation()
  const [saveService, { status: updateStatus }] = useSaveServiceMutation()
  const [selectedUseCaseIds, setSelectedUseCaseIds] = useState<string[]>([])
  const [userCaseNameListItems, setUserCaseNameListItems] = useState<string[]>(
    []
  )
  //crop state
  const [imageState, setImageState] = useState<any>()
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const [completedCropedImage, setCompletedCropedImage] = useState<PixelCrop>()
  const cropedFileRef = useRef<File | DropzoneFile>()
  const cropedFileName = useRef<string>('')
  let aspect: number | undefined = 16 / 9
  const hiddenAnchorRef = useRef<HTMLAnchorElement>(null)
  const blobUrlRef = useRef('')

  const [openCropDialog, setOpenCropDialog] = useState<boolean>(false)
  const [selectedUseCases, setSelectedUseCases] = useState<string[]>([
    'Use Cases',
  ])

  const { data: fetchServiceStatus, refetch } = useFetchServiceStatusQuery(
    serviceId ?? '',
    {
      refetchOnMountOrArgChange: true,
    }
  )
  const [defaultServiceTypeVal, setDefaultServiceTypeVal] = useState<
    ServiceTypeIdsType[]
  >([])
  const serviceTypeData = useFetchServiceTypeIdsQuery().data
  const serviceTypeIds = useMemo(() => serviceTypeData || [], [serviceTypeData])

  const [selectedServiceTypeIds, setSelectedServiceTypeIds] = useState<
    string[]
  >([])
  const [serviceTypeNameListItems, setServiceTypeNameListItems] = useState<
    string[]
  >([])
  const [selectedLanguage, setSelectedLanguage] = useState<string[]>(['en'])
  const [visibleDropdown, setVisibleDropdown] = useState<boolean>(false)
  const fixedLanguage = [LANGUAGE_SUPPORTED[0]]
  const [cardImage, setCardImage] = useState(PageHeaderImg)
  const [isDataLoaded, setDataLoaded] = useState<boolean>(false)
  const [selectedProviderCompanyId, setSelectedProviderCompanyId] = useState<
    string | undefined
  >(
    serviceStatusData?.providerCompanyId === ''
      ? companyDetails?.companyId
      : serviceStatusData?.providerCompanyId
  )

  const listOfProviders =
    (providersData &&
      providersData?.map((provider) => {
        return {
          label: provider?.name,
          id: provider?.companyId,
        }
      })) ??
    []

  const options = listOfProviders.map((option) => {
    const firstLetter = option.label[0].toUpperCase()
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    }
  })

  useEffect(() => {
    if (fetchServiceStatus !== undefined) {
      dispatch(setServiceStatus(fetchServiceStatus))
      setSelectedProviderCompanyId(fetchServiceStatus?.providerCompanyId)
    } else {
      dispatch(setServiceStatus(initialState.serviceStatusData))
      setSelectedProviderCompanyId(companyDetails?.companyId)
    }
  }, [dispatch, fetchServiceStatus, companyDetails])

  const defaultValues = {
    title: serviceStatusData?.title ?? '',
    provider:
      serviceStatusData?.provider === ''
        ? UserService.getCompany()
        : serviceStatusData?.provider,
    descriptions: LANGUAGE_SUPPORTED.map((language: string) => {
      return {
        [language]: {
          shortDescription:
            serviceStatusData?.longDescription?.filter(
              (appStatus: any) => appStatus.languageCode === language
            )[0]?.shortDescription ?? '',
          longDescription:
            serviceStatusData?.longDescription?.filter(
              (appStatus: any) => appStatus.languageCode === language
            )[0]?.longDescription ?? '',
        },
      }
    }),
    useCases:
      serviceStatusData?.useCases
        ?.filter((eachUseCase: any) => eachUseCase?.label !== 'None')
        ?.map((useCase: any) => useCase?.label) ?? [],
    keywords: serviceStatusData?.tags ?? [],
    coverImage: serviceStatusData?.leadPictureId ?? null,
    serviceTypeIds:
      serviceStatusData?.serviceTypeIds
        ?.filter((item: any) => item !== 'None')
        ?.map((item: any) => item) ?? [],
  }

  const {
    handleSubmit,
    getValues,
    control,
    trigger,
    setValue,
    resetField,
    setError,
    clearErrors,
    formState: { errors, isDirty, isSubmitting },
    reset,
  } = useForm<ServiceOverviewType>({
    defaultValues,
    mode: 'onChange',
  })

  const fetchCardImage = async (documentId: string, documentName: string) => {
    try {
      const response = await fetchDocumentById({
        serviceId,
        documentId,
      }).unwrap()
      if (response.data) {
        const file = response.data

        const setFileStatus = (status: UploadFileStatus) => {
          setValue('coverImage', {
            id: documentId,
            name: documentName,
            status: UploadStatus.UPLOAD_SUCCESS,
            size: file.size,
            type: file.type,
          } as DropzoneFile)
        }
        setFileStatus(UploadStatus.UPLOAD_SUCCESS)
        setCardImage(URL.createObjectURL(file))
        return
      }
    } catch (error) {
      setValue('coverImage', null)
    }
  }

  function onDownloadCropClick(e: any) {
    if (!previewCanvasRef.current) {
      throw new Error('Crop canvas does not exist')
    }
    previewCanvasRef.current.toBlob((blob) => {
      if (!blob) {
        throw new Error('Failed to create blob')
      }
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current)
      }
      blobUrlRef.current = URL.createObjectURL(blob)
      setCardImage(blobUrlRef.current)
      hiddenAnchorRef.current!.href = blobUrlRef.current
      const BlobToFile = new File([blob], cropedFileName.current, {
        type: 'image/png',
      })
      cropedFileRef.current = BlobToFile
    })
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (imgRef.current) {
      const { width, height } = imgRef.current
      aspect = 16 / 9
      setImageState(centerAspectCrop(width, height, aspect))
    }
  }

  const handleFileDetails = (file: File) => {
    cropedFileName.current = file?.name
  }

  function collectUseCases(getTagData: any) {
    if (getTagData?.length > 0 && getTagData !== undefined) {
      setSelectedUseCases(getTagData)
      const filteredUseCaseIds = useCasesList
        .filter((everyUseCase: any) =>
          getTagData?.some((everyTag: any) => everyUseCase?.name === everyTag)
        )
        .map((eachUseCase: any) => eachUseCase.useCaseId)
      setSelectedUseCaseIds(filteredUseCaseIds)
      setValue('useCases', filteredUseCaseIds)
      clearErrors('useCases')
    }
  }
  function collectServiceType(data: any) {
    if (data?.length > 0 && data !== undefined) {
      setSelectedServiceTypeIds(data)
      const filteredServiceTypeIds = serviceTypeIds
        .filter((item: any) =>
          data?.some((everyTag: any) => item?.name === everyTag)
        )
        .map((eachItem: any) => eachItem.name)
      setSelectedServiceTypeIds(filteredServiceTypeIds)
      setValue('serviceTypeIds', [...filteredServiceTypeIds])
      clearErrors('serviceTypeIds')
    }
  }

  const uploadDocumentApi = async (
    serviceId: string,
    documentTypeId: string,
    file: any
  ) => {
    const data = {
      serviceId,
      documentTypeId,
      body: { file },
    }
    try {
      return await updateDocumentUpload(data).unwrap()
    } catch (error) {
      return error
    }
  }

  const cardImageData: any = getValues().coverImage
  useEffect(() => {
    if (cardImageData !== null && cardImageData !== PageHeaderImg) {
      const isFile: any = cardImageData instanceof File

      if (isFile) {
        const blobFile = new Blob([cardImageData], {
          type: 'image/png',
        })
        handleDialog()
        setCardImage(URL.createObjectURL(blobFile))
      }
    } else {
      setCardImage(PageHeaderImg)
    }
  }, [cardImageData])

  useEffect(() => {
    if (serviceStatusData?.leadPictureId) {
      fetchCardImage(
        serviceStatusData?.leadPictureId,
        serviceStatusData?.documents?.filter(
          (eachDoc) => eachDoc.documentTypeId === 'APP_LEADIMAGE'
        )[0]?.documentName ?? 'APP_LEADIMAGE'
      )
    }
    if (serviceStatusData?.serviceTypeIds) {
      setDefaultServiceTypeVal(
        serviceStatusData?.serviceTypeIds.map((item: string) => {
          return {
            name: item,
            serviceTypeId: item,
          }
        })
      )
    }
    reset(defaultValues)
  }, [serviceStatusData])

  useDebounceEffect(
    () => {
      if (
        completedCropedImage?.width &&
        completedCropedImage?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCropedImage
        )
      }
    },
    100,
    [completedCropedImage, imgRef.current]
  )

  useEffect(() => {
    setSelectedUseCases(getValues().useCases)
    setSelectedServiceTypeIds(getValues().serviceTypeIds)
  }, [getValues])

  useEffect(() => {
    if (useCasesList?.length > 0) {
      const useCaseNameList = useCasesList
        ?.filter((item) => item.name !== 'None')
        ?.map((item) => item.name)
      setUserCaseNameListItems(useCaseNameList)
      if (
        serviceStatusData?.longDescription?.filter(
          (appStatus: any) => appStatus.languageCode === 'de'
        )[0]?.shortDescription.length >= 10
      ) {
        const newLanguages = [...fixedLanguage, 'de']
        setSelectedLanguage(newLanguages)
      }
    }
    if (useCasesList?.length > 0) {
      const serviceTypeNameList = serviceTypeIds
        ?.filter((item) => item.name !== 'None')
        ?.map((item) => item.name)
      setServiceTypeNameListItems(serviceTypeNameList)
    }
  }, [serviceStatusData, useCasesList, serviceTypeIds])

  const handleDialog = () => {
    setOpenCropDialog(!openCropDialog)
  }

  const cardServiceTitle =
    getValues().title ||
    t('content.servicePublish.serviceOverview.serviceTitle')
  const cardServiceProvider =
    getValues().provider === ORGANIZATION_NAME.CX_OPERATOR
      ? ORGANIZATION_NAME.COFINITY_X
      : getValues().provider ||
        t('content.servicePublish.serviceOverview.serviceProvider')
  const cardServiceDescription =
    getValues().descriptions[0].en.shortDescription ||
    t('content.servicePublish.serviceOverview.defaultCardShortDescriptionEN')

  const onSubmit = async (data: ServiceOverviewType, buttonLabel: string) => {
    const validateFields = await trigger([
      'coverImage',
      'descriptions',
      'keywords',
      'provider',
      'title',
      'serviceTypeIds',
      'useCases',
    ])
    if (validateFields) {
      handleSave(data, buttonLabel)
    }
  }

  const handleSave = async (data: ServiceOverviewType, buttonLabel: string) => {
    const SERVICE_DATA = {
      title: data.title,
      provider:
        data.provider === ORGANIZATION_NAME.CX_OPERATOR
          ? ORGANIZATION_NAME.COFINITY_X
          : data.provider,
      providerCompanyId: selectedProviderCompanyId ?? companyDetails?.companyId,
      leadPictureUri:
        data.coverImage !== null &&
        Object.keys(data.coverImage).length > 0 &&
        Object.values(data.coverImage)[0],
      descriptions: LANGUAGE_SUPPORTED.map((language, index) => {
        return {
          languageCode: language,
          longDescription:
            serviceStatusData?.longDescription?.filter(
              (appStatus: any) => appStatus.languageCode === language
            )[0]?.longDescription ?? '',
          shortDescription: data.descriptions[index][language].shortDescription,
        }
      }),
      supportedLanguageCodes: [],
      tags: data.keywords,
      useCaseIds: selectedUseCaseIds,
      serviceTypeIds: selectedServiceTypeIds,
      salesManagerId: null,
      offerTypeId: 'Service',
    }
    const uploadImageValue =
      (cropedFileRef.current as File as DropzoneFile) ??
      (defaultValues?.coverImage as unknown as DropzoneFile)
    if (isDirty) {
      const saveServiceData = {
        serviceId,
        body: SERVICE_DATA,
      }
      if (serviceId) {
        await saveService(saveServiceData)
          .unwrap()
          .then(async (res) => {
            dispatch(setServiceId(serviceId))
            const setFileStatus = (status: UploadFileStatus) => {
              setValue('coverImage', {
                name: uploadImageValue.name,
                size: uploadImageValue.size,
                status,
              } as DropzoneFile)
            }
            setFileStatus(UploadStatus.UPLOADING)
            const response = uploadDocumentApi(
              serviceId,
              'APP_LEADIMAGE',
              uploadImageValue
            )
            await response
              .then((res) => {
                setValue('coverImage', {
                  id: res,
                  name: uploadImageValue.name,
                  size: uploadImageValue.size,
                  status: UploadStatus.UPLOAD_SUCCESS,
                } as DropzoneFile)
              })
              .catch(() => {
                setValue('coverImage', {
                  name: uploadImageValue.name,
                  size: uploadImageValue.size,
                  status: UploadStatus.UPLOAD_ERROR,
                } as DropzoneFile)
              })
            buttonLabel === 'saveAndProceed' && dispatch(increment())
            buttonLabel === 'save' && setAppCardSnackbar(true)
            dispatch(setServiceStatus(fetchServiceStatus))
          })
          .catch(() => {
            setAppCardNotification(true)
          })
      } else {
        await addCreateService(SERVICE_DATA)
          .unwrap()
          .then(async (result) => {
            if (isString(result)) {
              const setFileStatus = (status: UploadFileStatus) => {
                setValue('coverImage', {
                  name: uploadImageValue.name,
                  size: uploadImageValue.size,
                  status,
                } as DropzoneFile)
              }
              setFileStatus(UploadStatus.UPLOADING)
              const response = uploadDocumentApi(
                result,
                'APP_LEADIMAGE',
                uploadImageValue
              )
              await response
                .then((res) => {
                  setValue('coverImage', {
                    id: res,
                    name: uploadImageValue.name,
                    size: uploadImageValue.size,
                    status: UploadStatus.UPLOAD_SUCCESS,
                  } as DropzoneFile)
                })
                .catch(() => {
                  setValue('coverImage', {
                    name: uploadImageValue.name,
                    size: uploadImageValue.size,
                    status: UploadStatus.UPLOAD_ERROR,
                  } as DropzoneFile)
                })
              dispatch(setServiceId(result))
              updateBrowerPath(result)
              buttonLabel === 'saveAndProceed' && dispatch(increment())
              buttonLabel === 'save' && setAppCardSnackbar(true)
              dispatch(setServiceStatus(fetchServiceStatus))
            }
          })
          .catch((error) => {
            setAppCardNotification(true)
          })
      }
    } else {
      buttonLabel === 'saveAndProceed' && dispatch(increment())
      buttonLabel === 'save' && setAppCardSnackbar(true)
      dispatch(setServiceStatus(fetchServiceStatus))
    }
  }

  setTimeout(() => {
    setDataLoaded(true)
  }, 5000)

  function updateBrowerPath(serviceId: string) {
    window.history.pushState('', '', `serviceonboard?serviceId=${serviceId}`)
  }

  if (!isDataLoaded) {
    return (
      <Box
        style={{
          display: 'grid',
          placeItems: 'center',
          height: '100vh',
        }}
      >
        <span
          style={{
            padding: 10,
          }}
        >
          <span className="invite-loader" />
        </span>
      </Box>
    )
  } else {
    return (
      <Paper
        elevation={5}
        className="service-container service-detail__container service-detail__paper"
      >
        <Box className="certification__item-box">
          <div className="app-market-card">
            <Box
              mt={4}
              sx={{
                backgroundColor: theme.palette.stepper.stepCurrent,
                borderRadius: '50%',
                width: '30px',
                height: '30px',
                color: '#fff',
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              <Typography
                variant="body1"
                fontSize="14px"
                color="#fff"
                sx={{
                  margin: 'auto',
                  paddingTop: '4px',
                  width: 'fit-content',
                }}
              >
                {activeStep}
              </Typography>
            </Box>
            <Typography variant="h3" mt={5} mb={4} align="center">
              Overview
            </Typography>
            <Grid container spacing={2}>
              <Grid item md={11} sx={{ mr: 'auto', ml: 'auto' }}>
                <Typography variant="body2" align="center">
                  {t(
                    'content.servicePublish.serviceOverview.headerDescription'
                  )}
                </Typography>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={2}
              sx={{ mt: 10 }}
              className={'service-release-container'}
            >
              <div className={'service-release-card apppre'}>
                <AppReviewCard
                  image={{
                    alt: 'serviceCard',
                    image: cardImage,
                  }}
                  title={cardServiceTitle}
                  subtitle={cardServiceProvider}
                  description={cardServiceDescription}
                  useCases={selectedUseCases}
                />
              </div>
              <div className={'service-release-details'}>
                <form>
                  <div className="form-field">
                    <ConnectorFormInputField
                      {...{
                        control,
                        trigger,
                        errors,
                        name: 'title',
                        type: 'input',
                        label:
                          t(
                            'content.servicePublish.serviceOverview.serviceTitle'
                          ) + ' *',
                        placeholder: `${t(
                          'content.servicePublish.serviceOverview.appTitlePlcaholder'
                        )}`,
                        rules: {
                          required: {
                            value: true,
                            message: `${t(
                              'content.servicePublish.serviceOverview.serviceTitle'
                            )} ${t(
                              'content.servicePublish.appReleaseForm.isMandatory'
                            )}`,
                          },
                          minLength: {
                            value: 5,
                            message: `${t(
                              'content.servicePublish.appReleaseForm.minimum'
                            )} 5 ${t(
                              'content.servicePublish.appReleaseForm.charactersRequired'
                            )}`,
                          },
                          pattern: {
                            value: Patterns.appMarketCard.appTitle,
                            message: `${t(
                              'content.servicePublish.appReleaseForm.validCharactersIncludes'
                            )} A-Za-z0-9.:_- @&`,
                          },
                          maxLength: {
                            value: 40,
                            message: `${t(
                              'content.servicePublish.appReleaseForm.maximum'
                            )} 40 ${t(
                              'content.servicePublish.appReleaseForm.charactersAllowed'
                            )}`,
                          },
                        },
                      }}
                    />
                  </div>
                  {UserService.hasRole(ROLES.CX_ADMIN) ? (
                    <div className="form-field">
                      <InputLabel>
                        <strong
                          style={{
                            color: errors?.provider ? '#c02424' : 'inherit',
                          }}
                        >
                          {t(
                            'content.servicePublish.serviceOverview.serviceProvider'
                          ) + ' * '}
                        </strong>
                      </InputLabel>
                      <Controller
                        name={'provider'}
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            fullWidth
                            disablePortal
                            options={options.sort(
                              (a, b) =>
                                -b.firstLetter.localeCompare(a.firstLetter)
                            )}
                            getOptionLabel={(option) => option.label ?? ''}
                            defaultValue={{
                              label:
                                serviceStatusData?.provider === ''
                                  ? companyDetails?.name
                                  : serviceStatusData?.provider ===
                                    ORGANIZATION_NAME.CX_OPERATOR
                                  ? ORGANIZATION_NAME.COFINITY_X
                                  : serviceStatusData?.provider,
                              id:
                                serviceStatusData?.providerCompanyId === ''
                                  ? companyDetails?.companyId
                                  : serviceStatusData?.providerCompanyId,
                              firstLetter:
                                serviceStatusData?.provider === ''
                                  ? companyDetails?.name[0]
                                  : serviceStatusData?.provider ===
                                    ORGANIZATION_NAME.CX_OPERATOR
                                  ? ORGANIZATION_NAME.COFINITY_X[0]
                                  : serviceStatusData?.provider[0],
                            }}
                            onChange={async (event, value) => {
                              setSelectedProviderCompanyId(value?.id)
                              field.onChange(value?.label ?? '')
                              await trigger('provider')
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                placeholder="Select service provider"
                              />
                            )}
                          />
                        )}
                      />
                    </div>
                  ) : (
                    <div className="form-field">
                      <ConnectorFormInputField
                        {...{
                          control,
                          trigger,
                          errors,
                          name: 'provider',
                          disabled: true,
                          label:
                            t(
                              'content.servicePublish.serviceOverview.serviceProvider'
                            ) + ' * ',
                          type: 'input',
                        }}
                      />
                    </div>
                  )}
                  {LANGUAGE_SUPPORTED.filter((language) =>
                    selectedLanguage.some((selected) => selected === language)
                  ).map((language, index) => {
                    return (
                      <div className="form-field">
                        <ConnectorFormInputField
                          {...{
                            control,
                            trigger,
                            errors,
                            name: `descriptions.${index}.${language}.shortDescription` as const,
                            type: 'input',
                            // label: `Short description (${language}) *`,
                            label: (
                              <strong
                                style={{
                                  color: errors?.descriptions?.[index]?.[
                                    language
                                  ]?.shortDescription
                                    ? '#c02424'
                                    : 'inherit',
                                }}
                              >
                                {t(
                                  `content.servicePublish.serviceOverview.shortDescription${language}`
                                )}{' '}
                                {'*'}
                              </strong>
                            ),
                            textarea: true,
                            placeholder: `${t(
                              'content.servicePublish.serviceOverview.shortDescriptionPlaceholder'
                            )}`,
                            rules: {
                              required: {
                                value: language === 'en' ? true : false,
                                message: `${t(
                                  `content.servicePublish.serviceOverview.shortDescription${language}`
                                )} ${t(
                                  'content.appPublish.appReleaseForm.isMandatory'
                                )}`,
                              },
                              minLength: {
                                value: 10,
                                message: `${t(
                                  'content.appPublish.appReleaseForm.minimum'
                                )} 10 ${t(
                                  'content.appPublish.appReleaseForm.charactersRequired'
                                )}`,
                              },
                              pattern: {
                                value:
                                  language === 'en'
                                    ? Patterns.appMarketCard.commonDescriptionEN
                                    : Patterns.appMarketCard
                                        .commonDescriptionDE,
                                message: `${t(
                                  'content.appPublish.appReleaseForm.validCharactersIncludes'
                                )} ${
                                  language === 'en'
                                    ? 'a-zA-Z0-9 !?@&#\'"()_-=/*.,;:'
                                    : 'a-zA-ZÀ-ÿ0-9 !?@&#\'"()_-=/*.,;:'
                                }`,
                              },
                              maxLength: {
                                value: 110,
                                message: `${t(
                                  'content.appPublish.appReleaseForm.maximum'
                                )} 110 ${t(
                                  'content.appPublish.appReleaseForm.charactersAllowed'
                                )}`,
                              },
                            },
                          }}
                        />
                        {errors &&
                          errors?.descriptions?.[index]?.[language]
                            ?.shortDescription?.type === 'required' && (
                            <Typography
                              variant="body2"
                              className="file-error-msg"
                              align="left"
                            >
                              {t(
                                `content.servicePublish.serviceOverview.shortDescription${language}`
                              )}{' '}
                              {t(
                                'content.appPublish.appReleaseForm.isMandatory'
                              )}
                            </Typography>
                          )}
                        {errors &&
                          errors?.descriptions?.[index]?.[language]
                            ?.shortDescription?.type === 'minLength' && (
                            <Typography
                              variant="body2"
                              className="file-error-msg"
                              align="left"
                            >
                              {t('content.appPublish.appReleaseForm.minimum')}{' '}
                              10
                              {t(
                                'content.appPublish.appReleaseForm.charactersRequired'
                              )}
                            </Typography>
                          )}
                        {errors &&
                          errors?.descriptions?.[index]?.[language]
                            ?.shortDescription?.type === 'maxLength' && (
                            <Typography
                              variant="body2"
                              className="file-error-msg"
                              align="left"
                            >
                              {t('content.appPublish.appReleaseForm.maximum')}{' '}
                              110
                              {t(
                                'content.appPublish.appReleaseForm.charactersAllowed'
                              )}
                            </Typography>
                          )}
                        <Typography
                          variant="body2"
                          className="form-field"
                          align="right"
                          style={{
                            fontWeight: 600,
                          }}
                        >
                          {getValues().descriptions[index]?.[language]
                            ?.shortDescription.length + '/110'}
                        </Typography>
                      </div>
                    )
                  })}
                  <div className="form-field">
                    <InputLabel
                      id="demo-multiple-name-label"
                      onClick={() => {
                        setVisibleDropdown(!visibleDropdown)
                      }}
                    >
                      <strong
                        style={{
                          color: visibleDropdown ? '#E34C92' : '#4D4D4F',
                        }}
                      >
                        {t(
                          'content.servicePublish.serviceOverview.addShortDescOtherLang'
                        )}
                      </strong>
                    </InputLabel>
                  </div>
                  <FormControl sx={{ m: 1, width: '100%' }}>
                    {visibleDropdown && (
                      <Autocomplete
                        multiple
                        fullWidth
                        id="fixed-tags-demo"
                        value={selectedLanguage}
                        onChange={(event, newValue) => {
                          setSelectedLanguage([
                            ...fixedLanguage,
                            ...newValue.filter(
                              (eachLan) => fixedLanguage.indexOf(eachLan) === -1
                            ),
                          ])
                          LANGUAGE_SUPPORTED.filter((lang, index) =>
                            newValue.every((newVal) => newVal !== lang)
                          ).forEach((language) => {
                            const index = LANGUAGE_SUPPORTED.indexOf(language)
                            resetField(
                              `descriptions.${index}.${language}.shortDescription`
                            )
                            setValue(
                              `descriptions.${index}.${language}.shortDescription`,
                              ''
                            )
                          })
                        }}
                        options={LANGUAGE_SUPPORTED}
                        getOptionLabel={(option) => option}
                        renderTags={(tagValue, getTagProps) => {
                          return tagValue.map((option, index) => (
                            <Chip
                              label={option}
                              size="small"
                              // color="primary"
                              sx={{ color: '#D31184' }}
                              {...getTagProps({ index })}
                              disabled={fixedLanguage.indexOf(option) !== -1}
                              deleteIcon={<CloseIcon />}
                            />
                          ))
                        }}
                        style={{ width: '100%' }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder={t(
                              'content.servicePublish.avalibleLanguages'
                            )}
                            variant="standard"
                          />
                        )}
                      />
                    )}
                  </FormControl>
                  <div className="form-field">
                    <InputLabel>
                      <strong
                        style={{
                          color: errors?.serviceTypeIds ? '#c02424' : 'inherit',
                        }}
                      >
                        {t(
                          'content.servicePublish.serviceOverview.serviceType'
                        ) + ' *'}
                      </strong>
                    </InputLabel>
                    <MultiSelectDropdown
                      control={control}
                      trigger={trigger}
                      name={'serviceTypeIds'}
                      rules={{
                        required: {
                          value: true,
                          message: t(
                            'content.servicePublish.serviceOverview.serviceTypeMandatory'
                          ),
                        },
                      }}
                      setValue={setValue}
                      setError={setError}
                      helperTextMessage={t(
                        'content.servicePublish.serviceOverview.serviceTypeMandatory'
                      )}
                      placeholder={t(
                        'content.servicePublish.serviceOverview.serviceTypePlaceholder'
                      )}
                      isRequired={true}
                      getMultiselectData={collectServiceType}
                      options={serviceTypeNameListItems}
                      defaultValues={defaultValues?.serviceTypeIds}
                    />
                  </div>
                  <div className="form-field">
                    <InputLabel>
                      <strong
                        style={{
                          color: errors?.useCases ? '#c02424' : 'inherit',
                        }}
                      >
                        {t('content.servicePublish.serviceOverview.useCases') +
                          ' *'}
                      </strong>
                    </InputLabel>
                    <MultiSelectDropdown
                      control={control}
                      trigger={trigger}
                      name={'useCases'}
                      rules={{
                        required: {
                          value: true,
                          message: t(
                            'content.appPublish.appMarketCard.useCasesMandatory'
                          ),
                        },
                      }}
                      setValue={setValue}
                      setError={setError}
                      helperTextMessage={t(
                        'content.appPublish.appMarketCard.useCasesMandatory'
                      )}
                      placeholder={t(
                        'content.servicePublish.serviceOverview.useCasePlaceholder'
                      )}
                      isRequired={true}
                      getMultiselectData={collectUseCases}
                      options={userCaseNameListItems}
                      defaultValues={defaultValues?.useCases}
                    />
                  </div>
                  <div className="form-field">
                    <InputLabel sx={{ marginTop: 3 }}>
                      <strong
                        style={{
                          color: errors?.keywords ? '#c02424' : 'inherit',
                        }}
                      >
                        {t('content.servicePublish.serviceOverview.keyWords') +
                          ' *'}
                      </strong>
                    </InputLabel>
                    <KeyFeatureControlledTag
                      name="keywords"
                      control={control}
                      placeholder={t(
                        'content.servicePublish.serviceOverview.keywordPlaceHolder'
                      )}
                      defaultValues={defaultValues?.keywords}
                      rules={{
                        required: {
                          value: true,
                          message: `${t(
                            'content.appPublish.appMarketCard.keyWordsMandatory'
                          )}`,
                        },
                      }}
                    />
                    {!errors?.keywords ? (
                      <Typography sx={{ fontSize: '12px' }}>
                        {t('content.appPublish.appMarketCard.keywordsLabel')}
                      </Typography>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className="form-field">
                    <InputLabel sx={{ mb: 3, mt: 3, display: 'flex' }}>
                      <strong
                        style={{
                          color: errors?.coverImage ? '#c02424' : 'inherit',
                        }}
                      >
                        {t(
                          'content.servicePublish.serviceOverview.serviceCoverImage'
                        ) + ' *'}
                      </strong>
                      <Tooltip
                        title={`${t(
                          'content.servicePublish.serviceOverview.appCoverImageItxt'
                        )}`}
                        arrow
                      >
                        <IconButton
                          sx={{ color: '#939393', marginLeft: 'auto' }}
                          size="small"
                        >
                          <InfoOutlined />
                        </IconButton>
                      </Tooltip>
                    </InputLabel>
                    <ConnectorFormInputField
                      {...{
                        control,
                        trigger,
                        errors,
                        name: 'coverImage',
                        handleFileDetails,
                        type: 'dropzone',
                        shouldCrop: true,
                        acceptFormat: {
                          'image/png': [],
                          'image/jpeg': [],
                        },
                        maxFilesToUpload: 1,
                        maxFileSize: SIZE_LIMITS.FILESIZE_1MB,
                        rules: {
                          required: {
                            value: true,
                          },
                        },
                      }}
                      handleDelete={async (documentId: string) => {
                        if (documentId !== N_ID.ID && documentId.length > 0) {
                          await deleteDocument(documentId)
                            .unwrap()
                            .then((res) => {
                              setCardImage(PageHeaderImg)
                              setValue('coverImage', null)
                            })
                            .catch((error) => {
                              console.log('Somthing went wrong')
                            })
                        } else {
                          setCardImage(PageHeaderImg)
                          setValue('coverImage', null)
                        }
                      }}
                    />
                    {errors?.coverImage?.type === 'required' && (
                      <Typography variant="body2" className="file-error-msg">
                        {t(
                          'content.appPublish.appReleaseForm.fileUploadIsMandatory'
                        )}
                      </Typography>
                    )}
                  </div>
                  <ImageDialog
                    saveChanges={(event: Event) => {
                      onDownloadCropClick(event)
                    }}
                    open={openCropDialog}
                    handleOpenDialog={handleDialog}
                  >
                    <div className="form-field">
                      <ReactCrop
                        crop={imageState}
                        locked
                        minHeight={200}
                        minWidth={320}
                        ruleOfThirds
                        onChange={(_, percentCrop) => {
                          setImageState(percentCrop)
                        }}
                        onComplete={(c) => {
                          setCompletedCropedImage(c)
                        }}
                        aspect={aspect}
                      >
                        <img
                          ref={imgRef}
                          crossOrigin="anonymous"
                          src={cardImage}
                          alt={'ServiceImage'}
                          onLoad={onImageLoad}
                          style={{
                            objectFit: 'contain',
                          }}
                        />
                      </ReactCrop>
                    </div>
                    {!!completedCropedImage && (
                      <>
                        <div>
                          <canvas
                            ref={previewCanvasRef}
                            style={{
                              border: '1px solid black',
                              objectFit: 'contain',
                              width: completedCropedImage.width,
                              height: completedCropedImage.height,
                              visibility: 'hidden',
                              maxHeight: 0,
                            }}
                          />
                        </div>
                        <div>
                          <a
                            ref={hiddenAnchorRef}
                            href="#"
                            download
                            style={{
                              position: 'absolute',
                              top: '-200vh',
                              visibility: 'hidden',
                            }}
                          >
                            Hidden download
                          </a>
                        </div>
                      </>
                    )}
                  </ImageDialog>
                </form>
              </div>
            </Grid>
          </div>
        </Box>
        <Box
          style={{
            padding: '5% 2% 5% 2%',
            borderBottomLeftRadius: '30px',
            borderBottomRightRadius: '30px',
            background: 'rgba(250, 250, 250, 1)',
          }}
        >
          {appCardNotification && (
            <Grid container xs={12} sx={{ mb: 2 }}>
              <Grid xs={6}></Grid>
              <Grid xs={6}>
                <PageNotifications
                  title={t('content.appPublish.appReleaseForm.error.title')}
                  description={t(
                    'content.appPublish.appReleaseForm.error.message'
                  )}
                  open
                  severity="error"
                  onCloseNotification={() => {
                    setAppCardNotification(false)
                  }}
                />
              </Grid>
            </Grid>
          )}
          <PageSnackbar
            open={appCardSnackbar}
            onCloseNotification={() => {
              setAppCardSnackbar(false)
            }}
            severity="success"
            description={t(
              'content.appPublish.appReleaseForm.dataSavedSuccessMessage'
            )}
            autoClose={true}
          />

          <Box textAlign={'center'}>
            <Typography variant="caption2">
              {t('content.servicePublish.footerText')}{' '}
              <strong>
                <a
                  href={COFINITY_SERVICE_ONBOARDING_DOCUMENTATION_URL}
                  target="_blank"
                  rel="noreferrer"
                >
                  {t('content.servicePublish.footerTextLink')}
                </a>
              </strong>
            </Typography>
          </Box>
          <Box textAlign="center" m={'32px'}>
            <Button
              variant="outlined"
              disabled={
                createStatus === QueryStatus.pending ||
                updateStatus === QueryStatus.pending
              }
              sx={{ mr: 2 }}
              onClick={handleSubmit((data) => onSubmit(data, 'save'))}
            >
              {t('content.appPublish.footerButtons.save')}
            </Button>
            <Button
              variant="contained"
              name="send"
              disabled={
                createStatus === QueryStatus.pending ||
                updateStatus === QueryStatus.pending
              }
              onClick={handleSubmit((data) => onSubmit(data, 'saveAndProceed'))}
            >
              {t('content.appPublish.footerButtons.saveAndProceed')}
            </Button>
          </Box>
        </Box>
        <Backdrop
          sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={
            createStatus === QueryStatus.pending ||
            updateStatus === QueryStatus.pending ||
            uploadDocumentStatus === QueryStatus.pending
          }
        >
          <CircularProgress color="primary" />
        </Backdrop>
      </Paper>
    )
  }
}
export default ServiceOverview
