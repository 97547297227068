import React, { useState } from 'react'
import { type AppStatusDataState } from 'features/Custom/appOnboard/types'
import { type CustomtableType } from '../type'
import RendertTable from '../RenderTable'
import { AttachFile } from '@mui/icons-material'
import { InputLabel } from '@mui/material'
import { download } from 'utils/downloadUtils'
import {
  Button,
  Dialog,
  DialogContent,
  DialogHeader,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import ImageViewer from './ImageViewer'
import { useFetchDocumentByIdMutation } from 'features/apps/apiSlice'
import { useFetchDocumentByTypeMutation } from 'features/Custom/appOnboard/apiSlice'
import {
  CONTROLLERS,
  OFFERTYPES,
  type ServiceStatusDataState,
} from 'features/Custom/serviceOnboard/types'
import { useTranslation } from 'react-i18next'

export const DescriptionAndFeatures = (props: {
  appStatusData: AppStatusDataState | ServiceStatusDataState
  offerTypeId: string
}) => {
  const { t } = useTranslation()
  const [imagePreview, setImagePreview] = useState<string>('')
  const [loaded, setLoaded] = useState<boolean>(false)
  const [fetchDocumentByType] = useFetchDocumentByTypeMutation()
  const [getDocumentById] = useFetchDocumentByIdMutation()
  const handleImageDownload = async (
    id: string,
    documentName: string,
    documentId: string
  ) => {
    try {
      const response = await fetchDocumentByType({
        id,
        documentId,
        controller:
          props.offerTypeId === OFFERTYPES.APP
            ? CONTROLLERS.APP_ONBOARD
            : CONTROLLERS.SERVICE_ONBOARD,
        offerTypeId: props.offerTypeId,
      }).unwrap()
      const file = response.data
      download(file, '.pdf', documentName)
    } catch (error) {
      console.error(error, 'ERROR WHILE FETCHING DOCUMENT')
    }
  }
  const handleView = async (
    id: string,
    documentName: string,
    documentId: string
  ) => {
    try {
      const response = await fetchDocumentByType({
        id,
        documentId,
        controller:
          props.offerTypeId === OFFERTYPES.APP
            ? CONTROLLERS.APP_ONBOARD
            : CONTROLLERS.SERVICE_ONBOARD,
        offerTypeId: props.offerTypeId,
      }).unwrap()
      const file = response.data
      const blob = new Blob([file], { type: 'image/*' })
      const blobURL = URL.createObjectURL(blob)
      setImagePreview(blobURL)
    } catch (error) {
      console.error(error, 'ERROR WHILE FETCHING DOCUMENT')
    }
  }
  const LogoData: CustomtableType = {
    head: [`${t('content.submissionOverlay.descriptionFeature.logo')}`, ''],
    body: props.appStatusData?.documents
      .filter((eachDoc: any) => eachDoc.documentTypeId === 'APP_LOGO')
      ?.map((eachDoc: any) => [
        [
          <React.Fragment>
            <div
              style={{
                display: 'flex',
              }}
            >
              <AttachFile htmlColor="#2484C6" />
              <strong
                style={{
                  color: '#2484C6',
                }}
              >
                {eachDoc?.documentName ??
                  `${t(
                    'content.submissionOverlay.descriptionFeature.noDocumentFound'
                  )}`}
              </strong>
            </div>
          </React.Fragment>,
        ],
        [
          <React.Fragment>
            <Button
              sx={{ padding: '.2rem 1rem' }}
              variant="main"
              size="small"
              onClick={() => {
                handleImageDownload(
                  props.appStatusData.id,
                  eachDoc.documentName,
                  eachDoc.documentId
                )
              }}
            >
              {t('content.submissionOverlay.downloadBtn')}
            </Button>
            <Button
              sx={{ padding: '.2rem 1rem', marginLeft: 2 }}
              variant="main"
              size="small"
              onClick={() => {
                handleView(
                  props.appStatusData.id,
                  eachDoc.documentName,
                  eachDoc.documentId
                )
                setLoaded(true)
              }}
            >
              {t('content.submissionOverlay.viewBtn')}
            </Button>
          </React.Fragment>,
        ],
      ]),
  }
  const longDescriptionData: CustomtableType = {
    head: [
      `${t('content.submissionOverlay.descriptionFeature.longDescription')}`,
      '',
    ],
    body: [
      [
        `${
          props.appStatusData?.longDescription?.filter(
            (lan: any) => lan?.languageCode === 'en'
          )[0]?.longDescription ??
          `${t('content.submissionOverlay.descriptionFeature.noDescription')}`
        }`,
      ],
    ],
  }
  const highlightedImagesData: CustomtableType = {
    head: [
      `${t('content.submissionOverlay.descriptionFeature.highlightedImage')}`,
      '',
    ],
    body: props.appStatusData?.documents
      ?.filter(
        (eachDoc: any) => eachDoc?.documentTypeId === 'APP_HIGHLIGHTED_IMAGE'
      )
      .map((eachDoc) => [
        [
          <React.Fragment>
            <div
              style={{
                display: 'flex',
              }}
            >
              <AttachFile htmlColor="#2484C6" />
              <strong
                style={{
                  color: '#2484C6',
                }}
              >
                {eachDoc?.documentName ??
                  `${t(
                    'content.submissionOverlay.descriptionFeature.noDocumentFound'
                  )}`}
              </strong>
            </div>
          </React.Fragment>,
        ],
        [
          <React.Fragment>
            <Button
              sx={{ padding: '.2rem 1rem' }}
              variant="main"
              size="small"
              onClick={() => {
                handleImageDownload(
                  props.appStatusData.id,
                  eachDoc.documentName,
                  eachDoc.documentId
                )
              }}
            >
              {t('content.submissionOverlay.downloadBtn')}
            </Button>
            <Button
              sx={{ padding: '.2rem 1rem', marginLeft: 2 }}
              variant="main"
              size="small"
              onClick={() => {
                handleView(
                  props.appStatusData.id,
                  eachDoc.documentName,
                  eachDoc.documentId
                )
                setLoaded(true)
              }}
            >
              {t('content.submissionOverlay.viewBtn')}
            </Button>
          </React.Fragment>,
        ],
      ]) ?? [`${t('content.submissionOverlay.descriptionFeature.noImage')}`],
  }

  const featuresSummaryListData: CustomtableType = {
    head: [
      props.offerTypeId === OFFERTYPES.APP
        ? `${t(
            'content.submissionOverlay.descriptionFeature.app.featuresSummary'
          )}`
        : `${t(
            'content.submissionOverlay.descriptionFeature.service.benefitsSummary'
          )}`,
    ],
    body: [
      [
        props.appStatusData?.feature[0]?.localizedSummary?.values?.filter(
          (eachLan: any) => eachLan.langCode === 'en'
        )[0].value ?? props.appStatusData?.feature[0]?.summary,
        // this Change is For Older Published Apps
      ],
    ],
  }

  const KeyfeaturesListData: CustomtableType = {
    head: [
      props.offerTypeId === OFFERTYPES.APP
        ? `${t(
            'content.submissionOverlay.descriptionFeature.app.keyFeatureTitle'
          )}`
        : `${t(
            'content.submissionOverlay.descriptionFeature.service.keyBenefitTitle'
          )}`,
      `${t('content.submissionOverlay.descriptionFeature.shortDescription')}`,
    ],
    body: props.appStatusData?.feature[0]?.keyFeatures?.map(
      (eachKeyFeature: any) => [
        <InputLabel>
          <strong> {eachKeyFeature?.title}</strong>
        </InputLabel>,
        `${
          eachKeyFeature?.shortDescription ??
          `${t(
            'content.submissionOverlay.descriptionFeature.noShortDescription'
          )}`
        }`,
      ]
    ),
  }

  const ImagesData: CustomtableType = {
    head: [`${t('content.submissionOverlay.descriptionFeature.image')}`, ''],
    body: props.appStatusData?.documents
      ?.filter((eachDoc: any) => eachDoc?.documentTypeId === 'APP_IMAGE')
      ?.map((eachDoc) => [
        [
          <React.Fragment>
            <div
              style={{
                display: 'flex',
              }}
            >
              <AttachFile htmlColor="#2484C6" />
              <strong
                style={{
                  color: '#2484C6',
                }}
              >
                {eachDoc?.documentName ?? (
                  <strong
                    style={{
                      color: 'red',
                    }}
                  >
                    {t(
                      'content.submissionOverlay.descriptionFeature.noDocumentFound'
                    )}
                  </strong>
                )}
              </strong>
            </div>
          </React.Fragment>,
        ],
        [
          <React.Fragment>
            <Button
              sx={{ padding: '.2rem 1rem' }}
              variant="main"
              size="small"
              onClick={() => {
                handleImageDownload(
                  props.appStatusData.id,
                  eachDoc.documentName,
                  eachDoc.documentId
                )
              }}
            >
              {t('content.submissionOverlay.downloadBtn')}
            </Button>
            <Button
              sx={{ padding: '.2rem 1rem', marginLeft: 2 }}
              variant="main"
              size="small"
              onClick={() => {
                handleView(
                  props.appStatusData.id,
                  eachDoc.documentName,
                  eachDoc.documentId
                )
                setLoaded(true)
              }}
            >
              {t('content.submissionOverlay.viewBtn')}
            </Button>
          </React.Fragment>,
        ],
      ]) ?? [
      <strong
        style={{
          color: 'red',
        }}
      >
        {t('content.submissionOverlay.descriptionFeature.noImage')}
      </strong>,
    ],
  }

  const videoLink: CustomtableType = {
    head: [
      `${t('content.submissionOverlay.descriptionFeature.videoLink')}`,
      '',
    ],
    body: [
      [
        <a
          href={`https://${
            props.appStatusData?.feature[0]?.videoLink &&
            props.appStatusData?.feature[0]?.videoLink?.split('https://')
              ?.length > 1
              ? props.appStatusData?.feature[0]?.videoLink?.split('https://')[1]
              : props.appStatusData?.feature[0]?.videoLink ?? '#'
          }`}
          target="_blank"
          rel="noreferrer"
        >
          <strong
            style={{
              color: '#2484C6',
            }}
          >
            {props.appStatusData?.feature[0]?.videoLink ?? (
              <strong
                style={{
                  color: 'red',
                }}
              >
                {t(
                  'content.submissionOverlay.descriptionFeature.videoNotFound'
                )}
              </strong>
            )}
          </strong>
        </a>,
      ],
    ],
  }

  return (
    <div className="appdetail-privacy">
      <div className="appdetail-privacy-table">
        <Dialog
          open={loaded}
          additionalModalRootStyles={{
            maxWidth: '100vw',
            width: '1004px',
          }}
          scroll="paper"
        >
          <DialogHeader
            wrapperStyle={{
              paddingTop: 5,
            }}
            closeWithIcon={true}
            onCloseWithIcon={() => {
              setLoaded(false)
              setImagePreview('')
            }}
          />
          <DialogContent
            sx={{
              padding: '0px 63px 40px 63px',
              maxHeight: '672px',
            }}
          >
            <ImageViewer file={imagePreview} />
          </DialogContent>
        </Dialog>

        <RendertTable key={'LogoData'} data={LogoData || []} />
        <RendertTable
          key={'longDescriptionData'}
          data={longDescriptionData || []}
        />
        <RendertTable
          key={'highlightedImagesData'}
          data={highlightedImagesData || []}
        />
        <RendertTable
          key={'featuresSummaryListData'}
          data={featuresSummaryListData || []}
        />
        <RendertTable
          key={'KeyfeaturesListData'}
          data={KeyfeaturesListData || []}
        />
        <RendertTable key={'ImagesData'} data={ImagesData || []} />
        <RendertTable key={'VideoLink'} data={videoLink || []} />
      </div>
    </div>
  )
}
