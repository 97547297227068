import { type AppStatusDataState } from 'features/Custom/appOnboard/types'
import RendertTable from '../RenderTable'
import React from 'react'
import { type CustomtableType } from '../type'
import {
  OFFERTYPES,
  type ServiceStatusDataState,
} from 'features/Custom/serviceOnboard/types'
import { useTranslation } from 'react-i18next'

const Pricing = (props: {
  appStatusData: AppStatusDataState | ServiceStatusDataState
  offerTypeId: string
}) => {
  const { t } = useTranslation()
  const PricingOverviewData: CustomtableType = {
    head: [`${t('content.submissionOverlay.pricing.pricingOverview')}`, ''],
    body: [
      [
        `${t('content.submissionOverlay.pricing.freeTrial')}`,
        <strong
          style={{
            color: props.appStatusData?.pricingDetail?.freeTrial
              ? 'green'
              : 'grey',
          }}
        >
          {props.appStatusData?.pricingDetail?.freeTrial === true
            ? 'Yes'
            : 'No'}
        </strong>,
      ],
      [
        `${t('content.submissionOverlay.pricing.freeVersion')}`,
        <strong
          style={{
            color: props.appStatusData?.pricingDetail?.freeVersion
              ? 'green'
              : 'grey',
          }}
        >
          {props.appStatusData?.pricingDetail?.freeVersion === true
            ? 'Yes'
            : 'No'}
        </strong>,
      ],
      [
        `${t('content.submissionOverlay.pricing.pricingPolicyWebpage')}`,
        <a
          href={`https://${
            props.appStatusData?.pricingDetail?.url &&
            props.appStatusData?.pricingDetail?.url?.split('https://')?.length >
              1
              ? props.appStatusData?.pricingDetail?.url?.split('https://')[1]
              : props.appStatusData?.pricingDetail?.url ?? '#'
          }`}
          target="_blank"
          rel="noreferrer"
          style={{
            color: '#2484C6',
            fontWeight: '600',
          }}
        >
          {props.appStatusData?.pricingDetail?.url ?? (
            <strong
              style={{
                color: 'red',
              }}
            >
              {t(
                'content.submissionOverlay.pricing.privacyPolicyWebpageNotFound'
              )}
            </strong>
          )}
        </a>,
      ],
    ],
  }
  const PricingDetailData: CustomtableType = {
    head: [`${t('content.submissionOverlay.pricing.pricingDetails')}`, ''],
    body: [
      [
        props.appStatusData?.pricingDetail?.localizedSummary?.values?.filter(
          (eachLan: any) => eachLan.langCode === 'en'
        )[0].value ?? (
          <strong
            style={{
              color: 'red',
            }}
          >
            {t('content.submissionOverlay.pricing.noShortDescription')}
          </strong>
        ),
      ],
    ],
  }

  return (
    <div className="appdetail-privacy">
      <div className="appdetail-privacy-table">
        {props.offerTypeId === OFFERTYPES.APP ? (
          <RendertTable
            key={'PricingOverviewData'}
            data={PricingOverviewData || []}
          />
        ) : null}
        <RendertTable
          key={'PricingDetailData'}
          data={PricingDetailData || []}
        />
        <div
          style={{
            marginTop: 20,
          }}
        >
          {props.appStatusData?.pricingDetail?.plans.map((eachPlan: any) => (
            <RendertTable
              key={`Plan Details (${eachPlan.title ?? ''})`}
              data={{
                head: [
                  `${t('content.submissionOverlay.pricing.planDetails')} (${
                    eachPlan.title ?? ''
                  })`,
                  '',
                ],
                body:
                  props.offerTypeId === OFFERTYPES.APP
                    ? [
                        [
                          `${t('content.submissionOverlay.pricing.plan')}`,
                          <strong>
                            {eachPlan.title ?? (
                              <strong
                                style={{
                                  color: 'red',
                                }}
                              >
                                {t(
                                  'content.submissionOverlay.pricing.noPlanTitle'
                                )}
                              </strong>
                            )}
                          </strong>,
                        ],
                        [
                          `${
                            eachPlan?.customPricingType === true
                              ? t(
                                  'content.submissionOverlay.pricing.contactName'
                                )
                              : t('content.submissionOverlay.pricing.price')
                          }`,
                          <strong>
                            {eachPlan?.customPricingType === true
                              ? eachPlan?.salesContactName
                              : eachPlan?.amount}
                          </strong>,
                        ],
                        [
                          `${
                            eachPlan?.customPricingType === true
                              ? t(
                                  'content.submissionOverlay.pricing.contactEmail'
                                )
                              : t(
                                  'content.submissionOverlay.pricing.pricingModel'
                                )
                          }`,
                          <strong>
                            {eachPlan?.customPricingType === true
                              ? eachPlan?.salesContactEmail
                              : eachPlan?.model ?? '-'}
                          </strong>,
                        ],
                        [
                          `${
                            eachPlan?.customPricingType === true
                              ? t(
                                  'content.submissionOverlay.pricing.contactTelephone'
                                )
                              : t(
                                  'content.submissionOverlay.pricing.pricingFrequency'
                                )
                          }`,
                          <strong>
                            {eachPlan?.customPricingType === true
                              ? eachPlan?.salesContactPhone
                              : eachPlan?.frequency}
                          </strong>,
                        ],
                        [
                          `${t(
                            'content.submissionOverlay.pricing.descriptionPricingPlan'
                          )}`,
                          <strong>
                            {eachPlan?.localizedSummary?.values?.filter(
                              (eachLan: any) => eachLan.langCode === 'en'
                            )[0].value ?? (
                              <strong
                                style={{
                                  color: 'red',
                                }}
                              >
                                {t(
                                  'content.submissionOverlay.pricing.noShortDescription'
                                )}
                              </strong>
                            )}
                          </strong>,
                        ],
                        [
                          `${
                            eachPlan?.customPricingType === true
                              ? ''
                              : t(
                                  'content.submissionOverlay.pricing.keyFeaturesPricingPlan'
                                )
                          }`,
                          <strong>
                            {eachPlan?.customPricingType === false &&
                              (eachPlan?.planFeatures?.map(
                                (eachLan: any) =>
                                  eachLan?.localizedSummary?.values?.filter(
                                    (each: any) => each.langCode === 'en'
                                  )[0]?.value
                              ) ?? (
                                <strong
                                  style={{
                                    color: 'red',
                                  }}
                                >
                                  {t(
                                    'content.submissionOverlay.pricing.noKeyFeaturesProvided'
                                  )}
                                </strong>
                              ))}
                          </strong>,
                        ],
                      ]
                    : [
                        [
                          `${t('content.submissionOverlay.pricing.plan')}`,
                          <strong>
                            {eachPlan.title ?? (
                              <strong
                                style={{
                                  color: 'red',
                                }}
                              >
                                {t(
                                  'content.submissionOverlay.pricing.noPlanTitle'
                                )}
                              </strong>
                            )}
                          </strong>,
                        ],
                        [
                          `${
                            eachPlan?.customPricingType === true
                              ? t(
                                  'content.submissionOverlay.pricing.contactName'
                                )
                              : t('content.submissionOverlay.pricing.price')
                          }`,
                          <strong>
                            {eachPlan?.customPricingType === true
                              ? eachPlan?.salesContactName
                              : eachPlan?.amount}
                          </strong>,
                        ],
                        [
                          `${
                            eachPlan?.customPricingType === true
                              ? t(
                                  'content.submissionOverlay.pricing.contactEmail'
                                )
                              : t(
                                  'content.submissionOverlay.pricing.pricingModel'
                                )
                          }`,
                          <strong>
                            {eachPlan?.customPricingType === true
                              ? eachPlan?.salesContactEmail
                              : eachPlan?.model ?? '-'}
                          </strong>,
                        ],
                        eachPlan?.customPricingType === true
                          ? [
                              `${t(
                                'content.submissionOverlay.pricing.contactTelephone'
                              )}`,
                              <strong>{eachPlan?.salesContactPhone}</strong>,
                            ]
                          : [],
                        [
                          `${t(
                            'content.submissionOverlay.pricing.descriptionPricingPlan'
                          )}`,
                          <strong>
                            {eachPlan?.localizedSummary?.values?.filter(
                              (eachLan: any) => eachLan.langCode === 'en'
                            )[0].value ?? (
                              <strong
                                style={{
                                  color: 'red',
                                }}
                              >
                                {t(
                                  'content.submissionOverlay.pricing.noShortDescription'
                                )}
                              </strong>
                            )}
                          </strong>,
                        ],
                        [
                          `${
                            eachPlan?.customPricingType === true
                              ? ''
                              : t(
                                  'content.submissionOverlay.pricing.keyFeaturesPricingPlan'
                                )
                          }`,
                          <strong>
                            {eachPlan?.customPricingType === false &&
                              (eachPlan?.planFeatures?.map(
                                (eachLan: any) =>
                                  eachLan?.localizedSummary?.values?.filter(
                                    (each: any) => each.langCode === 'en'
                                  )[0]?.value
                              ) ?? (
                                <strong
                                  style={{
                                    color: 'red',
                                  }}
                                >
                                  {t(
                                    'content.submissionOverlay.pricing.noKeyFeaturesProvided'
                                  )}
                                </strong>
                              ))}
                          </strong>,
                        ],
                      ],
              }}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default Pricing
