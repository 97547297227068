import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'
import {
  ArrowUpGraphic,
  Box,
  EuroIcon,
  Grid,
  TickIcon,
  Typography,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import { useTranslation } from 'react-i18next'
// import { useAppPlansQuery, useAppPricingQuery } from 'features/apps/apiSlice'
import { type AppDetails } from 'features/apps/details/types'

import CommonService from 'services/CommonService'
import { LABEL_IDS, OBSERVER_OPTIONS } from '../types'
import './AppDetailPricing.scss'
import PricingDialog from './PricingDialog'
import i18next from 'i18next'
import { type PlansType } from 'features/apps/apiSlice'

function AppDetailPricing({
  data,
  pricingDetail,
  isLoading,
  error,
  item,
  handleSetSelectedTab,
}: {
  data: any
  pricingDetail: any
  isLoading: any
  error: any
  item: AppDetails
  handleSetSelectedTab: (tab: string) => void
}) {
  const { inView, ref } = useInView(OBSERVER_OPTIONS)
  const [openPriceDialog, setOpenPriceDialog] = useState(false)
  // const { data } = useAppPricingQuery(item.id ?? '')
  // const {
  //   data: pricingDetail,
  //   isLoading,
  //   error,
  // } = useAppPlansQuery(item.id ?? '')
  const { t } = useTranslation()

  useEffect(() => {
    if (inView) {
      handleSetSelectedTab(LABEL_IDS.APPDETAIL_PRICING)
    }
  }, [inView])

  if (!data) {
    return null
  }

  return (
    <>
      <PricingDialog
        id={item.id}
        open={openPriceDialog}
        onClose={() => { setOpenPriceDialog(false) }}
      />

      <div
        ref={ref}
        className="appdetail-pricing"
        id={LABEL_IDS.APPDETAIL_PRICING}
      >
        <Typography
          marginBottom="1rem"
          fontSize="1.25rem"
          fontWeight="600"
          color="#000"
        >
          {t('content.appdetail.pricing.title')}
        </Typography>

        <Grid container spacing={2}>
          <Grid item sm={12} md={3}>
            <Grid container>
              <Grid item sm={12} md={5}>
                <Typography fontSize=".875rem" color="#4D4D4F" fontWeight="500">
                  {t('content.appdetail.pricing.startingFrom')}:
                </Typography>
                <Typography
                  fontSize=".875rem"
                  color="#000"
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                  fontWeight="500"
                  gap="5px"
                  flexDirection="row"
                >
                  {pricingDetail?.plans?.filter(
                    (plans: PlansType) => plans?.customPricingType
                  ).length <= 0 ? (
                    <EuroIcon fill="#4D4D4F" />
                  ) : null}
                  <span>
                    {pricingDetail?.plans?.filter(
                      (plans: PlansType) => plans?.customPricingType
                    )?.length ? (
                      <strong>
                        {t('content.appPublish.pricing.customPricing')}
                      </strong>
                    ) : (
                      CommonService.convertPriceToEuroFormat(
                        pricingDetail?.plans
                          ?.filter((plan: PlansType) => plan?.amount !== '')
                          ?.sort(
                            (a: PlansType, b: PlansType) =>
                              parseInt(a?.amount) - parseInt(b?.amount)
                          )[0]?.amount ?? 0
                      )
                    )}
                  </span>
                </Typography>
              </Grid>

              <Grid item sm={12} md={5}>
                <Typography fontSize=".875rem" color="#4D4D4F" fontWeight="500">
                  {t('content.appdetail.pricing.pricingModel')}:
                </Typography>
                <Typography fontSize=".875rem" color="#000" fontWeight="500">
                  {pricingDetail?.plans?.filter(
                    (plans: PlansType) => plans?.customPricingType
                  ).length > 0
                    ? t('content.partnernetwork.filters.others')
                    : pricingDetail?.plans
                        ?.filter((plan: PlansType) => plan.amount !== '')
                        .sort(
                          (a: PlansType, b: PlansType) =>
                            parseInt(a?.amount) - parseInt(b?.amount)
                        )[0]?.model}
                </Typography>
              </Grid>
            </Grid>

            <Grid container marginY="1rem">
              <Grid sm={12} item>
                <a
                  href={`https://${
                    data.weblink && data.weblink?.split('https://')?.length > 1
                      ? data.weblink?.split('https://')[1]
                      : data.weblink ?? '#'
                  }`}
                  target="_blank"
                  rel="noreferrer"
                  className="appdetail-pricing-vendor-link"
                >
                  {t('content.appdetail.pricing.vendorWebsite')}
                  <span>
                    <ArrowUpGraphic />
                  </span>
                </a>
              </Grid>
            </Grid>

            <Grid container>
              {data?.freeTrial && (
                <Grid sm={12} item display="flex" gap="5px" alignItems="center">
                  <TickIcon height={16} width={16} />
                  <Typography fontSize=".875rem" fontWeight={400} color="#000">
                    {t('content.appdetail.pricing.freeTrial')}
                  </Typography>
                </Grid>
              )}

              {data?.freeVersion && (
                <Grid sm={12} item display="flex" gap="5px" alignItems="center">
                  <TickIcon height={16} width={16} />
                  <Typography fontSize=".875rem" fontWeight={400} color="#000">
                    {t('content.appdetail.pricing.freeVersion')}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item sm={12} md={9}>
            <Typography
              marginBottom=".5rem"
              fontSize=".875rem"
              fontWeight={500}
              color="#000"
            >
              {t('content.appdetail.pricing.details')}
            </Typography>
            <Box
              marginBottom=".5rem"
              padding="1.5rem"
              sx={{ boxShadow: '0px 0px 8px #0000001A', borderRadius: '10px' }}
            >
              <Typography fontSize=".875rem" fontWeight={400} color="#000">
                {data?.description === ''
                  ? data?.localizedSummary.values?.filter(
                      (eachLang: { langCode: string }) =>
                        eachLang?.langCode === i18next.language
                    )?.[0]?.value ??
                    data?.localizedSummary.values?.filter(
                      (eachLang: { langCode: string }) =>
                        eachLang?.langCode === 'en'
                    )?.[0]?.value
                  : data?.description}
              </Typography>
            </Box>
            <Typography
              variant="button"
              onClick={() => { setOpenPriceDialog(true) }}
              color="#2484C6"
              fontSize="1rem"
              fontWeight="500"
              sx={{ cursor: 'pointer' }}
            >
              {t('content.appdetail.pricing.seeAllPricing')}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default AppDetailPricing
