import React from 'react'
import { useTranslation } from 'react-i18next'

function HMI() {
  const { t } = useTranslation()

  return (
    <div className="swiper-section">
      <div className="swiper-content">
        <h5>{t('content.appstore.appOverviewSection.releaseNote')}</h5>
        <h2 className="livenow">
          {t('content.appstore.appOverviewSection.weLive')}
        </h2>
        <h2
          dangerouslySetInnerHTML={{
            __html: t('content.appstore.appOverviewSection.hmi'),
          }}
        ></h2>
      </div>
    </div>
  )
}

export default HMI
