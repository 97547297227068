import AppDetailImage from 'components/pages/AppDetail/components/AppDetailImageGallery'

const ImageViewer = ({ file }: { file: string }) => {
  return file === '' ? (
    <span
      style={{
        padding: 10,
      }}
    >
      <span className="invite-loader" />
    </span>
  ) : (
    <AppDetailImage
      images={[
        {
          url: file,
          text: '',
        },
      ]}
    />
  )
}

export default ImageViewer
