import React, { useState } from 'react'
import {
  Box,
  Button,
  CircleProgress,
  CrossIconGraphic,
  Dialog,
  DialogContent,
  IconButton,
} from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import Loading from 'components/pages/AppPublish/OverView/Loading'
import './style.scss'
import { ClickAwayListener, Tooltip } from '@mui/material'
import { ReactComponent as IconCopy } from 'assets/images/icon-copy.svg'
import { t } from 'i18next'

function CredentialDetail({
  didText,
  open,
  onClose,
}: {
  didText: string
  open: boolean
  onClose: () => void
}) {
  // tooltop
  const [openTooltip, setOpenTooltip] = useState(false)
  const handleTooltipClose = () => {
    setOpenTooltip(false)
  }

  const handleTooltipOpen = (text: string) => {
    navigator.clipboard.writeText(text)
    setOpenTooltip(true)
  }

  return (
    <Dialog
      open={open}
      additionalModalRootStyles={{
        maxWidth: '100vw',
        width: '1004px',
      }}
      scroll="paper"
      onBackdropClick={onClose}
    >
      {didText.length <= 0 ? (
        <Box textAlign="center" height={200} width={200}>
          <span
            style={{
              padding: 10,
            }}
          >
            <span className="invite-loader" />
          </span>
        </Box>
      ) : (
        <DialogContent>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 16,
              top: 16,
            }}
          >
            <CrossIconGraphic height={20} width={20} />
          </IconButton>

          <div className="credential__box">
            <div className="credential__heading">
              {t(
                'content.managedIdentityWallet.companyWallet.credentialOverlay.didTitle'
              )}
            </div>
            <div className="credential__para">
              {t(
                'content.managedIdentityWallet.companyWallet.credentialOverlay.didDescription'
              )}
            </div>
            <div className="credential__gray-text">
              <div className="credential__copy-text">{didText}</div>
              <div aria-label="close" className="credential__copy">
                <ClickAwayListener onClickAway={handleTooltipClose}>
                  <div>
                    <Tooltip
                      onClose={handleTooltipClose}
                      arrow
                      open={openTooltip}
                      placement="left"
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      title="Copied"
                    >
                      <div
                        className="credential__copy--btn"
                        onClick={() => {
                          handleTooltipOpen(didText)
                        }}
                      >
                        <IconCopy className="credential__copy--icon" />
                        <span className="credential__copy--text">Copy</span>
                      </div>
                    </Tooltip>
                  </div>
                </ClickAwayListener>
              </div>
            </div>
            <Button
              aria-label="close"
              onClick={onClose}
              className="credential__close-btn"
            >
              Close
            </Button>
          </div>
        </DialogContent>
      )}
    </Dialog>
  )
}

export default CredentialDetail
