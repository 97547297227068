import { type AppStatusDataState } from 'features/Custom/appOnboard/types'
import React from 'react'
import { type CustomtableType } from '../type'
import RendertTable from '../RenderTable'
import { Link } from 'react-router-dom'
import { DocumentActions } from 'components/pages/AppDetail/components/AppDetailDocuments'
import { PDFColoredIcon } from '@cofinity-x/cofinity-x-portal-shared-components-merged'
import {
  OFFERTYPES,
  type ServiceStatusDataState,
} from 'features/Custom/serviceOnboard/types'
import { useTranslation } from 'react-i18next'

export const DocumentationAndPrivacy = (props: {
  appStatusData: AppStatusDataState | ServiceStatusDataState
  offerTypeId: string
}) => {
  const { t } = useTranslation()
  const ReleventDocData: CustomtableType = {
    head: [
      props.offerTypeId === OFFERTYPES.APP
        ? `${t(
            'content.submissionOverlay.documentationPrivacy.app.relevantDocument'
          )}`
        : `${t(
            'content.submissionOverlay.documentationPrivacy.service.caseStudies'
          )}`,
      '',
    ],
    body: props.appStatusData?.relatedDocuments?.map((eachDoc: any) => [
      <strong>
        {eachDoc.title ??
          `${t('content.submissionOverlay.documentationPrivacy.noTitle')}`}
      </strong>,
      `${
        eachDoc.localizedSummary?.values?.filter(
          (eachLan: any) => eachLan.langCode === 'en'
        )[0].value ??
        `${t('content.submissionOverlay.documentationPrivacy.NoDescription')}`
      }`,
    ]),
  }
  const DocumentData: CustomtableType = {
    head: [
      `${t('content.submissionOverlay.documentationPrivacy.document')}`,
      '',
    ],
    body: props.appStatusData?.relatedDocuments?.map((eachDoc: any) => [
      [
        <PDFColoredIcon height={15} />,
        <strong
          style={{
            textDecoration: 'none',
            color: '#2484C6',
          }}
        >
          {eachDoc?.documentName}
        </strong>,
      ],
      [
        <DocumentActions
          hideIcon={false}
          documentId={eachDoc.id}
          documentName={eachDoc.documentName}
          id={props.appStatusData.id}
          offerTypeId={props.offerTypeId}
        />,
      ],
    ]) ?? [
      `${t('content.submissionOverlay.descriptionFeature.noDocumentFound')}`,
    ],
  }
  const PrivacyPolicyData: CustomtableType = {
    head: [
      `${t('content.submissionOverlay.documentationPrivacy.privacyPolicy')}`,
      '',
    ],
    body: [
      [
        `${t(
          'content.submissionOverlay.documentationPrivacy.app.attributeLinked'
        )}`,
        <strong>
          {props.appStatusData?.offerPrivacyPolicy?.linked?.toString() ??
            `${t(
              'content.submissionOverlay.documentationPrivacy.app.attributeLinkedNotFound'
            )}`}
        </strong>,
      ],
      [
        `${t(
          'content.submissionOverlay.documentationPrivacy.app.attributeNotLinked'
        )}`,
        <strong>
          {props.appStatusData?.offerPrivacyPolicy?.notLinked?.toString() ??
            `${t(
              'content.submissionOverlay.documentationPrivacy.app.attributeNotLinkedNotFound'
            )}`}
        </strong>,
      ],
      [
        `${t(
          'content.submissionOverlay.documentationPrivacy.app.privacyPolicyWebpage'
        )}`,
        <a
          href={props.appStatusData?.offerPrivacyPolicy?.url ?? '#'}
          target="_blank"
          rel="noreferrer"
        >
          <strong
            style={{
              textDecoration: 'none',
              color: '#2484C6',
            }}
          >
            {props.appStatusData?.offerPrivacyPolicy?.url ??
              `${t(
                'content.submissionOverlay.documentationPrivacy.app.privacyPolicyWebpageNotFound'
              )}`}
          </strong>
        </a>,
      ],
    ],
  }

  const ProviderInformationData: CustomtableType = {
    head: [
      `${t(
        'content.submissionOverlay.documentationPrivacy.providerInformation'
      )}`,
      '',
    ],
    body: [
      [
        `${t('content.submissionOverlay.documentationPrivacy.appProvider')}`,
        <strong>{props?.appStatusData?.provider}</strong>,
      ],
      [
        `${t('content.submissionOverlay.documentationPrivacy.website')}`,
        <a
          href={`https://${
            props?.appStatusData?.providerUri &&
            props?.appStatusData?.providerUri?.split('https://')?.length > 1
              ? props?.appStatusData?.providerUri?.split('https://')[1]
              : props?.appStatusData?.providerUri ?? '#'
          }`}
          target="_blank"
          rel="noreferrer"
        >
          <strong
            style={{
              color: '#2484C6',
            }}
          >
            {props?.appStatusData?.providerUri}
          </strong>
        </a>,
      ],
      [
        `${t('content.submissionOverlay.documentationPrivacy.email')}`,
        <a
          href={
            props.appStatusData.contactEmail
              ? `mailto:${props.appStatusData.contactEmail}`
              : '#'
          }
          style={{
            color: '#2484C6',
            fontWeight: '600',
          }}
        >
          {props.appStatusData.contactEmail ?? (
            <strong
              style={{
                color: 'red',
              }}
            >
              {t(
                'content.submissionOverlay.documentationPrivacy.emailNotFound'
              )}
            </strong>
          )}
        </a>,
      ],
      [
        `${t('content.submissionOverlay.documentationPrivacy.phone')}`,
        <strong>{props.appStatusData.contactNumber}</strong>,
      ],
    ],
  }

  return (
    <div className="appdetail-privacy">
      <div className="appdetail-privacy-table">
        <RendertTable key={'ReleventDocData'} data={ReleventDocData || []} />
        <RendertTable key={'DocumentData'} data={DocumentData || []} />
        {props.offerTypeId === OFFERTYPES.APP ? (
          <RendertTable
            key={'PrivacyPolicyData'}
            data={PrivacyPolicyData || []}
          />
        ) : null}
        <RendertTable
          key={'ProviderInformationData'}
          data={ProviderInformationData || []}
        />
      </div>
    </div>
  )
}
