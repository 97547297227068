import React, { useEffect, useState } from 'react'
import './TabTextareaComponent.scss'
import { Controller, type UseFormTrigger } from 'react-hook-form'
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import { Typography } from '@cofinity-x/cofinity-x-portal-shared-components-merged'

interface TabTextareaProps {
  tabs: string[]
  control: any
  trigger?: UseFormTrigger<any>
  errors: any
  name: string
  rules?: any
  getValues?: any
  errorMessage: string
}

export const TabTextareaComponent: React.FC<TabTextareaProps> = ({
  tabs,
  control,
  errors,
  rules,
  name,
  getValues,
  errorMessage,
}) => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0])
  const [isTouched, setTouched] = useState<string>()

  const handleTabChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    tabId: string
  ) => {
    event.preventDefault()
    setActiveTab(tabId)
  }

  return (
    <div className="tab-container">
      {tabs?.map((tab, index) => (
        <button
          key={tab}
          className={`tab-btn ${activeTab === tab ? 'selected' : ''}`}
          onClick={(event) => {
            handleTabChange(event, tab)
          }}
          style={{
            borderColor: errors?.[name]?.[index]?.[tab] ? '#d32f2f' : '#a6a6a6',
            textTransform: 'uppercase',
          }}
        >
          {tab}
          {getValues()?.[name]?.[index]?.[tab]?.shortDescription?.length <
          rules.minLength ? (
            <CloseIcon
              fontSize="inherit"
              htmlColor={isTouched === tab ? 'red' : 'grey'}
              sx={{
                fontWeight: 'bold',
              }}
            />
          ) : (
            <CheckIcon
              fontSize="inherit"
              htmlColor="green"
              sx={{
                fontWeight: 'bold',
              }}
            />
          )}
        </button>
      ))}

      {tabs?.map((tab, index) => (
        <div
          key={tab}
          id={tab}
          className={`tab-content ${activeTab === tab ? 'active' : ''}`}
        >
          <Controller
            name={`${name}.${index}.${tab}.shortDescription` as const}
            control={control}
            rules={rules}
            render={({ field }) => {
              return (
                <div>
                  <textarea
                    {...field}
                    id={'_' + index.toLocaleString()}
                    onFocus={(e) => {
                      setTouched(tab)
                    }}
                    rows={5}
                    minLength={4}
                    maxLength={250}
                    placeholder={`Please enter role description for ${tab}`}
                    style={{
                      borderColor: errors?.[name]?.[index]?.[tab]
                        ? '#d32f2f'
                        : '#a6a6a6',
                    }}
                  />
                  <div
                    className="form-field"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    {activeTab === tab &&
                    isTouched === activeTab &&
                    Object.keys(errors).length ? (
                      <Typography
                        variant="helper"
                        color="#d32f2f"
                        fontSize={'14px'}
                      >
                        {errorMessage}
                      </Typography>
                    ) : (
                      <Typography variant="helper"></Typography>
                    )}
                    {activeTab === tab && (
                      <Typography variant="helper" fontSize={'14px'}>
                        {
                          getValues()[name][index][tab]?.shortDescription
                            ?.length
                        }
                        /{rules.maxLength}
                      </Typography>
                    )}
                  </div>
                </div>
              )
            }}
          />
        </div>
      ))}
    </div>
  )
}

export const ReadOnlyTabs = ({
  tabs,
}: {
  tabs: { tabname: string; description: string }[]
}): JSX.Element => {
  const [activeTab, setActiveTab] = useState<string>(tabs[0].tabname)

  const handleTabChange = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    tabId: string
  ) => {
    event.preventDefault()
    setActiveTab(tabId)
  }
  return (
    <div className="tab-container">
      {tabs.map((tab, index) => (
        <button
          key={tab.tabname}
          className={`tab-btn ${activeTab === tab.tabname ? 'selected' : ''}`}
          onClick={(event) => {
            handleTabChange(event, tab.tabname)
          }}
          style={{
            textTransform: 'uppercase',
          }}
        >
          {tab.tabname}
          <CheckIcon
            fontSize="inherit"
            htmlColor="green"
            sx={{
              fontWeight: 'bold',
            }}
          />
        </button>
      ))}
      {tabs.map((tab, index) => (
        <div
          key={index}
          id={tab.tabname}
          className={`tab-content ${activeTab === tab.tabname ? 'active' : ''}`}
        >
          <div>
            <textarea
              style={{
                userSelect: 'none',
              }}
              id={'_' + index.toLocaleString()}
              rows={5}
              disabled
              value={tab.description}
            />
          </div>
        </div>
      ))}
    </div>
  )
}
